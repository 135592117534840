import { z } from 'zod';
import { UserCreateWithoutPodcastSubscriptionsInputObjectSchema } from './UserCreateWithoutPodcastSubscriptionsInput.schema';
import { UserUncheckedCreateWithoutPodcastSubscriptionsInputObjectSchema } from './UserUncheckedCreateWithoutPodcastSubscriptionsInput.schema';
import { UserCreateOrConnectWithoutPodcastSubscriptionsInputObjectSchema } from './UserCreateOrConnectWithoutPodcastSubscriptionsInput.schema';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUncheckedCreateNestedManyWithoutPodcastSubscriptionsInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(
              () => UserCreateWithoutPodcastSubscriptionsInputObjectSchema
            ),
            z
              .lazy(
                () => UserCreateWithoutPodcastSubscriptionsInputObjectSchema
              )
              .array(),
            z.lazy(
              () =>
                UserUncheckedCreateWithoutPodcastSubscriptionsInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  UserUncheckedCreateWithoutPodcastSubscriptionsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(
              () =>
                UserCreateOrConnectWithoutPodcastSubscriptionsInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  UserCreateOrConnectWithoutPodcastSubscriptionsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const UserUncheckedCreateNestedManyWithoutPodcastSubscriptionsInputObjectSchema =
  Schema;
