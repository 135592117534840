import { z } from 'zod';
import { UserCreateWithoutPodcastsInputObjectSchema } from './UserCreateWithoutPodcastsInput.schema';
import { UserUncheckedCreateWithoutPodcastsInputObjectSchema } from './UserUncheckedCreateWithoutPodcastsInput.schema';
import { UserCreateOrConnectWithoutPodcastsInputObjectSchema } from './UserCreateOrConnectWithoutPodcastsInput.schema';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserCreateNestedManyWithoutPodcastsInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => UserCreateWithoutPodcastsInputObjectSchema),
            z.lazy(() => UserCreateWithoutPodcastsInputObjectSchema).array(),
            z.lazy(() => UserUncheckedCreateWithoutPodcastsInputObjectSchema),
            z
              .lazy(() => UserUncheckedCreateWithoutPodcastsInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(() => UserCreateOrConnectWithoutPodcastsInputObjectSchema),
            z
              .lazy(() => UserCreateOrConnectWithoutPodcastsInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const UserCreateNestedManyWithoutPodcastsInputObjectSchema = Schema;
