import { z } from 'zod';

export const PodcastScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'publishedAt',
  'importedAt',
  'status',
  'statusReason',
  'avatarUrl',
  'isOriginal',
  'isExplicit',
  'title',
  'description',
  'websiteUrl',
  'socialMedia',
]);
