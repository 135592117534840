import { z } from 'zod';
import { TokenWhereInputObjectSchema } from './objects/TokenWhereInput.schema';
import { TokenOrderByWithAggregationInputObjectSchema } from './objects/TokenOrderByWithAggregationInput.schema';
import { TokenScalarWhereWithAggregatesInputObjectSchema } from './objects/TokenScalarWhereWithAggregatesInput.schema';
import { TokenScalarFieldEnumSchema } from './enums/TokenScalarFieldEnum.schema';

export const TokenGroupBySchema = z.object({
  where: TokenWhereInputObjectSchema.optional(),
  orderBy: z
    .union([
      TokenOrderByWithAggregationInputObjectSchema,
      TokenOrderByWithAggregationInputObjectSchema.array(),
    ])
    .optional(),
  having: TokenScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(TokenScalarFieldEnumSchema),
});
