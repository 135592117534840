import { Badge, Heading } from '@chakra-ui/react';
import { TRPCUseQueryBaseOptions } from '@trpc/react-query/shared';
import { MdStars, MdWeb } from 'react-icons/md';
import { z } from 'zod';

import { UserFindManySchema } from '@theqube/schemas';
import { HTMLRenderer, Link } from '../../components';
import { PageHero } from '../../components/heros/page';
import { StudioRoutes, trpc, useAnalytics } from '../../utils';
import { Detail, Details } from './details';
import { TagList } from './tag-list';
import { EpisodeWindow } from '../episodes/window';
import { useEffect } from 'react';

type PodcastHeaderProps = { podcastId: number };

export function PodcastHeader({ podcastId }: PodcastHeaderProps) {
  const { gtag } = useAnalytics();
  const { data: podcast, isSuccess } = trpc.podcasts.findOne.useQuery(
    { where: podcastId ? { id: +podcastId } : { id: 0 } },
    { enabled: Boolean(podcastId) }
  );

  useEffect(() => {
    if (gtag && podcast) {
      gtag('event', 'view_item', {
        item_id: podcast.id,
        item_name: podcast.title,
      });
    }
  }, [gtag, podcast]);

  return (
    <PageHero
      isLoaded={isSuccess}
      thumbnail={{ src: podcast?.avatarUrl ?? undefined }}
    >
      {podcast ? (
        <>
          <Heading my="4">{podcast.title}</Heading>

          <TagList
            query={{ where: { podcasts: { some: { id: podcast.id } } } }}
          />

          <HTMLRenderer html={podcast.description} />

          <Details my="12" p="2.5%">
            {podcast.isOriginal ? (
              <Detail icon={MdStars}>
                <Badge>Original</Badge>
              </Detail>
            ) : null}

            <OwnersList
              query={{ where: { podcasts: { some: { id: podcast.id } } } }}
            />

            {podcast.websiteUrl ? (
              <Detail icon={MdWeb}>
                <a href={podcast.websiteUrl}>{podcast.websiteUrl}</a>
              </Detail>
            ) : null}
          </Details>

          <Heading>
            {podcast?._count.episodes.toString().padStart(2, '0') ?? '00'}{' '}
            Episodes
          </Heading>

          <EpisodeWindow
            query={{ where: { podcastId: podcast.id } }}
            config={{
              loader: undefined,
              resizer: {},
              window: { itemSize: 200, layout: 'vertical' },
            }}
          />
        </>
      ) : null}
    </PageHero>
  );
}

//           <EpisodeList query={{ where: { podcastId: podcast.id } }} />

type OwnersListProps = {
  query: z.infer<typeof UserFindManySchema>;
  options?: TRPCUseQueryBaseOptions;
};

function OwnersList({ query, options }: OwnersListProps) {
  const { data: owners } = trpc.users.findMany.useQuery(query, options);

  return (
    <section>
      {owners?.map((owner) => (
        <Detail key={owner.id}>
          <Link
            href={
              StudioRoutes.discover.children?.users.children?.id.href(
                owner.id
              ) ?? ''
            }
          >
            {owner.displayName ?? owner.fullName}
          </Link>
        </Detail>
      )) ?? null}
    </section>
  );
}
