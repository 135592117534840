import * as z from "zod"
import { CompleteUser, RelatedUserSchema, CompleteEpisode, RelatedEpisodeSchema } from "./index"

export const PlaylistSchema = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  avatarUrl: z.string().nullish(),
  title: z.string(),
  description: z.string(),
})

export interface CompletePlaylist extends z.infer<typeof PlaylistSchema> {
  owners: CompleteUser[]
  subscribers: CompleteUser[]
  episodes: CompleteEpisode[]
}

/**
 * RelatedPlaylistSchema contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedPlaylistSchema: z.ZodSchema<CompletePlaylist> = z.lazy(() => PlaylistSchema.extend({
  owners: RelatedUserSchema.array(),
  subscribers: RelatedUserSchema.array(),
  episodes: RelatedEpisodeSchema.array(),
}))
