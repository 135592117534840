import { z } from 'zod';
import { TagWhereUniqueInputObjectSchema } from './TagWhereUniqueInput.schema';
import { TagUpdateWithoutPodcastsInputObjectSchema } from './TagUpdateWithoutPodcastsInput.schema';
import { TagUncheckedUpdateWithoutPodcastsInputObjectSchema } from './TagUncheckedUpdateWithoutPodcastsInput.schema';
import { TagCreateWithoutPodcastsInputObjectSchema } from './TagCreateWithoutPodcastsInput.schema';
import { TagUncheckedCreateWithoutPodcastsInputObjectSchema } from './TagUncheckedCreateWithoutPodcastsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.TagUpsertWithWhereUniqueWithoutPodcastsInput> = z
  .object({
    where: z.lazy(() => TagWhereUniqueInputObjectSchema),
    update: z.union([
      z.lazy(() => TagUpdateWithoutPodcastsInputObjectSchema),
      z.lazy(() => TagUncheckedUpdateWithoutPodcastsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => TagCreateWithoutPodcastsInputObjectSchema),
      z.lazy(() => TagUncheckedCreateWithoutPodcastsInputObjectSchema),
    ]),
  })
  .strict();

export const TagUpsertWithWhereUniqueWithoutPodcastsInputObjectSchema = Schema;
