import { z } from 'zod';
import { TokenWhereInputObjectSchema } from './objects/TokenWhereInput.schema';
import { TokenOrderByWithRelationInputObjectSchema } from './objects/TokenOrderByWithRelationInput.schema';
import { TokenWhereUniqueInputObjectSchema } from './objects/TokenWhereUniqueInput.schema';
import { TokenCountAggregateInputObjectSchema } from './objects/TokenCountAggregateInput.schema';
import { TokenMinAggregateInputObjectSchema } from './objects/TokenMinAggregateInput.schema';
import { TokenMaxAggregateInputObjectSchema } from './objects/TokenMaxAggregateInput.schema';

export const TokenAggregateSchema = z.object({
  where: TokenWhereInputObjectSchema.optional(),
  orderBy: z
    .union([
      TokenOrderByWithRelationInputObjectSchema,
      TokenOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  cursor: TokenWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), TokenCountAggregateInputObjectSchema])
    .optional(),
  _min: TokenMinAggregateInputObjectSchema.optional(),
  _max: TokenMaxAggregateInputObjectSchema.optional(),
});
