import { z } from 'zod';
import { EpisodeWhereUniqueInputObjectSchema } from './objects/EpisodeWhereUniqueInput.schema';
import { EpisodeCreateInputObjectSchema } from './objects/EpisodeCreateInput.schema';
import { EpisodeUpdateInputObjectSchema } from './objects/EpisodeUpdateInput.schema';

export const EpisodeUpsertSchema = z.object({
  where: EpisodeWhereUniqueInputObjectSchema,
  create: EpisodeCreateInputObjectSchema,
  update: EpisodeUpdateInputObjectSchema,
});
