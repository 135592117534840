import { z } from 'zod';
import { EpisodeCreateWithoutPlaylistsInputObjectSchema } from './EpisodeCreateWithoutPlaylistsInput.schema';
import { EpisodeUncheckedCreateWithoutPlaylistsInputObjectSchema } from './EpisodeUncheckedCreateWithoutPlaylistsInput.schema';
import { EpisodeCreateOrConnectWithoutPlaylistsInputObjectSchema } from './EpisodeCreateOrConnectWithoutPlaylistsInput.schema';
import { EpisodeWhereUniqueInputObjectSchema } from './EpisodeWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.EpisodeUncheckedCreateNestedManyWithoutPlaylistsInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => EpisodeCreateWithoutPlaylistsInputObjectSchema),
            z
              .lazy(() => EpisodeCreateWithoutPlaylistsInputObjectSchema)
              .array(),
            z.lazy(
              () => EpisodeUncheckedCreateWithoutPlaylistsInputObjectSchema
            ),
            z
              .lazy(
                () => EpisodeUncheckedCreateWithoutPlaylistsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(
              () => EpisodeCreateOrConnectWithoutPlaylistsInputObjectSchema
            ),
            z
              .lazy(
                () => EpisodeCreateOrConnectWithoutPlaylistsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema),
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const EpisodeUncheckedCreateNestedManyWithoutPlaylistsInputObjectSchema =
  Schema;
