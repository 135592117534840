import { Flex, Stack, StackProps, Text } from '@chakra-ui/react';

import { RainbowWaveForm, PageHeading } from '../atoms';

type PrimaryHeroProps = {
  title: string | React.ReactNode;
  description?: string;
  bgImage?: string;
  containerProps?: StackProps;
  actions?: React.ReactNode;
  children?: React.ReactNode;
};

export function PrimaryHero({
  title,
  description,
  bgImage,
  containerProps = {},
  actions,
  children,
}: PrimaryHeroProps) {
  const heroImage = `url(${bgImage})`;

  return (
    <Flex
      as="header"
      mx="auto"
      mt={-150}
      mb="24"
      px="5%"
      minHeight="100vh"
      position="relative"
      backgroundImage={heroImage}
      backgroundSize="cover"
      backgroundPosition="center"
      {...containerProps}
    >
      <Stack
        width="100%"
        align="left"
        justify="center"
        spacing={4}
        {...containerProps}
      >
        <PageHeading maxInlineSize="25ch">{title}</PageHeading>

        {description && (
          <Text maxW="45ch" fontSize={{ base: 'lg', sm: '2xl', lg: '3xl' }}>
            {description}
          </Text>
        )}

        <Stack align="center" spacing={6} direction="row" my="12">
          {actions}
        </Stack>

        {children}

        <RainbowWaveForm
          position="absolute"
          bottom={{
            base: -10,
            sm: -60,
            md: -85,
            lg: -110,
            xl: -150,
            '2xl': -210,
            '3xl': -280,
          }}
          left="0"
          right="0"
        />
      </Stack>
    </Flex>
  );
}
