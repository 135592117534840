import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
} from '@chakra-ui/react';
import {
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  Path,
  PathValue,
  UnPackAsyncDefaultValues,
  useController,
  useFormContext,
} from 'react-hook-form';

import { FormField, FormFieldProps } from '../helpers';

export type CheckboxProps<
  FormData extends FieldValues = FieldValues,
  Name extends FieldPath<FormData> = Path<UnPackAsyncDefaultValues<FormData>>
> = Omit<FormFieldProps<FormData, Name>, 'render'> & {
  input?: ChakraCheckboxProps;
};

const checkboxInputContainerStyle = {
  order: -1,
  flexBasis: 'auto',
  width: 'auto',
  flexGrow: 0,
  flexShrink: 1,
  bg: 'transparent',
  alignItems: 'flex-start',
  mr: 2,
};

export function Checkbox<
  FormData extends FieldValues = FieldValues,
  Name extends FieldPath<FormData> = Path<UnPackAsyncDefaultValues<FormData>>
>(props: CheckboxProps<FormData, Name>) {
  const { input, ...rest } = props;

  const makeOnChangeHandler =
    (field: ControllerRenderProps<FormData, Name>) =>
    (e: React.ChangeEvent<HTMLInputElement>) =>
      field.onChange(e);

  return (
    <FormField
      {...rest}
      layout={rest.layout ?? 'inline'}
      containerStyle={{ my: '4', ...rest.containerStyle }}
      labelStyle={{ flexGrow: 1, ...rest.labelStyle }}
      inputContainerStyle={{
        ...checkboxInputContainerStyle,
        ...rest.inputContainerStyle,
      }}
      render={({ field }) => (
        <ChakraCheckbox
          {...field}
          {...input}
          isChecked={field.value}
          onChange={makeOnChangeHandler(field)}
          colorScheme="themeGold"
          borderColor="themeGold.500"
        />
      )}
    />
  );
}

/**
 * Alternate version of a checkbox used to capture the date it was changed.
 * Useful for terms of service and privacy policy type arguments.
 */
export function DatedCheckbox<
  FormData extends FieldValues = FieldValues,
  Name extends FieldPath<FormData> = FieldPath<FormData>
>(
  props: CheckboxProps<FormData, Name> & {
    /** Set the empty value, defaults to false */
    whenEmpty?: unknown;
  }
) {
  const { input, name, whenEmpty = false, ...rest } = props;

  const context = useFormContext<FormData>();

  const { field } = useController<FormData, Name>({
    control: context?.control,
    name: props.name as Name,
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      context.setValue(
        name,
        new Date() as PathValue<UnPackAsyncDefaultValues<FormData>, Name>
      );
    } else {
      context.setValue(
        name,
        whenEmpty as PathValue<UnPackAsyncDefaultValues<FormData>, Name>
      );
    }
  };

  return (
    <FormField
      {...rest}
      name={name}
      layout={rest.layout ?? 'inline'}
      containerStyle={{ my: '4', ...rest.containerStyle }}
      labelStyle={{ flexGrow: 1, ...rest.labelStyle }}
      inputContainerStyle={{
        ...checkboxInputContainerStyle,
        ...rest.inputContainerStyle,
      }}
      render={() => (
        <ChakraCheckbox
          {...field}
          {...input}
          value={String(Boolean(field.value))}
          isChecked={Boolean(field.value)}
          onChange={onChangeHandler}
          colorScheme="themeGold"
          borderColor="themeGold.500"
        />
      )}
    />
  );
}
