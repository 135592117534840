import { z } from 'zod';
import { CurationWhereInputObjectSchema } from './objects/CurationWhereInput.schema';
import { CurationOrderByWithRelationInputObjectSchema } from './objects/CurationOrderByWithRelationInput.schema';
import { CurationWhereUniqueInputObjectSchema } from './objects/CurationWhereUniqueInput.schema';
import { CurationScalarFieldEnumSchema } from './enums/CurationScalarFieldEnum.schema';

export const CurationFindFirstSchema = z.object({
  where: CurationWhereInputObjectSchema.optional(),
  orderBy: z
    .union([
      CurationOrderByWithRelationInputObjectSchema,
      CurationOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  cursor: CurationWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(CurationScalarFieldEnumSchema).optional(),
});
