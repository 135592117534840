import { z } from 'zod';

export const EpisodeScalarFieldEnumSchema = z.enum([
  'id',
  'updatedAt',
  'publishedAt',
  'podcastId',
  'title',
  'description',
  'imageUrl',
  'audioUrl',
  'duration',
  'explicit',
]);
