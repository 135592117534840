import { z } from 'zod';
import { UserCreateWithoutPodcastsInputObjectSchema } from './UserCreateWithoutPodcastsInput.schema';
import { UserUncheckedCreateWithoutPodcastsInputObjectSchema } from './UserUncheckedCreateWithoutPodcastsInput.schema';
import { UserCreateOrConnectWithoutPodcastsInputObjectSchema } from './UserCreateOrConnectWithoutPodcastsInput.schema';
import { UserUpsertWithWhereUniqueWithoutPodcastsInputObjectSchema } from './UserUpsertWithWhereUniqueWithoutPodcastsInput.schema';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserUpdateWithWhereUniqueWithoutPodcastsInputObjectSchema } from './UserUpdateWithWhereUniqueWithoutPodcastsInput.schema';
import { UserUpdateManyWithWhereWithoutPodcastsInputObjectSchema } from './UserUpdateManyWithWhereWithoutPodcastsInput.schema';
import { UserScalarWhereInputObjectSchema } from './UserScalarWhereInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUncheckedUpdateManyWithoutPodcastsNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => UserCreateWithoutPodcastsInputObjectSchema),
            z.lazy(() => UserCreateWithoutPodcastsInputObjectSchema).array(),
            z.lazy(() => UserUncheckedCreateWithoutPodcastsInputObjectSchema),
            z
              .lazy(() => UserUncheckedCreateWithoutPodcastsInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(() => UserCreateOrConnectWithoutPodcastsInputObjectSchema),
            z
              .lazy(() => UserCreateOrConnectWithoutPodcastsInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .union([
            z.lazy(
              () => UserUpsertWithWhereUniqueWithoutPodcastsInputObjectSchema
            ),
            z
              .lazy(
                () => UserUpsertWithWhereUniqueWithoutPodcastsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        set: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        disconnect: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        delete: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(
              () => UserUpdateWithWhereUniqueWithoutPodcastsInputObjectSchema
            ),
            z
              .lazy(
                () => UserUpdateWithWhereUniqueWithoutPodcastsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        updateMany: z
          .union([
            z.lazy(
              () => UserUpdateManyWithWhereWithoutPodcastsInputObjectSchema
            ),
            z
              .lazy(
                () => UserUpdateManyWithWhereWithoutPodcastsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        deleteMany: z
          .union([
            z.lazy(() => UserScalarWhereInputObjectSchema),
            z.lazy(() => UserScalarWhereInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const UserUncheckedUpdateManyWithoutPodcastsNestedInputObjectSchema =
  Schema;
