import { z } from 'zod';
import { UserCreateWithoutPlaylistSubscriptionsInputObjectSchema } from './UserCreateWithoutPlaylistSubscriptionsInput.schema';
import { UserUncheckedCreateWithoutPlaylistSubscriptionsInputObjectSchema } from './UserUncheckedCreateWithoutPlaylistSubscriptionsInput.schema';
import { UserCreateOrConnectWithoutPlaylistSubscriptionsInputObjectSchema } from './UserCreateOrConnectWithoutPlaylistSubscriptionsInput.schema';
import { UserUpsertWithWhereUniqueWithoutPlaylistSubscriptionsInputObjectSchema } from './UserUpsertWithWhereUniqueWithoutPlaylistSubscriptionsInput.schema';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserUpdateWithWhereUniqueWithoutPlaylistSubscriptionsInputObjectSchema } from './UserUpdateWithWhereUniqueWithoutPlaylistSubscriptionsInput.schema';
import { UserUpdateManyWithWhereWithoutPlaylistSubscriptionsInputObjectSchema } from './UserUpdateManyWithWhereWithoutPlaylistSubscriptionsInput.schema';
import { UserScalarWhereInputObjectSchema } from './UserScalarWhereInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUpdateManyWithoutPlaylistSubscriptionsNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(
              () => UserCreateWithoutPlaylistSubscriptionsInputObjectSchema
            ),
            z
              .lazy(
                () => UserCreateWithoutPlaylistSubscriptionsInputObjectSchema
              )
              .array(),
            z.lazy(
              () =>
                UserUncheckedCreateWithoutPlaylistSubscriptionsInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  UserUncheckedCreateWithoutPlaylistSubscriptionsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(
              () =>
                UserCreateOrConnectWithoutPlaylistSubscriptionsInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  UserCreateOrConnectWithoutPlaylistSubscriptionsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .union([
            z.lazy(
              () =>
                UserUpsertWithWhereUniqueWithoutPlaylistSubscriptionsInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  UserUpsertWithWhereUniqueWithoutPlaylistSubscriptionsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        set: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        disconnect: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        delete: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(
              () =>
                UserUpdateWithWhereUniqueWithoutPlaylistSubscriptionsInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  UserUpdateWithWhereUniqueWithoutPlaylistSubscriptionsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        updateMany: z
          .union([
            z.lazy(
              () =>
                UserUpdateManyWithWhereWithoutPlaylistSubscriptionsInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  UserUpdateManyWithWhereWithoutPlaylistSubscriptionsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        deleteMany: z
          .union([
            z.lazy(() => UserScalarWhereInputObjectSchema),
            z.lazy(() => UserScalarWhereInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const UserUpdateManyWithoutPlaylistSubscriptionsNestedInputObjectSchema =
  Schema;
