import { z } from 'zod';
import { TokenWhereInputObjectSchema } from './objects/TokenWhereInput.schema';
import { TokenOrderByWithRelationInputObjectSchema } from './objects/TokenOrderByWithRelationInput.schema';
import { TokenWhereUniqueInputObjectSchema } from './objects/TokenWhereUniqueInput.schema';
import { TokenScalarFieldEnumSchema } from './enums/TokenScalarFieldEnum.schema';

export const TokenFindFirstSchema = z.object({
  where: TokenWhereInputObjectSchema.optional(),
  orderBy: z
    .union([
      TokenOrderByWithRelationInputObjectSchema,
      TokenOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  cursor: TokenWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(TokenScalarFieldEnumSchema).optional(),
});
