import { Select, Spinner } from '@chakra-ui/react';
import { TagType } from '@theqube/schemas';
import { ControllerRenderProps } from 'react-hook-form';
import MultiSelect from 'react-select';

import { trpc } from '../../../utils';
import { ErrorWrapper } from '../../atoms';

type TagSelectProps = ControllerRenderProps & {
  tagType: TagType;
};

export function TagSelect(props: TagSelectProps) {
  const { tagType, ...field } = props;

  const {
    data: tags,
    isLoading,
    error,
    refetch,
  } = trpc.tags.findMany.useQuery({
    where: { type: tagType },
    orderBy: { name: 'desc' },
  });

  return (
    <Select fontSize="lg" placeholder={`Select a ${tagType}`} {...field}>
      {isLoading ? <Spinner /> : null}
      {tags?.map(({ id, name, slug }) => (
        <option key={id} value={slug}>
          {name}
        </option>
      )) ?? <option disabled>No tags loaded...</option>}

      {error ? <ErrorWrapper error={error} retry={refetch} /> : null}
    </Select>
  );
}

export function TagMultiSelect({
  tagType,
  ...props
}: ControllerRenderProps & { tagType: TagType }) {
  const query = trpc.tags.findMany.useQuery({
    where: { type: tagType },
    orderBy: { name: 'desc' },
  });

  const options =
    query.data?.map((tag) => ({ label: tag.name, value: tag.slug })) ?? [];

  return (
    <MultiSelect
      isMulti
      placeholder="Select tags"
      isLoading={query.isLoading}
      options={options}
      {...props}
    />
  );
}
