/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';
import AutoSizer, { AutoSizerProps } from 'react-virtualized-auto-sizer';
import {
  CommonProps,
  FixedSizeGrid as Grid,
  FixedSizeGridProps,
  FixedSizeList as List,
  FixedSizeListProps,
} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

export type ExtractExternalWindowProps<
  WindowProps extends CommonProps = CommonProps
> = Omit<WindowProps, 'onItemsRendered' | 'ref' | 'height' | 'width'>;

export type WindowLayout = 'grid' | 'list';

export type WindowLayoutProps<Layout extends WindowLayout> =
  Layout extends 'list' ? FixedSizeListProps : FixedSizeGridProps;

export type WindowProps<
  Layout extends WindowLayout,
  WindowConfig = ExtractExternalWindowProps<WindowLayoutProps<Layout>>,
  Loader = Omit<InfiniteLoaderProps, 'children'>,
  Resizer = Omit<AutoSizerProps, 'children'>
> = {
  resizer: Resizer;
  loader: Loader;
  window: WindowConfig;
};

export type WindowConfigProps<Layout extends WindowLayout> = WindowProps<
  // List
  Layout,
  // Window
  ExtractExternalWindowProps<
    Omit<WindowLayoutProps<Layout>, 'children' | 'itemCount'>
  > &
    (Layout extends 'list'
      ? Partial<Pick<WindowLayoutProps<Layout>, 'height' | 'width'>>
      : // eslint-disable-next-line @typescript-eslint/ban-types
        {}),
  // Loader
  | Omit<
      InfiniteLoaderProps,
      'children' | 'itemCount' | 'isItemLoaded' | 'loadMoreItems'
    >
  | undefined
  // Resizer
>;

/**
 * A VirtualizedList component powered by `react-window`
 *
 * Use the `loader` options to configure how data is loaded.
 * Use the `resizer` options to configure how the list is resized according to it's parent.
 * Use the `window`  options to configure how the list is rendered.
 *
 * @note Currently, only `FixedSizeList` is supported
 *
 *  - see [react-window](https://github.com/bvaughn/react-window) for more information.
 *  - either know or define the width of your internal components beforehand.
 */
export function ListWindow({ loader, resizer, window }: WindowProps<'list'>) {
  return (
    <AutoSizer {...resizer}>
      {({ height, width }) => (
        <InfiniteLoader {...loader}>
          {({ onItemsRendered, ref }) => (
            <List
              ref={ref}
              onItemsRendered={onItemsRendered}
              height={height}
              width={width}
              {...window}
            />
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
}

export function GridWindow({ loader, resizer, window }: WindowProps<'grid'>) {
  return (
    <InfiniteLoader {...loader}>
      {({ onItemsRendered, ref }) => (
        <AutoSizer {...resizer}>
          {({ height, width }) => (
            <Grid
              ref={ref}
              // @ts-expect-error -- the types are a bit funky here, pulling only lists instead of list or grid, should still work
              onItemsRendered={onItemsRendered}
              height={height}
              width={width}
              {...window}
            />
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
}

type OnItemsRendered = (props: CommonProps) => any;

/** Copy/Paste from @types/react-window-infinite-loader */
interface InfiniteLoaderProps {
  isItemLoaded: (index: number) => boolean;
  loadMoreItems: (
    startIndex: number,
    stopIndex: number
  ) => Promise<void> | void;
  itemCount: number;

  children: (props: {
    onItemsRendered: OnItemsRendered;
    ref: (ref: any) => void;
  }) => ReactNode;
  threshold?: number | undefined;
  minimumBatchSize?: number | undefined;
}
