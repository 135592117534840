import { z } from 'zod';
import { UserCreateWithoutTagsInputObjectSchema } from './UserCreateWithoutTagsInput.schema';
import { UserUncheckedCreateWithoutTagsInputObjectSchema } from './UserUncheckedCreateWithoutTagsInput.schema';
import { UserCreateOrConnectWithoutTagsInputObjectSchema } from './UserCreateOrConnectWithoutTagsInput.schema';
import { UserUpsertWithWhereUniqueWithoutTagsInputObjectSchema } from './UserUpsertWithWhereUniqueWithoutTagsInput.schema';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserUpdateWithWhereUniqueWithoutTagsInputObjectSchema } from './UserUpdateWithWhereUniqueWithoutTagsInput.schema';
import { UserUpdateManyWithWhereWithoutTagsInputObjectSchema } from './UserUpdateManyWithWhereWithoutTagsInput.schema';
import { UserScalarWhereInputObjectSchema } from './UserScalarWhereInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUncheckedUpdateManyWithoutTagsNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => UserCreateWithoutTagsInputObjectSchema),
            z.lazy(() => UserCreateWithoutTagsInputObjectSchema).array(),
            z.lazy(() => UserUncheckedCreateWithoutTagsInputObjectSchema),
            z
              .lazy(() => UserUncheckedCreateWithoutTagsInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(() => UserCreateOrConnectWithoutTagsInputObjectSchema),
            z
              .lazy(() => UserCreateOrConnectWithoutTagsInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .union([
            z.lazy(() => UserUpsertWithWhereUniqueWithoutTagsInputObjectSchema),
            z
              .lazy(() => UserUpsertWithWhereUniqueWithoutTagsInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        set: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        disconnect: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        delete: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(() => UserUpdateWithWhereUniqueWithoutTagsInputObjectSchema),
            z
              .lazy(() => UserUpdateWithWhereUniqueWithoutTagsInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        updateMany: z
          .union([
            z.lazy(() => UserUpdateManyWithWhereWithoutTagsInputObjectSchema),
            z
              .lazy(() => UserUpdateManyWithWhereWithoutTagsInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        deleteMany: z
          .union([
            z.lazy(() => UserScalarWhereInputObjectSchema),
            z.lazy(() => UserScalarWhereInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const UserUncheckedUpdateManyWithoutTagsNestedInputObjectSchema = Schema;
