import { Button, ButtonGroup, Textarea } from '@chakra-ui/react';
import { z } from 'zod';

import { Episode, EpisodeUpdateOneSchema } from '@theqube/schemas';
import {
  Checkbox,
  FileField,
  Form,
  FormField,
  TextInput,
} from '../../../components';
import { trpc, useZodForm } from '../../../utils';

type FormData = z.infer<typeof EpisodeUpdateOneSchema>;

function makeDefaultValues(initialValues?: Episode): FormData {
  return {
    where: { id: initialValues?.id ?? 0 },

    data: {
      publishedAt: initialValues?.publishedAt ?? '',
      title: initialValues?.title ?? '',
      description: initialValues?.description ?? '',

      explicit: initialValues?.explicit ?? false,
      audioUrl: initialValues?.audioUrl ?? '',
      imageUrl: initialValues?.imageUrl ?? '',

      podcast: { connect: { id: initialValues?.podcastId ?? 0 } },
    },
  };
}

export function EditEpisodeForm(props: { initialValues: Episode }) {
  const { initialValues } = props;

  const { mutateAsync: updateEpisode, ...mutation } =
    trpc.episodes.updateOne.useMutation();

  const form = useZodForm<typeof EpisodeUpdateOneSchema>({
    mode: 'onChange',
    defaultValues: makeDefaultValues(initialValues),
    schema: EpisodeUpdateOneSchema,
  });

  const handleFormSubmit = form.handleSubmit((values) => updateEpisode(values));

  return (
    <Form
      context={form}
      w="75%"
      maxW="lg"
      margin="0 auto"
      onSubmit={handleFormSubmit}
    >
      <FormField
        name="data.isExplicit"
        label="Explicit"
        render={({ field }) => <Checkbox {...field} />}
      />

      <FileField
        name="data.imageUrl"
        label="Attach Header"
        placeholder="Upload a file to attach to the episode"
      />

      <FileField
        name="data.audioUrl"
        label="Attach Audio"
        placeholder="Upload a file to attach to the episode"
      />

      <FormField
        name="data.title"
        label="Title"
        render={({ field }) => (
          <TextInput
            {...field}
            input={{ placeholder: 'The title of the episode' }}
          />
        )}
      />

      <FormField
        name="data.description"
        label="Description"
        render={({ field }) => (
          <Textarea
            {...field}
            placeholder="A short description of the episode"
            cols={30}
            rows={10}
          />
        )}
      />

      <ButtonGroup display="flex" justifyContent="center" my="4">
        <Button
          type="submit"
          isLoading={form.formState.isSubmitting || mutation.isLoading}
          disabled={form.formState.isSubmitting || mutation.isLoading}
          bg={
            mutation.isError || !form.formState.isValid
              ? 'error'
              : 'themeGold.500'
          }
        >
          Submit
        </Button>
      </ButtonGroup>
    </Form>
  );
}
