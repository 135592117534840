import * as React from 'react';

import { Box, HStack, Image, Stack, Text } from '@chakra-ui/react';

import { OriginalTeamSocials } from './socials';

interface OriginalShowMemberProps {
  image: string;
  name: string;
  title: string;
  bio: string;
  socials: Array<{ [key: string]: string }>;
}

export function OriginalShowMember(props: OriginalShowMemberProps) {
  const { image, name, title, bio, socials } = props;

  return (
    <HStack justify="space-between" align="flex-start" py={3}>
      <Box display="flex" alignItems="flex-start">
        <Image
          htmlWidth="200px"
          htmlHeight="200px"
          src={image}
          alt={`Picture of Second Sunday Team member, ${name} - ${title}`}
        />
      </Box>

      <Stack w="80%" justify="flex-start" align="flex-start" p={0} spacing={30}>
        <Text fontWeight="semibold" fontSize="2xl" p={0} lineHeight={1}>
          {name}

          <Box as="span" fontWeight="normal" p={0}>{`, ${title}`}</Box>
        </Text>

        <Text lineHeight={1.5}>{bio}</Text>

        {socials.length > 0 && (
          <HStack spacing={5} width="100%">
            {socials.map((social, idx) => (
              <React.Fragment key={idx}>
                <OriginalTeamSocials
                  instagram={social['instagram']}
                  instagram_link={social['instagram_link']}
                  twitter={social['twitter']}
                  twitter_link={social['twitter_link']}
                  facebook={social['facebook']}
                  facebook_link={social['facebook_link']}
                  web={social['web']}
                />
              </React.Fragment>
            ))}
          </HStack>
        )}

        <Text as="span" fontWeight="normal" p={0}>{`, ${title}`}</Text>

        <Text lineHeight={1.5}>{bio}</Text>
      </Stack>
    </HStack>
  );
}
