import { z } from 'zod';
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema';
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema';
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema';
import { RssFeedUncheckedUpdateOneWithoutPodcastNestedInputObjectSchema } from './RssFeedUncheckedUpdateOneWithoutPodcastNestedInput.schema';
import { TagUncheckedUpdateManyWithoutPodcastsNestedInputObjectSchema } from './TagUncheckedUpdateManyWithoutPodcastsNestedInput.schema';
import { UserUncheckedUpdateManyWithoutPodcastsNestedInputObjectSchema } from './UserUncheckedUpdateManyWithoutPodcastsNestedInput.schema';
import { EpisodeUncheckedUpdateManyWithoutPodcastNestedInputObjectSchema } from './EpisodeUncheckedUpdateManyWithoutPodcastNestedInput.schema';
import { UserUncheckedUpdateManyWithoutPodcastsAssignedNestedInputObjectSchema } from './UserUncheckedUpdateManyWithoutPodcastsAssignedNestedInput.schema';
import { CurationUncheckedUpdateManyWithoutPodcastNestedInputObjectSchema } from './CurationUncheckedUpdateManyWithoutPodcastNestedInput.schema';
import { PodcastStatusSchema } from '../enums/PodcastStatus.schema';
import { EnumPodcastStatusFieldUpdateOperationsInputObjectSchema } from './EnumPodcastStatusFieldUpdateOperationsInput.schema';
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema';
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema';
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema';
import { NullableJsonNullValueInputSchema } from '../enums/NullableJsonNullValueInput.schema';

import type { Prisma } from '@prisma/client';

const literalSchema = z.union([z.string(), z.number(), z.boolean()]);
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ])
);

const Schema: z.ZodType<Prisma.PodcastUncheckedUpdateWithoutSubscribersInput> =
  z
    .object({
      id: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      createdAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      updatedAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      publishedAt: z
        .union([
          z.date(),
          z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      importedAt: z
        .union([
          z.date(),
          z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      rssFeed: z
        .lazy(
          () => RssFeedUncheckedUpdateOneWithoutPodcastNestedInputObjectSchema
        )
        .optional(),
      tags: z
        .lazy(
          () => TagUncheckedUpdateManyWithoutPodcastsNestedInputObjectSchema
        )
        .optional(),
      owners: z
        .lazy(
          () => UserUncheckedUpdateManyWithoutPodcastsNestedInputObjectSchema
        )
        .optional(),
      episodes: z
        .lazy(
          () => EpisodeUncheckedUpdateManyWithoutPodcastNestedInputObjectSchema
        )
        .optional(),
      curators: z
        .lazy(
          () =>
            UserUncheckedUpdateManyWithoutPodcastsAssignedNestedInputObjectSchema
        )
        .optional(),
      curations: z
        .lazy(
          () => CurationUncheckedUpdateManyWithoutPodcastNestedInputObjectSchema
        )
        .optional(),
      status: z
        .union([
          z.lazy(() => PodcastStatusSchema),
          z.lazy(() => EnumPodcastStatusFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      statusReason: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      avatarUrl: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      isOriginal: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      isExplicit: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      title: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      description: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      websiteUrl: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      socialMedia: z
        .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
        .optional(),
    })
    .strict();

export const PodcastUncheckedUpdateWithoutSubscribersInputObjectSchema = Schema;
