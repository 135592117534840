import { z } from 'zod';
import { AdvertisementWhereInputObjectSchema } from './objects/AdvertisementWhereInput.schema';
import { AdvertisementOrderByWithRelationInputObjectSchema } from './objects/AdvertisementOrderByWithRelationInput.schema';
import { AdvertisementWhereUniqueInputObjectSchema } from './objects/AdvertisementWhereUniqueInput.schema';
import { AdvertisementCountAggregateInputObjectSchema } from './objects/AdvertisementCountAggregateInput.schema';
import { AdvertisementMinAggregateInputObjectSchema } from './objects/AdvertisementMinAggregateInput.schema';
import { AdvertisementMaxAggregateInputObjectSchema } from './objects/AdvertisementMaxAggregateInput.schema';
import { AdvertisementAvgAggregateInputObjectSchema } from './objects/AdvertisementAvgAggregateInput.schema';
import { AdvertisementSumAggregateInputObjectSchema } from './objects/AdvertisementSumAggregateInput.schema';

export const AdvertisementAggregateSchema = z.object({
  where: AdvertisementWhereInputObjectSchema.optional(),
  orderBy: z
    .union([
      AdvertisementOrderByWithRelationInputObjectSchema,
      AdvertisementOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  cursor: AdvertisementWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), AdvertisementCountAggregateInputObjectSchema])
    .optional(),
  _min: AdvertisementMinAggregateInputObjectSchema.optional(),
  _max: AdvertisementMaxAggregateInputObjectSchema.optional(),
  _avg: AdvertisementAvgAggregateInputObjectSchema.optional(),
  _sum: AdvertisementSumAggregateInputObjectSchema.optional(),
});
