import { z } from 'zod';
import { UserCreateNestedManyWithoutPlaylistSubscriptionsInputObjectSchema } from './UserCreateNestedManyWithoutPlaylistSubscriptionsInput.schema';
import { EpisodeCreateNestedManyWithoutPlaylistsInputObjectSchema } from './EpisodeCreateNestedManyWithoutPlaylistsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistCreateWithoutOwnersInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    avatarUrl: z.string().optional().nullable(),
    title: z.string(),
    description: z.string(),
    subscribers: z
      .lazy(
        () => UserCreateNestedManyWithoutPlaylistSubscriptionsInputObjectSchema
      )
      .optional(),
    episodes: z
      .lazy(() => EpisodeCreateNestedManyWithoutPlaylistsInputObjectSchema)
      .optional(),
  })
  .strict();

export const PlaylistCreateWithoutOwnersInputObjectSchema = Schema;
