import { createIcon } from '@chakra-ui/icons';

export const EyeSlashIcon = createIcon({
  displayName: 'EyeSlashIcon',
  viewBox: '0 0 44 44',
  path: [
    <path
      key="path0"
      d="M34.821 20.181C33.943 18.261 30.5 12 23 12s-10.943 6.261-11.821 8.181a1.969 1.969 0 0 0 0 1.64C12.057 23.739 15.5 30 23 30s10.943-6.261 11.821-8.181a1.968 1.968 0 0 0 0-1.638ZM23 28c-6.307 0-9.25-5.366-10-6.989C13.75 19.366 16.693 14 23 14c6.292 0 9.236 5.343 10 7-.764 1.657-3.708 7-10 7Z"
      data-name="Path 1070"
    />,
    <path
      key="path1"
      d="M23 16a5 5 0 1 0 5 5 5 5 0 0 0-5-5Zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3Z"
      data-name="Path 1071"
    />,

    <path
      key="path2"
      d="M34.821 20.181a15.736 15.736 0 0 0-4.145-5.44l3.032-3.032-1.415-1.416L29 13.583A11.783 11.783 0 0 0 23 12c-7.5 0-10.943 6.261-11.821 8.181a1.969 1.969 0 0 0 0 1.64 15.736 15.736 0 0 0 4.145 5.44l-3.031 3.032 1.414 1.414L17 28.417A11.783 11.783 0 0 0 23 30c7.5 0 10.943-6.261 11.821-8.181a1.968 1.968 0 0 0 0-1.638ZM13 21.011C13.75 19.366 16.693 14 23 14a9.847 9.847 0 0 1 4.518 1.068l-1.765 1.765a4.992 4.992 0 0 0-6.92 6.92l-2.079 2.079A13.647 13.647 0 0 1 13 21.011ZM26 21a3 3 0 0 1-3 3 2.951 2.951 0 0 1-1.285-.3l3.985-3.985A2.951 2.951 0 0 1 26 21Zm-6 0a3 3 0 0 1 3-3 2.951 2.951 0 0 1 1.285.3L20.3 22.285A2.951 2.951 0 0 1 20 21Zm3 7a9.847 9.847 0 0 1-4.518-1.068l1.765-1.765a4.992 4.992 0 0 0 6.92-6.92l2.078-2.078A13.584 13.584 0 0 1 33 21c-.764 1.657-3.708 7-10 7Z"
      data-name="Path 1069"
    />,
    <path
      key="path3"
      fill="none"
      d="M0 0h44v44H0z"
      data-name="Rectangle 492"
    />,
  ],
});
