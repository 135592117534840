import { Box, Image as ChakraImage } from '@chakra-ui/react';

export type UserAvatarProps = {
  name?: string | null;
  src?: string | null;
};
export function UserAvatar(props: UserAvatarProps) {
  const { name, src } = props;

  return (
    <Box
      boxSize="4"
      mr="4"
      sx={{
        zIndex: 1,
        position: 'relative',

        '&::after': {
          zIndex: -1,
          position: 'absolute',
          content: '""',
          top: '-45%',
          left: '-50%',
          width: '200%',
          height: '200%',
          backgroundColor: 'white',
          background: "url('/images/logo--border.png')",
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          borderRadius: 'full',
        },
      }}
    >
      <ChakraImage
        alt={name ?? 'Qube User'}
        src={src ?? '/images/logo.png'}
        objectFit="cover"
        objectPosition="center"
        rounded="full"
      />
    </Box>
  );
}
