import { EnvelopeIcon } from '../../atoms/icons/envelope';
import { TextInput, TextInputProps } from './text';

export function EmailInput(props: Partial<TextInputProps>) {
  const { input, ...rest } = props;

  return (
    <TextInput
      {...rest}
      name={rest['name'] ?? 'email'}
      label={rest['label'] ?? "What's your email address?"}
      leftAddon={rest['leftAddon'] ?? <EnvelopeIcon />}
      input={{
        type: 'email',
        placeholder: 'Enter your email address',
        autoComplete: 'email',
        ...input,
      }}
    />
  );
}
