import { z } from 'zod';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.CurationPodcastIdCuratorIdCompoundUniqueInput> =
  z
    .object({
      podcastId: z.number(),
      curatorId: z.string(),
    })
    .strict();

export const CurationPodcastIdCuratorIdCompoundUniqueInputObjectSchema = Schema;
