import { z } from 'zod';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserUpdateWithoutPlaylistsInputObjectSchema } from './UserUpdateWithoutPlaylistsInput.schema';
import { UserUncheckedUpdateWithoutPlaylistsInputObjectSchema } from './UserUncheckedUpdateWithoutPlaylistsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUpdateWithWhereUniqueWithoutPlaylistsInput> =
  z
    .object({
      where: z.lazy(() => UserWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => UserUpdateWithoutPlaylistsInputObjectSchema),
        z.lazy(() => UserUncheckedUpdateWithoutPlaylistsInputObjectSchema),
      ]),
    })
    .strict();

export const UserUpdateWithWhereUniqueWithoutPlaylistsInputObjectSchema =
  Schema;
