import { z } from 'zod';
import { PlaylistWhereUniqueInputObjectSchema } from './PlaylistWhereUniqueInput.schema';
import { PlaylistUpdateWithoutEpisodesInputObjectSchema } from './PlaylistUpdateWithoutEpisodesInput.schema';
import { PlaylistUncheckedUpdateWithoutEpisodesInputObjectSchema } from './PlaylistUncheckedUpdateWithoutEpisodesInput.schema';
import { PlaylistCreateWithoutEpisodesInputObjectSchema } from './PlaylistCreateWithoutEpisodesInput.schema';
import { PlaylistUncheckedCreateWithoutEpisodesInputObjectSchema } from './PlaylistUncheckedCreateWithoutEpisodesInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistUpsertWithWhereUniqueWithoutEpisodesInput> =
  z
    .object({
      where: z.lazy(() => PlaylistWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => PlaylistUpdateWithoutEpisodesInputObjectSchema),
        z.lazy(() => PlaylistUncheckedUpdateWithoutEpisodesInputObjectSchema),
      ]),
      create: z.union([
        z.lazy(() => PlaylistCreateWithoutEpisodesInputObjectSchema),
        z.lazy(() => PlaylistUncheckedCreateWithoutEpisodesInputObjectSchema),
      ]),
    })
    .strict();

export const PlaylistUpsertWithWhereUniqueWithoutEpisodesInputObjectSchema =
  Schema;
