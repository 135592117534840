import { useState } from 'react';

import {
  Box,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';

import { Link, PageHeading } from '../../../components';
import { StudioRoutes } from '../../../utils';
import { submissionData } from './data';

export type PodcastersDataProps = {
  data?: Array<{ image_url: string; title: string; description: string }>;
};

export function PodcastersSubmitCTA(props: PodcastersDataProps) {
  const { data = submissionData } = props;
  const [ctaSection] = useState(data);

  return (
    <Flex
      as="section"
      mt={200}
      mx="auto"
      p="5%"
      maxHeight="85vh"
      position="relative"
    >
      <HStack m="auto" spacing={10} alignItems="flex-start">
        <VStack w="50%" alignItems="left" spacing={10}>
          <PageHeading>{'We value quality over quantity'}</PageHeading>
          <Text fontSize="2xl">
            To ensure all podcasts inside The Qube are of the highest quality
            our podcast curation team listens to every piece of conte while
            leveraging our Qubric as a guide. Our Qubric values your
            introduction, content, delivery, graphic & music enhancements,
            production quality and consistency. If your podcast meets the
            criteria your podcast will be activated inside of The Qube.
          </Text>

          <Link
            href={StudioRoutes['auth'].children?.register.href ?? '#'}
            width="300px"
            p={4}
            variant="ctaButton"
          >
            Submit Your Podcast For Curation
          </Link>
        </VStack>

        <Box width="50%">
          <VStack align="left">
            {ctaSection.map((cta) => {
              return (
                <Flex key={cta.title} alignItems="flex-start">
                  <Box w="88px" h="88px" mx="auto">
                    <Image
                      mx="auto"
                      src={cta.image_url}
                      alt={`${cta.title} icon image for the Qube's podcasters page.`}
                    />
                  </Box>

                  <Box width="80%" mb={5} ml={-10}>
                    <Heading
                      as="h3"
                      fontSize={'lg'}
                      mb={2}
                      sx={{ color: 'gold.500', fontWeight: 'bold' }}
                    >
                      {cta.title}
                    </Heading>

                    <Text>{cta.description}</Text>
                  </Box>
                </Flex>
              );
            })}
          </VStack>
        </Box>
      </HStack>
    </Flex>
  );
}
