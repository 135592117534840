import { z } from 'zod';
import { UserCreateNestedOneWithoutSettingsInputObjectSchema } from './UserCreateNestedOneWithoutSettingsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.SettingsCreateInput> = z
  .object({
    owner: z.lazy(() => UserCreateNestedOneWithoutSettingsInputObjectSchema),
    onboardedAt: z.date().optional().nullable(),
    acceptedTermsAt: z.date().optional().nullable(),
    acceptedPrivacyAt: z.date().optional().nullable(),
    acceptedNewsletterAt: z.date().optional().nullable(),
  })
  .strict();

export const SettingsCreateInputObjectSchema = Schema;
