import { ArrowLeftIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FaHouseDamage } from 'react-icons/fa';
import { PrimaryHero } from '../components';

import { BaseLayout } from './layouts';

export function NotFoundPage() {
  const router = useRouter();

  return (
    <PrimaryHero
      title="Whoops"
      description="The page you're looking for no longer exists. Our bad. This error has been reported."
      actions={
        <>
          <Button
            leftIcon={<ArrowLeftIcon />}
            onClick={() => router.back()}
            colorScheme="themeBlue"
          >
            Go Back
          </Button>

          <Button
            leftIcon={<FaHouseDamage />}
            onClick={() => router.push('/')}
            colorScheme="themeGold"
          >
            Go home
          </Button>
        </>
      }
    />
  );
}

NotFoundPage.getLayout = (page: React.ReactNode) => (
  <BaseLayout
    title="404 - Not Found"
    description="The page you're looking for no longer exists. Our bad. This error has been reported."
  >
    {page}
  </BaseLayout>
);
