import { z } from 'zod';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';
import { PodcastCreateWithoutCurationsInputObjectSchema } from './PodcastCreateWithoutCurationsInput.schema';
import { PodcastUncheckedCreateWithoutCurationsInputObjectSchema } from './PodcastUncheckedCreateWithoutCurationsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastCreateOrConnectWithoutCurationsInput> = z
  .object({
    where: z.lazy(() => PodcastWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => PodcastCreateWithoutCurationsInputObjectSchema),
      z.lazy(() => PodcastUncheckedCreateWithoutCurationsInputObjectSchema),
    ]),
  })
  .strict();

export const PodcastCreateOrConnectWithoutCurationsInputObjectSchema = Schema;
