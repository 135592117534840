import * as React from 'react';

import { PaginationSchema } from '@theqube/schemas';

export function usePagination(
  defaults: PaginationSchema = { take: 25, skip: 0 }
) {
  const [pagination, setPagination] = React.useState<PaginationSchema>(() => ({
    take: defaults.take ?? 25,
    skip: defaults.skip ?? 0,
  }));

  const move = () => {
    setPagination((prev) => ({
      ...prev,
      take: prev.take ? prev.take + (defaults.take ?? 0) : defaults.take,
    }));
  };

  return [pagination, { move }] as const;
}

type ICursor = { [key: string]: string | number };

type PaginationState<Cursor extends ICursor = { id: string }> =
  PaginationSchema & { cursor?: Cursor };

export function useCursorPagination<Cursor extends ICursor>(
  defaults?: PaginationSchema
) {
  const [pagination, setPagination] = React.useState<PaginationState<Cursor>>(
    () => ({
      take: defaults?.take ?? 25,
      skip: defaults?.skip ?? 0,
    })
  );

  const move = React.useCallback(
    (cursor?: Cursor, direction?: 'prev' | 'next') => {
      setPagination((prev) => {
        let take = prev.take;

        if (take && direction === 'prev') {
          take = -take;
        }

        return {
          ...prev,
          take,
          cursor,
        };
      });
    },
    [setPagination]
  );

  const moveInList = <Item = unknown>(
    direction: 'prev' | 'next' = 'next',
    list: Array<Item> | undefined | null,
    getCursor: (item: Item) => Cursor
  ) => {
    const item = direction === 'prev' ? list?.at(0) : list?.at(-1);
    if (item) {
      move(getCursor(item), direction);
    }
  };

  return [pagination, { moveInList, move }] as const;
}
