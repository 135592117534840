import { z } from 'zod';
import { PodcastWhereInputObjectSchema } from './objects/PodcastWhereInput.schema';
import { PodcastOrderByWithRelationInputObjectSchema } from './objects/PodcastOrderByWithRelationInput.schema';
import { PodcastWhereUniqueInputObjectSchema } from './objects/PodcastWhereUniqueInput.schema';
import { PodcastScalarFieldEnumSchema } from './enums/PodcastScalarFieldEnum.schema';

export const PodcastFindFirstSchema = z.object({
  where: PodcastWhereInputObjectSchema.optional(),
  orderBy: z
    .union([
      PodcastOrderByWithRelationInputObjectSchema,
      PodcastOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  cursor: PodcastWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(PodcastScalarFieldEnumSchema).optional(),
});
