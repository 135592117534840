import { z } from 'zod';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserUpdateWithoutTagsInputObjectSchema } from './UserUpdateWithoutTagsInput.schema';
import { UserUncheckedUpdateWithoutTagsInputObjectSchema } from './UserUncheckedUpdateWithoutTagsInput.schema';
import { UserCreateWithoutTagsInputObjectSchema } from './UserCreateWithoutTagsInput.schema';
import { UserUncheckedCreateWithoutTagsInputObjectSchema } from './UserUncheckedCreateWithoutTagsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUpsertWithWhereUniqueWithoutTagsInput> = z
  .object({
    where: z.lazy(() => UserWhereUniqueInputObjectSchema),
    update: z.union([
      z.lazy(() => UserUpdateWithoutTagsInputObjectSchema),
      z.lazy(() => UserUncheckedUpdateWithoutTagsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => UserCreateWithoutTagsInputObjectSchema),
      z.lazy(() => UserUncheckedCreateWithoutTagsInputObjectSchema),
    ]),
  })
  .strict();

export const UserUpsertWithWhereUniqueWithoutTagsInputObjectSchema = Schema;
