import { Button, ButtonGroup, useToast } from '@chakra-ui/react';

import { ResetPasswordBody } from '@theqube/schemas';
import { ErrorWrapper, Form, PasswordInput } from '../../../components';
import { trpc, useZodForm } from '../../../utils';

type ResetPasswordFormProps = {
  token?: string;
  onSuccess?: () => void;
};

const makeDefaultValues = (
  props: ResetPasswordFormProps
): ResetPasswordBody => ({
  token: props.token ?? '',
  password: '',
  passwordConfirm: '',
});

export function ResetPasswordForm(props: ResetPasswordFormProps) {
  const { onSuccess } = props;
  const toast = useToast();

  const {
    mutateAsync: requestPasswordReset,
    isLoading,
    isError,
    error,
    reset,
  } = trpc.auth.password.reset.useMutation({
    onSuccess() {
      toast({
        status: 'success',
        title: 'Password Reset!',
        description: 'Password reset completed successfully.',
      });
      onSuccess?.();
    },
  });

  const form = useZodForm<typeof ResetPasswordBody>({
    mode: 'onChange',
    schema: ResetPasswordBody,
    defaultValues: makeDefaultValues(props),
  });

  const handleSubmit = form.handleSubmit((values) =>
    requestPasswordReset(values)
  );

  if (error) {
    return <ErrorWrapper error={error} reset={reset} />;
  }

  return (
    <Form context={form} my="12" onSubmit={handleSubmit}>
      <PasswordInput
        name="token"
        label="Code"
        helperText="Enter the code from the email we sent you."
        containerStyle={{ my: 4 }}
        input={{ placeholder: '1234567890', type: 'password' }}
      />

      <PasswordInput
        name="password"
        label="New password"
        containerStyle={{ my: 4 }}
      />

      <PasswordInput
        name="passwordConfirm"
        label="Confirm new password"
        containerStyle={{ my: 4 }}
      />

      <ButtonGroup display="flex" justifyContent="flex-end" my="4">
        <Button
          type="submit"
          isLoading={form.formState.isSubmitting || isLoading}
          disabled={form.formState.isSubmitting || isLoading}
          bg={isError || !form.formState.isValid ? 'error' : 'themeGold.500'}
        >
          Submit
        </Button>
      </ButtonGroup>
    </Form>
  );
}
