import { z } from 'zod';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.SettingsWhereUniqueInput> = z
  .object({
    ownerId: z.string().optional(),
  })
  .strict();

export const SettingsWhereUniqueInputObjectSchema = Schema;
