import { z } from 'zod';

export const AdvertisementScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'header',
  'title',
  'description',
  'url',
  'order',
]);
