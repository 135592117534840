import { z } from 'zod';
import { PodcastCreateWithoutOwnersInputObjectSchema } from './PodcastCreateWithoutOwnersInput.schema';
import { PodcastUncheckedCreateWithoutOwnersInputObjectSchema } from './PodcastUncheckedCreateWithoutOwnersInput.schema';
import { PodcastCreateOrConnectWithoutOwnersInputObjectSchema } from './PodcastCreateOrConnectWithoutOwnersInput.schema';
import { PodcastUpsertWithWhereUniqueWithoutOwnersInputObjectSchema } from './PodcastUpsertWithWhereUniqueWithoutOwnersInput.schema';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';
import { PodcastUpdateWithWhereUniqueWithoutOwnersInputObjectSchema } from './PodcastUpdateWithWhereUniqueWithoutOwnersInput.schema';
import { PodcastUpdateManyWithWhereWithoutOwnersInputObjectSchema } from './PodcastUpdateManyWithWhereWithoutOwnersInput.schema';
import { PodcastScalarWhereInputObjectSchema } from './PodcastScalarWhereInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastUpdateManyWithoutOwnersNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => PodcastCreateWithoutOwnersInputObjectSchema),
            z.lazy(() => PodcastCreateWithoutOwnersInputObjectSchema).array(),
            z.lazy(() => PodcastUncheckedCreateWithoutOwnersInputObjectSchema),
            z
              .lazy(() => PodcastUncheckedCreateWithoutOwnersInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(() => PodcastCreateOrConnectWithoutOwnersInputObjectSchema),
            z
              .lazy(() => PodcastCreateOrConnectWithoutOwnersInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .union([
            z.lazy(
              () => PodcastUpsertWithWhereUniqueWithoutOwnersInputObjectSchema
            ),
            z
              .lazy(
                () => PodcastUpsertWithWhereUniqueWithoutOwnersInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        set: z
          .union([
            z.lazy(() => PodcastWhereUniqueInputObjectSchema),
            z.lazy(() => PodcastWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        disconnect: z
          .union([
            z.lazy(() => PodcastWhereUniqueInputObjectSchema),
            z.lazy(() => PodcastWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        delete: z
          .union([
            z.lazy(() => PodcastWhereUniqueInputObjectSchema),
            z.lazy(() => PodcastWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => PodcastWhereUniqueInputObjectSchema),
            z.lazy(() => PodcastWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(
              () => PodcastUpdateWithWhereUniqueWithoutOwnersInputObjectSchema
            ),
            z
              .lazy(
                () => PodcastUpdateWithWhereUniqueWithoutOwnersInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        updateMany: z
          .union([
            z.lazy(
              () => PodcastUpdateManyWithWhereWithoutOwnersInputObjectSchema
            ),
            z
              .lazy(
                () => PodcastUpdateManyWithWhereWithoutOwnersInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        deleteMany: z
          .union([
            z.lazy(() => PodcastScalarWhereInputObjectSchema),
            z.lazy(() => PodcastScalarWhereInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const PodcastUpdateManyWithoutOwnersNestedInputObjectSchema = Schema;
