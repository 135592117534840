import * as z from "zod"
import { CompleteAccount, RelatedAccountSchema, CompleteSession, RelatedSessionSchema, CompleteTag, RelatedTagSchema, CompleteSettings, RelatedSettingsSchema, CompletePermissions, RelatedPermissionsSchema, CompletePlaylist, RelatedPlaylistSchema, CompletePodcast, RelatedPodcastSchema, CompleteCuration, RelatedCurationSchema } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const UserSchema = z.object({
  id: z.string(),
  avatarUrl: z.string().nullish(),
  displayName: z.string().nullish(),
  fullName: z.string(),
  email: z.string().nullish(),
  emailVerified: z.date().nullish(),
  passwordHash: z.string().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
  biography: z.string().nullish(),
  birthday: z.date().nullish(),
  gender: z.string().nullish(),
  socialMedia: jsonSchema,
})

export interface CompleteUser extends z.infer<typeof UserSchema> {
  accounts: CompleteAccount[]
  sessions: CompleteSession[]
  tags: CompleteTag[]
  settings?: CompleteSettings | null
  permissions?: CompletePermissions | null
  playlists: CompletePlaylist[]
  playlistSubscriptions: CompletePlaylist[]
  podcastsAssigned: CompletePodcast[]
  podcastsCurated: CompleteCuration[]
  podcasts: CompletePodcast[]
  podcastSubscriptions: CompletePodcast[]
}

/**
 * RelatedUserSchema contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedUserSchema: z.ZodSchema<CompleteUser> = z.lazy(() => UserSchema.extend({
  accounts: RelatedAccountSchema.array(),
  sessions: RelatedSessionSchema.array(),
  tags: RelatedTagSchema.array(),
  settings: RelatedSettingsSchema.nullish(),
  permissions: RelatedPermissionsSchema.nullish(),
  playlists: RelatedPlaylistSchema.array(),
  playlistSubscriptions: RelatedPlaylistSchema.array(),
  podcastsAssigned: RelatedPodcastSchema.array(),
  podcastsCurated: RelatedCurationSchema.array(),
  podcasts: RelatedPodcastSchema.array(),
  podcastSubscriptions: RelatedPodcastSchema.array(),
}))
