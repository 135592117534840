import * as z from "zod"
import { CompletePodcast, RelatedPodcastSchema, CompleteUser, RelatedUserSchema } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const CurationSchema = z.object({
  updatedAt: z.date(),
  podcastId: z.number().int(),
  curatorId: z.string(),
  contactName: z.string(),
  contactEmail: z.string(),
  wouldRecommend: z.boolean(),
  ratings: jsonSchema,
  notes: z.string(),
})

export interface CompleteCuration extends z.infer<typeof CurationSchema> {
  podcast: CompletePodcast
  curator: CompleteUser
}

/**
 * RelatedCurationSchema contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCurationSchema: z.ZodSchema<CompleteCuration> = z.lazy(() => CurationSchema.extend({
  podcast: RelatedPodcastSchema,
  curator: RelatedUserSchema,
}))
