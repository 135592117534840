import { z } from 'zod';
import {
  Prisma,
  Tag,
  TagType,
  TagWhereUniqueInputObjectSchema,
} from './index-generated';

export type UpdateTagsBody = z.infer<typeof UpdateTagsBody>;

export const UpdateTagsBody = z.object({
  tags: z.array(z.string()),
});

export const makeTagImageUrl = (path: string) => `/images/tags/${path}`;

/**
 * Some tags get their images served locally if available.
 * Use this map or the {@link makeTagImageUrl} function to get the correct path.
 */
export const TagImageMap = {
  art: makeTagImageUrl('art.jpg'),
  business: makeTagImageUrl('business.jpg'),
  drama: makeTagImageUrl('drama.jpg'),
  education: makeTagImageUrl('education.jpg'),
  entertainment: makeTagImageUrl('entertainment.jpg'),
  faithAndSpirituality: makeTagImageUrl('faith-and-spirituality.jpg'),
  food: makeTagImageUrl('food.jpg'),
  gaming: makeTagImageUrl('gaming.jpg'),
  healthAndWellness: makeTagImageUrl('health-and-wellness.jpg'),
  history: makeTagImageUrl('history.jpg'),
  kidsAndFamily: makeTagImageUrl('kids-and-family.jpg'),
  loveAndRelationships: makeTagImageUrl('love-and-relationships.jpg'),
  moneyAndFinance: makeTagImageUrl('money-and-finance.jpg'),
  music: makeTagImageUrl('music.jpg'),
  politics: makeTagImageUrl('politics.jpg'),
  science: makeTagImageUrl('science.jpg'),
  societyAndCulture: makeTagImageUrl('society-and-culture.jpg'),
  sports: makeTagImageUrl('sports.jpg'),
  storytelling: makeTagImageUrl('storytelling.jpg'),
  technology: makeTagImageUrl('technology.jpg'),
  travelAndLeisure: makeTagImageUrl('travel-and-leisure.jpg'),
  trueCrime: makeTagImageUrl('true-crime.jpg'),
} as const;

export type TagImageMap = typeof TagImageMap;
export type TagImageUrl = typeof TagImageMap[keyof TagImageMap];
export type TagImageKey = keyof TagImageMap;
export type TagDiffResult = {
  data: Prisma.UserUpdateInput;
  where: Prisma.UserWhereUniqueInput;
};

export type EditTagsFormData = z.infer<typeof EditTagsFormData>;
export const EditTagsFormData = z.object({
  tags: z.array(TagWhereUniqueInputObjectSchema),
});

/**
 * Get the difference between the two sets of tags
 *
 * `connect` tags that are NOT already in use.
 * `disconnect` tags that ARE already in use.
 *
 * Not my favorite especially on the client, but it's better than a new endpoint.
 */
export function tagDiff(
  type: TagType,
  {
    local,
    remote,
  }: {
    local: EditTagsFormData['tags'];
    remote: Pick<Tag, 'id' | 'type'>[];
  }
): TagDiffResult['data']['tags'] {
  const toAdd = local.filter((tag) => {
    const index = remote.findIndex((_tag) => _tag.id === tag.id);
    return index < 0;
  });

  const toKeep: EditTagsFormData['tags'] = remote
    .filter((tag) => {
      if (tag.type === type) {
        return local.findIndex((_tag) => _tag.id === tag.id) >= 0;
      }

      return true;
    })
    .map((tag) => ({ id: tag.id }));

  return { set: toKeep.concat(toAdd) };
}

export function slugify(name: string): string {
  let copy = String(name);

  copy = copy.toLowerCase();
  copy = copy.replace(/&/g, 'and');

  // The caret (^) character is the negation of the set [...],
  // The safelist in this example is digits, word characters, underscores (\w)
  // gi say global and case-insensitive
  // See: https://stackoverflow.com/questions/4374822/remove-all-special-characters-with-regexp
  copy = copy.replace(/[^\w]/gi, '-');

  return copy;
}
