import { z } from 'zod';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.CurationCountAggregateInputType> = z
  .object({
    updatedAt: z.literal(true).optional(),
    podcastId: z.literal(true).optional(),
    curatorId: z.literal(true).optional(),
    contactName: z.literal(true).optional(),
    contactEmail: z.literal(true).optional(),
    wouldRecommend: z.literal(true).optional(),
    ratings: z.literal(true).optional(),
    notes: z.literal(true).optional(),
    _all: z.literal(true).optional(),
  })
  .strict();

export const CurationCountAggregateInputObjectSchema = Schema;
