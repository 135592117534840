import { z } from 'zod';
import { TagCreateWithoutUsersInputObjectSchema } from './TagCreateWithoutUsersInput.schema';
import { TagUncheckedCreateWithoutUsersInputObjectSchema } from './TagUncheckedCreateWithoutUsersInput.schema';
import { TagCreateOrConnectWithoutUsersInputObjectSchema } from './TagCreateOrConnectWithoutUsersInput.schema';
import { TagUpsertWithWhereUniqueWithoutUsersInputObjectSchema } from './TagUpsertWithWhereUniqueWithoutUsersInput.schema';
import { TagWhereUniqueInputObjectSchema } from './TagWhereUniqueInput.schema';
import { TagUpdateWithWhereUniqueWithoutUsersInputObjectSchema } from './TagUpdateWithWhereUniqueWithoutUsersInput.schema';
import { TagUpdateManyWithWhereWithoutUsersInputObjectSchema } from './TagUpdateManyWithWhereWithoutUsersInput.schema';
import { TagScalarWhereInputObjectSchema } from './TagScalarWhereInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.TagUpdateManyWithoutUsersNestedInput> = z.union([
  z
    .object({
      create: z
        .union([
          z.lazy(() => TagCreateWithoutUsersInputObjectSchema),
          z.lazy(() => TagCreateWithoutUsersInputObjectSchema).array(),
          z.lazy(() => TagUncheckedCreateWithoutUsersInputObjectSchema),
          z.lazy(() => TagUncheckedCreateWithoutUsersInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict(),
  z
    .object({
      connectOrCreate: z
        .union([
          z.lazy(() => TagCreateOrConnectWithoutUsersInputObjectSchema),
          z.lazy(() => TagCreateOrConnectWithoutUsersInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict(),
  z
    .object({
      upsert: z
        .union([
          z.lazy(() => TagUpsertWithWhereUniqueWithoutUsersInputObjectSchema),
          z
            .lazy(() => TagUpsertWithWhereUniqueWithoutUsersInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict(),
  z
    .object({
      set: z
        .union([
          z.lazy(() => TagWhereUniqueInputObjectSchema),
          z.lazy(() => TagWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict(),
  z
    .object({
      disconnect: z
        .union([
          z.lazy(() => TagWhereUniqueInputObjectSchema),
          z.lazy(() => TagWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict(),
  z
    .object({
      delete: z
        .union([
          z.lazy(() => TagWhereUniqueInputObjectSchema),
          z.lazy(() => TagWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict(),
  z
    .object({
      connect: z
        .union([
          z.lazy(() => TagWhereUniqueInputObjectSchema),
          z.lazy(() => TagWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict(),
  z
    .object({
      update: z
        .union([
          z.lazy(() => TagUpdateWithWhereUniqueWithoutUsersInputObjectSchema),
          z
            .lazy(() => TagUpdateWithWhereUniqueWithoutUsersInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict(),
  z
    .object({
      updateMany: z
        .union([
          z.lazy(() => TagUpdateManyWithWhereWithoutUsersInputObjectSchema),
          z
            .lazy(() => TagUpdateManyWithWhereWithoutUsersInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict(),
  z
    .object({
      deleteMany: z
        .union([
          z.lazy(() => TagScalarWhereInputObjectSchema),
          z.lazy(() => TagScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict(),
]);

export const TagUpdateManyWithoutUsersNestedInputObjectSchema = Schema;
