import { z } from 'zod';

export const PlaylistScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'avatarUrl',
  'title',
  'description',
]);
