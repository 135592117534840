import { z } from 'zod';
import { PermissionsCreateWithoutOwnerInputObjectSchema } from './PermissionsCreateWithoutOwnerInput.schema';
import { PermissionsUncheckedCreateWithoutOwnerInputObjectSchema } from './PermissionsUncheckedCreateWithoutOwnerInput.schema';
import { PermissionsCreateOrConnectWithoutOwnerInputObjectSchema } from './PermissionsCreateOrConnectWithoutOwnerInput.schema';
import { PermissionsUpsertWithoutOwnerInputObjectSchema } from './PermissionsUpsertWithoutOwnerInput.schema';
import { PermissionsWhereUniqueInputObjectSchema } from './PermissionsWhereUniqueInput.schema';
import { PermissionsUpdateWithoutOwnerInputObjectSchema } from './PermissionsUpdateWithoutOwnerInput.schema';
import { PermissionsUncheckedUpdateWithoutOwnerInputObjectSchema } from './PermissionsUncheckedUpdateWithoutOwnerInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PermissionsUncheckedUpdateOneWithoutOwnerNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => PermissionsCreateWithoutOwnerInputObjectSchema),
            z.lazy(
              () => PermissionsUncheckedCreateWithoutOwnerInputObjectSchema
            ),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .lazy(() => PermissionsCreateOrConnectWithoutOwnerInputObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .lazy(() => PermissionsUpsertWithoutOwnerInputObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        disconnect: z.boolean().optional(),
      })
      .strict(),
    z
      .object({
        delete: z.boolean().optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .lazy(() => PermissionsWhereUniqueInputObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(() => PermissionsUpdateWithoutOwnerInputObjectSchema),
            z.lazy(
              () => PermissionsUncheckedUpdateWithoutOwnerInputObjectSchema
            ),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const PermissionsUncheckedUpdateOneWithoutOwnerNestedInputObjectSchema =
  Schema;
