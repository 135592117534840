import { z } from 'zod';
import { PermissionsUpdateWithoutOwnerInputObjectSchema } from './PermissionsUpdateWithoutOwnerInput.schema';
import { PermissionsUncheckedUpdateWithoutOwnerInputObjectSchema } from './PermissionsUncheckedUpdateWithoutOwnerInput.schema';
import { PermissionsCreateWithoutOwnerInputObjectSchema } from './PermissionsCreateWithoutOwnerInput.schema';
import { PermissionsUncheckedCreateWithoutOwnerInputObjectSchema } from './PermissionsUncheckedCreateWithoutOwnerInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PermissionsUpsertWithoutOwnerInput> = z
  .object({
    update: z.union([
      z.lazy(() => PermissionsUpdateWithoutOwnerInputObjectSchema),
      z.lazy(() => PermissionsUncheckedUpdateWithoutOwnerInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => PermissionsCreateWithoutOwnerInputObjectSchema),
      z.lazy(() => PermissionsUncheckedCreateWithoutOwnerInputObjectSchema),
    ]),
  })
  .strict();

export const PermissionsUpsertWithoutOwnerInputObjectSchema = Schema;
