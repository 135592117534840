import { Button } from './button';
import { Input } from './input';
import { Link } from './link';
import { Text } from './text';

export const components = {
  Text,
  Button,
  Link,
  Input,
} as const;
