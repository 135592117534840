import { z } from 'zod';
import { PodcastWhereInputObjectSchema } from './objects/PodcastWhereInput.schema';
import { PodcastOrderByWithRelationInputObjectSchema } from './objects/PodcastOrderByWithRelationInput.schema';
import { PodcastWhereUniqueInputObjectSchema } from './objects/PodcastWhereUniqueInput.schema';
import { PodcastCountAggregateInputObjectSchema } from './objects/PodcastCountAggregateInput.schema';
import { PodcastMinAggregateInputObjectSchema } from './objects/PodcastMinAggregateInput.schema';
import { PodcastMaxAggregateInputObjectSchema } from './objects/PodcastMaxAggregateInput.schema';
import { PodcastAvgAggregateInputObjectSchema } from './objects/PodcastAvgAggregateInput.schema';
import { PodcastSumAggregateInputObjectSchema } from './objects/PodcastSumAggregateInput.schema';

export const PodcastAggregateSchema = z.object({
  where: PodcastWhereInputObjectSchema.optional(),
  orderBy: z
    .union([
      PodcastOrderByWithRelationInputObjectSchema,
      PodcastOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  cursor: PodcastWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), PodcastCountAggregateInputObjectSchema])
    .optional(),
  _min: PodcastMinAggregateInputObjectSchema.optional(),
  _max: PodcastMaxAggregateInputObjectSchema.optional(),
  _avg: PodcastAvgAggregateInputObjectSchema.optional(),
  _sum: PodcastSumAggregateInputObjectSchema.optional(),
});
