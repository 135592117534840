import { z } from 'zod';
import { UserUpdateWithoutPodcastsCuratedInputObjectSchema } from './UserUpdateWithoutPodcastsCuratedInput.schema';
import { UserUncheckedUpdateWithoutPodcastsCuratedInputObjectSchema } from './UserUncheckedUpdateWithoutPodcastsCuratedInput.schema';
import { UserCreateWithoutPodcastsCuratedInputObjectSchema } from './UserCreateWithoutPodcastsCuratedInput.schema';
import { UserUncheckedCreateWithoutPodcastsCuratedInputObjectSchema } from './UserUncheckedCreateWithoutPodcastsCuratedInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUpsertWithoutPodcastsCuratedInput> = z
  .object({
    update: z.union([
      z.lazy(() => UserUpdateWithoutPodcastsCuratedInputObjectSchema),
      z.lazy(() => UserUncheckedUpdateWithoutPodcastsCuratedInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => UserCreateWithoutPodcastsCuratedInputObjectSchema),
      z.lazy(() => UserUncheckedCreateWithoutPodcastsCuratedInputObjectSchema),
    ]),
  })
  .strict();

export const UserUpsertWithoutPodcastsCuratedInputObjectSchema = Schema;
