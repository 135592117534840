import { useMemo } from 'react';
import { useRouter } from 'next/router';

import { shouldUserOnboard } from '@theqube/schemas';
import { useSession } from './use-session';

export type UseOnbaordingProps = never;

export function useOnboarding(_props?: UseOnbaordingProps) {
  const { pathname } = useRouter();
  const [{ data: session, isSuccess, isFetching }] = useSession();

  const userTags = session?.user.tags;

  // Determines if the current user should be redirected to a specific preferences screen,
  // and if so which one
  const shouldGoToPreferences = useMemo(() => {
    const isDataAvailable = !isFetching && Boolean(isSuccess && userTags);
    const isDuplicate = pathname.split('/').indexOf('preferences') >= 0;

    if (isDataAvailable && !isDuplicate) {
      return shouldUserOnboard({ tags: userTags });
    }

    return false;
  }, [pathname, userTags, isSuccess, isFetching]);

  return {
    shouldGoToPreferences,
  };
}
