import { z } from 'zod';
import { EpisodeCreateManyPodcastInputObjectSchema } from './EpisodeCreateManyPodcastInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.EpisodeCreateManyPodcastInputEnvelope> = z
  .object({
    data: z.lazy(() => EpisodeCreateManyPodcastInputObjectSchema).array(),
    skipDuplicates: z.boolean().optional(),
  })
  .strict();

export const EpisodeCreateManyPodcastInputEnvelopeObjectSchema = Schema;
