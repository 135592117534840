import { z } from 'zod';
import { CurationWhereUniqueInputObjectSchema } from './objects/CurationWhereUniqueInput.schema';
import { CurationCreateInputObjectSchema } from './objects/CurationCreateInput.schema';
import { CurationUpdateInputObjectSchema } from './objects/CurationUpdateInput.schema';

export const CurationUpsertSchema = z.object({
  where: CurationWhereUniqueInputObjectSchema,
  create: CurationCreateInputObjectSchema,
  update: CurationUpdateInputObjectSchema,
});
