import { z } from 'zod';
import { PodcastUpdateWithoutCurationsInputObjectSchema } from './PodcastUpdateWithoutCurationsInput.schema';
import { PodcastUncheckedUpdateWithoutCurationsInputObjectSchema } from './PodcastUncheckedUpdateWithoutCurationsInput.schema';
import { PodcastCreateWithoutCurationsInputObjectSchema } from './PodcastCreateWithoutCurationsInput.schema';
import { PodcastUncheckedCreateWithoutCurationsInputObjectSchema } from './PodcastUncheckedCreateWithoutCurationsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastUpsertWithoutCurationsInput> = z
  .object({
    update: z.union([
      z.lazy(() => PodcastUpdateWithoutCurationsInputObjectSchema),
      z.lazy(() => PodcastUncheckedUpdateWithoutCurationsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => PodcastCreateWithoutCurationsInputObjectSchema),
      z.lazy(() => PodcastUncheckedCreateWithoutCurationsInputObjectSchema),
    ]),
  })
  .strict();

export const PodcastUpsertWithoutCurationsInputObjectSchema = Schema;
