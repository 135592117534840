import { Box, Flex, Text, VStack } from '@chakra-ui/react';

import { Logo, PageHeading } from '../atoms';

interface QubeElementHeroProps {
  heading: string | string[] | undefined;
  description?: string | string[] | undefined;
  children?: React.ReactElement;
}

export function QubeElementHero({
  heading,
  description,
  children,
}: QubeElementHeroProps) {
  return (
    <Flex
      as="header"
      flexFlow="row wrap"
      mx="auto"
      px="5%"
      minHeight="50vh"
      position="relative"
      justify="center"
    >
      <VStack justify="center" alignItems="center" spacing={5} w="80%">
        <Box w="100%">
          <Logo />
        </Box>

        <PageHeading mx="auto" textAlign="center" fontSize="6xl">
          {heading}
        </PageHeading>

        {description ? (
          Array.isArray(description) ? (
            description.map((desc) => (
              <Text w="80%" fontSize="xl" textAlign="center">
                {desc}
              </Text>
            ))
          ) : (
            <Text w="80%" fontSize="xl" textAlign="center">
              {description}
            </Text>
          )
        ) : null}

        {children}

        {children}
      </VStack>
    </Flex>
  );
}
