import { z } from 'zod';
import { AdvertisementWhereInputObjectSchema } from './objects/AdvertisementWhereInput.schema';
import { AdvertisementOrderByWithRelationInputObjectSchema } from './objects/AdvertisementOrderByWithRelationInput.schema';
import { AdvertisementWhereUniqueInputObjectSchema } from './objects/AdvertisementWhereUniqueInput.schema';
import { AdvertisementScalarFieldEnumSchema } from './enums/AdvertisementScalarFieldEnum.schema';

export const AdvertisementFindFirstSchema = z.object({
  where: AdvertisementWhereInputObjectSchema.optional(),
  orderBy: z
    .union([
      AdvertisementOrderByWithRelationInputObjectSchema,
      AdvertisementOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  cursor: AdvertisementWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(AdvertisementScalarFieldEnumSchema).optional(),
});
