import { z } from 'zod';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.AdvertisementCountAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    header: z.literal(true).optional(),
    title: z.literal(true).optional(),
    description: z.literal(true).optional(),
    url: z.literal(true).optional(),
    order: z.literal(true).optional(),
    _all: z.literal(true).optional(),
  })
  .strict();

export const AdvertisementCountAggregateInputObjectSchema = Schema;
