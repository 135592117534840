import { z } from 'zod';
import { EpisodeWhereUniqueInputObjectSchema } from './EpisodeWhereUniqueInput.schema';
import { EpisodeUpdateWithoutPodcastInputObjectSchema } from './EpisodeUpdateWithoutPodcastInput.schema';
import { EpisodeUncheckedUpdateWithoutPodcastInputObjectSchema } from './EpisodeUncheckedUpdateWithoutPodcastInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.EpisodeUpdateWithWhereUniqueWithoutPodcastInput> =
  z
    .object({
      where: z.lazy(() => EpisodeWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => EpisodeUpdateWithoutPodcastInputObjectSchema),
        z.lazy(() => EpisodeUncheckedUpdateWithoutPodcastInputObjectSchema),
      ]),
    })
    .strict();

export const EpisodeUpdateWithWhereUniqueWithoutPodcastInputObjectSchema =
  Schema;
