import * as React from 'react';

import { IconButton, InputProps } from '@chakra-ui/react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

import { LockIcon } from '../../atoms/icons/lock';
import { FormFieldProps } from '../helpers';
import { TextInput } from './text';

type PasswordInputProps = Partial<FormFieldProps> & {
  placeholder?: string;
  input?: InputProps;
};

export function PasswordInput(props: PasswordInputProps) {
  const { placeholder, input, ...rest } = props;
  const [showPassword, setShowPassword] = React.useState(() => false);
  const toggleShowPassword = () => setShowPassword((prev) => !prev);

  return (
    <TextInput
      name={rest.name ?? 'password'}
      label={rest.label ?? 'Create your password'}
      leftAddon={rest.leftAddon ?? <LockIcon key="password-icon" />}
      rightElement={
        <IconButton
          borderLeftRadius="0"
          borderRightRadius="md"
          aria-label="Toggle password visibility"
          icon={showPassword ? <MdVisibility /> : <MdVisibilityOff />}
          onClick={toggleShowPassword}
        />
      }
      input={{
        type: showPassword ? 'text' : 'password',
        placeholder: placeholder ?? 'At least 8 characters',
        autoCapitalize: 'none',
        autoComplete: 'new-password',
        ...input,
      }}
    />
  );
}
