import { z } from 'zod';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserUpdateWithoutPlaylistsInputObjectSchema } from './UserUpdateWithoutPlaylistsInput.schema';
import { UserUncheckedUpdateWithoutPlaylistsInputObjectSchema } from './UserUncheckedUpdateWithoutPlaylistsInput.schema';
import { UserCreateWithoutPlaylistsInputObjectSchema } from './UserCreateWithoutPlaylistsInput.schema';
import { UserUncheckedCreateWithoutPlaylistsInputObjectSchema } from './UserUncheckedCreateWithoutPlaylistsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUpsertWithWhereUniqueWithoutPlaylistsInput> =
  z
    .object({
      where: z.lazy(() => UserWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => UserUpdateWithoutPlaylistsInputObjectSchema),
        z.lazy(() => UserUncheckedUpdateWithoutPlaylistsInputObjectSchema),
      ]),
      create: z.union([
        z.lazy(() => UserCreateWithoutPlaylistsInputObjectSchema),
        z.lazy(() => UserUncheckedCreateWithoutPlaylistsInputObjectSchema),
      ]),
    })
    .strict();

export const UserUpsertWithWhereUniqueWithoutPlaylistsInputObjectSchema =
  Schema;
