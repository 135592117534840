import { z } from 'zod';

export type NowPlayingSchema = z.infer<typeof NowPlayingSchema>;
export const NowPlayingSchema = z.object({
  guid: z.string(),
  album: z.string(),
  sku: z.string(),
  artist: z.string(),
  title: z.string(),
  show: z.record(z.string(), z.unknown()),
  buy_urls: z.string(),
  info_urls: z.string(),
  duration: z
    .string()
    .refine(
      (duration) => Number(duration) > 0,
      'Duration must be a valid number'
    ),

  stream: z.object({
    mp3: z.string(),
    m3u: z.string(),
    pls: z.string(),
  }),
});
