import { z } from 'zod';
import { CurationPodcastIdCuratorIdCompoundUniqueInputObjectSchema } from './CurationPodcastIdCuratorIdCompoundUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.CurationWhereUniqueInput> = z
  .object({
    podcastId_curatorId: z
      .lazy(() => CurationPodcastIdCuratorIdCompoundUniqueInputObjectSchema)
      .optional(),
  })
  .strict();

export const CurationWhereUniqueInputObjectSchema = Schema;
