import { z } from 'zod';
import { PodcastUpdateWithoutRssFeedInputObjectSchema } from './PodcastUpdateWithoutRssFeedInput.schema';
import { PodcastUncheckedUpdateWithoutRssFeedInputObjectSchema } from './PodcastUncheckedUpdateWithoutRssFeedInput.schema';
import { PodcastCreateWithoutRssFeedInputObjectSchema } from './PodcastCreateWithoutRssFeedInput.schema';
import { PodcastUncheckedCreateWithoutRssFeedInputObjectSchema } from './PodcastUncheckedCreateWithoutRssFeedInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastUpsertWithoutRssFeedInput> = z
  .object({
    update: z.union([
      z.lazy(() => PodcastUpdateWithoutRssFeedInputObjectSchema),
      z.lazy(() => PodcastUncheckedUpdateWithoutRssFeedInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => PodcastCreateWithoutRssFeedInputObjectSchema),
      z.lazy(() => PodcastUncheckedCreateWithoutRssFeedInputObjectSchema),
    ]),
  })
  .strict();

export const PodcastUpsertWithoutRssFeedInputObjectSchema = Schema;
