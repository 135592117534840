import { z } from 'zod';

export type Void = z.infer<typeof Void>;
export const Void = z.void();

export function shuffle<Item = unknown>(items: Array<Item>) {
  const shuffledItems = [...items];
  let oldElement;

  for (let i = shuffledItems.length - 1; i > 0; i--) {
    const rand = Math.floor(Math.random() * (i + 1));
    oldElement = shuffledItems[i];
    shuffledItems[i] = shuffledItems[rand];
    shuffledItems[rand] = oldElement;
  }

  return shuffledItems;
}
