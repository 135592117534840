import * as z from "zod"

export const FileSchema = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  name: z.string(),
  type: z.string().nullish(),
  size: z.number().int().nullish(),
  provider: z.string().nullish(),
  path: z.string().nullish(),
  url: z.string().nullish(),
})
