import { z } from 'zod';
import { PodcastStatusSchema } from '../enums/PodcastStatus.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.NestedEnumPodcastStatusFilter> = z
  .object({
    equals: z.lazy(() => PodcastStatusSchema).optional(),
    in: z
      .lazy(() => PodcastStatusSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => PodcastStatusSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => PodcastStatusSchema),
        z.lazy(() => NestedEnumPodcastStatusFilterObjectSchema),
      ])
      .optional(),
  })
  .strict();

export const NestedEnumPodcastStatusFilterObjectSchema = Schema;
