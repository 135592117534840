import * as React from 'react';

import { Box, Button, Input, Text, Textarea, useToast } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

import { CreateContactBody } from '@theqube/schemas';
import {
  EmailInput,
  ErrorWrapper,
  Form,
  FormField,
  PageHeading,
} from '../../../components';
import { trpc } from '../../../utils';

const defaultValues: CreateContactBody = {
  name: '',
  email: '',
  message: '',
};

export function ContactForm() {
  const toast = useToast();
  const [txtAreaCount, setTxtAreaCount] = React.useState(0);

  const form = useForm<CreateContactBody>({
    mode: 'onChange',
    resolver: zodResolver(CreateContactBody),
    defaultValues,
  });

  const {
    handleSubmit,
    reset: formReset,
    formState: { isDirty, isValid },
  } = form;

  const {
    mutateAsync: sendMessage,
    isLoading,
    isSuccess,
    error,
    reset: mutationReset,
  } = trpc.support.contact.useMutation({
    onSuccess() {
      toast({
        title: 'Form Submitted Successfully.',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    },
  });

  const handleFormSubmit = handleSubmit((formData) => sendMessage(formData));

  const txtAreaCalculate = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setTxtAreaCount(e.target.value.length);
  };

  const reset = () => {
    formReset();
    mutationReset();
  };

  if (error) {
    return <ErrorWrapper error={error} reset={reset} />;
  }

  return isSuccess ? (
    <>
      <PageHeading fontSize="xl">Thank You For Contacting Us. </PageHeading>
      <Text> Someone from our team will contact you shortly.</Text>
    </>
  ) : (
    <>
      <PageHeading fontSize="xl">Send Us A Message</PageHeading>

      <Form
        context={form}
        onSubmit={handleFormSubmit}
        opacity={isLoading ? 0.5 : 1}
        pointerEvents={isLoading ? 'none' : 'auto'}
      >
        <FormField
          name="name"
          label="Name"
          containerStyle={{ my: '4', isRequired: true }}
          render={({ field }) => (
            <Input
              {...field}
              id="name"
              type="text"
              placeholder="What is your name?"
              focusBorderColor="gold.500"
              errorBorderColor="error"
            />
          )}
        />

        <EmailInput
          name="email"
          label="Email"
          containerStyle={{ my: '4', isRequired: true }}
        />

        <FormField
          name="message"
          label="Message"
          containerStyle={{ my: '4', isRequired: true }}
          helperText={`Remaining: ${600 - txtAreaCount}`}
          render={({ field }) => (
            <Textarea
              {...field}
              id="message"
              placeholder="Enter Message"
              focusBorderColor="gold.500"
              errorBorderColor="red.500"
              border="1px solid #707070"
              bgColor="#3A3A3B"
              onChange={(e) => {
                field.onChange(e);
                txtAreaCalculate(e);
              }}
            />
          )}
        />

        <Box display="flex" justifyContent="right" mt={10}>
          <Button
            type="submit"
            disabled={!isDirty || !isValid}
            isLoading={isLoading}
          >
            Send Message
          </Button>
        </Box>
      </Form>
    </>
  );
}
