import { z } from 'zod';

export type Username = z.infer<typeof Username>;

export type FacebookUsername = z.infer<typeof FacebookUsername>;

const SluggedUsername = z
  .string()
  .max(144)
  // remove leading and trailing whitespace
  .transform((value) => value.trim())
  .transform((value) => value.replace(/^@+/, '')) // remove leading @s

  .transform((value) => value.replace(/\/.*$/, '')); // remove anything up to (and including) the last slash, if there is one

export const Username = SluggedUsername.transform((value) =>
  value.toLowerCase()
).refine(
  (username) => username === '' || /^[a-z0-9\-_.]+$/.test(username),
  'Username cannot contain special characters. (only a-z, A-Z, 0-9, and _ are allowed)'
);

export const FacebookUsername = SluggedUsername.refine(
  (username) => username === '' || /^[a-zA-Z0-9\-_.\s]+$/.test(username),
  'Username cannot contain special characters. (only a-z, A-Z, 0-9, and -, _, ., and <space> are allowed)'
);
