import { z } from 'zod';
import { RssFeedCreateWithoutPodcastInputObjectSchema } from './RssFeedCreateWithoutPodcastInput.schema';
import { RssFeedUncheckedCreateWithoutPodcastInputObjectSchema } from './RssFeedUncheckedCreateWithoutPodcastInput.schema';
import { RssFeedCreateOrConnectWithoutPodcastInputObjectSchema } from './RssFeedCreateOrConnectWithoutPodcastInput.schema';
import { RssFeedUpsertWithoutPodcastInputObjectSchema } from './RssFeedUpsertWithoutPodcastInput.schema';
import { RssFeedWhereUniqueInputObjectSchema } from './RssFeedWhereUniqueInput.schema';
import { RssFeedUpdateWithoutPodcastInputObjectSchema } from './RssFeedUpdateWithoutPodcastInput.schema';
import { RssFeedUncheckedUpdateWithoutPodcastInputObjectSchema } from './RssFeedUncheckedUpdateWithoutPodcastInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.RssFeedUpdateOneWithoutPodcastNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => RssFeedCreateWithoutPodcastInputObjectSchema),
            z.lazy(() => RssFeedUncheckedCreateWithoutPodcastInputObjectSchema),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .lazy(() => RssFeedCreateOrConnectWithoutPodcastInputObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .lazy(() => RssFeedUpsertWithoutPodcastInputObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        disconnect: z.boolean().optional(),
      })
      .strict(),
    z
      .object({
        delete: z.boolean().optional(),
      })
      .strict(),
    z
      .object({
        connect: z.lazy(() => RssFeedWhereUniqueInputObjectSchema).optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(() => RssFeedUpdateWithoutPodcastInputObjectSchema),
            z.lazy(() => RssFeedUncheckedUpdateWithoutPodcastInputObjectSchema),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const RssFeedUpdateOneWithoutPodcastNestedInputObjectSchema = Schema;
