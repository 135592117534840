import * as React from 'react';
import { VStack, Box, StackProps, Icon, As } from '@chakra-ui/react';
import { MdMic } from 'react-icons/md';

export type DetailsProps = StackProps & {
  children: React.ReactNode;
};

export type DetailProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: As<any>;
  children: React.ReactNode;
};

export function Details({ children, ...props }: DetailsProps) {
  return (
    <VStack
      spacing={2}
      marginY="12"
      align="start"
      justify="center"
      rounded="md"
      bg="whiteAlpha.300"
      shadow="md"
      borderWidth="2px"
      borderStyle="solid"
      borderColor="themeGold.500"
      {...props}
    >
      {children}
    </VStack>
  );
}

export function Detail({ children, icon = MdMic }: DetailProps) {
  return (
    <Box display="flex" alignItems="center" justifyContent="start">
      <Icon as={icon} w={6} h={6} mr="2" color="themeGold.500" /> {children}
    </Box>
  );
}
