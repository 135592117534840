import { z } from 'zod';
import { EpisodeWhereInputObjectSchema } from './objects/EpisodeWhereInput.schema';
import { EpisodeOrderByWithAggregationInputObjectSchema } from './objects/EpisodeOrderByWithAggregationInput.schema';
import { EpisodeScalarWhereWithAggregatesInputObjectSchema } from './objects/EpisodeScalarWhereWithAggregatesInput.schema';
import { EpisodeScalarFieldEnumSchema } from './enums/EpisodeScalarFieldEnum.schema';

export const EpisodeGroupBySchema = z.object({
  where: EpisodeWhereInputObjectSchema.optional(),
  orderBy: z
    .union([
      EpisodeOrderByWithAggregationInputObjectSchema,
      EpisodeOrderByWithAggregationInputObjectSchema.array(),
    ])
    .optional(),
  having: EpisodeScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(EpisodeScalarFieldEnumSchema),
});
