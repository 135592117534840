import { Box, Flex, Heading, Image, Stack } from '@chakra-ui/react';

import { partnerData } from './data';

export type NetworkPartnersProps = {
  data?: Array<{ name: string; imageUrl: string }>;
};

export function NetworkPartners(props: NetworkPartnersProps) {
  const { data: partners = partnerData } = props;

  return (
    <Flex
      as="section"
      mb={100}
      mt={-75}
      h="40vh"
      justify="center"
      alignItems="center"
    >
      <Stack w="85%" justify="center" align="center" spacing={10}>
        <Heading as={'h4'} fontSize="2xl" p={5}>
          Our Partner Networks
        </Heading>

        <Flex
          flexFlow="row wrap"
          justifyContent="space-evenly"
          alignItems="center"
        >
          {partners?.map(({ name, imageUrl }) => {
            return (
              <Box
                key={name}
                w="150px"
                flexShrink={1}
                m={8}
                justifyContent="center"
                my="auto"
              >
                <Image w="100%" objectFit="cover" src={imageUrl} alt={name} />
              </Box>
            );
          })}
        </Flex>
      </Stack>
    </Flex>
  );
}
