import { useEffect } from 'react';
import { Heading } from '@chakra-ui/react';
import { PageHero } from '../../components/heros/page';

import { trpc, useAnalytics } from '../../utils';

type TagHeaderProps = {
  tagId: number;
};
export function TagHeader({ tagId }: TagHeaderProps) {
  const { gtag } = useAnalytics();

  const { data: tag, isSuccess } = trpc.tags.findOne.useQuery(
    {
      where: tagId ? { id: +tagId } : { id: 0 },
      orderBy: { publishedAt: 'desc' },
    },
    { enabled: Boolean(tagId) }
  );

  useEffect(() => {
    if (gtag && tag) {
      gtag('event', 'view_item_list', {
        item_list_id: tag.id,
        item_list_name: tag.name,
      });
    }
  }, [gtag, tag]);

  return (
    <PageHero isLoaded={isSuccess}>
      <Heading textAlign="center">{tag?.name}</Heading>
    </PageHero>
  );
}
