import { z } from 'zod';
import { AdvertisementWhereUniqueInputObjectSchema } from './objects/AdvertisementWhereUniqueInput.schema';
import { AdvertisementCreateInputObjectSchema } from './objects/AdvertisementCreateInput.schema';
import { AdvertisementUpdateInputObjectSchema } from './objects/AdvertisementUpdateInput.schema';

export const AdvertisementUpsertSchema = z.object({
  where: AdvertisementWhereUniqueInputObjectSchema,
  create: AdvertisementCreateInputObjectSchema,
  update: AdvertisementUpdateInputObjectSchema,
});
