import { z } from 'zod';
import { SortOrderSchema } from '../enums/SortOrder.schema';
import { TokenCountOrderByAggregateInputObjectSchema } from './TokenCountOrderByAggregateInput.schema';
import { TokenMaxOrderByAggregateInputObjectSchema } from './TokenMaxOrderByAggregateInput.schema';
import { TokenMinOrderByAggregateInputObjectSchema } from './TokenMinOrderByAggregateInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.TokenOrderByWithAggregationInput> = z
  .object({
    identifier: z.lazy(() => SortOrderSchema).optional(),
    token: z.lazy(() => SortOrderSchema).optional(),
    expires: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => TokenCountOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z.lazy(() => TokenMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => TokenMinOrderByAggregateInputObjectSchema).optional(),
  })
  .strict();

export const TokenOrderByWithAggregationInputObjectSchema = Schema;
