import { z } from 'zod';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';
import { PodcastCreateWithoutRssFeedInputObjectSchema } from './PodcastCreateWithoutRssFeedInput.schema';
import { PodcastUncheckedCreateWithoutRssFeedInputObjectSchema } from './PodcastUncheckedCreateWithoutRssFeedInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastCreateOrConnectWithoutRssFeedInput> = z
  .object({
    where: z.lazy(() => PodcastWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => PodcastCreateWithoutRssFeedInputObjectSchema),
      z.lazy(() => PodcastUncheckedCreateWithoutRssFeedInputObjectSchema),
    ]),
  })
  .strict();

export const PodcastCreateOrConnectWithoutRssFeedInputObjectSchema = Schema;
