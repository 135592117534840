import { Spinner } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { PageHeading } from '../../components';
import { LoginForm, useSession, useOnboarding } from '../../features';
import { StudioRoutes } from '../../utils';
import { BaseLayout, LayoutProps } from './base';

export type UserLayoutProps = LayoutProps & {
  fallback?: () => JSX.Element;
};

export function UserLayout({
  children,
  fallback: Fallback = LoginForm,
  ...props
}: UserLayoutProps) {
  const router = useRouter();
  const [{ data: session, isSuccess }] = useSession();

  // Determines if the current user should be redirected to a specific preferences screen,
  // and if so which one
  const { shouldGoToPreferences } = useOnboarding();

  useEffect(() => {
    if (isSuccess && shouldGoToPreferences) {
      const { href } = StudioRoutes.account.children?.preferences ?? {};
      if (href) {
        router.push(href(shouldGoToPreferences));
      }
    }
  }, [shouldGoToPreferences, router, isSuccess]);

  if (session && isSuccess) {
    if (shouldGoToPreferences) {
      return (
        <BaseLayout
          {...props}
          mainProps={{
            py: '4',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <PageHeading>
            One moment. We're taking you to your account.
          </PageHeading>

          <Spinner display="block" mx="auto" h="16" w="16" />
        </BaseLayout>
      );
    }

    return (
      <BaseLayout {...props} mainProps={{ py: '4' }} footer={null}>
        {children}
      </BaseLayout>
    );
  }

  return (
    <BaseLayout {...props}>
      <Fallback />
    </BaseLayout>
  );
}
