import { z } from 'zod';

import { FacebookUsername, Username } from '../username';

/**
 * These are the known headers from the CSV file used for seeding.
 *
 * They're used to map the CSV headers to the ZOD schema and eventually to the create input for the podcast service.
 */
export type PodcastCSVHeader = z.infer<typeof PodcastCSVHeader>;
export const PodcastCSVHeader = z.enum([
  'Podcast Creator Name(s)',
  'Creator Bio (150 words or less)',
  'Name of Podcast',
  'Podcast Description',
  'Podcast Website',
  'Podcast Artwork',
  'Email Address',
  'Is your podcast considered explicit?',
  'RSS Feed',
  'Podcast Categories (choose no more than 3)',
  'Podcast Affiliations',
  'How do you identify? (Check All that Apply)',
  'Was one or more of your identities not included? Help us out and add them below. We want your full self to be represented.',
  'Facebook Page',
  'Twitter Handle',
  'Tik Tok Handle',
  'IG Handle',
]);
/**
 * The schema for the podcast CSV file.
 *
 * This is used to validate the CSV file against {@link PodcastCSVHeader}s.
 */
export type PodcastCSVSchema = z.infer<typeof PodcastCSVSchema>;
export const PodcastCSVSchema = z.object({
  [PodcastCSVHeader.enum['Podcast Creator Name(s)']]: z.string().optional(),
  [PodcastCSVHeader.enum['Creator Bio (150 words or less)']]: z
    .string()
    .optional(),
  [PodcastCSVHeader.enum['Name of Podcast']]: z.string().optional(),
  [PodcastCSVHeader.enum['Podcast Description']]: z.string().optional(),
  [PodcastCSVHeader.enum['Podcast Website']]: z.string().url().optional(),
  [PodcastCSVHeader.enum['Podcast Artwork']]: z.string().optional(), // should be a url, but it's only used as a fallback if rss feed doesn't have an image
  [PodcastCSVHeader.enum['Email Address']]: z.string().email().optional(),
  [PodcastCSVHeader.enum['Is your podcast considered explicit?']]: z
    .string()
    .optional(),
  [PodcastCSVHeader.enum['RSS Feed']]: z.string().url().optional(),
  [PodcastCSVHeader.enum['Podcast Categories (choose no more than 3)']]:
    z.string(),
  [PodcastCSVHeader.enum['Podcast Affiliations']]: z.string().optional(),
  [PodcastCSVHeader.enum['How do you identify? (Check All that Apply)']]: z
    .string()
    .optional(),
  [PodcastCSVHeader.enum[
    'Was one or more of your identities not included? Help us out and add them below. We want your full self to be represented.'
  ]]: z.string().optional(),
  [PodcastCSVHeader.enum['Facebook Page']]: FacebookUsername,
  [PodcastCSVHeader.enum['Twitter Handle']]: Username,
  [PodcastCSVHeader.enum['Tik Tok Handle']]: Username,
  [PodcastCSVHeader.enum['IG Handle']]: Username,
});
