import { z } from 'zod';
import { EpisodeWhereInputObjectSchema } from './objects/EpisodeWhereInput.schema';
import { EpisodeOrderByWithRelationInputObjectSchema } from './objects/EpisodeOrderByWithRelationInput.schema';
import { EpisodeWhereUniqueInputObjectSchema } from './objects/EpisodeWhereUniqueInput.schema';
import { EpisodeScalarFieldEnumSchema } from './enums/EpisodeScalarFieldEnum.schema';

export const EpisodeFindFirstSchema = z.object({
  where: EpisodeWhereInputObjectSchema.optional(),
  orderBy: z
    .union([
      EpisodeOrderByWithRelationInputObjectSchema,
      EpisodeOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  cursor: EpisodeWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(EpisodeScalarFieldEnumSchema).optional(),
});
