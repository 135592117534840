import { z } from 'zod';
import { PodcastStatusSchema } from '../enums/PodcastStatus.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.EnumPodcastStatusFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => PodcastStatusSchema).optional(),
  })
  .strict();

export const EnumPodcastStatusFieldUpdateOperationsInputObjectSchema = Schema;
