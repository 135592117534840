import { z } from 'zod';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserUpdateWithoutPodcastsAssignedInputObjectSchema } from './UserUpdateWithoutPodcastsAssignedInput.schema';
import { UserUncheckedUpdateWithoutPodcastsAssignedInputObjectSchema } from './UserUncheckedUpdateWithoutPodcastsAssignedInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUpdateWithWhereUniqueWithoutPodcastsAssignedInput> =
  z
    .object({
      where: z.lazy(() => UserWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => UserUpdateWithoutPodcastsAssignedInputObjectSchema),
        z.lazy(
          () => UserUncheckedUpdateWithoutPodcastsAssignedInputObjectSchema
        ),
      ]),
    })
    .strict();

export const UserUpdateWithWhereUniqueWithoutPodcastsAssignedInputObjectSchema =
  Schema;
