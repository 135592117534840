import { z } from 'zod';
import { SortOrderSchema } from '../enums/SortOrder.schema';
import { CurationCountOrderByAggregateInputObjectSchema } from './CurationCountOrderByAggregateInput.schema';
import { CurationAvgOrderByAggregateInputObjectSchema } from './CurationAvgOrderByAggregateInput.schema';
import { CurationMaxOrderByAggregateInputObjectSchema } from './CurationMaxOrderByAggregateInput.schema';
import { CurationMinOrderByAggregateInputObjectSchema } from './CurationMinOrderByAggregateInput.schema';
import { CurationSumOrderByAggregateInputObjectSchema } from './CurationSumOrderByAggregateInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.CurationOrderByWithAggregationInput> = z
  .object({
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    podcastId: z.lazy(() => SortOrderSchema).optional(),
    curatorId: z.lazy(() => SortOrderSchema).optional(),
    contactName: z.lazy(() => SortOrderSchema).optional(),
    contactEmail: z.lazy(() => SortOrderSchema).optional(),
    wouldRecommend: z.lazy(() => SortOrderSchema).optional(),
    ratings: z.lazy(() => SortOrderSchema).optional(),
    notes: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => CurationCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z.lazy(() => CurationAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => CurationMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => CurationMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => CurationSumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict();

export const CurationOrderByWithAggregationInputObjectSchema = Schema;
