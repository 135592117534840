import { z } from 'zod';
import { EpisodeCreateWithoutPodcastInputObjectSchema } from './EpisodeCreateWithoutPodcastInput.schema';
import { EpisodeUncheckedCreateWithoutPodcastInputObjectSchema } from './EpisodeUncheckedCreateWithoutPodcastInput.schema';
import { EpisodeCreateOrConnectWithoutPodcastInputObjectSchema } from './EpisodeCreateOrConnectWithoutPodcastInput.schema';
import { EpisodeUpsertWithWhereUniqueWithoutPodcastInputObjectSchema } from './EpisodeUpsertWithWhereUniqueWithoutPodcastInput.schema';
import { EpisodeCreateManyPodcastInputEnvelopeObjectSchema } from './EpisodeCreateManyPodcastInputEnvelope.schema';
import { EpisodeWhereUniqueInputObjectSchema } from './EpisodeWhereUniqueInput.schema';
import { EpisodeUpdateWithWhereUniqueWithoutPodcastInputObjectSchema } from './EpisodeUpdateWithWhereUniqueWithoutPodcastInput.schema';
import { EpisodeUpdateManyWithWhereWithoutPodcastInputObjectSchema } from './EpisodeUpdateManyWithWhereWithoutPodcastInput.schema';
import { EpisodeScalarWhereInputObjectSchema } from './EpisodeScalarWhereInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.EpisodeUpdateManyWithoutPodcastNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => EpisodeCreateWithoutPodcastInputObjectSchema),
            z.lazy(() => EpisodeCreateWithoutPodcastInputObjectSchema).array(),
            z.lazy(() => EpisodeUncheckedCreateWithoutPodcastInputObjectSchema),
            z
              .lazy(() => EpisodeUncheckedCreateWithoutPodcastInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(() => EpisodeCreateOrConnectWithoutPodcastInputObjectSchema),
            z
              .lazy(() => EpisodeCreateOrConnectWithoutPodcastInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .union([
            z.lazy(
              () => EpisodeUpsertWithWhereUniqueWithoutPodcastInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  EpisodeUpsertWithWhereUniqueWithoutPodcastInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        createMany: z
          .lazy(() => EpisodeCreateManyPodcastInputEnvelopeObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        set: z
          .union([
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema),
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        disconnect: z
          .union([
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema),
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        delete: z
          .union([
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema),
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema),
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(
              () => EpisodeUpdateWithWhereUniqueWithoutPodcastInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  EpisodeUpdateWithWhereUniqueWithoutPodcastInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        updateMany: z
          .union([
            z.lazy(
              () => EpisodeUpdateManyWithWhereWithoutPodcastInputObjectSchema
            ),
            z
              .lazy(
                () => EpisodeUpdateManyWithWhereWithoutPodcastInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        deleteMany: z
          .union([
            z.lazy(() => EpisodeScalarWhereInputObjectSchema),
            z.lazy(() => EpisodeScalarWhereInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const EpisodeUpdateManyWithoutPodcastNestedInputObjectSchema = Schema;
