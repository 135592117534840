import { Button, ButtonGroup, useToast } from '@chakra-ui/react';

import { ForgotPasswordBody } from '@theqube/schemas';
import { EmailInput, ErrorWrapper, Form } from '../../../components';
import { trpc, useZodForm } from '../../../utils';

const defaultValues: ForgotPasswordBody = {
  email: '',
};

export type ForgotPasswordFormProps = {
  onSuccess?: () => void;
};

export function ForgotPasswordForm({ onSuccess }: ForgotPasswordFormProps) {
  const toast = useToast();

  const {
    mutate: sendEmail,
    isLoading,
    isError,
    error,
    reset,
  } = trpc.auth.password['reset--request'].useMutation({
    onSuccess() {
      toast({
        status: 'success',
        title: 'Email Sent!',
        description: 'Check your email for a link to reset your password.',
      });

      onSuccess?.();
    },
  });

  const form = useZodForm({
    mode: 'onChange',
    defaultValues: defaultValues,
    schema: ForgotPasswordBody,
  });

  const handleFormSubmit = form.handleSubmit(async (values) => {
    return sendEmail(values);
  });

  if (error) {
    return <ErrorWrapper error={error} reset={reset} />;
  }

  return (
    <Form context={form} my="12" onSubmit={handleFormSubmit}>
      <EmailInput name="email" label="Email" />

      <ButtonGroup display="flex" justifyContent="flex-end" my="4">
        <Button
          type="submit"
          isLoading={form.formState.isSubmitting || isLoading}
          disabled={form.formState.isSubmitting || isLoading}
          bg={isError || !form.formState.isValid ? 'error' : 'themeGold.500'}
        >
          Submit
        </Button>
      </ButtonGroup>
    </Form>
  );
}
