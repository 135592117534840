import { z } from 'zod';
import { SortOrderSchema } from '../enums/SortOrder.schema';
import { EpisodeCountOrderByAggregateInputObjectSchema } from './EpisodeCountOrderByAggregateInput.schema';
import { EpisodeAvgOrderByAggregateInputObjectSchema } from './EpisodeAvgOrderByAggregateInput.schema';
import { EpisodeMaxOrderByAggregateInputObjectSchema } from './EpisodeMaxOrderByAggregateInput.schema';
import { EpisodeMinOrderByAggregateInputObjectSchema } from './EpisodeMinOrderByAggregateInput.schema';
import { EpisodeSumOrderByAggregateInputObjectSchema } from './EpisodeSumOrderByAggregateInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.EpisodeOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    publishedAt: z.lazy(() => SortOrderSchema).optional(),
    podcastId: z.lazy(() => SortOrderSchema).optional(),
    title: z.lazy(() => SortOrderSchema).optional(),
    description: z.lazy(() => SortOrderSchema).optional(),
    imageUrl: z.lazy(() => SortOrderSchema).optional(),
    audioUrl: z.lazy(() => SortOrderSchema).optional(),
    duration: z.lazy(() => SortOrderSchema).optional(),
    explicit: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => EpisodeCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z.lazy(() => EpisodeAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => EpisodeMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => EpisodeMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => EpisodeSumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict();

export const EpisodeOrderByWithAggregationInputObjectSchema = Schema;
