import { z } from 'zod';

export const CurationScalarFieldEnumSchema = z.enum([
  'updatedAt',
  'podcastId',
  'curatorId',
  'contactName',
  'contactEmail',
  'wouldRecommend',
  'ratings',
  'notes',
]);
