import * as Sentry from '@sentry/nextjs';
import * as React from 'react';

import { ErrorWrapper } from '../components';

/**
 * Custom Next.JS Error boundary.
 *
 * @see https://nextjs.org/docs/advanced-features/error-handling
 */
export class ErrorBoundary extends React.Component {
  override props: {
    children: React.ReactNode;
    FallbackComponent?: React.ReactNode;
  };
  override state: { hasError: boolean } = { hasError: false };

  constructor(props: { children: React.ReactNode }) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
    this.props = props;
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI

    return { hasError: Boolean(error) };
  }

  override componentDidCatch(error: unknown, _: React.ErrorInfo) {
    Sentry.captureException(error);
  }

  override render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        this.props.FallbackComponent ?? (
          <ErrorWrapper reset={() => this.setState({ hasError: false })} />
        )
      );
    }

    // Return children components in case of no error
    return this.props.children;
  }
}
