import { z } from 'zod';
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema';
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema';
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema';
import { AccountUncheckedUpdateManyWithoutUserNestedInputObjectSchema } from './AccountUncheckedUpdateManyWithoutUserNestedInput.schema';
import { SessionUncheckedUpdateManyWithoutUserNestedInputObjectSchema } from './SessionUncheckedUpdateManyWithoutUserNestedInput.schema';
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema';
import { TagUncheckedUpdateManyWithoutUsersNestedInputObjectSchema } from './TagUncheckedUpdateManyWithoutUsersNestedInput.schema';
import { SettingsUncheckedUpdateOneWithoutOwnerNestedInputObjectSchema } from './SettingsUncheckedUpdateOneWithoutOwnerNestedInput.schema';
import { PermissionsUncheckedUpdateOneWithoutOwnerNestedInputObjectSchema } from './PermissionsUncheckedUpdateOneWithoutOwnerNestedInput.schema';
import { PlaylistUncheckedUpdateManyWithoutOwnersNestedInputObjectSchema } from './PlaylistUncheckedUpdateManyWithoutOwnersNestedInput.schema';
import { PlaylistUncheckedUpdateManyWithoutSubscribersNestedInputObjectSchema } from './PlaylistUncheckedUpdateManyWithoutSubscribersNestedInput.schema';
import { CurationUncheckedUpdateManyWithoutCuratorNestedInputObjectSchema } from './CurationUncheckedUpdateManyWithoutCuratorNestedInput.schema';
import { PodcastUncheckedUpdateManyWithoutOwnersNestedInputObjectSchema } from './PodcastUncheckedUpdateManyWithoutOwnersNestedInput.schema';
import { PodcastUncheckedUpdateManyWithoutSubscribersNestedInputObjectSchema } from './PodcastUncheckedUpdateManyWithoutSubscribersNestedInput.schema';
import { NullableJsonNullValueInputSchema } from '../enums/NullableJsonNullValueInput.schema';

import type { Prisma } from '@prisma/client';

const literalSchema = z.union([z.string(), z.number(), z.boolean()]);
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ])
);

const Schema: z.ZodType<Prisma.UserUncheckedUpdateWithoutPodcastsAssignedInput> =
  z
    .object({
      id: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      avatarUrl: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      displayName: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      fullName: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      email: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      emailVerified: z
        .union([
          z.date(),
          z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      passwordHash: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      accounts: z
        .lazy(
          () => AccountUncheckedUpdateManyWithoutUserNestedInputObjectSchema
        )
        .optional(),
      sessions: z
        .lazy(
          () => SessionUncheckedUpdateManyWithoutUserNestedInputObjectSchema
        )
        .optional(),
      createdAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      updatedAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      tags: z
        .lazy(() => TagUncheckedUpdateManyWithoutUsersNestedInputObjectSchema)
        .optional(),
      settings: z
        .lazy(
          () => SettingsUncheckedUpdateOneWithoutOwnerNestedInputObjectSchema
        )
        .optional(),
      permissions: z
        .lazy(
          () => PermissionsUncheckedUpdateOneWithoutOwnerNestedInputObjectSchema
        )
        .optional(),
      playlists: z
        .lazy(
          () => PlaylistUncheckedUpdateManyWithoutOwnersNestedInputObjectSchema
        )
        .optional(),
      playlistSubscriptions: z
        .lazy(
          () =>
            PlaylistUncheckedUpdateManyWithoutSubscribersNestedInputObjectSchema
        )
        .optional(),
      podcastsCurated: z
        .lazy(
          () => CurationUncheckedUpdateManyWithoutCuratorNestedInputObjectSchema
        )
        .optional(),
      podcasts: z
        .lazy(
          () => PodcastUncheckedUpdateManyWithoutOwnersNestedInputObjectSchema
        )
        .optional(),
      podcastSubscriptions: z
        .lazy(
          () =>
            PodcastUncheckedUpdateManyWithoutSubscribersNestedInputObjectSchema
        )
        .optional(),
      biography: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      birthday: z
        .union([
          z.date(),
          z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      gender: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      socialMedia: z
        .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
        .optional(),
    })
    .strict();

export const UserUncheckedUpdateWithoutPodcastsAssignedInputObjectSchema =
  Schema;
