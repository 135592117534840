import { chakra, ChakraProps } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { environment, useInterval } from '../../utils';
import { useSession } from './use-session';

type GoogleButtonProps = {
  opts?: GsiButtonConfiguration;
  style?: ChakraProps;
};

export function GoogleButton({
  opts = {
    type: 'standard',
    width: '100%',
    logo_alignment: 'center',
    size: 'large',
    shape: 'pill',
    theme: 'outline',
    text: 'continue_with',
  },
  style = {
    maxWidth: '48',
    mx: 'auto',
    my: '4',
  },
}: GoogleButtonProps): JSX.Element {
  const [_, { continueWithGoogle }] = useSession();
  const buttonRef = useRef<HTMLDivElement>(null);

  const [google, setGoogle] = useState<Google>();
  const [googleIsLoading, setGoogleIsLoading] = useState(true);

  useInterval(
    () => {
      if (typeof window !== 'undefined' && window.google) {
        setGoogle(window.google);
        setGoogleIsLoading(false);
      }
    },
    googleIsLoading ? 100 : null
  );

  useEffect(() => {
    if (!google || !buttonRef.current) {
      return;
    }

    try {
      google.accounts.id.initialize({
        client_id: environment.get('NEXT_PUBLIC_GCP_CLIENT_ID'),
        callback: async ({ credential: token }) => {
          if (token) return continueWithGoogle({ token }).catch(() => null);
          return false;
        },
      });
      google.accounts.id.renderButton(buttonRef.current, opts);
    } catch (error) {
      alert('Error');
    }
  }, [google, continueWithGoogle, opts]);

  return <chakra.div ref={buttonRef} {...style} />;
}
