import { StyleConfig } from '@chakra-ui/theme-tools';

export const Text: StyleConfig = {
  baseStyle: {
    // color: 'white',
  },
  variants: {
    tagline: {
      maxW: '60ch',
      mx: 'auto',
      fontSize: 'xl',
      fontWeight: 'semibold',
      lineHeight: 'tall',
      textAlign: 'center',
      color: 'whiteAlpha.700',
    },
  },
};
