import { Button, ButtonGroup, Text } from '@chakra-ui/react';

import { RegisterInput } from '@theqube/schemas';
import {
  DatedCheckbox,
  DateInput,
  EmailInput,
  Form,
  Link,
  PasswordInput,
  TextInput,
} from '../../../components';
import { environment, StudioRoutes, useZodForm } from '../../../utils';
import { useSession } from '../use-session';

/** Use some default values for development */
const isDev = !environment.isProduction;

const defaultValues: RegisterInput = {
  email: isDev ? 'example@email.com' : '',
  password: isDev ? 'password' : '',
  passwordConfirm: isDev ? 'password' : '',

  fullName: isDev ? 'John Doe' : '',
  birthday: new Date('2000-01-01'),
  settings: {
    acceptedNewsletterAt: isDev ? new Date() : null,
    acceptedPrivacyAt: isDev ? new Date() : null,
    acceptedTermsAt: isDev ? new Date() : null,
  },
};

type RegisterFormProps = {
  onSuccess: (values: RegisterInput) => void | Promise<void>;
  onError: (error: Error) => void | Promise<void>;
};

export function RegisterForm({ onSuccess, onError }: RegisterFormProps) {
  const [, { register }] = useSession();

  const form = useZodForm({
    schema: RegisterInput,
    defaultValues,
  });

  const handleFormSubmit = form.handleSubmit(async (formData) => {
    return register(formData)
      .then(() => onSuccess(formData))
      .catch(onError);
  });

  return (
    <>
      <Form context={form}>
        <EmailInput name="email" label="Email" />

        <PasswordInput name="password" label="Password" />
        <PasswordInput name="passwordConfirm" label="Confirm Password" />

        <TextInput
          name="fullName"
          label="Full name"
          helperText="This helps us stay legal"
        />

        <DateInput name="birthday" label="Birthday" />

        <DatedCheckbox
          name="settings.acceptedNewsletterAt"
          label="Receive announcements, updates, and offers from E3"
          input={{
            flex: '1',
            flexBasis: '100%',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            mr: '2',
            lineHeight: '0.9',
            colorScheme: 'themeGold',
            borderColor: 'themeGold.500',
          }}
        />
        <DatedCheckbox
          name="settings.acceptedTermsAt"
          label={
            <Text>
              Agree to our{' '}
              <Link href={StudioRoutes.info.children?.terms.href ?? ''}>
                Terms & Conditions
              </Link>
            </Text>
          }
          input={{
            flex: '1',
            flexBasis: '100%',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            mr: '2',
            lineHeight: '0.9',
            colorScheme: 'themeGold',
            borderColor: 'themeGold.500',
          }}
        />

        <DatedCheckbox
          name="settings.acceptedPrivacyAt"
          label={
            <Text>
              Agree to our{' '}
              <Link href={StudioRoutes.info.children?.privacy.href ?? ''}>
                Privacy Policy
              </Link>
            </Text>
          }
          input={{
            flex: '1',
            flexBasis: '100%',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            mr: '2',
            lineHeight: '0.9',
            colorScheme: 'themeGold',
            borderColor: 'themeGold.500',
          }}
        />

        <ButtonGroup display="flex" justifyContent="center" my="4">
          <Button
            onClick={handleFormSubmit}
            isLoading={form.formState.isSubmitting}
            disabled={form.formState.isSubmitting}
            bg={!form.formState.isValid ? 'error' : 'themeGold.500'}
          >
            Sign Up
          </Button>
        </ButtonGroup>
      </Form>

      <Text mt="6" textAlign="center" fontSize="xs" maxW="90%" mx="auto">
        By selecting &quot;Sign Up&quot; you confirm that you have read and
        agreed to E3&apos;s{' '}
        <Link
          href={StudioRoutes['info'].children?.terms.href ?? '#'}
          fontWeight="bold"
        >
          Terms &amp; Conditions
        </Link>{' '}
        and{' '}
        <Link
          href={StudioRoutes['info'].children?.privacy.href ?? '#'}
          fontWeight="bold"
        >
          Privacy Policy
        </Link>
        .
      </Text>

      <Text my="12" textAlign="center" fontWeight="bold">
        Already in the Qube?{' '}
        <Link
          href={StudioRoutes['auth'].children?.login.href ?? '#'}
          fontWeight="bold"
          color="themeGold.500"
          textDecoration="underline"
        >
          Log in
        </Link>
        .
      </Text>

      <Text textAlign="center" mt="4">
        <Text as="span" fontWeight="bold">
          Disclaimer:{' '}
        </Text>
        Please note that this software is still in the beta testing phase and
        may contain bugs or other issues. If you encounter any problems, please
        reach out to the development team at{' '}
        <a href="mailto:dev@e3radio.fm" target="_blank" rel="noreferrer">
          dev@e3radio.fm{' '}
        </a>
        for assistance.
      </Text>
    </>
  );
}
