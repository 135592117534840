import { z } from 'zod';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.EpisodeMaxAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    publishedAt: z.literal(true).optional(),
    podcastId: z.literal(true).optional(),
    title: z.literal(true).optional(),
    description: z.literal(true).optional(),
    imageUrl: z.literal(true).optional(),
    audioUrl: z.literal(true).optional(),
    duration: z.literal(true).optional(),
    explicit: z.literal(true).optional(),
  })
  .strict();

export const EpisodeMaxAggregateInputObjectSchema = Schema;
