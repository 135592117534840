import { z } from 'zod';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserUpdateWithoutPlaylistSubscriptionsInputObjectSchema } from './UserUpdateWithoutPlaylistSubscriptionsInput.schema';
import { UserUncheckedUpdateWithoutPlaylistSubscriptionsInputObjectSchema } from './UserUncheckedUpdateWithoutPlaylistSubscriptionsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUpdateWithWhereUniqueWithoutPlaylistSubscriptionsInput> =
  z
    .object({
      where: z.lazy(() => UserWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => UserUpdateWithoutPlaylistSubscriptionsInputObjectSchema),
        z.lazy(
          () => UserUncheckedUpdateWithoutPlaylistSubscriptionsInputObjectSchema
        ),
      ]),
    })
    .strict();

export const UserUpdateWithWhereUniqueWithoutPlaylistSubscriptionsInputObjectSchema =
  Schema;
