import * as z from "zod"
import { Role } from "@prisma/client"
import { CompleteUser, RelatedUserSchema } from "./index"

export const PermissionsSchema = z.object({
  updatedAt: z.date(),
  ownerId: z.string(),
  roles: z.nativeEnum(Role).array(),
  canCreateOriginalPodcast: z.boolean(),
  canReadOriginalPodcast: z.boolean(),
  canUpdateOriginalPodcasts: z.boolean(),
  canDeleteOriginalPodcasts: z.boolean(),
  canCreateAdvertisement: z.boolean(),
  canReadAdvertisement: z.boolean(),
  canUpdateAdvertisements: z.boolean(),
  canDeleteAdvertisements: z.boolean(),
  canCreateUser: z.boolean(),
  canReadUser: z.boolean(),
  canUpdateUsers: z.boolean(),
  canDeleteUsers: z.boolean(),
  canCreateOriginalPlaylist: z.boolean(),
  canReadOriginalPlaylist: z.boolean(),
  canUpdateOriginalPlaylists: z.boolean(),
  canDeleteOriginalPlaylists: z.boolean(),
})

export interface CompletePermissions extends z.infer<typeof PermissionsSchema> {
  owner: CompleteUser
}

/**
 * RelatedPermissionsSchema contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedPermissionsSchema: z.ZodSchema<CompletePermissions> = z.lazy(() => PermissionsSchema.extend({
  owner: RelatedUserSchema,
}))
