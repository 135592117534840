import { z } from 'zod';
import { SortOrderSchema } from '../enums/SortOrder.schema';
import { UserOrderByWithRelationInputObjectSchema } from './UserOrderByWithRelationInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PermissionsOrderByWithRelationInput> = z
  .object({
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    ownerId: z.lazy(() => SortOrderSchema).optional(),
    owner: z.lazy(() => UserOrderByWithRelationInputObjectSchema).optional(),
    roles: z.lazy(() => SortOrderSchema).optional(),
    canCreateOriginalPodcast: z.lazy(() => SortOrderSchema).optional(),
    canReadOriginalPodcast: z.lazy(() => SortOrderSchema).optional(),
    canUpdateOriginalPodcasts: z.lazy(() => SortOrderSchema).optional(),
    canDeleteOriginalPodcasts: z.lazy(() => SortOrderSchema).optional(),
    canCreateAdvertisement: z.lazy(() => SortOrderSchema).optional(),
    canReadAdvertisement: z.lazy(() => SortOrderSchema).optional(),
    canUpdateAdvertisements: z.lazy(() => SortOrderSchema).optional(),
    canDeleteAdvertisements: z.lazy(() => SortOrderSchema).optional(),
    canCreateUser: z.lazy(() => SortOrderSchema).optional(),
    canReadUser: z.lazy(() => SortOrderSchema).optional(),
    canUpdateUsers: z.lazy(() => SortOrderSchema).optional(),
    canDeleteUsers: z.lazy(() => SortOrderSchema).optional(),
    canCreateOriginalPlaylist: z.lazy(() => SortOrderSchema).optional(),
    canReadOriginalPlaylist: z.lazy(() => SortOrderSchema).optional(),
    canUpdateOriginalPlaylists: z.lazy(() => SortOrderSchema).optional(),
    canDeleteOriginalPlaylists: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict();

export const PermissionsOrderByWithRelationInputObjectSchema = Schema;
