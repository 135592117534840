import { z } from 'zod';
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema';
import { StringFilterObjectSchema } from './StringFilter.schema';
import { UserRelationFilterObjectSchema } from './UserRelationFilter.schema';
import { UserWhereInputObjectSchema } from './UserWhereInput.schema';
import { EnumRoleNullableListFilterObjectSchema } from './EnumRoleNullableListFilter.schema';
import { BoolFilterObjectSchema } from './BoolFilter.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PermissionsWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => PermissionsWhereInputObjectSchema),
        z.lazy(() => PermissionsWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => PermissionsWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => PermissionsWhereInputObjectSchema),
        z.lazy(() => PermissionsWhereInputObjectSchema).array(),
      ])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    ownerId: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    owner: z
      .union([
        z.lazy(() => UserRelationFilterObjectSchema),
        z.lazy(() => UserWhereInputObjectSchema),
      ])
      .optional(),
    roles: z.lazy(() => EnumRoleNullableListFilterObjectSchema).optional(),
    canCreateOriginalPodcast: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    canReadOriginalPodcast: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    canUpdateOriginalPodcasts: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    canDeleteOriginalPodcasts: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    canCreateAdvertisement: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    canReadAdvertisement: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    canUpdateAdvertisements: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    canDeleteAdvertisements: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    canCreateUser: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    canReadUser: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    canUpdateUsers: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    canDeleteUsers: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    canCreateOriginalPlaylist: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    canReadOriginalPlaylist: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    canUpdateOriginalPlaylists: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    canDeleteOriginalPlaylists: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
  })
  .strict();

export const PermissionsWhereInputObjectSchema = Schema;
