import { z } from 'zod';
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema';
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema';
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema';
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema';
import { UserUncheckedUpdateManyWithoutPlaylistSubscriptionsNestedInputObjectSchema } from './UserUncheckedUpdateManyWithoutPlaylistSubscriptionsNestedInput.schema';
import { EpisodeUncheckedUpdateManyWithoutPlaylistsNestedInputObjectSchema } from './EpisodeUncheckedUpdateManyWithoutPlaylistsNestedInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistUncheckedUpdateWithoutOwnersInput> = z
  .object({
    id: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    avatarUrl: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    title: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    description: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    subscribers: z
      .lazy(
        () =>
          UserUncheckedUpdateManyWithoutPlaylistSubscriptionsNestedInputObjectSchema
      )
      .optional(),
    episodes: z
      .lazy(
        () => EpisodeUncheckedUpdateManyWithoutPlaylistsNestedInputObjectSchema
      )
      .optional(),
  })
  .strict();

export const PlaylistUncheckedUpdateWithoutOwnersInputObjectSchema = Schema;
