import * as React from 'react';

import { useRouter } from 'next/router';
import { useAnalytics } from './use-analytics';
import { environment } from './environment.next';

export function usePageView() {
  const router = useRouter();
  const { gtag } = useAnalytics();

  React.useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (typeof gtag === 'function') {
        gtag('config', environment.get('NEXT_PUBLIC_GCP_MEASUREMENT_ID'), {
          page_path: url,
        });
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    // Remove Event Listener after un-mount
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, gtag]);
}
