import {
  Box,
  Button,
  ButtonGroup,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { FaFacebookF, FaTiktok, FaTwitter } from 'react-icons/fa';
import { RiInstagramFill } from 'react-icons/ri';

import { MAX_MESSAGE_LENGTH, MusicSubmissionBody } from '@theqube/schemas';
import {
  ErrorWrapper,
  FileInput,
  Form,
  FormField,
  PageHeading,
} from '../../../components';
import { environment, trpc, useZodForm } from '../../../utils';

const defaultValues: MusicSubmissionBody = environment.isProduction
  ? {
      name: '',
      description: '',
      websiteUrl: '',
      attachments: [],
      socialMedia: {
        facebook: '',
        instagram: '',
        tik_tok: '',
        twitter: '',
      },
    }
  : {
      name: 'First Last',
      description:
        'Short description of message. This was made automatically. Please ignore if you are not the author.',
      websiteUrl: 'https://example.com',
      attachments: [],
      socialMedia: {
        facebook: 'e3radio',
        instagram: 'e3radio',
        tik_tok: 'e3radio',
        twitter: 'e3radio',
      },
    };

export function MusicSubmissionForm() {
  const toast = useToast();

  const {
    mutateAsync: submitMusic,
    isLoading,
    isSuccess,
    error,
    reset: resetMutation,
  } = trpc.support.musicians.useMutation({
    onSuccess(data) {
      toast({
        title: 'Form Submitted Successfully.',
        description: data.message,
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    },
    onError() {
      toast({
        status: 'error',
        title: 'Form Submission Failed.',
        description: 'Please check the form for errors and try again.',
        duration: 4000,
        isClosable: true,
      });
    },
  });

  const form = useZodForm<typeof MusicSubmissionBody>({
    mode: 'onChange',
    schema: MusicSubmissionBody,
    defaultValues,
  });

  const {
    watch,
    formState: { isDirty, isValid },
    handleSubmit,
    reset: resetForm,
  } = form;

  const reset = () => {
    resetForm();
    resetMutation();
  };

  const handleFormSubmit = handleSubmit((values) => submitMusic(values));

  const remainingDescription = MAX_MESSAGE_LENGTH - watch('description').length;

  if (error) {
    return <ErrorWrapper error={error} reset={reset} />;
  }

  return (
    <Stack
      w={{ base: '90%', md: '60%' }}
      mx="auto"
      border="1px solid"
      borderColor="gold.500"
      px="10%"
      py="12"
    >
      {isSuccess ? (
        <Box my={5}>
          <PageHeading fontSize="xl" my={5}>
            Submitted Successfully!
          </PageHeading>

          <Text mt={5}>Thanks for submitting your music.</Text>

          <Text mt={5}>
            Someone from our team will follow-up with you shortly.
          </Text>

          <ButtonGroup my="5">
            <Button onClick={reset} variant="outline">
              Submit More Music
            </Button>
          </ButtonGroup>
        </Box>
      ) : (
        <>
          <PageHeading fontSize="xl">Submit Your Music.</PageHeading>

          <Form context={form} onSubmit={handleFormSubmit}>
            <FormField<MusicSubmissionBody, 'name'>
              name="name"
              label="Name"
              containerStyle={{ my: 5, isRequired: true }}
              render={({ field }) => (
                <Input {...field} placeholder="What is your name?" />
              )}
            />

            <FormField<MusicSubmissionBody, 'description'>
              name="description"
              label="Description"
              containerStyle={{ my: 5, isRequired: true }}
              helperText={`Remaining: ${remainingDescription}`}
              render={({ field }) => (
                <Textarea
                  {...field}
                  placeholder="Tell us about your music..."
                  maxLength={MAX_MESSAGE_LENGTH}
                />
              )}
            />

            <FormField<MusicSubmissionBody, 'websiteUrl'>
              name="websiteUrl"
              label="Website"
              containerStyle={{ my: 5, isRequired: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="url"
                  placeholder="https://www.example.com"
                />
              )}
            />

            <Stack spacing={5}>
              <PageHeading fontSize="sm">Enter Social Media</PageHeading>

              <FormField<MusicSubmissionBody, 'socialMedia.facebook'>
                name="socialMedia.facebook"
                label="Facebook"
                leftAddon={<FaFacebookF />}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=" Enter @socialhandle or Facebook link"
                    borderLeftRadius={0}
                  />
                )}
              />

              <FormField<MusicSubmissionBody, 'socialMedia.instagram'>
                name="socialMedia.instagram"
                label="Instagram"
                leftAddon={<RiInstagramFill />}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=" Enter @socialhandle or Instagrm link"
                    borderLeftRadius={0}
                  />
                )}
              />

              <FormField<MusicSubmissionBody, 'socialMedia.twitter'>
                name="socialMedia.twitter"
                label="Twitter"
                leftAddon={<FaTwitter />}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=" Enter @socialhandle or Instagrm link"
                    borderLeftRadius={0}
                  />
                )}
              />

              <FormField<MusicSubmissionBody, 'socialMedia.tik_tok'>
                name="socialMedia.tik_tok"
                label="Tik Tok"
                leftAddon={<FaTiktok />}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=" Enter @socialhandle or Tik Tok link"
                    borderLeftRadius={0}
                  />
                )}
              />

              <FormField<MusicSubmissionBody, 'attachments'>
                name="attachments"
                label="Upload File"
                containerStyle={{ my: 5, isRequired: true }}
                render={(form) => (
                  <FileInput<MusicSubmissionBody, 'attachments'>
                    form={form}
                    id="upload_files"
                    placeholder="Upload your music"
                    dropzone={{
                      multiple: false,
                      accept: {
                        'audio/*': [
                          '.mp3',
                          '.mp4',
                          '.wav',
                          '.m4a',
                          '.aac',
                          '.ogg',
                        ],
                        'video/*': [
                          '.mp4',
                          '.mov',
                          '.avi',
                          '.wmv',
                          '.flv',
                          '.webm',
                        ],
                      },
                      minSize: 0, // 0 bytes
                      maxSize: 10000000, // 10MB
                    }}
                  />
                )}
              />
            </Stack>

            <ButtonGroup display="flex" justifyContent="flex-end" mt={10}>
              <Button
                type="button"
                disabled={isLoading}
                isLoading={isLoading}
                onClick={() => resetForm()}
                colorScheme="yellow"
                variant="ghost"
              >
                Reset
              </Button>

              {error ? (
                <Button
                  type="button"
                  disabled={isLoading}
                  onClick={resetMutation}
                  colorScheme="red"
                >
                  Try Again
                </Button>
              ) : (
                <Button
                  type="submit"
                  disabled={!isDirty || !isValid || isLoading || Boolean(error)}
                  isLoading={isLoading}
                  loadingText="Submitting..."
                >
                  Submit
                </Button>
              )}
            </ButtonGroup>
          </Form>
        </>
      )}
    </Stack>
  );
}
