import { z } from 'zod';
import { PodcastCreateNestedOneWithoutRssFeedInputObjectSchema } from './PodcastCreateNestedOneWithoutRssFeedInput.schema';
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema';

import type { Prisma } from '@prisma/client';

const literalSchema = z.union([z.string(), z.number(), z.boolean()]);
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ])
);

const Schema: z.ZodType<Prisma.RssFeedCreateInput> = z
  .object({
    updatedAt: z.date().optional(),
    podcast: z.lazy(
      () => PodcastCreateNestedOneWithoutRssFeedInputObjectSchema
    ),
    url: z.string(),
    data: z.union([z.lazy(() => JsonNullValueInputSchema), jsonSchema]),
  })
  .strict();

export const RssFeedCreateInputObjectSchema = Schema;
