import { z } from 'zod';
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema';

import type { Prisma } from '@prisma/client';

const literalSchema = z.union([z.string(), z.number(), z.boolean()]);
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ])
);

const Schema: z.ZodType<Prisma.CurationUncheckedCreateWithoutCuratorInput> = z
  .object({
    updatedAt: z.date().optional(),
    podcastId: z.number(),
    contactName: z.string(),
    contactEmail: z.string(),
    wouldRecommend: z.boolean(),
    ratings: z.union([z.lazy(() => JsonNullValueInputSchema), jsonSchema]),
    notes: z.string(),
  })
  .strict();

export const CurationUncheckedCreateWithoutCuratorInputObjectSchema = Schema;
