export const originalsData = [
  {
    id: 1,
    image: '/images/originals/second_sunday/second_sunday.png',
    category: 'Religion & Spirituality',
    originalTitle: 'Second Sunday',
    quote:
      'Black Queer folks have always been an intrical part of the Black church and their stories deserve to be heard.',
    quote_author: 'Anna DeShawn',
    sub_heading:
      'A narrative exploration of Black Queer people finding, keeping, and sometimes losing faith. ',
    description: [
      ' is about spirituality and religion from the perspective of those who have been historically excluded & persecuted. It’s about finding faith, keeping faith and sometimes losing faith. It’s a second look at a topic that feels increasingly dismissed and minimized. It’s second Sunday.',
      ' will be heard exclusively on The Qube app. The Qube is a curated app of music & podcasts by BIPOC & QTPOC creatives. It is a place for people to be inspired by the various ways BIPOC & QTPOC people show up in the world. Life attempts to put us in boxes and limit us to particular labels but inside The Qube we are three-dimensional. We are showing our depth & breathe by speaking our truth, sharing our wisdom, and spreading joy through music & podcasts.',
    ],
    team: [
      {
        image: '/images/originals/second_sunday/team/esther_ikoro.png',
        name: 'Esther Ikoro',
        title: 'Host',
        bio: 'Esther Ikoro (She/Her) is a comedic writer, producer, and host from the southside of Chicago. Her work focuses primarily on podcasts, web series, and independent films. Her goal is to promote empathy, curiosity, and critical thinking through stories. Esther is also a nature-loving science nerd who enjoys hiking and gardening. You can connect with her through instagram.',
        socials: [
          {
            instagram: '@toseeyououtside',
            instagram_link: 'https://www.instagram.com/toseeyououtside/',
            web: 'https://linktr.ee/seeyououtside',
          },
        ],
      },
      {
        image: '/images/originals/second_sunday/team/darren_calhoun.png',
        name: 'Darren Calhoun',
        title: 'Host',
        bio: `Darren Calhoun (He/Him) is a justice advocate, worship leader, and photographer based out of Chicago. He works to bridge connections between people of differing perspectives through story and relationship.

        Currently, Darren leads worship at Urban Village Church and serves in multiple capacities with organizations like Christians for Social Action, The Reformation Project, and Q Christian Fellowship. He also sings with a progressive Christian band called The Many.

        He’s facilitated workshops and led worship for local and national gatherings and events. Darren brings with him an intentional focus on communities being inclusive of diverse people and expressions as an authentic reflection of God’s love and justice. Lastly, Darren is an extrovert who loves hugs. Follow him on social media at @heyDarren or through his blog, DarrenCalhoun.com.

        `,
        socials: [
          {
            instagram: '@heyDarren',
            instagram_link: 'https://www.instagram.com/heydarren/',
            web: 'http://DarrenCalhoun.com',
          },
        ],
      },
      {
        image: '/images/originals/second_sunday/team/gabriela_santana.png',
        name: 'Gabriela Santana',
        title: 'Producer',
        bio: `Gabriela Santana is a researcher, graphic designer, social media strategist, and podcast producer based out of Richmond, Virginia. Gabriela is passionate about combining her love of human-centered design with her degree in communications. She works to capture and share the important perspectives of others through multimedia – video, audio, digital, print, and product design. Gabriela is currently working on the digital team at VPM PBS, helping to inform, inspire, and educate Virginian audiences through public media. \

        She educated audiences in her production of “When Time Slows Down,” a podcast that explored the intersectionality between social activism and art. The show went on to become a top 10 finalist in the 2020 NPR Student Podcast Challenge, catapulting her love for storytelling even further. \

        The common thread through all of Gabriela’s work is empathy. She believes that it is through empathetic research, collaboration, and understanding that we can produce truly impactful work.
        `,
        socials: [],
      },
      {
        image: '/images/originals/second_sunday/team/anna_deshawn.png',
        name: 'Anna DeShawn',
        title: 'Executive Producer',
        bio: `Anna DeShawn is a Chicago-born media professional and social entrepreneur who is determined to ride media into its next era by utilizing online radio streams to tell the stories and play the music that deserves to be heard. Media has always been her passion and she turned that passion into a reality when I founded E3 Radio & The Qube; two media entities centering & celebrating BIPOC & QTPOC musicians and podcasters.

        Favorite Quote: “First they ignore you, then they laugh at you, then they fight you, then you win.” –Gandhi
        `,
        socials: [
          {
            instagram: '@annadeshawn',
            instagram_link: 'https://www.instagram.com/annadeshawn/',
            twitter: '@AnnaDeShawn',
            twitter_link: 'https://twitter.com/AnnaDeShawn',
            facebook: '@E3Radio',
            facebook_link: 'https://www.facebook.com/E3Radio',
            web: 'https://linktr.ee/annadeshawn',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    image: '/images/originals/behind_closed_doors/behind_closed_doors.png',
    originalTitle: 'Behind Closed Doors',
    sub_heading:
      'An intimate chronicle that reflects, amplifies, and transforms lives through storytelling.',
    category: 'Storytelling',
    quote:
      'Stories help us survive & there is more to our stories than coming out. This podcast will dig deeper while making us laugh too.',
    quote_author: 'Anna DeShawn',
    description: [
      " goes beyond coming out and deeper than what's on the surface through storytelling. We will center the stories of male-identified folk as a way to heal and that alone is a revolutionary act.",
      ' will be heard exclusively on The Qube platform. The Qube is a curated app of music & podcasts by BIPOC & QTPOC creatives. It is a place for people to be inspired by the various ways BIPOC & QTPOC people show up in the world. Life attempts to put us in boxes and limit us to particular labels but inside The Qube we are three-dimensional. We are showing our depth & breathe by speaking our truth, sharing our wisdom, and spreading joy through music & podcasts.',
    ],
    team: [
      {
        image: '/images/originals/behind_closed_doors/team/jace_william.png',
        name: 'Jace William',
        title: 'Host',
        bio: 'Jace William (He//Him) is a storyteller, singer-songwriter, voice actor, and native of Chicago. Jace started writing and performing in the fifth grade under his mentor/English teacher, Ms. Washington. Jace William prides himself on his unique ability to captivate his audiences through his storytelling.“ I want people to see themselves in my stories; I believe my journey is unique but relatable to many, says William. Jace William believes the art of storytelling is healing, therapeutic and necessary for the human experience. He is currently working on his next project, “How Am I Still Standing,” with no scheduled release date. ',
        socials: [
          {
            instagram: '@i_am_jacewilliam',
            instagram_link: 'https://www.instagram.com/i_am_jacewilliam/',
            web: 'https://songwhip.com/jace-william/pray-for-me',
          },
        ],
      },
      {
        image: '/images/originals/behind_closed_doors/team/dr_s.png',
        name: 'Dr. S.',
        title: 'Host',
        bio: `Dr. S (He/Him) is a Black, trans educator, writer, storyteller, and lover of people. Growing up in a Black family in Chicago Heights, I have learned many lessons about identity, the world, and relationships. I believe in the practice of telling our stories first to ourselves and then to others to build connections and offer different perspectives. I am currently working on several projects to those ends including writing a memoir, co-writing a TV show, and developing a family history exhibition. `,
        socials: [],
      },
      {
        image: '/images/originals/second_sunday/team/anna_deshawn.png',
        name: 'Anna DeShawn',
        title: 'Executive Producer',
        bio: `Anna DeShawn is a Chicago-born media professional and social entrepreneur who is determined to ride media into its next era by utilizing online radio streams to tell the stories and play the music that deserves to be heard. Media has always been her passion and she turned that passion into a reality when I founded E3 Radio & The Qube; two media entities centering & celebrating BIPOC & QTPOC musicians and podcasters.

        Favorite Quote: “First they ignore you, then they laugh at you, then they fight you, then you win.” –Gandhi
        `,
        socials: [
          {
            instagram: '@annadeshawn',
            instagram_link: 'https://www.instagram.com/annadeshawn/',
            twitter: '@AnnaDeShawn',
            twitter_link: 'https://twitter.com/AnnaDeShawn',
            facebook: '@E3Radio',
            facebook_link: 'https://www.facebook.com/E3Radio',
            web: 'https://linktr.ee/annadeshawn',
          },
        ],
      },
    ],
  },
  {
    id: 3,
    image: '/images/originals/polyam_chat/polyam_chat.png',
    originalTitle: 'Polyam Chat',
    sub_heading:
      'Candid conversations on non-monogamy from a Black queer perspective.',
    category: 'Society and Culture',
    quote: '',
    quote_author: '',
    description: [
      " features culture curators Jae, TORi, and Michelle, a Black queer triad, as they dive into non-monogamy and other love and lifestyle topics you've always wanted to know more about. ",
      ' will be heard exclusively on The Qube platform. The Qube is a curated app of music & podcasts by BIPOC & QTPOC creatives. It is a place for people to be inspired by the various ways BIPOC & QTPOC people show up in the world. Life attempts to put us in boxes and limit us to particular labels but inside The Qube we are three-dimensional. We are showing our depth & breathe by speaking our truth, sharing our wisdom, and spreading joy through music & podcasts.',
    ],
    team: [
      {
        image: '/images/originals/polyam_chat/team/TORi.png',
        name: 'TORi',
        title: 'Host',
        bio: 'TORi (they/them) is a multidisciplinary artist based in Chicago. They co-founded smallWORLD Collective in 2017 with their life partner Jae Rice, also known as DJ Dapper. They are not only a self taught photographer and graphic designer, but also DJ as an intentional space curator. ',
        socials: [
          {
            instagram: '@tori.photos.design',
            instagram_link: '',
            twitter: '@no_TORI_ous',
            twitter_link: 'https://twitter.com/no_tori_ous',
          },
        ],
      },
      {
        image: '/images/originals/polyam_chat/team/michelle.png',
        name: 'Michelle',
        title: 'Host',
        bio: 'Michelle (she.her) is a local Chicago creative and advocate for authenticity. As a writer and space curator, her work reflects the way we liberate ourselves by creating space to find what brings us joy, making intentional connections, and prioritizing our pleasure. As a speaker, she’s candid about her journey and self reflection at the intersection of queer, Black, non-monogamous womanhood. ',
        socials: [
          {
            instagram: '@germanchoclit',
            instagram_link: 'https://www.instagram.com/germanchoclit/',
          },
        ],
      },
      {
        image: '/images/originals/polyam_chat/team/jae.png',
        name: 'Jae',
        title: 'Host',
        bio: 'Jae (he/him) is a trans-masculine public figure fighting for the liberation of oppressed communities on a daily basis. Jae is a cultural curator, DJ, composer, Director of Communications at Brave Space Alliance and overall practitioner of freedom, joy and justice. His background in political science and intercultural communications shapes his dedication to the progress of all Black LGBTQIA+ folks and other marginalized groups through perpetuating on-the-ground societal shifts, advancements, cultural change and growth. This dedication can be seen and holistically felt through over a decade of creating intentional spaces that center all Black LGBTQIA+ women, femmes, TGNC, and queer folks in Chicago, and around the world. Jae continues to foster an equitable future and moves intentionally towards a collectively liberated society. ',
        socials: [
          {
            instagram: '@djdapper_chicago',
            instagram_link: 'https://www.instagram.com/djdapper_chicago/',
            twitter: '@DJDapper_Chi',
            twitter_link: 'https://twitter.com/DJDapper_Chi',
            facebook: '@DJDapperChicago',
            facebook_link: 'https://www.facebook.com/DJDapperChicago',
            web: 'https://linktr.ee/jaerice',
          },
        ],
      },
      {
        image:
          '/images/originals/polyam_chat/team/latrice_sampson_richards.png',
        name: 'Latrice Sampson Richards',
        title: 'Producer',
        bio: 'Latrice Sampson Richards (she/her) is a Mental and Emotional Wellness Creative, licensed therapist, and CEO of STS Productions, a podcast development & production company.',
        socials: [
          {
            instagram: '@Latricesampsonrichards',
            instagram_link: 'https://www.instagram.com/latricesampsonrichards/',
            facebook: '@LatriceSampsonRichards',
            facebook_link: 'https://www.facebook.com/LatriceSampsonRichards',
            web: 'http://www.latricesampsonrichards.com',
          },
        ],
      },
      {
        image: '/images/originals/second_sunday/team/anna_deshawn.png',
        name: 'Anna DeShawn',
        title: 'Executive Producer',
        bio: `Anna DeShawn is a Chicago-born media professional and social entrepreneur who is determined to ride media into its next era by utilizing online radio streams to tell the stories and play the music that deserves to be heard. Media has always been her passion and she turned that passion into a reality when I founded E3 Radio & The Qube; two media entities centering & celebrating BIPOC & QTPOC musicians and podcasters.

        Favorite Quote: “First they ignore you, then they laugh at you, then they fight you, then you win.” –Gandhi
        `,
        socials: [
          {
            instagram: '@annadeshawn',
            instagram_link: 'https://www.instagram.com/annadeshawn/',
            twitter: '@AnnaDeShawn',
            twitter_link: 'https://twitter.com/AnnaDeShawn',
            facebook: '@E3Radio',
            facebook_link: 'https://www.facebook.com/E3Radio',
            web: 'https://linktr.ee/annadeshawn',
          },
        ],
      },
    ],
  },
  {
    id: 4,
    image:
      '/images/originals/decolonization_domonique_moseley/decolonization_domonique_moseley.png',
    originalTitle: 'The Decolonization of Domonique Moseley',
    sub_heading:
      'An audio drama about an unorthodox awakening that forces a highly controversial, best selling author to take a hard look at her own self-imposed limitations and the contradictory stigmas placed on race and sexuality by the media and society. ',
    category: 'Audio Drama/Storytelling',
    quote: '',
    quote_author: '',
    description: [
      ' is an audio drama about an unorthodox awakening that forces a highly controversial, best-selling author to take a hard look at her own self-imposed limitations and the contradictory stigmas placed on race and sexuality by the media and society.  ',
      ' will be heard exclusively on The Qube platform. The Qube is a curated app of music & podcasts by BIPOC & QTPOC creatives. It is a place for people to be inspired by the various ways BIPOC & QTPOC people show up in the world. Life attempts to put us in boxes and limit us to particular labels but inside The Qube we are three-dimensional. We are showing our depth & breathe by speaking our truth, sharing our wisdom, and spreading joy through music & podcasts.',
    ],
    team: [
      {
        image:
          '/images/originals/decolonization_domonique_moseley/team/xperience_j.png',
        name: 'Xperience J',
        title: 'Host',
        bio: `Xperience J (Crystal Judkins, she/her) is a multi-hyphenate creative from Alexandria, Virginia. She is the co-founder of Black Audio Dramas Exist, where she promotes creatively provocative audio dramas written, produced, and starring Black creatives. Xperience is passionately driven by her love of the arts and the innate desire to amplify Black voices through media. She has written and produced two audio dramas, "Past Due Audio Series" and "Kiss: The Audio Series,” which can currently be found on most major streaming platforms. Most recently, she established Shhh! Jus’ Listen Media, where she uses her love of sound to produce innovative audio experiences that will change the face of audio dramas forever.`,
        socials: [
          {
            instagram: '@xperiencejay',
            instagram_link: 'https://www.instagram.com/xperiencejay/',
            twitter: '@xperiencejay',
            twitter_link: 'https://twitter.com/XperienceJ',
            facebook: '@xperiencedapoet',
            facebook_link: 'https://www.facebook.com/xperiencej',
            web: 'https://xshewrites.com',
          },
        ],
      },
      {
        image:
          '/images/originals/decolonization_domonique_moseley/team/emanie_antonette.png',
        name: 'Emanie Antonette',
        title: 'Host',
        bio: 'Emanie Antonette (she/her)is fat, Black, and shamelessly unapologetic. Emanie Antonette is going against all societal rules one stretch mark at a time. This Brooklyn native is a self-proclaimed BLACKtivist and “the favorite cousin you didn’t know you had”. She creates healing spaces for Black folks over the globe, more recently in the erotic audio realm under her alias Cashmere. Her content creates a world where Black people can explore the limits of love and sexuality one episode at a time.',
        socials: [
          {
            instagram: '@cashmereandfriends',
            instagram_link: 'https://www.instagram.com/cashmereandfriends/',
            web: 'https://anchor.fm/cashmereandfriends',
          },
        ],
      },
      {
        image: '/images/originals/second_sunday/team/anna_deshawn.png',
        name: 'Anna DeShawn',
        title: 'Executive Producer',
        bio: `Anna DeShawn is a Chicago-born media professional and social entrepreneur who is determined to ride media into its next era by utilizing online radio streams to tell the stories and play the music that deserves to be heard. Media has always been her passion and she turned that passion into a reality when I founded E3 Radio & The Qube; two media entities centering & celebrating BIPOC & QTPOC musicians and podcasters.

        Favorite Quote: “First they ignore you, then they laugh at you, then they fight you, then you win.” –Gandhi
        `,
        socials: [
          {
            instagram: '@annadeshawn',
            instagram_link: 'https://www.instagram.com/annadeshawn/',
            twitter: '@AnnaDeShawn',
            twitter_link: 'https://twitter.com/AnnaDeShawn',
            facebook: '@E3Radio',
            facebook_link: 'https://www.facebook.com/E3Radio',
            web: 'https://linktr.ee/annadeshawn',
          },
        ],
      },
    ],
  },
];
