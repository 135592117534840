var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import SentryRRWeb from '@sentry/rrweb';
import { BrowserTracing } from '@sentry/tracing';
import type { Integration } from '@sentry/types';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENV =
  process.env.SENTRY_ENV ||
  process.env.NEXT_PUBLIC_APP_NAME ||
  process.env.NODE_ENV ||
  'development';

const shouldEnableSessionReplay = (() => {
  const storageKey = 'rrweb';

  if (typeof window === 'undefined') {
    return false;
  }

  if (Boolean(window) && Boolean(localStorage)) {
    const value = localStorage.getItem(storageKey);

    if (value) {
      // eslint-disable-next-line no-console
      console.warn('[Sentry] Enabling RRWeb');
      return Boolean(value);
    }
  }

  return false;
})();

const extendedIntegrations: Array<Integration> = (() => {
  const _integrations: Array<Integration> = [];

  if (shouldEnableSessionReplay) {
    _integrations.push(
      new SentryRRWeb({
        // checkoutEveryNms: 15 * 60 * 100,
        // checkoutEveryNth: 100,
        maskAllInputs: true,
      })
    );
  }

  _integrations.push(new BrowserTracing());

  return _integrations;
})();

Sentry.init({
  enabled: true,
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  // release: "...",

  // Adjust this value in production, or use tracesSampler for greater control
  //  Currently having issues with the integration so I want everything traced,
  //    Hence, the 1 (for 100%, or all traces) below.
  // * Adjust this as needed for production
  tracesSampleRate: 1,
  integrations: (defaults) => defaults.concat(extendedIntegrations),
});

Sentry.setTag('rrweb.active', shouldEnableSessionReplay ? 'yes' : 'no');
