import { z } from 'zod';
import { UserCreateWithoutPlaylistSubscriptionsInputObjectSchema } from './UserCreateWithoutPlaylistSubscriptionsInput.schema';
import { UserUncheckedCreateWithoutPlaylistSubscriptionsInputObjectSchema } from './UserUncheckedCreateWithoutPlaylistSubscriptionsInput.schema';
import { UserCreateOrConnectWithoutPlaylistSubscriptionsInputObjectSchema } from './UserCreateOrConnectWithoutPlaylistSubscriptionsInput.schema';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUncheckedCreateNestedManyWithoutPlaylistSubscriptionsInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(
              () => UserCreateWithoutPlaylistSubscriptionsInputObjectSchema
            ),
            z
              .lazy(
                () => UserCreateWithoutPlaylistSubscriptionsInputObjectSchema
              )
              .array(),
            z.lazy(
              () =>
                UserUncheckedCreateWithoutPlaylistSubscriptionsInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  UserUncheckedCreateWithoutPlaylistSubscriptionsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(
              () =>
                UserCreateOrConnectWithoutPlaylistSubscriptionsInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  UserCreateOrConnectWithoutPlaylistSubscriptionsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const UserUncheckedCreateNestedManyWithoutPlaylistSubscriptionsInputObjectSchema =
  Schema;
