import { z } from 'zod';
import { AccountUncheckedCreateNestedManyWithoutUserInputObjectSchema } from './AccountUncheckedCreateNestedManyWithoutUserInput.schema';
import { SessionUncheckedCreateNestedManyWithoutUserInputObjectSchema } from './SessionUncheckedCreateNestedManyWithoutUserInput.schema';
import { TagUncheckedCreateNestedManyWithoutUsersInputObjectSchema } from './TagUncheckedCreateNestedManyWithoutUsersInput.schema';
import { SettingsUncheckedCreateNestedOneWithoutOwnerInputObjectSchema } from './SettingsUncheckedCreateNestedOneWithoutOwnerInput.schema';
import { PermissionsUncheckedCreateNestedOneWithoutOwnerInputObjectSchema } from './PermissionsUncheckedCreateNestedOneWithoutOwnerInput.schema';
import { PlaylistUncheckedCreateNestedManyWithoutOwnersInputObjectSchema } from './PlaylistUncheckedCreateNestedManyWithoutOwnersInput.schema';
import { PlaylistUncheckedCreateNestedManyWithoutSubscribersInputObjectSchema } from './PlaylistUncheckedCreateNestedManyWithoutSubscribersInput.schema';
import { PodcastUncheckedCreateNestedManyWithoutCuratorsInputObjectSchema } from './PodcastUncheckedCreateNestedManyWithoutCuratorsInput.schema';
import { CurationUncheckedCreateNestedManyWithoutCuratorInputObjectSchema } from './CurationUncheckedCreateNestedManyWithoutCuratorInput.schema';
import { PodcastUncheckedCreateNestedManyWithoutOwnersInputObjectSchema } from './PodcastUncheckedCreateNestedManyWithoutOwnersInput.schema';
import { NullableJsonNullValueInputSchema } from '../enums/NullableJsonNullValueInput.schema';

import type { Prisma } from '@prisma/client';

const literalSchema = z.union([z.string(), z.number(), z.boolean()]);
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ])
);

const Schema: z.ZodType<Prisma.UserUncheckedCreateWithoutPodcastSubscriptionsInput> =
  z
    .object({
      id: z.string().optional(),
      avatarUrl: z.string().optional().nullable(),
      displayName: z.string().optional().nullable(),
      fullName: z.string(),
      email: z.string().optional().nullable(),
      emailVerified: z.date().optional().nullable(),
      passwordHash: z.string().optional().nullable(),
      accounts: z
        .lazy(
          () => AccountUncheckedCreateNestedManyWithoutUserInputObjectSchema
        )
        .optional(),
      sessions: z
        .lazy(
          () => SessionUncheckedCreateNestedManyWithoutUserInputObjectSchema
        )
        .optional(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      tags: z
        .lazy(() => TagUncheckedCreateNestedManyWithoutUsersInputObjectSchema)
        .optional(),
      settings: z
        .lazy(
          () => SettingsUncheckedCreateNestedOneWithoutOwnerInputObjectSchema
        )
        .optional(),
      permissions: z
        .lazy(
          () => PermissionsUncheckedCreateNestedOneWithoutOwnerInputObjectSchema
        )
        .optional(),
      playlists: z
        .lazy(
          () => PlaylistUncheckedCreateNestedManyWithoutOwnersInputObjectSchema
        )
        .optional(),
      playlistSubscriptions: z
        .lazy(
          () =>
            PlaylistUncheckedCreateNestedManyWithoutSubscribersInputObjectSchema
        )
        .optional(),
      podcastsAssigned: z
        .lazy(
          () => PodcastUncheckedCreateNestedManyWithoutCuratorsInputObjectSchema
        )
        .optional(),
      podcastsCurated: z
        .lazy(
          () => CurationUncheckedCreateNestedManyWithoutCuratorInputObjectSchema
        )
        .optional(),
      podcasts: z
        .lazy(
          () => PodcastUncheckedCreateNestedManyWithoutOwnersInputObjectSchema
        )
        .optional(),
      biography: z.string().optional().nullable(),
      birthday: z.date().optional().nullable(),
      gender: z.string().optional().nullable(),
      socialMedia: z
        .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
        .optional(),
    })
    .strict();

export const UserUncheckedCreateWithoutPodcastSubscriptionsInputObjectSchema =
  Schema;
