import { z } from 'zod';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';
import { PodcastCreateWithoutOwnersInputObjectSchema } from './PodcastCreateWithoutOwnersInput.schema';
import { PodcastUncheckedCreateWithoutOwnersInputObjectSchema } from './PodcastUncheckedCreateWithoutOwnersInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastCreateOrConnectWithoutOwnersInput> = z
  .object({
    where: z.lazy(() => PodcastWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => PodcastCreateWithoutOwnersInputObjectSchema),
      z.lazy(() => PodcastUncheckedCreateWithoutOwnersInputObjectSchema),
    ]),
  })
  .strict();

export const PodcastCreateOrConnectWithoutOwnersInputObjectSchema = Schema;
