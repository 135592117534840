import { z } from 'zod';
import { PlaylistCreateWithoutEpisodesInputObjectSchema } from './PlaylistCreateWithoutEpisodesInput.schema';
import { PlaylistUncheckedCreateWithoutEpisodesInputObjectSchema } from './PlaylistUncheckedCreateWithoutEpisodesInput.schema';
import { PlaylistCreateOrConnectWithoutEpisodesInputObjectSchema } from './PlaylistCreateOrConnectWithoutEpisodesInput.schema';
import { PlaylistWhereUniqueInputObjectSchema } from './PlaylistWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistUncheckedCreateNestedManyWithoutEpisodesInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => PlaylistCreateWithoutEpisodesInputObjectSchema),
            z
              .lazy(() => PlaylistCreateWithoutEpisodesInputObjectSchema)
              .array(),
            z.lazy(
              () => PlaylistUncheckedCreateWithoutEpisodesInputObjectSchema
            ),
            z
              .lazy(
                () => PlaylistUncheckedCreateWithoutEpisodesInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(
              () => PlaylistCreateOrConnectWithoutEpisodesInputObjectSchema
            ),
            z
              .lazy(
                () => PlaylistCreateOrConnectWithoutEpisodesInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema),
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const PlaylistUncheckedCreateNestedManyWithoutEpisodesInputObjectSchema =
  Schema;
