import { z } from 'zod';
import { PlaylistWhereUniqueInputObjectSchema } from './PlaylistWhereUniqueInput.schema';
import { PlaylistCreateWithoutEpisodesInputObjectSchema } from './PlaylistCreateWithoutEpisodesInput.schema';
import { PlaylistUncheckedCreateWithoutEpisodesInputObjectSchema } from './PlaylistUncheckedCreateWithoutEpisodesInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistCreateOrConnectWithoutEpisodesInput> = z
  .object({
    where: z.lazy(() => PlaylistWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => PlaylistCreateWithoutEpisodesInputObjectSchema),
      z.lazy(() => PlaylistUncheckedCreateWithoutEpisodesInputObjectSchema),
    ]),
  })
  .strict();

export const PlaylistCreateOrConnectWithoutEpisodesInputObjectSchema = Schema;
