import { z } from 'zod';
import { SortOrderSchema } from '../enums/SortOrder.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.SettingsMaxOrderByAggregateInput> = z
  .object({
    ownerId: z.lazy(() => SortOrderSchema).optional(),
    onboardedAt: z.lazy(() => SortOrderSchema).optional(),
    acceptedTermsAt: z.lazy(() => SortOrderSchema).optional(),
    acceptedPrivacyAt: z.lazy(() => SortOrderSchema).optional(),
    acceptedNewsletterAt: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict();

export const SettingsMaxOrderByAggregateInputObjectSchema = Schema;
