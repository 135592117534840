import { z } from 'zod';
import { SortOrderSchema } from '../enums/SortOrder.schema';
import { AdvertisementCountOrderByAggregateInputObjectSchema } from './AdvertisementCountOrderByAggregateInput.schema';
import { AdvertisementAvgOrderByAggregateInputObjectSchema } from './AdvertisementAvgOrderByAggregateInput.schema';
import { AdvertisementMaxOrderByAggregateInputObjectSchema } from './AdvertisementMaxOrderByAggregateInput.schema';
import { AdvertisementMinOrderByAggregateInputObjectSchema } from './AdvertisementMinOrderByAggregateInput.schema';
import { AdvertisementSumOrderByAggregateInputObjectSchema } from './AdvertisementSumOrderByAggregateInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.AdvertisementOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    header: z.lazy(() => SortOrderSchema).optional(),
    title: z.lazy(() => SortOrderSchema).optional(),
    description: z.lazy(() => SortOrderSchema).optional(),
    url: z.lazy(() => SortOrderSchema).optional(),
    order: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => AdvertisementCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => AdvertisementAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => AdvertisementMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => AdvertisementMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => AdvertisementSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict();

export const AdvertisementOrderByWithAggregationInputObjectSchema = Schema;
