import { z } from 'zod';
import { PlaylistWhereUniqueInputObjectSchema } from './PlaylistWhereUniqueInput.schema';
import { PlaylistUpdateWithoutEpisodesInputObjectSchema } from './PlaylistUpdateWithoutEpisodesInput.schema';
import { PlaylistUncheckedUpdateWithoutEpisodesInputObjectSchema } from './PlaylistUncheckedUpdateWithoutEpisodesInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistUpdateWithWhereUniqueWithoutEpisodesInput> =
  z
    .object({
      where: z.lazy(() => PlaylistWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => PlaylistUpdateWithoutEpisodesInputObjectSchema),
        z.lazy(() => PlaylistUncheckedUpdateWithoutEpisodesInputObjectSchema),
      ]),
    })
    .strict();

export const PlaylistUpdateWithWhereUniqueWithoutEpisodesInputObjectSchema =
  Schema;
