import { z } from 'zod';
import { SortOrderSchema } from '../enums/SortOrder.schema';
import { PodcastOrderByWithRelationInputObjectSchema } from './PodcastOrderByWithRelationInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.RssFeedOrderByWithRelationInput> = z
  .object({
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    podcastId: z.lazy(() => SortOrderSchema).optional(),
    podcast: z
      .lazy(() => PodcastOrderByWithRelationInputObjectSchema)
      .optional(),
    url: z.lazy(() => SortOrderSchema).optional(),
    data: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict();

export const RssFeedOrderByWithRelationInputObjectSchema = Schema;
