import { z } from 'zod';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserUpdateWithoutPodcastsInputObjectSchema } from './UserUpdateWithoutPodcastsInput.schema';
import { UserUncheckedUpdateWithoutPodcastsInputObjectSchema } from './UserUncheckedUpdateWithoutPodcastsInput.schema';
import { UserCreateWithoutPodcastsInputObjectSchema } from './UserCreateWithoutPodcastsInput.schema';
import { UserUncheckedCreateWithoutPodcastsInputObjectSchema } from './UserUncheckedCreateWithoutPodcastsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUpsertWithWhereUniqueWithoutPodcastsInput> =
  z
    .object({
      where: z.lazy(() => UserWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => UserUpdateWithoutPodcastsInputObjectSchema),
        z.lazy(() => UserUncheckedUpdateWithoutPodcastsInputObjectSchema),
      ]),
      create: z.union([
        z.lazy(() => UserCreateWithoutPodcastsInputObjectSchema),
        z.lazy(() => UserUncheckedCreateWithoutPodcastsInputObjectSchema),
      ]),
    })
    .strict();

export const UserUpsertWithWhereUniqueWithoutPodcastsInputObjectSchema = Schema;
