import {
  ChakraProvider,
  CSSReset,
  extendTheme,
  ThemeConfig,
} from '@chakra-ui/react';

import { Fonts, RootTheme } from '@theqube/theme';

const direction = 'ltr';

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
  cssVarPrefix: 'qube',
};

/*
# Chakra UI Theme

This is a Chakra UI theme generated with [Hypertheme Editor](https://hyperthe.me).

## Usage
Put the entire directory inside the `src/` folder of your project.

Use it in inside your ChakraProvider component.
 */
export const theme = extendTheme({
  direction,
  config,
  ...RootTheme,
  breakpoints: {
    base: '0em',
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
    '3xl': '160em',
  },
});

export type ThemeProviderProps = { children: React.ReactNode };
export function ThemeProvider({ children }: ThemeProviderProps) {
  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <Fonts />
      {children}
    </ChakraProvider>
  );
}
