import { z } from 'zod';
import { SortOrderSchema } from '../enums/SortOrder.schema';
import { PodcastOrderByWithRelationInputObjectSchema } from './PodcastOrderByWithRelationInput.schema';
import { PlaylistOrderByRelationAggregateInputObjectSchema } from './PlaylistOrderByRelationAggregateInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.EpisodeOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    publishedAt: z.lazy(() => SortOrderSchema).optional(),
    podcastId: z.lazy(() => SortOrderSchema).optional(),
    podcast: z
      .lazy(() => PodcastOrderByWithRelationInputObjectSchema)
      .optional(),
    playlists: z
      .lazy(() => PlaylistOrderByRelationAggregateInputObjectSchema)
      .optional(),
    title: z.lazy(() => SortOrderSchema).optional(),
    description: z.lazy(() => SortOrderSchema).optional(),
    imageUrl: z.lazy(() => SortOrderSchema).optional(),
    audioUrl: z.lazy(() => SortOrderSchema).optional(),
    duration: z.lazy(() => SortOrderSchema).optional(),
    explicit: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict();

export const EpisodeOrderByWithRelationInputObjectSchema = Schema;
