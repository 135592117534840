import { Spinner } from '@chakra-ui/react';

import { Permissions, RoleSchema } from '@theqube/schemas';
import { ErrorWrapper } from '../../components';
import { LoginForm, useSession } from '../../features';
import { BaseLayout } from './base';
import { UserLayoutProps } from './user';

interface AdminLayoutProps extends UserLayoutProps {
  permissions?: Record<keyof Permissions, boolean>;
}

export function AdminLayout({ children, ...props }: AdminLayoutProps) {
  const [{ isLoading, isError, error, data: session, refetch }] = useSession();

  const isAuthorized = Boolean(
    session?.user?.permissions?.roles.includes(RoleSchema.enum.admin)
  );

  if (isLoading) {
    return <Spinner />;
  } else if (isError) {
    return (
      <BaseLayout title="Error">
        <ErrorWrapper error={error} retry={refetch} />
      </BaseLayout>
    );
  } else if (isAuthorized) {
    return (
      <BaseLayout title="Admin Dashboard" {...props}>
        {children}
      </BaseLayout>
    );
  } else {
    return (
      <BaseLayout title="Login">
        <LoginForm />
      </BaseLayout>
    );
  }
}
