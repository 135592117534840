import { Box, Flex, Text } from '@chakra-ui/react';

interface ShowQuoteProps {
  quote: string;
  quote_author: string;
}

export function OriginalShowQuote(props: ShowQuoteProps) {
  const { quote, quote_author } = props;

  return (
    <Flex>
      <Box w="50%" mx="auto">
        <Box fontSize="4xl" textAlign="center">
          <Text
            display="block"
            as="span"
            color="gold.500"
            fontSize="4xl"
            fontFamily="MrsSheppards"
            mt={-150}
            mr={2}
          >
            &quot;
          </Text>

          {quote}
          <Text
            display="block"
            as="span"
            color="gold.500"
            fontSize="4xl"
            fontFamily="MrsSheppards"
            ml={2}
          >
            &quot;
          </Text>
        </Box>

        <Text w="50%" mt={50} mx="auto" textAlign="center" fontSize="2xl">
          - {quote_author}
        </Text>
      </Box>
    </Flex>
  );
}
