import { z } from 'zod';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserUpdateWithoutTagsInputObjectSchema } from './UserUpdateWithoutTagsInput.schema';
import { UserUncheckedUpdateWithoutTagsInputObjectSchema } from './UserUncheckedUpdateWithoutTagsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUpdateWithWhereUniqueWithoutTagsInput> = z
  .object({
    where: z.lazy(() => UserWhereUniqueInputObjectSchema),
    data: z.union([
      z.lazy(() => UserUpdateWithoutTagsInputObjectSchema),
      z.lazy(() => UserUncheckedUpdateWithoutTagsInputObjectSchema),
    ]),
  })
  .strict();

export const UserUpdateWithWhereUniqueWithoutTagsInputObjectSchema = Schema;
