import { Input, InputProps } from '@chakra-ui/react';

import { FormField, FormFieldProps } from '../helpers';

export type TextInputProps = Omit<FormFieldProps, 'render'> & {
  input?: InputProps;
};

export function TextInput(props: TextInputProps) {
  const { input, ...rest } = props;

  return (
    <FormField
      render={({ field }) => (
        <Input
          {...field}
          {...input}
          focusBorderColor="gold.500"
          errorBorderColor="error"
          borderRadius="md"
          borderLeftRadius={props.leftAddon || props.leftElement ? 0 : 'md'}
        />
      )}
      {...rest}
    />
  );
}
