import { z } from 'zod';
import { PlaylistWhereUniqueInputObjectSchema } from './PlaylistWhereUniqueInput.schema';
import { PlaylistUpdateWithoutOwnersInputObjectSchema } from './PlaylistUpdateWithoutOwnersInput.schema';
import { PlaylistUncheckedUpdateWithoutOwnersInputObjectSchema } from './PlaylistUncheckedUpdateWithoutOwnersInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistUpdateWithWhereUniqueWithoutOwnersInput> =
  z
    .object({
      where: z.lazy(() => PlaylistWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => PlaylistUpdateWithoutOwnersInputObjectSchema),
        z.lazy(() => PlaylistUncheckedUpdateWithoutOwnersInputObjectSchema),
      ]),
    })
    .strict();

export const PlaylistUpdateWithWhereUniqueWithoutOwnersInputObjectSchema =
  Schema;
