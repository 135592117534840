import { z } from 'zod';
import { UserUncheckedCreateNestedManyWithoutPlaylistsInputObjectSchema } from './UserUncheckedCreateNestedManyWithoutPlaylistsInput.schema';
import { EpisodeUncheckedCreateNestedManyWithoutPlaylistsInputObjectSchema } from './EpisodeUncheckedCreateNestedManyWithoutPlaylistsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistUncheckedCreateWithoutSubscribersInput> =
  z
    .object({
      id: z.number().optional(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      avatarUrl: z.string().optional().nullable(),
      title: z.string(),
      description: z.string(),
      owners: z
        .lazy(
          () => UserUncheckedCreateNestedManyWithoutPlaylistsInputObjectSchema
        )
        .optional(),
      episodes: z
        .lazy(
          () =>
            EpisodeUncheckedCreateNestedManyWithoutPlaylistsInputObjectSchema
        )
        .optional(),
    })
    .strict();

export const PlaylistUncheckedCreateWithoutSubscribersInputObjectSchema =
  Schema;
