import { z } from 'zod';
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema';
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema';
import { RssFeedUpdateOneWithoutPodcastNestedInputObjectSchema } from './RssFeedUpdateOneWithoutPodcastNestedInput.schema';
import { TagUpdateManyWithoutPodcastsNestedInputObjectSchema } from './TagUpdateManyWithoutPodcastsNestedInput.schema';
import { UserUpdateManyWithoutPodcastsNestedInputObjectSchema } from './UserUpdateManyWithoutPodcastsNestedInput.schema';
import { EpisodeUpdateManyWithoutPodcastNestedInputObjectSchema } from './EpisodeUpdateManyWithoutPodcastNestedInput.schema';
import { UserUpdateManyWithoutPodcastsAssignedNestedInputObjectSchema } from './UserUpdateManyWithoutPodcastsAssignedNestedInput.schema';
import { CurationUpdateManyWithoutPodcastNestedInputObjectSchema } from './CurationUpdateManyWithoutPodcastNestedInput.schema';
import { PodcastStatusSchema } from '../enums/PodcastStatus.schema';
import { EnumPodcastStatusFieldUpdateOperationsInputObjectSchema } from './EnumPodcastStatusFieldUpdateOperationsInput.schema';
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema';
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema';
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema';
import { NullableJsonNullValueInputSchema } from '../enums/NullableJsonNullValueInput.schema';

import type { Prisma } from '@prisma/client';

const literalSchema = z.union([z.string(), z.number(), z.boolean()]);
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ])
);

const Schema: z.ZodType<Prisma.PodcastUpdateWithoutSubscribersInput> = z
  .object({
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    publishedAt: z
      .union([
        z.date(),
        z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    importedAt: z
      .union([
        z.date(),
        z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    rssFeed: z
      .lazy(() => RssFeedUpdateOneWithoutPodcastNestedInputObjectSchema)
      .optional(),
    tags: z
      .lazy(() => TagUpdateManyWithoutPodcastsNestedInputObjectSchema)
      .optional(),
    owners: z
      .lazy(() => UserUpdateManyWithoutPodcastsNestedInputObjectSchema)
      .optional(),
    episodes: z
      .lazy(() => EpisodeUpdateManyWithoutPodcastNestedInputObjectSchema)
      .optional(),
    curators: z
      .lazy(() => UserUpdateManyWithoutPodcastsAssignedNestedInputObjectSchema)
      .optional(),
    curations: z
      .lazy(() => CurationUpdateManyWithoutPodcastNestedInputObjectSchema)
      .optional(),
    status: z
      .union([
        z.lazy(() => PodcastStatusSchema),
        z.lazy(() => EnumPodcastStatusFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    statusReason: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    avatarUrl: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    isOriginal: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    isExplicit: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    title: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    description: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    websiteUrl: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    socialMedia: z
      .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
      .optional(),
  })
  .strict();

export const PodcastUpdateWithoutSubscribersInputObjectSchema = Schema;
