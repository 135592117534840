import { z } from 'zod';
import { TagCreateWithoutUsersInputObjectSchema } from './TagCreateWithoutUsersInput.schema';
import { TagUncheckedCreateWithoutUsersInputObjectSchema } from './TagUncheckedCreateWithoutUsersInput.schema';
import { TagCreateOrConnectWithoutUsersInputObjectSchema } from './TagCreateOrConnectWithoutUsersInput.schema';
import { TagWhereUniqueInputObjectSchema } from './TagWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.TagCreateNestedManyWithoutUsersInput> = z.union([
  z
    .object({
      create: z
        .union([
          z.lazy(() => TagCreateWithoutUsersInputObjectSchema),
          z.lazy(() => TagCreateWithoutUsersInputObjectSchema).array(),
          z.lazy(() => TagUncheckedCreateWithoutUsersInputObjectSchema),
          z.lazy(() => TagUncheckedCreateWithoutUsersInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict(),
  z
    .object({
      connectOrCreate: z
        .union([
          z.lazy(() => TagCreateOrConnectWithoutUsersInputObjectSchema),
          z.lazy(() => TagCreateOrConnectWithoutUsersInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict(),
  z
    .object({
      connect: z
        .union([
          z.lazy(() => TagWhereUniqueInputObjectSchema),
          z.lazy(() => TagWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict(),
]);

export const TagCreateNestedManyWithoutUsersInputObjectSchema = Schema;
