import { createIcon } from '@chakra-ui/icons';

export const EnvelopeIcon = createIcon({
  displayName: 'EnvelopeIcon',
  viewBox: '0 0 24 22',
  path: (
    <path
      fill="#d6c36f"
      d="M21 0H3a3 3 0 0 0-3 3v19h24V3a3 3 0 0 0-3-3ZM3 2h18a1 1 0 0 1 1 1v.667l-7.878 7.879a3.007 3.007 0 0 1-4.244 0L2 3.667V3a1 1 0 0 1 1-1ZM2 20V6.5l6.464 6.46a5.007 5.007 0 0 0 7.072 0L22 6.5V20Z"
    />
  ),
});
