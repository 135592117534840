import * as React from 'react';

/** Always import by type for Router */
import type { Context as AuthContext } from '@theqube/trpc';
import Script from 'next/script';
import { REFRESH_TOKEN_KEY } from '../../utils/tokens';

interface State {
  token: string | false;
}

type Actions =
  | { type: 'LOGIN'; payload: { token: string } }
  | { type: 'LOGOUT' };

type AuthReducer = React.Reducer<State, Actions>;

const initialState: State = { token: false };

const AuthContext = React.createContext<undefined | State>(undefined);
export type AuthDispatch = React.Dispatch<Actions>;

const AuthDispatchContext = React.createContext<undefined | AuthDispatch>(
  undefined
);

const authReducer: AuthReducer = (_, action) => {
  const { type } = action;

  switch (type) {
    case 'LOGIN': {
      return action.payload;
    }

    case 'LOGOUT':
    default: {
      return initialState;
    }
  }
};

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider(props: AuthProviderProps) {
  const { children } = props;
  const [state, dispatch] = React.useReducer<AuthReducer>(
    authReducer,
    initialState
  );

  /**
   * Effect used to synchronize local-storage and React's state, (w/ priority on state).
   **/
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

      // Tokens don't match, prioritise the state's token
      if (state.token && state.token !== refreshToken) {
        localStorage.setItem(REFRESH_TOKEN_KEY, state.token);
      } else if (refreshToken && !state.token) {
        // Token is not in state, but is in local storage
        dispatch({ type: 'LOGIN', payload: { token: refreshToken } });
      }
    }

    return () => {
      if (typeof window !== 'undefined') {
        if (!state.token) {
          localStorage.removeItem(REFRESH_TOKEN_KEY);
        }
      }
    };
  }, [state.token]);

  return (
    <AuthContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
        <Script
          src="https://accounts.google.com/gsi/client"
          id="gsi-client"
          async
          defer
        />
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  const state = React.useContext(AuthContext);
  const dispatch = React.useContext(AuthDispatchContext);

  if (typeof state === 'undefined' || typeof dispatch === 'undefined') {
    throw new Error('[Auth] Must be used within an AuthProvider');
  }

  return [state, dispatch] as const;
}
