import { AuthProvider } from '../features';
import { usePageView } from '../utils';
import { ErrorBoundary } from './error-boundary';
import { ThemeProvider } from './theme-provider';

export type AppProviderProps = {
  children: React.ReactNode;
};
/**
 * Root `_app.tsx` Component to connect all stores, hooks, and contexts to the root app.
 */
export function AppProvider({ children }: AppProviderProps) {
  usePageView();

  return (
    <ErrorBoundary>
      <ThemeProvider>
        <AuthProvider>{children}</AuthProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}
