import { z } from 'zod';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.SettingsMaxAggregateInputType> = z
  .object({
    ownerId: z.literal(true).optional(),
    onboardedAt: z.literal(true).optional(),
    acceptedTermsAt: z.literal(true).optional(),
    acceptedPrivacyAt: z.literal(true).optional(),
    acceptedNewsletterAt: z.literal(true).optional(),
  })
  .strict();

export const SettingsMaxAggregateInputObjectSchema = Schema;
