import { createIcon } from '@chakra-ui/icons';

export const LockIcon = createIcon({
  displayName: 'LockIcon',
  viewBox: '0 0 20.28 24.336',
  path: [
    <path
      key="1"
      fill="#d6c36f"
      d="M17.238 8.112V7.1a7.1 7.1 0 1 0-14.2 0v1.012H0v13.182a3.042 3.042 0 0 0 3.042 3.042h14.2a3.042 3.042 0 0 0 3.042-3.042V8.112ZM5.07 7.1a5.07 5.07 0 1 1 10.14 0v1.012H5.07Zm13.182 14.2a1.014 1.014 0 0 1-1.014 1.014H3.042A1.014 1.014 0 0 1 2.028 21.3V10.14h16.224Z"
      data-name="Path 1068"
    />,
    <path
      key="2"
      fill="#d6c36f"
      d="M8.655 15.137h2.969v2.969H8.655z"
      data-name="Rectangle 491"
    />,
  ],
});
