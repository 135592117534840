import { Box, BoxProps } from '@chakra-ui/react';
import * as React from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

import { ErrorWrapper, ErrorWrapperProps } from '../../atoms';

type FormProps<
  FormData extends FieldValues,
  Context extends object
> = BoxProps & {
  context: UseFormReturn<FormData, Context>;
  children: React.ReactNode;
  result?: ErrorWrapperProps;
};

/** Wrapper used to inject the context from `useForm` into all of it's children. */
export function Form<FormData extends FieldValues, Context extends object>(
  props: FormProps<FormData, Context>
) {
  const { context, children, result, ...style } = props;

  if (result?.error) return <ErrorWrapper {...result} />;

  return (
    <FormProvider {...context}>
      <Box as="form" flexGrow={1} width="100%" {...style}>
        {children}
      </Box>
    </FormProvider>
  );
}
