import { HamburgerIcon } from '@chakra-ui/icons';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';

import { Link } from '../../../../components';
import { StudioRoutes } from '../../../../utils/route-map';

export function InfoMenu() {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        rounded="full"
        variant="ghost"
        bg="transparent"
        icon={<HamburgerIcon w={5} h={5} />}
        aria-label="Toggle Info Menu"
        justifySelf="flex-start"
      />

      <MenuList rounded="none">
        {Object.values(StudioRoutes['info'].children ?? {}).map(
          ({ label, href }) => (
            <MenuItem key={href} as={Link} href={href ?? '#'}>
              {label}
            </MenuItem>
          )
        )}

        <MenuItem
          as={Link}
          href={StudioRoutes['auth'].children?.login.href ?? ''}
        >
          {StudioRoutes['auth'].children?.login.label}
        </MenuItem>

        <MenuItem
          as={Link}
          href={StudioRoutes['auth'].children?.register.href ?? ''}
        >
          {StudioRoutes['auth'].children?.register.label}
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
