import { z } from 'zod';
import { EpisodeWhereUniqueInputObjectSchema } from './EpisodeWhereUniqueInput.schema';
import { EpisodeUpdateWithoutPlaylistsInputObjectSchema } from './EpisodeUpdateWithoutPlaylistsInput.schema';
import { EpisodeUncheckedUpdateWithoutPlaylistsInputObjectSchema } from './EpisodeUncheckedUpdateWithoutPlaylistsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.EpisodeUpdateWithWhereUniqueWithoutPlaylistsInput> =
  z
    .object({
      where: z.lazy(() => EpisodeWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => EpisodeUpdateWithoutPlaylistsInputObjectSchema),
        z.lazy(() => EpisodeUncheckedUpdateWithoutPlaylistsInputObjectSchema),
      ]),
    })
    .strict();

export const EpisodeUpdateWithWhereUniqueWithoutPlaylistsInputObjectSchema =
  Schema;
