import {
  AppPropsWithLayout,
  AppProvider,
  StudioHeader,
  trpc,
  UserLayout,
} from '@theqube/ui';

function App(props: AppPropsWithLayout) {
  const { Component, pageProps } = props;

  /** get custom layout or set default - see {@link AppPropsWithLayout} */
  const getLayout =
    Component.getLayout ||
    ((page: React.ReactNode) => (
      <UserLayout header={<StudioHeader />}>{page}</UserLayout>
    ));

  return <AppProvider>{getLayout(<Component {...pageProps} />)}</AppProvider>;
}

export default trpc.withTRPC(App);
