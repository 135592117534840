import { z } from 'zod';
import { PermissionsCreateWithoutOwnerInputObjectSchema } from './PermissionsCreateWithoutOwnerInput.schema';
import { PermissionsUncheckedCreateWithoutOwnerInputObjectSchema } from './PermissionsUncheckedCreateWithoutOwnerInput.schema';
import { PermissionsCreateOrConnectWithoutOwnerInputObjectSchema } from './PermissionsCreateOrConnectWithoutOwnerInput.schema';
import { PermissionsWhereUniqueInputObjectSchema } from './PermissionsWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PermissionsCreateNestedOneWithoutOwnerInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => PermissionsCreateWithoutOwnerInputObjectSchema),
            z.lazy(
              () => PermissionsUncheckedCreateWithoutOwnerInputObjectSchema
            ),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .lazy(() => PermissionsCreateOrConnectWithoutOwnerInputObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .lazy(() => PermissionsWhereUniqueInputObjectSchema)
          .optional(),
      })
      .strict(),
  ]);

export const PermissionsCreateNestedOneWithoutOwnerInputObjectSchema = Schema;
