import { z } from 'zod';

import { UserSchema } from './__generated__/zod-prisma';

export type PingResponse = z.infer<typeof PingResponse>;
export type HealthResponse = z.infer<typeof HealthResponse>;
export type BuildInfo = z.infer<typeof BuildInfo>;
export type InfoResponse = z.infer<typeof InfoResponse>;

export const PingResponse = z.literal('OK');
export const InfoResponse = z.object({
  req: z.object({
    ip: z.string(),
    hostname: z.string(),
    protocol: z.string(),
    headers: z.record(z.string().or(z.array(z.string())).or(z.undefined())),

    body: z.unknown(),
    query: z.record(z.string().or(z.array(z.string())).or(z.unknown())),
    params: z.record(z.string()),
    cookies: z.record(z.string()),
    session: z.unknown(),
    sessionID: z.string(),
    user: UserSchema.or(z.null()).or(z.undefined()),
  }),

  res: z.object({
    headers: z.record(
      z.string().or(z.number()).or(z.array(z.string())).or(z.unknown())
    ),
    statusCode: z.number(),
    statusMessage: z.string(),
  }),
});

export const BuildInfo = z.object({
  name: z.string(),
  version: z.string(),
  description: z.string(),
});

export const HealthResponse = z.object({
  date: z.string(),

  process: z.object({
    uptime: z.number(),
    cpu: z.object({ user: z.number(), system: z.number() }),
    memory: z.object({
      rss: z.number(),
      heapTotal: z.number(),
      heapUsed: z.number(),
      external: z.number(),
      arrayBuffers: z.number(),
    }),
  }),
});
