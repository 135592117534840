import { Button, ButtonGroup, Skeleton } from '@chakra-ui/react';

import { ChangePasswordBody } from '@theqube/schemas';
import { Form, PasswordInput } from '../../../components';
import { trpc, useZodForm } from '../../../utils';
import { useSession } from '../use-session';

const defaultValues: ChangePasswordBody = {
  oldPassword: '',
  password: '',
  passwordConfirm: '',
};

export function ChangePasswordForm() {
  const [{ isSuccess: isAuthenticated }] = useSession();
  const { mutateAsync: changePassword, ...mutation } =
    trpc.auth.password.change.useMutation();

  const form = useZodForm({
    schema: ChangePasswordBody,
    mode: 'onChange',
    defaultValues,
  });

  const handleFormSubmit = form.handleSubmit(async (values) => {
    if (isAuthenticated) {
      await changePassword(values);
      form.reset(defaultValues);
    }
  });

  return (
    <Skeleton isLoaded={isAuthenticated}>
      <Form context={form} result={mutation}>
        <PasswordInput name="oldPassword" label="Old Password" />
        <PasswordInput name="newPassword" label="New Password" />
        <PasswordInput name="newPasswordConfirm" label="Confirm New Password" />

        <ButtonGroup display="flex" justifyContent="center" my="4">
          <Button
            onClick={handleFormSubmit}
            isLoading={form.formState.isSubmitting || mutation.isLoading}
            disabled={form.formState.isSubmitting || mutation.isLoading}
            bg={
              mutation.isError || !form.formState.isValid
                ? 'error'
                : 'themeGold.500'
            }
          >
            Change Password
          </Button>
        </ButtonGroup>
      </Form>
    </Skeleton>
  );
}
