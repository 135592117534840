import { z } from 'zod';
import { PlaylistScalarWhereInputObjectSchema } from './PlaylistScalarWhereInput.schema';
import { PlaylistUpdateManyMutationInputObjectSchema } from './PlaylistUpdateManyMutationInput.schema';
import { PlaylistUncheckedUpdateManyWithoutPlaylistSubscriptionsInputObjectSchema } from './PlaylistUncheckedUpdateManyWithoutPlaylistSubscriptionsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistUpdateManyWithWhereWithoutSubscribersInput> =
  z
    .object({
      where: z.lazy(() => PlaylistScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => PlaylistUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            PlaylistUncheckedUpdateManyWithoutPlaylistSubscriptionsInputObjectSchema
        ),
      ]),
    })
    .strict();

export const PlaylistUpdateManyWithWhereWithoutSubscribersInputObjectSchema =
  Schema;
