import { z } from 'zod';
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema';
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema';
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema';
import { AccountUpdateManyWithoutUserNestedInputObjectSchema } from './AccountUpdateManyWithoutUserNestedInput.schema';
import { SessionUpdateManyWithoutUserNestedInputObjectSchema } from './SessionUpdateManyWithoutUserNestedInput.schema';
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema';
import { TagUpdateManyWithoutUsersNestedInputObjectSchema } from './TagUpdateManyWithoutUsersNestedInput.schema';
import { SettingsUpdateOneWithoutOwnerNestedInputObjectSchema } from './SettingsUpdateOneWithoutOwnerNestedInput.schema';
import { PermissionsUpdateOneWithoutOwnerNestedInputObjectSchema } from './PermissionsUpdateOneWithoutOwnerNestedInput.schema';
import { PlaylistUpdateManyWithoutOwnersNestedInputObjectSchema } from './PlaylistUpdateManyWithoutOwnersNestedInput.schema';
import { PlaylistUpdateManyWithoutSubscribersNestedInputObjectSchema } from './PlaylistUpdateManyWithoutSubscribersNestedInput.schema';
import { PodcastUpdateManyWithoutCuratorsNestedInputObjectSchema } from './PodcastUpdateManyWithoutCuratorsNestedInput.schema';
import { CurationUpdateManyWithoutCuratorNestedInputObjectSchema } from './CurationUpdateManyWithoutCuratorNestedInput.schema';
import { PodcastUpdateManyWithoutSubscribersNestedInputObjectSchema } from './PodcastUpdateManyWithoutSubscribersNestedInput.schema';
import { NullableJsonNullValueInputSchema } from '../enums/NullableJsonNullValueInput.schema';

import type { Prisma } from '@prisma/client';

const literalSchema = z.union([z.string(), z.number(), z.boolean()]);
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ])
);

const Schema: z.ZodType<Prisma.UserUpdateWithoutPodcastsInput> = z
  .object({
    id: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    avatarUrl: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    displayName: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    fullName: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    email: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    emailVerified: z
      .union([
        z.date(),
        z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    passwordHash: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    accounts: z
      .lazy(() => AccountUpdateManyWithoutUserNestedInputObjectSchema)
      .optional(),
    sessions: z
      .lazy(() => SessionUpdateManyWithoutUserNestedInputObjectSchema)
      .optional(),
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    tags: z
      .lazy(() => TagUpdateManyWithoutUsersNestedInputObjectSchema)
      .optional(),
    settings: z
      .lazy(() => SettingsUpdateOneWithoutOwnerNestedInputObjectSchema)
      .optional(),
    permissions: z
      .lazy(() => PermissionsUpdateOneWithoutOwnerNestedInputObjectSchema)
      .optional(),
    playlists: z
      .lazy(() => PlaylistUpdateManyWithoutOwnersNestedInputObjectSchema)
      .optional(),
    playlistSubscriptions: z
      .lazy(() => PlaylistUpdateManyWithoutSubscribersNestedInputObjectSchema)
      .optional(),
    podcastsAssigned: z
      .lazy(() => PodcastUpdateManyWithoutCuratorsNestedInputObjectSchema)
      .optional(),
    podcastsCurated: z
      .lazy(() => CurationUpdateManyWithoutCuratorNestedInputObjectSchema)
      .optional(),
    podcastSubscriptions: z
      .lazy(() => PodcastUpdateManyWithoutSubscribersNestedInputObjectSchema)
      .optional(),
    biography: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    birthday: z
      .union([
        z.date(),
        z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    gender: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    socialMedia: z
      .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
      .optional(),
  })
  .strict();

export const UserUpdateWithoutPodcastsInputObjectSchema = Schema;
