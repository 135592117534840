import { chakra, ChakraProps } from '@chakra-ui/react';

export function RainbowWaveForm(props: ChakraProps) {
  return (
    <chakra.img
      src="/images/rainbow-waveform.png"
      alt="Rainbow waveform"
      width="100%"
      display="block"
      objectFit="contain"
      objectPosition="center"
      {...props}
    />
  );
}
