import { z } from 'zod';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';
import { PodcastCreateWithoutTagsInputObjectSchema } from './PodcastCreateWithoutTagsInput.schema';
import { PodcastUncheckedCreateWithoutTagsInputObjectSchema } from './PodcastUncheckedCreateWithoutTagsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastCreateOrConnectWithoutTagsInput> = z
  .object({
    where: z.lazy(() => PodcastWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => PodcastCreateWithoutTagsInputObjectSchema),
      z.lazy(() => PodcastUncheckedCreateWithoutTagsInputObjectSchema),
    ]),
  })
  .strict();

export const PodcastCreateOrConnectWithoutTagsInputObjectSchema = Schema;
