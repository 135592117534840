import * as React from 'react';

import { CheckCircleIcon } from '@chakra-ui/icons';
import { Button, Stack, Text, Tooltip } from '@chakra-ui/react';

import { ErrorWrapper } from '../../components/atoms';
import { trpc } from '../../utils';
import { useSession } from './use-session';

export function EmailVerificationButton({ text: children }: { text: string }) {
  const [
    {
      data: session,
      isLoading: isSessionLoading,
      isError: isSessionError,
      error: sessionError,
      refetch: sessionRefetch,
    },
  ] = useSession();

  const {
    mutateAsync: requestEmail,
    isLoading: isRequestLoading,
    isError: isRequestError,
    isSuccess: isRequestSuccess,
    isIdle: isRequestIdle,
    error: requestError,
    reset: requestReset,
  } = trpc.auth.email['verify--request'].useMutation();

  const isVerified = Boolean(session?.user.emailVerified);

  const handleClick = React.useCallback(async () => {
    if (isRequestIdle) requestEmail();
  }, [isRequestIdle, requestEmail]);

  switch (true) {
    case isVerified: {
      return (
        <Button disabled colorScheme="green" leftIcon={<CheckCircleIcon />}>
          Email verified
        </Button>
      );
    }

    case isSessionError: {
      return <ErrorWrapper error={sessionError} retry={sessionRefetch} />;
    }

    case isRequestError: {
      return <ErrorWrapper error={requestError} reset={requestReset} />;
    }

    case isRequestIdle:
    case isRequestLoading:
    case isSessionLoading:
    case isRequestSuccess: {
      return (
        <Stack spacing="2">
          <Tooltip
            fontSize="xs"
            textAlign="center"
            label="We sent you an email when you signed up. Please click the link in the email to verify your email address."
          >
            <Button
              onClick={handleClick}
              isLoading={isRequestLoading || isSessionLoading}
              isDisabled={isRequestLoading || isSessionLoading}
              type="button"
              border="2px solid white"
              bg="transparent"
            >
              {children}
            </Button>
          </Tooltip>

          {isRequestSuccess ? (
            <Text>An email has been sent to {session?.user.email}</Text>
          ) : null}
        </Stack>
      );
    }

    default: {
      return null;
    }
  }
}
