import { Prisma } from '@prisma/client';
import { z } from 'zod';

import { publicPodcastSelect } from '../podcasts';
import { publicEpisodeSelect } from '../podcasts/episodes';
import { TagSchema, UserSchema } from '../__generated__/zod-prisma';

export type AlgoliaIndices = z.infer<typeof AlgoliaIndicesEnum>;

export type IndexedSearchQuery = z.infer<typeof IndexedSearchQuery>;

export type SearchIndexPodcast = Prisma.PodcastGetPayload<{
  select: typeof podcastSearchIndexSelect;
}>;

export type SearchIndexEpisode = Prisma.EpisodeGetPayload<{
  select: typeof episodeSearchIndexSelect;
}>;

export type PodcastSearchIndex = z.infer<typeof PodcastSearchIndex>;

export type EpisodeSearchIndex = z.infer<typeof EpisodeSearchIndex>;

export type UserSearchIndex = z.infer<typeof UserSearchIndex>;

export type SearchResponse = z.infer<typeof SearchResponse>;

export const SearchResponse = z.object({
  message: z.string(),
  results: z.nullable(z.array(z.any())),
});

export const AlgoliaIndicesEnum = z.enum([
  'podcasts',
  'tags',
  'tags',
  'playlists',
  'tags',
  'users',
  'episodes',
]);

export const AlgoliaIndices = AlgoliaIndicesEnum.enum;

export const IndexedSearchQuery = z.object({
  indexName: AlgoliaIndicesEnum,
  query: z.string(),
});

export const PodcastSearchIndex = z.object({
  objectID: z.string(),

  isOriginal: z.boolean(),
  title: z.string(),
  description: z.nullable(z.string()),
  websiteUrl: z.nullable(z.string().url()),
  avatarUrl: z.nullable(z.string().url()),

  tags: z.array(TagSchema.pick({ name: true })),
  owners: z.array(UserSchema.pick({ id: true, displayName: true })),
});

export const EpisodeSearchIndex = z.object({
  objectID: z.string(),

  publishedAt: z.string(),
  title: z.string(),
  description: z.nullable(z.string()),
  imageUrl: z.nullable(z.string().url()),
  audioUrl: z.nullable(z.string().url()),

  podcast: PodcastSearchIndex.pick({
    objectID: true,
    title: true,
    description: true,
    websiteUrl: true,
    avatarUrl: true,

    owners: true,
  }),
});

export const UserSearchIndex = z.object({
  objectID: z.string(),

  displayName: z.nullable(z.string()),
  biography: z.nullable(z.string()),
  avatarUrl: z.nullable(z.string()),

  podcasts: z.nullable(
    z.array(
      PodcastSearchIndex.pick({
        objectID: true,
        title: true,
        description: true,
        websiteUrl: true,
        avatarUrl: true,
      })
    )
  ),

  tags: z.nullable(z.array(TagSchema.pick({ name: true }))),
});

export const podcastSearchIndexSelect = {
  ...publicPodcastSelect,
  episodes: { select: publicEpisodeSelect },

  owners:
    typeof publicPodcastSelect.owners === 'object'
      ? {
          ...publicPodcastSelect.owners,
          select: {
            ...publicPodcastSelect.owners.select,
            id: true,
            displayName: true,
            biography: true,
            tags: { select: { name: true } },
          },
        }
      : publicPodcastSelect.owners,
} as const;

export const episodeSearchIndexSelect = publicEpisodeSelect;

export const userSearchIndexSelect /*: Prisma.UserSelect */ = {
  id: true,
  email: true,
  podcasts: { select: podcastSearchIndexSelect },
  avatarUrl: true,
  displayName: true,
  biography: true,
  tags: { select: { name: true } },
} as const;
