import { z } from 'zod';

export const FileScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'name',
  'type',
  'size',
  'provider',
  'path',
  'url',
]);
