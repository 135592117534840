import { Button, ButtonGroup } from '@chakra-ui/react';
import { z } from 'zod';

import { TagSchema, TagUpdateOneSchema } from '@theqube/schemas';
import { FileField, Form, FormField, TextInput } from '../../../components';
import { trpc, useZodForm } from '../../../utils';

type FormInput = z.infer<typeof TagSchema>;
type FormData = z.infer<typeof TagUpdateOneSchema>;

function makeDefaultValues(initialValues?: FormInput): FormData {
  return {
    where: { id: initialValues?.id ?? 0 },

    data: {
      publishedAt: initialValues?.publishedAt ?? '',
      name: initialValues?.name ?? '',

      avatarUrl: initialValues?.avatarUrl ?? '',
    },
  };
}

export function EditTagForm(props: { initialValues?: FormInput }) {
  const { initialValues } = props;

  const { mutateAsync: updateTag, ...mutation } =
    trpc.tags.updateOne.useMutation();

  const form = useZodForm<typeof TagUpdateOneSchema>({
    mode: 'onChange',
    defaultValues: makeDefaultValues(initialValues),
    schema: TagUpdateOneSchema,
  });

  const handleFormSubmit = form.handleSubmit((values) => updateTag(values));

  return (
    <Form
      context={form}
      w="75%"
      maxW="lg"
      margin="0 auto"
      onSubmit={handleFormSubmit}
    >
      <FileField
        name="data.avatarUrl"
        label="Attach Header"
        placeholder="Upload a file to attach to the tag"
      />

      <FormField
        name="data.name"
        label="name"
        render={({ field }) => (
          <TextInput
            {...field}
            input={{ placeholder: 'The name of the tag' }}
          />
        )}
      />

      <ButtonGroup display="flex" justifyContent="center" my="4">
        <Button
          type="submit"
          isLoading={form.formState.isSubmitting || mutation.isLoading}
          disabled={form.formState.isSubmitting || mutation.isLoading}
          bg={
            mutation.isError || !form.formState.isValid
              ? 'error'
              : 'themeGold.500'
          }
        >
          Submit
        </Button>
      </ButtonGroup>
    </Form>
  );
}
