import { z } from 'zod';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserUpdateWithoutPodcastSubscriptionsInputObjectSchema } from './UserUpdateWithoutPodcastSubscriptionsInput.schema';
import { UserUncheckedUpdateWithoutPodcastSubscriptionsInputObjectSchema } from './UserUncheckedUpdateWithoutPodcastSubscriptionsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUpdateWithWhereUniqueWithoutPodcastSubscriptionsInput> =
  z
    .object({
      where: z.lazy(() => UserWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => UserUpdateWithoutPodcastSubscriptionsInputObjectSchema),
        z.lazy(
          () => UserUncheckedUpdateWithoutPodcastSubscriptionsInputObjectSchema
        ),
      ]),
    })
    .strict();

export const UserUpdateWithWhereUniqueWithoutPodcastSubscriptionsInputObjectSchema =
  Schema;
