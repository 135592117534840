import React from 'react';

export function useInterval(
  callback: (...args: unknown[]) => unknown,
  delay: number | null
) {
  const intervalRef = React.useRef<number | null>(null);
  const savedCallback = React.useRef(callback);
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(tick, delay);
      return () => {
        intervalRef.current && window.clearInterval(intervalRef.current);
      };
    }

    return () => {
      return;
    };
  }, [delay]);

  return intervalRef;
}
