import { z } from 'zod';
import { CurationWhereUniqueInputObjectSchema } from './CurationWhereUniqueInput.schema';
import { CurationUpdateWithoutPodcastInputObjectSchema } from './CurationUpdateWithoutPodcastInput.schema';
import { CurationUncheckedUpdateWithoutPodcastInputObjectSchema } from './CurationUncheckedUpdateWithoutPodcastInput.schema';
import { CurationCreateWithoutPodcastInputObjectSchema } from './CurationCreateWithoutPodcastInput.schema';
import { CurationUncheckedCreateWithoutPodcastInputObjectSchema } from './CurationUncheckedCreateWithoutPodcastInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.CurationUpsertWithWhereUniqueWithoutPodcastInput> =
  z
    .object({
      where: z.lazy(() => CurationWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => CurationUpdateWithoutPodcastInputObjectSchema),
        z.lazy(() => CurationUncheckedUpdateWithoutPodcastInputObjectSchema),
      ]),
      create: z.union([
        z.lazy(() => CurationCreateWithoutPodcastInputObjectSchema),
        z.lazy(() => CurationUncheckedCreateWithoutPodcastInputObjectSchema),
      ]),
    })
    .strict();

export const CurationUpsertWithWhereUniqueWithoutPodcastInputObjectSchema =
  Schema;
