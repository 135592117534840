import { components } from '../components';
import { blur } from './blur';
import { borders } from './borders';
import { colors } from './colors';
import { global } from './global';
import { radii } from './radius';
import { shadows } from './shadows';
import { sizes } from './sizes';
import { spacing } from './space';
import { transition } from './transition';
import { typography } from './typography';
import { zIndices } from './z-index';

export type RootThemeProps = {
  theme?: RootTheme;
};

export type RootTheme = typeof RootTheme & {
  colorScheme?: 'light' | 'dark';
};

export const RootTheme = {
  zIndices,
  blur,
  colors,
  shadows,
  borders,
  transition,
  components,
  global,
  radii,
  sizes,
  spacing,
  ...typography,
} as const;
