import { z } from 'zod';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';
import { PodcastUpdateWithoutSubscribersInputObjectSchema } from './PodcastUpdateWithoutSubscribersInput.schema';
import { PodcastUncheckedUpdateWithoutSubscribersInputObjectSchema } from './PodcastUncheckedUpdateWithoutSubscribersInput.schema';
import { PodcastCreateWithoutSubscribersInputObjectSchema } from './PodcastCreateWithoutSubscribersInput.schema';
import { PodcastUncheckedCreateWithoutSubscribersInputObjectSchema } from './PodcastUncheckedCreateWithoutSubscribersInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastUpsertWithWhereUniqueWithoutSubscribersInput> =
  z
    .object({
      where: z.lazy(() => PodcastWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => PodcastUpdateWithoutSubscribersInputObjectSchema),
        z.lazy(() => PodcastUncheckedUpdateWithoutSubscribersInputObjectSchema),
      ]),
      create: z.union([
        z.lazy(() => PodcastCreateWithoutSubscribersInputObjectSchema),
        z.lazy(() => PodcastUncheckedCreateWithoutSubscribersInputObjectSchema),
      ]),
    })
    .strict();

export const PodcastUpsertWithWhereUniqueWithoutSubscribersInputObjectSchema =
  Schema;
