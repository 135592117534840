import { z } from 'zod';
import { UserCreateWithoutPlaylistsInputObjectSchema } from './UserCreateWithoutPlaylistsInput.schema';
import { UserUncheckedCreateWithoutPlaylistsInputObjectSchema } from './UserUncheckedCreateWithoutPlaylistsInput.schema';
import { UserCreateOrConnectWithoutPlaylistsInputObjectSchema } from './UserCreateOrConnectWithoutPlaylistsInput.schema';
import { UserUpsertWithWhereUniqueWithoutPlaylistsInputObjectSchema } from './UserUpsertWithWhereUniqueWithoutPlaylistsInput.schema';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserUpdateWithWhereUniqueWithoutPlaylistsInputObjectSchema } from './UserUpdateWithWhereUniqueWithoutPlaylistsInput.schema';
import { UserUpdateManyWithWhereWithoutPlaylistsInputObjectSchema } from './UserUpdateManyWithWhereWithoutPlaylistsInput.schema';
import { UserScalarWhereInputObjectSchema } from './UserScalarWhereInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUpdateManyWithoutPlaylistsNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => UserCreateWithoutPlaylistsInputObjectSchema),
            z.lazy(() => UserCreateWithoutPlaylistsInputObjectSchema).array(),
            z.lazy(() => UserUncheckedCreateWithoutPlaylistsInputObjectSchema),
            z
              .lazy(() => UserUncheckedCreateWithoutPlaylistsInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(() => UserCreateOrConnectWithoutPlaylistsInputObjectSchema),
            z
              .lazy(() => UserCreateOrConnectWithoutPlaylistsInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .union([
            z.lazy(
              () => UserUpsertWithWhereUniqueWithoutPlaylistsInputObjectSchema
            ),
            z
              .lazy(
                () => UserUpsertWithWhereUniqueWithoutPlaylistsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        set: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        disconnect: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        delete: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(
              () => UserUpdateWithWhereUniqueWithoutPlaylistsInputObjectSchema
            ),
            z
              .lazy(
                () => UserUpdateWithWhereUniqueWithoutPlaylistsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        updateMany: z
          .union([
            z.lazy(
              () => UserUpdateManyWithWhereWithoutPlaylistsInputObjectSchema
            ),
            z
              .lazy(
                () => UserUpdateManyWithWhereWithoutPlaylistsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        deleteMany: z
          .union([
            z.lazy(() => UserScalarWhereInputObjectSchema),
            z.lazy(() => UserScalarWhereInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const UserUpdateManyWithoutPlaylistsNestedInputObjectSchema = Schema;
