import { z } from 'zod';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastMaxAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    publishedAt: z.literal(true).optional(),
    importedAt: z.literal(true).optional(),
    status: z.literal(true).optional(),
    statusReason: z.literal(true).optional(),
    avatarUrl: z.literal(true).optional(),
    isOriginal: z.literal(true).optional(),
    isExplicit: z.literal(true).optional(),
    title: z.literal(true).optional(),
    description: z.literal(true).optional(),
    websiteUrl: z.literal(true).optional(),
  })
  .strict();

export const PodcastMaxAggregateInputObjectSchema = Schema;
