import { z } from 'zod';
import { CurationCreateWithoutCuratorInputObjectSchema } from './CurationCreateWithoutCuratorInput.schema';
import { CurationUncheckedCreateWithoutCuratorInputObjectSchema } from './CurationUncheckedCreateWithoutCuratorInput.schema';
import { CurationCreateOrConnectWithoutCuratorInputObjectSchema } from './CurationCreateOrConnectWithoutCuratorInput.schema';
import { CurationCreateManyCuratorInputEnvelopeObjectSchema } from './CurationCreateManyCuratorInputEnvelope.schema';
import { CurationWhereUniqueInputObjectSchema } from './CurationWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.CurationCreateNestedManyWithoutCuratorInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => CurationCreateWithoutCuratorInputObjectSchema),
            z.lazy(() => CurationCreateWithoutCuratorInputObjectSchema).array(),
            z.lazy(
              () => CurationUncheckedCreateWithoutCuratorInputObjectSchema
            ),
            z
              .lazy(
                () => CurationUncheckedCreateWithoutCuratorInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(
              () => CurationCreateOrConnectWithoutCuratorInputObjectSchema
            ),
            z
              .lazy(
                () => CurationCreateOrConnectWithoutCuratorInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        createMany: z
          .lazy(() => CurationCreateManyCuratorInputEnvelopeObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => CurationWhereUniqueInputObjectSchema),
            z.lazy(() => CurationWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const CurationCreateNestedManyWithoutCuratorInputObjectSchema = Schema;
