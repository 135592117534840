import { z } from 'zod';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';
import { PodcastUpdateWithoutOwnersInputObjectSchema } from './PodcastUpdateWithoutOwnersInput.schema';
import { PodcastUncheckedUpdateWithoutOwnersInputObjectSchema } from './PodcastUncheckedUpdateWithoutOwnersInput.schema';
import { PodcastCreateWithoutOwnersInputObjectSchema } from './PodcastCreateWithoutOwnersInput.schema';
import { PodcastUncheckedCreateWithoutOwnersInputObjectSchema } from './PodcastUncheckedCreateWithoutOwnersInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastUpsertWithWhereUniqueWithoutOwnersInput> =
  z
    .object({
      where: z.lazy(() => PodcastWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => PodcastUpdateWithoutOwnersInputObjectSchema),
        z.lazy(() => PodcastUncheckedUpdateWithoutOwnersInputObjectSchema),
      ]),
      create: z.union([
        z.lazy(() => PodcastCreateWithoutOwnersInputObjectSchema),
        z.lazy(() => PodcastUncheckedCreateWithoutOwnersInputObjectSchema),
      ]),
    })
    .strict();

export const PodcastUpsertWithWhereUniqueWithoutOwnersInputObjectSchema =
  Schema;
