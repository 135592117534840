import { z } from 'zod';
import { PlaylistUncheckedCreateNestedManyWithoutEpisodesInputObjectSchema } from './PlaylistUncheckedCreateNestedManyWithoutEpisodesInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.EpisodeUncheckedCreateWithoutPodcastInput> = z
  .object({
    id: z.number().optional(),
    updatedAt: z.date().optional(),
    publishedAt: z.date().optional(),
    playlists: z
      .lazy(
        () => PlaylistUncheckedCreateNestedManyWithoutEpisodesInputObjectSchema
      )
      .optional(),
    title: z.string(),
    description: z.string().optional().nullable(),
    imageUrl: z.string().optional().nullable(),
    audioUrl: z.string(),
    duration: z.number().optional(),
    explicit: z.boolean().optional(),
  })
  .strict();

export const EpisodeUncheckedCreateWithoutPodcastInputObjectSchema = Schema;
