import { z } from 'zod';
import { NullableJsonNullValueInputSchema } from '../enums/NullableJsonNullValueInput.schema';

import type { Prisma } from '@prisma/client';

const literalSchema = z.union([z.string(), z.number(), z.boolean()]);
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ])
);

const Schema: z.ZodType<Prisma.UserCreateManyInput> = z
  .object({
    id: z.string().optional(),
    avatarUrl: z.string().optional().nullable(),
    displayName: z.string().optional().nullable(),
    fullName: z.string(),
    email: z.string().optional().nullable(),
    emailVerified: z.date().optional().nullable(),
    passwordHash: z.string().optional().nullable(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    biography: z.string().optional().nullable(),
    birthday: z.date().optional().nullable(),
    gender: z.string().optional().nullable(),
    socialMedia: z
      .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
      .optional(),
  })
  .strict();

export const UserCreateManyInputObjectSchema = Schema;
