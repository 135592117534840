import { Flex } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

import { PageHeading, PageHero, HTMLRenderer } from '../../components';
import { trpc, useAnalytics } from '../../utils';

type EpisodeHeaderProps = {
  episodeId: number;
};
export function EpisodeHeader({ episodeId }: EpisodeHeaderProps) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const { gtag } = useAnalytics();

  const { data: episode, isSuccess } = trpc.episodes.findOne.useQuery(
    {
      where: episodeId ? { id: +episodeId } : { id: 0 },
      orderBy: { publishedAt: 'desc' },
    },
    { enabled: Boolean(episodeId) }
  );

  useEffect(() => {
    if (gtag && episode) {
      gtag('event', 'view_item', {
        item_id: episode.id,
        item_name: episode.title,
      });
    }
  }, [gtag, episode]);

  useEffect(() => {
    const ref = audioRef.current ?? undefined;
    if (gtag && ref) {
      ref.onplay = () => {
        gtag('event', 'play_audio', {
          episode_id: episodeId,
        });
      };
    }
  }, [episodeId, gtag]);

  return (
    <PageHero
      isLoaded={isSuccess}
      thumbnail={{ src: episode?.imageUrl ?? undefined }}
    >
      <PageHeading>{episode?.title}</PageHeading>

      <Flex justify="center" align="center" my="6">
        <audio ref={audioRef} src={episode?.audioUrl} controls />
      </Flex>

      <HTMLRenderer html={episode?.description} />
    </PageHero>
  );
}
