import { useBreakpointValue } from '@chakra-ui/react';
import { useSession } from '../../../features/auth';
import { Header, HeaderProps } from './header';
import { AuthList, AuthMenu } from './menus/auth';
import { InfoMenu } from './menus/info';
import { HeaderLogo } from './menus/logo';

export function StudioHeader() {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const [{ isSuccess: isAuthenticated }] = useSession();

  const sections: HeaderProps['sections'] = [];

  if (isAuthenticated) {
    sections.push({ key: 'auth', content: <AuthMenu /> });
  } else if (isMobile) {
    sections.push(
      {
        key: 'info',
        content: <InfoMenu />,
        boxProps: {
          position: 'absolute',
          top: '2.5%',
          left: '2.5%',
        },
      },
      {
        key: 'logo',
        content: <HeaderLogo />,
        boxProps: { flexGrow: 1 },
      }
    );
  } else {
    sections.push(
      { key: 'logo', content: <HeaderLogo /> },

      {
        key: 'auth',
        content: <AuthList />,
        boxProps: {
          flex: '1 0 auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        },
      }
    );
  }

  return (
    <Header
      containerProps={{ justifyContent: 'flex-end' }}
      sections={sections}
    />
  );
}
