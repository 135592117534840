import { z } from 'zod';
import { UserCreateNestedManyWithoutTagsInputObjectSchema } from './UserCreateNestedManyWithoutTagsInput.schema';
import { TagTypeSchema } from '../enums/TagType.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.TagCreateWithoutPodcastsInput> = z
  .object({
    slug: z.string().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    publishedAt: z.date().optional().nullable(),
    avatarUrl: z.string().optional().nullable(),
    users: z
      .lazy(() => UserCreateNestedManyWithoutTagsInputObjectSchema)
      .optional(),
    type: z.lazy(() => TagTypeSchema),
    name: z.string(),
  })
  .strict();

export const TagCreateWithoutPodcastsInputObjectSchema = Schema;
