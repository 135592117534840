import { z } from 'zod';

import { SettingsSchema, UserSchema } from './__generated__/zod-prisma';

export type EmailVerificationBody = z.infer<typeof EmailVerificationBody>;
export type EmailVerificationResponse = z.infer<
  typeof EmailVerificationResponse
>;
export type OAuthFailureResponse = z.infer<typeof OAuthFailureResponse>;
export type OAuthSuccessResponse = z.infer<typeof OAuthSuccessResponse>;
export type ForgotPasswordResponse = z.infer<typeof ForgotPasswordResponse>;
export type ResetPasswordResponse = z.infer<typeof ResetPasswordResponse>;
export type ChangePasswordResponse = z.infer<typeof ChangePasswordResponse>;
export type LoginResponse = z.infer<typeof LoginResponse>;
export type LogoutResponse = z.infer<typeof LogoutResponse>;
export type LoginInput = z.infer<typeof LoginInput>;
export type RegisterInput = z.infer<typeof RegisterInput>;
export type RegisterResponse = { message: string; token: string };
export type ChangePasswordBody = z.infer<typeof ChangePasswordBody>;
export type ForgotPasswordBody = z.infer<typeof ForgotPasswordBody>;
export type ResetPasswordBody = z.infer<typeof ResetPasswordBody>;
export type ResetPasswordParams = { token: string };

export const EmailSchema = z
  .string()
  .email('Email must be a valid email address');

export type PasswordSchema = z.infer<typeof PasswordSchema>;
export const PasswordSchema = z
  .string()
  .min(8, 'Password must be at least 8 characters long')
  .max(50, 'Password cannot be longer than 50 characters')
  // contains uppercase letter
  .refine(
    (value) => /[A-Z]/.test(value),
    'Password must contain at least one uppercase letter'
  )
  // contains lowercase letter
  .refine((value) => /[a-z]/.test(value), {
    message: 'Password must contain at least one lowercase letter',
  })
  // contains number or special character
  .refine((value) => /[0-9]/.test(value) || /[!@#$%^&*]/.test(value), {
    message:
      'Password must contain at least one number or special character (e.g. !@#$%^&*)',
  });

export type RequestEmailVerificationResponse = z.infer<
  typeof RequestEmailVerificationResponse
>;
export const RequestEmailVerificationResponse = z.object({
  message: z.string(),
});

export const EmailVerificationBody = z.object({ token: z.string() });

export const EmailVerificationResponse = z.object({ message: z.string() });

export const OAuthFailureResponse = z.object({ message: z.string() });

export const OAuthSuccessResponse = z.object({
  message: z.string(),
  user: UserSchema,
});

export const ForgotPasswordResponse = z.object({
  message: z.string(),
});

export const ResetPasswordResponse = z.object({
  message: z.string(),
});

export const ChangePasswordResponse = z.object({
  message: z.string(),
});

export const LoginResponse = z.object({
  message: z.string(),
  token: z.string().min(10),
});

export const LogoutResponse = z.object({
  message: z.string(),
});

export const LoginInput = z.object({
  email: EmailSchema,
  password: PasswordSchema,
});

export const RegisterInput = UserSchema.pick({
  email: true,
  // avatarUrl: true,
  // biography: true,
  birthday: true,
  // displayName: true,
  fullName: true,
  // gender: true,
  // socialMedia: true,
})
  .extend({
    password: z.string(),
    passwordConfirm: z.string(),

    settings: z.lazy(() =>
      SettingsSchema.pick({
        acceptedNewsletterAt: true,
        acceptedPrivacyAt: true,
        acceptedTermsAt: true,
      })
    ),
  })
  .refine((values) => values.password === values.passwordConfirm, {
    message: 'Passwords do not match.',
    path: ['passwordConfirm'],
  });

export const UserIdSchema = z.string().min(1, 'User id cannot be empty');

export const ForgotPasswordBody = z.object({ email: EmailSchema });
export const ResetPasswordBody = z
  .object({
    token: z.string().min(1),
    password: PasswordSchema,
    passwordConfirm: PasswordSchema,
  })
  .refine((body) => body.password === body.passwordConfirm, {
    message: 'Passwords do not match.',
    path: ['passwordConfirm'],
  });

export const ChangePasswordBody = z
  .object({
    oldPassword: z.string(),
    password: PasswordSchema,
    passwordConfirm: PasswordSchema,
  })
  .refine((body) => body.password === body.passwordConfirm, {
    message: 'Passwords do not match.',
    path: ['passwordConfirm'],
  });

export type JWTPayload = z.infer<typeof JWTPayload>;

export const JWTPayload = z.object({
  id: z.string().min(1),
  name: z.string().nullable(),
  image: z.string().nullable(),
  email: z.string().nullable(),
  emailVerified: z.string().nullable(),
});

export type GoogleInput = z.infer<typeof GoogleInput>;
export const GoogleInput = z.object({ token: z.string().min(1) });
