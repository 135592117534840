import { z } from 'zod';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';
import { PodcastUpdateWithoutOwnersInputObjectSchema } from './PodcastUpdateWithoutOwnersInput.schema';
import { PodcastUncheckedUpdateWithoutOwnersInputObjectSchema } from './PodcastUncheckedUpdateWithoutOwnersInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastUpdateWithWhereUniqueWithoutOwnersInput> =
  z
    .object({
      where: z.lazy(() => PodcastWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => PodcastUpdateWithoutOwnersInputObjectSchema),
        z.lazy(() => PodcastUncheckedUpdateWithoutOwnersInputObjectSchema),
      ]),
    })
    .strict();

export const PodcastUpdateWithWhereUniqueWithoutOwnersInputObjectSchema =
  Schema;
