import { z } from 'zod';
import { SettingsCreateWithoutOwnerInputObjectSchema } from './SettingsCreateWithoutOwnerInput.schema';
import { SettingsUncheckedCreateWithoutOwnerInputObjectSchema } from './SettingsUncheckedCreateWithoutOwnerInput.schema';
import { SettingsCreateOrConnectWithoutOwnerInputObjectSchema } from './SettingsCreateOrConnectWithoutOwnerInput.schema';
import { SettingsWhereUniqueInputObjectSchema } from './SettingsWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.SettingsCreateNestedOneWithoutOwnerInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => SettingsCreateWithoutOwnerInputObjectSchema),
            z.lazy(() => SettingsUncheckedCreateWithoutOwnerInputObjectSchema),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .lazy(() => SettingsCreateOrConnectWithoutOwnerInputObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z.lazy(() => SettingsWhereUniqueInputObjectSchema).optional(),
      })
      .strict(),
  ]);

export const SettingsCreateNestedOneWithoutOwnerInputObjectSchema = Schema;
