import { z } from 'zod';
import { PodcastWhereInputObjectSchema } from './objects/PodcastWhereInput.schema';
import { PodcastOrderByWithAggregationInputObjectSchema } from './objects/PodcastOrderByWithAggregationInput.schema';
import { PodcastScalarWhereWithAggregatesInputObjectSchema } from './objects/PodcastScalarWhereWithAggregatesInput.schema';
import { PodcastScalarFieldEnumSchema } from './enums/PodcastScalarFieldEnum.schema';

export const PodcastGroupBySchema = z.object({
  where: PodcastWhereInputObjectSchema.optional(),
  orderBy: z
    .union([
      PodcastOrderByWithAggregationInputObjectSchema,
      PodcastOrderByWithAggregationInputObjectSchema.array(),
    ])
    .optional(),
  having: PodcastScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(PodcastScalarFieldEnumSchema),
});
