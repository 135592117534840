import { z } from 'zod';
import { UserCreateWithoutPodcastsCuratedInputObjectSchema } from './UserCreateWithoutPodcastsCuratedInput.schema';
import { UserUncheckedCreateWithoutPodcastsCuratedInputObjectSchema } from './UserUncheckedCreateWithoutPodcastsCuratedInput.schema';
import { UserCreateOrConnectWithoutPodcastsCuratedInputObjectSchema } from './UserCreateOrConnectWithoutPodcastsCuratedInput.schema';
import { UserUpsertWithoutPodcastsCuratedInputObjectSchema } from './UserUpsertWithoutPodcastsCuratedInput.schema';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserUpdateWithoutPodcastsCuratedInputObjectSchema } from './UserUpdateWithoutPodcastsCuratedInput.schema';
import { UserUncheckedUpdateWithoutPodcastsCuratedInputObjectSchema } from './UserUncheckedUpdateWithoutPodcastsCuratedInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutPodcastsCuratedNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => UserCreateWithoutPodcastsCuratedInputObjectSchema),
            z.lazy(
              () => UserUncheckedCreateWithoutPodcastsCuratedInputObjectSchema
            ),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .lazy(
            () => UserCreateOrConnectWithoutPodcastsCuratedInputObjectSchema
          )
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .lazy(() => UserUpsertWithoutPodcastsCuratedInputObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z.lazy(() => UserWhereUniqueInputObjectSchema).optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(() => UserUpdateWithoutPodcastsCuratedInputObjectSchema),
            z.lazy(
              () => UserUncheckedUpdateWithoutPodcastsCuratedInputObjectSchema
            ),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const UserUpdateOneRequiredWithoutPodcastsCuratedNestedInputObjectSchema =
  Schema;
