import { Box, Icon, Text } from '@chakra-ui/react';
import { BsFacebook, BsGlobe2, BsInstagram } from 'react-icons/bs';
import { FiTwitter } from 'react-icons/fi';

import { Link } from '../../../../components';

interface OriginalTeamSocials {
  instagram?: string;

  instagram_link?: string;
  twitter?: string;
  twitter_link?: string;
  web?: string;
  facebook?: string;
  facebook_link?: string;
}

export function OriginalTeamSocials(props: OriginalTeamSocials) {
  const {
    instagram,

    instagram_link,
    twitter,
    twitter_link,
    web,
    facebook,
    facebook_link = '',
  } = props;

  return (
    <>
      {instagram && (
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <Icon as={BsInstagram} color="gold.500" mr={1} />

          <Link
            href={instagram_link ? instagram_link : '#'}
            passHref
            isExternal
          >
            <Text>{instagram}</Text>
          </Link>
        </Box>
      )}

      {twitter && (
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <Icon as={FiTwitter} color="gold.500" mr={1} />
          <Link href={twitter_link ? twitter_link : '#'} passHref isExternal>
            <Text>{twitter}</Text>
          </Link>
        </Box>
      )}

      {facebook && (
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <Icon as={BsFacebook} color="gold.500" mr={1} />

          <Link href={facebook_link ? facebook_link : '#'} passHref isExternal>
            <Text>{facebook}</Text>
          </Link>
        </Box>
      )}

      {web && (
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <Icon as={BsGlobe2} color="gold.500" mr={1} />
          <Link href={web} passHref isExternal>
            <Text>
              {web.includes('https://')
                ? web.split('https://')
                : web.split('http://')}
            </Text>
          </Link>
        </Box>
      )}
    </>
  );
}
