import { z } from 'zod';
import { SettingsWhereUniqueInputObjectSchema } from './objects/SettingsWhereUniqueInput.schema';
import { SettingsCreateInputObjectSchema } from './objects/SettingsCreateInput.schema';
import { SettingsUpdateInputObjectSchema } from './objects/SettingsUpdateInput.schema';

export const SettingsUpsertSchema = z.object({
  where: SettingsWhereUniqueInputObjectSchema,
  create: SettingsCreateInputObjectSchema,
  update: SettingsUpdateInputObjectSchema,
});
