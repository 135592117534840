import { z } from 'zod';
import { CurationWhereUniqueInputObjectSchema } from './CurationWhereUniqueInput.schema';
import { CurationUpdateWithoutPodcastInputObjectSchema } from './CurationUpdateWithoutPodcastInput.schema';
import { CurationUncheckedUpdateWithoutPodcastInputObjectSchema } from './CurationUncheckedUpdateWithoutPodcastInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.CurationUpdateWithWhereUniqueWithoutPodcastInput> =
  z
    .object({
      where: z.lazy(() => CurationWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => CurationUpdateWithoutPodcastInputObjectSchema),
        z.lazy(() => CurationUncheckedUpdateWithoutPodcastInputObjectSchema),
      ]),
    })
    .strict();

export const CurationUpdateWithWhereUniqueWithoutPodcastInputObjectSchema =
  Schema;
