import { Button, ButtonGroup, Heading, Skeleton } from '@chakra-ui/react';
import { z } from 'zod';

import { EpisodeFindManySchema } from '@theqube/schemas';
import { TRPCUseQueryBaseOptions } from '@trpc/react-query/shared';
import { trpc, usePagination } from '../../utils';
import { EpisodeCard } from './card';

export type EpisodeListProps = {
  query: z.infer<typeof EpisodeFindManySchema>;
  options?: TRPCUseQueryBaseOptions;
};

export function EpisodeList({ query, options }: EpisodeListProps) {
  const [pagination, { move }] = usePagination();

  const {
    isLoading,
    isSuccess,
    data: episodes,
  } = trpc.episodes.findMany.useQuery(
    {
      orderBy: { publishedAt: 'desc' },
      ...pagination,
      ...query,
    },
    options
  );

  return (
    <Skeleton isLoaded={isSuccess}>
      {episodes && episodes?.length > 0 ? (
        <>
          {episodes.map((episode) => (
            <EpisodeCard key={episode.id} episode={episode} />
          ))}

          <ButtonGroup justifyContent="space-between" width="100%">
            <Button onClick={move} isDisabled={isLoading} isLoading={isLoading}>
              Show More
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <Heading>
          There doesn&apos;t appear to be any more episodes for this podcast.
        </Heading>
      )}
    </Skeleton>
  );
}
