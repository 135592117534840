import { z } from 'zod';
import { UserCreateWithoutTagsInputObjectSchema } from './UserCreateWithoutTagsInput.schema';
import { UserUncheckedCreateWithoutTagsInputObjectSchema } from './UserUncheckedCreateWithoutTagsInput.schema';
import { UserCreateOrConnectWithoutTagsInputObjectSchema } from './UserCreateOrConnectWithoutTagsInput.schema';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserCreateNestedManyWithoutTagsInput> = z.union([
  z
    .object({
      create: z
        .union([
          z.lazy(() => UserCreateWithoutTagsInputObjectSchema),
          z.lazy(() => UserCreateWithoutTagsInputObjectSchema).array(),
          z.lazy(() => UserUncheckedCreateWithoutTagsInputObjectSchema),
          z.lazy(() => UserUncheckedCreateWithoutTagsInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict(),
  z
    .object({
      connectOrCreate: z
        .union([
          z.lazy(() => UserCreateOrConnectWithoutTagsInputObjectSchema),
          z.lazy(() => UserCreateOrConnectWithoutTagsInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict(),
  z
    .object({
      connect: z
        .union([
          z.lazy(() => UserWhereUniqueInputObjectSchema),
          z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict(),
]);

export const UserCreateNestedManyWithoutTagsInputObjectSchema = Schema;
