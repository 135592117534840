import { Box, BoxProps, Heading, HStack, Text } from '@chakra-ui/react';
import { formatDistance } from 'date-fns';
import { Episode } from '@theqube/schemas';
import { Image, Link } from '../../components';
import { StudioRoutes } from '../../utils';
import { useMemo } from 'react';

type EpisodeListItemProps = {
  episode: Partial<Episode>;
  container?: BoxProps;
};

export function EpisodeCard({
  container = {
    px: '4',
    py: '8',
  },
  episode: { id, title = 'Untitled', publishedAt, duration, imageUrl },
}: EpisodeListItemProps) {
  const formattedDuration = useMemo(() => {
    return formatDistance(0, (duration ?? 0) * 1000, { includeSeconds: true });
  }, [duration]);

  return (
    <Box
      display="flex"
      alignItems="center"
      borderBottom="1px solid white"
      _hover={{ textDecoration: 'none' }}
      {...container}
    >
      <Link
        href={
          StudioRoutes.discover.children?.episodes.children?.id.href(
            String(id)
          ) ?? ''
        }
        flex="0 0 20%"
      >
        <Image src={imageUrl ?? ''} rounded="md" />
      </Link>

      <Box ml="4">
        <Heading
          as={Link}
          href={
            StudioRoutes.discover.children?.episodes.children?.id.href(
              String(id)
            ) ?? ''
          }
          size={'md'}
        >
          {title}
        </Heading>

        <HStack mt="4" spacing="2">
          <Text as="span">{publishedAt?.toLocaleDateString()}</Text>
          <Text as="span">{formattedDuration}</Text>
        </HStack>
      </Box>
    </Box>
  );
}
