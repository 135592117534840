import { z } from 'zod';
import { SettingsUpdateWithoutOwnerInputObjectSchema } from './SettingsUpdateWithoutOwnerInput.schema';
import { SettingsUncheckedUpdateWithoutOwnerInputObjectSchema } from './SettingsUncheckedUpdateWithoutOwnerInput.schema';
import { SettingsCreateWithoutOwnerInputObjectSchema } from './SettingsCreateWithoutOwnerInput.schema';
import { SettingsUncheckedCreateWithoutOwnerInputObjectSchema } from './SettingsUncheckedCreateWithoutOwnerInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.SettingsUpsertWithoutOwnerInput> = z
  .object({
    update: z.union([
      z.lazy(() => SettingsUpdateWithoutOwnerInputObjectSchema),
      z.lazy(() => SettingsUncheckedUpdateWithoutOwnerInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => SettingsCreateWithoutOwnerInputObjectSchema),
      z.lazy(() => SettingsUncheckedCreateWithoutOwnerInputObjectSchema),
    ]),
  })
  .strict();

export const SettingsUpsertWithoutOwnerInputObjectSchema = Schema;
