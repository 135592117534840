import { Flex, VStack } from '@chakra-ui/react';
import { ReactElement } from 'react';

import { Logo, RainbowWaveForm, PageHeading } from '../atoms';

export function CategoriesHero(): ReactElement {
  return (
    <Flex
      as="header"
      h="55vh"
      mb="25vh"
      flexFlow="row wrap"
      position="relative"
      justify="center"
      flexShrink={1}
    >
      <VStack mt={10} spacing={10}>
        <PageHeading fontSize="6xl">We Are The Culture</PageHeading>
        <Logo />
      </VStack>

      <RainbowWaveForm
        position="absolute"
        bottom={{
          base: -10,
          sm: -60,
          md: -85,
          lg: -110,
          xl: -150,
          '2xl': -210,
          '3xl': -280,
        }}
        left="0"
        right="0"
      />
    </Flex>
  );
}
