import { z } from 'zod';
import { PodcastCreateWithoutOwnersInputObjectSchema } from './PodcastCreateWithoutOwnersInput.schema';
import { PodcastUncheckedCreateWithoutOwnersInputObjectSchema } from './PodcastUncheckedCreateWithoutOwnersInput.schema';
import { PodcastCreateOrConnectWithoutOwnersInputObjectSchema } from './PodcastCreateOrConnectWithoutOwnersInput.schema';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastCreateNestedManyWithoutOwnersInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => PodcastCreateWithoutOwnersInputObjectSchema),
            z.lazy(() => PodcastCreateWithoutOwnersInputObjectSchema).array(),
            z.lazy(() => PodcastUncheckedCreateWithoutOwnersInputObjectSchema),
            z
              .lazy(() => PodcastUncheckedCreateWithoutOwnersInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(() => PodcastCreateOrConnectWithoutOwnersInputObjectSchema),
            z
              .lazy(() => PodcastCreateOrConnectWithoutOwnersInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => PodcastWhereUniqueInputObjectSchema),
            z.lazy(() => PodcastWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const PodcastCreateNestedManyWithoutOwnersInputObjectSchema = Schema;
