import { z } from 'zod';
import { EpisodeWhereUniqueInputObjectSchema } from './EpisodeWhereUniqueInput.schema';
import { EpisodeCreateWithoutPodcastInputObjectSchema } from './EpisodeCreateWithoutPodcastInput.schema';
import { EpisodeUncheckedCreateWithoutPodcastInputObjectSchema } from './EpisodeUncheckedCreateWithoutPodcastInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.EpisodeCreateOrConnectWithoutPodcastInput> = z
  .object({
    where: z.lazy(() => EpisodeWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => EpisodeCreateWithoutPodcastInputObjectSchema),
      z.lazy(() => EpisodeUncheckedCreateWithoutPodcastInputObjectSchema),
    ]),
  })
  .strict();

export const EpisodeCreateOrConnectWithoutPodcastInputObjectSchema = Schema;
