import { z } from 'zod';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';
import { PodcastCreateWithoutCuratorsInputObjectSchema } from './PodcastCreateWithoutCuratorsInput.schema';
import { PodcastUncheckedCreateWithoutCuratorsInputObjectSchema } from './PodcastUncheckedCreateWithoutCuratorsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastCreateOrConnectWithoutCuratorsInput> = z
  .object({
    where: z.lazy(() => PodcastWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => PodcastCreateWithoutCuratorsInputObjectSchema),
      z.lazy(() => PodcastUncheckedCreateWithoutCuratorsInputObjectSchema),
    ]),
  })
  .strict();

export const PodcastCreateOrConnectWithoutCuratorsInputObjectSchema = Schema;
