import { createTRPCNext, WithTRPCNoSSROptions } from '@trpc/next';
import { type inferReactQueryProcedureOptions } from '@trpc/react-query';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import { httpBatchLink, HTTPHeaders, loggerLink } from '@trpc/client';
import superjson from 'superjson';

import type { Router } from '@theqube/trpc';
import { REFRESH_TOKEN_KEY } from '../tokens';
import { environment } from '../../utils';

function makeTRPCBaseURL(): string {
  const suffix = '/api/trpc';

  return environment.get('NEXT_PUBLIC_API_URL') + suffix;
}

export type ReactQueryOptions = inferReactQueryProcedureOptions<Router>;
export type RouterInputs = inferRouterInputs<Router>;
export type RouterOutputs = inferRouterOutputs<Router>;

const trpcNextOptions: WithTRPCNoSSROptions<Router> = {
  ssr: false,
  config() {
    return {
      transformer: superjson,
      links: [
        loggerLink({
          enabled: (opts) =>
            !environment.isProduction ||
            (opts.direction === 'down' && opts.result instanceof Error),
        }),
        httpBatchLink({
          /**
           * If you want to use SSR, you need to use the server's full URL
           * @link https://trpc.io/docs/ssr
           */
          url: makeTRPCBaseURL(),

          headers() {
            const reqHeaders: HTTPHeaders = {};
            const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

            if (refreshToken) {
              reqHeaders['Authorization'] = `Bearer ${refreshToken}`;
            }

            return reqHeaders;
          },
        }),
      ],
    };
  },
};

export const trpc = createTRPCNext<Router>(trpcNextOptions);
