import { SubmitHandler, useFormContext } from 'react-hook-form';
import { z } from 'zod';

import {
  Button,
  ButtonGroup,
  ButtonProps,
  chakra,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { TagSchema, TagType, EditTagsFormData } from '@theqube/schemas';
import { ErrorWrapper, Form } from '../../../components';
import { trpc, useZodForm } from '../../../utils';
import { useSession } from '../../auth';

type EditTagsFormProps = {
  layout: 'block' | 'cloud';
  type: TagType;
  session: ReturnType<typeof useSession>[0]['data'];
  onFormSubmit: SubmitHandler<EditTagsFormData>;
};

export function EditTagsForm({
  layout,
  session,
  type,
  onFormSubmit,
}: EditTagsFormProps) {
  const initialTags =
    session?.user.tags.filter((tag) => tag.type === type) ?? [];

  const form = useZodForm({
    defaultValues: { tags: initialTags },
    schema: EditTagsFormData,
  });

  const handleFormSubmit = form.handleSubmit(onFormSubmit);

  return (
    <Form context={form} position="relative">
      <TagsButtonList layout={layout} type={type} onSubmit={handleFormSubmit} />
    </Form>
  );
}

function TagsButtonList({
  layout,
  type,
  onSubmit,
}: Pick<EditTagsFormProps, 'type' | 'layout'> & {
  onSubmit: () => void;
}) {
  const [
    {
      selectedTags,
      isAllSelected,
      query: { isError, error, refetch, isSuccess, data },
      disclosure: { onOpen, onClose, isOpen },
      form: {
        formState: { isSubmitting },
      },
    },
    { isSelected, makeOnClick, makeOnToggleAll },
  ] = useEditTagsForm({ type });

  //

  const styles: Record<
    EditTagsFormProps['layout'] | 'base',
    (tag: z.infer<typeof TagSchema>) => ButtonProps
  > = {
    base: (tag) => ({
      borderWidth: '4px',
      borderStyle: 'solid',
      borderColor: isSelected(tag) ? 'themeGold.500' : 'whiteAlpha.300',
      borderRadius: 'md',
      fontSize: ['lg', 'lg', 'xl'],
    }),

    cloud: (_tag) => ({
      flex: '1 1 20%',
      borderRadius: 'full',
      alignSelf: 'center',
      whiteSpace: 'nowrap',
      m: 1,
      py: '1',
      px: '4',
    }),

    block: (tag) => ({
      flex: '1 0 45%',
      mx: '2',
      my: '4',
      minHeight: '24',
      textShadow: 'md',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage: tag.avatarUrl
        ? `radial-gradient(rgba(0,0,0,0.9), rgba(255,255,255,0.1)), url('${tag.avatarUrl}')`
        : undefined,
    }),
  };

  if (isError) {
    return <ErrorWrapper error={error} retry={refetch} />;
  }

  return (
    <>
      <Button
        type="button"
        onClick={isAllSelected ? makeOnToggleAll('off') : onOpen}
        disabled={isSubmitting}
        variant="ghost"
        colorScheme="white"
        display="block"
        mx="auto"
        position="sticky"
        top="5%"
        shadow="md"
        textAlign="center"
        bg="gray.800"
        _hover={{
          bg: 'gray.900',
        }}
      >
        {isAllSelected ? 'Deselect All' : 'Select All'}
      </Button>

      <Skeleton
        isLoaded={isSuccess}
        display="flex"
        flexWrap="wrap"
        alignItems="stretch"
        justifyContent="space-between"
        maxW="container.md"
        mx="auto"
      >
        {data?.map((tag) => (
          <chakra.button
            type="button"
            key={tag.id}
            disabled={isSubmitting}
            onClick={makeOnClick(tag)}
            {...styles.base(tag)}
            {...styles[layout](tag)}
          >
            {tag.name}
          </chakra.button>
        ))}
      </Skeleton>

      {selectedTags.length > 0 ? (
        <Button
          onClick={onSubmit}
          disabled={isSubmitting}
          mx="auto"
          position="fixed"
          bottom="5%"
          left="50%"
          transform="auto"
          translateX="-50%"
          shadow="md"
        >
          Continue
        </Button>
      ) : null}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Dope!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              You want it all or nothing huh. Now selecting all won't generate
              specific results. You sure ALL is really what you want?
            </Text>
          </ModalBody>

          <ModalFooter as={ButtonGroup}>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>

            <Button onClick={makeOnToggleAll('on')}>Continue</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function useEditTagsForm({ type }: Pick<EditTagsFormProps, 'type'>) {
  const query = trpc.tags.findMany.useQuery({ where: { type } });
  const disclosure = useDisclosure();
  const form = useFormContext<EditTagsFormData>();

  const { onClose } = disclosure;
  const { data } = query;
  const { watch, setValue, getValues } = form;
  const { tags: selectedTags } = watch();

  const isAllSelected = (() => {
    if (!data) return false;
    if (!selectedTags) return false;

    const isLengthMatch = data.length === selectedTags.length;

    if (isLengthMatch) {
      const isContentMatch = data.every(
        (tag) =>
          selectedTags.findIndex((selected) => selected.id === tag.id) >= 0
      );

      return isContentMatch;
    }

    return false;
  })();

  const isSelected = (tag: z.infer<typeof TagSchema>) => {
    const index = selectedTags.findIndex((t) => t.id === tag.id);

    return index >= 0;
  };

  const makeOnToggleAll = (direction: 'on' | 'off') => () => {
    if (!data) return;

    const shouldToggleOn = direction === 'on';

    if (shouldToggleOn) {
      setValue(
        'tags',
        data?.map((tag) => ({ id: tag.id }))
      );
    } else {
      setValue('tags', []);
    }

    onClose();
  };

  const makeOnClick = (tag: z.infer<typeof TagSchema>) => () => {
    const tags = getValues().tags.slice();
    const index = tags?.findIndex((t) => t.id === tag.id);

    if (index <= -1) {
      setValue('tags', tags.concat({ id: tag.id }));
    } else {
      setValue(
        'tags',
        tags.filter((t) => t.id !== tag.id)
      );
    }
  };

  return [
    { isAllSelected, query, form, disclosure, selectedTags },
    { isSelected, makeOnToggleAll, makeOnClick },
  ] as const;
}
