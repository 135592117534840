import { TRPCClientErrorLike } from '@trpc/client';
import { UseTRPCMutationOptions } from '@trpc/react-query/shared';
import { useRouter } from 'next/router';

import { LoginInput, LoginResponse, RegisterInput } from '@theqube/schemas';
import type { Context, Router } from '@theqube/trpc';
import { REFRESH_TOKEN_KEY, trpc, useAnalytics } from '../../utils';
import { useAuthContext } from './provider';

export type UseSessionOptions = UseTRPCMutationOptions<
  LoginInput | RegisterInput | unknown,
  TRPCClientErrorLike<Router>,
  LoginResponse,
  Context
>;

/**
 * Hook to used to
 * - access the currently logged in user's data (0)
 * - expose methods to manipulate the session (1)
 */
export function useSession({
  onError: onSessionError,
  onSuccess: onSessionSuccess,
}: UseSessionOptions = {}) {
  const router = useRouter();
  const [state, dispatch] = useAuthContext();
  const { gtag } = useAnalytics();

  const session = trpc.auth.session.get.useQuery(undefined, {
    enabled: Boolean(state.token),
    onSuccess(data) {
      dispatch({ type: 'LOGIN', payload: { token: data.token } });
    },
    onError() {
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      dispatch({ type: 'LOGOUT' });
    },
  });

  const mutationOptions: UseSessionOptions = {
    onError(...args) {
      onSessionError?.(...args);
      dispatch({ type: 'LOGOUT' });
    },
    onSuccess(...args) {
      const [data] = args;
      onSessionSuccess?.(...args);
      dispatch({ type: 'LOGIN', payload: { token: data.token } });
    },
  };

  const { mutateAsync: register } = trpc.auth.register.useMutation({
    ...mutationOptions,
    onSuccess(...args) {
      if (gtag) {
        gtag('event', 'sign_up', { method: 'email' });
      }

      mutationOptions.onSuccess?.(...args);
    },
  });

  const { mutateAsync: login } = trpc.auth.login.useMutation({
    ...mutationOptions,
    onSuccess(...args) {
      if (gtag) {
        gtag('event', 'login', { method: 'email' });
      }

      mutationOptions.onSuccess?.(...args);
    },
  });

  const { mutateAsync: continueWithGoogle } =
    trpc.auth.continueWithGoogle.useMutation({
      ...mutationOptions,
      onSuccess(...args) {
        if (gtag) {
          gtag('event', 'login', { method: 'google' });
        }

        mutationOptions.onSuccess?.(...args);
      },
    });

  const { mutateAsync: logout } = trpc.auth.logout.useMutation({
    onError: mutationOptions.onError,
    onSuccess() {
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      dispatch({ type: 'LOGOUT' });
      router.reload();
    },
  });

  return [
    session,
    {
      register,
      login,
      logout,
      continueWithGoogle,
    },
  ] as const;
}
