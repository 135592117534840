export const publicUserSelect /**: Prisma.UserSelect /**/ = {
  id: true,
  createdAt: true,
  updatedAt: true,

  avatarUrl: true,
  tags: { select: { id: true, name: true, type: true } },
  displayName: true,
  biography: true,
  socialMedia: true,
} as const;

export const privateUserSelect /**: Prisma.UserSelect /**/ = {
  ...publicUserSelect,

  settings: true,
  permissions: true,

  email: true,
  emailVerified: true,

  fullName: true,
  passwordHash: true,
  birthday: true,
  gender: true,
} as const;
