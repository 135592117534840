import { z } from 'zod';
import { RssFeedWhereInputObjectSchema } from './objects/RssFeedWhereInput.schema';
import { RssFeedOrderByWithRelationInputObjectSchema } from './objects/RssFeedOrderByWithRelationInput.schema';
import { RssFeedWhereUniqueInputObjectSchema } from './objects/RssFeedWhereUniqueInput.schema';
import { RssFeedCountAggregateInputObjectSchema } from './objects/RssFeedCountAggregateInput.schema';
import { RssFeedMinAggregateInputObjectSchema } from './objects/RssFeedMinAggregateInput.schema';
import { RssFeedMaxAggregateInputObjectSchema } from './objects/RssFeedMaxAggregateInput.schema';
import { RssFeedAvgAggregateInputObjectSchema } from './objects/RssFeedAvgAggregateInput.schema';
import { RssFeedSumAggregateInputObjectSchema } from './objects/RssFeedSumAggregateInput.schema';

export const RssFeedAggregateSchema = z.object({
  where: RssFeedWhereInputObjectSchema.optional(),
  orderBy: z
    .union([
      RssFeedOrderByWithRelationInputObjectSchema,
      RssFeedOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  cursor: RssFeedWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), RssFeedCountAggregateInputObjectSchema])
    .optional(),
  _min: RssFeedMinAggregateInputObjectSchema.optional(),
  _max: RssFeedMaxAggregateInputObjectSchema.optional(),
  _avg: RssFeedAvgAggregateInputObjectSchema.optional(),
  _sum: RssFeedSumAggregateInputObjectSchema.optional(),
});
