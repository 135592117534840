import { StyleConfig } from '@chakra-ui/theme-tools';

export const Link: StyleConfig = {
  defaultProps: {
    colorScheme: 'themeBlue',
  },

  variants: {
    outline: {
      color: 'themeBlue.500',
      bg: 'transparent',
      border: '1px solid',
      borderColor: 'themeBlue.500',
      px: 6,
      py: 2,
      fontWeight: 'bold',
      rounded: 'full',
      fontSize: 'sm',
      lineHeight: 'sm',
      letterSpacing: 'wide',
      cursor: 'pointer',
      transition: 'all 0.2s',

      '&:hover': {
        bg: 'themeBlue.100',
      },

      '&:active': {
        bg: 'themeBlue.200',
      },
    },

    solid: {
      color: 'white',
      bg: 'themeBlue.500',
      px: 6,
      py: 2,
      fontWeight: 'bold',
      rounded: 'full',
      fontSize: 'sm',
      lineHeight: 'sm',
      letterSpacing: 'wide',

      '&:hover': {
        bg: 'themeBlue.100',
        color: 'themeBlue.800',
      },

      '&:active': {
        bg: 'themeBlue.200',
        color: 'themeBlue.900',
      },
    },

    premium: {
      '&:hover': {
        textDecoration: 'none',
      },
    },

    ctaButton: {
      bg: 'gold.500',
      color: 'black',
      fontWeight: 'bold',
      borderRadius: '5px',

      '&:hover': {
        textDecoration: 'none',
        bg: 'gold.700',
        color: 'gray.800',
      },
    },
  },
};
