import { z } from 'zod';
import { RssFeedWhereUniqueInputObjectSchema } from './objects/RssFeedWhereUniqueInput.schema';
import { RssFeedCreateInputObjectSchema } from './objects/RssFeedCreateInput.schema';
import { RssFeedUpdateInputObjectSchema } from './objects/RssFeedUpdateInput.schema';

export const RssFeedUpsertSchema = z.object({
  where: RssFeedWhereUniqueInputObjectSchema,
  create: RssFeedCreateInputObjectSchema,
  update: RssFeedUpdateInputObjectSchema,
});
