import { z } from 'zod';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserCreateWithoutPodcastsAssignedInputObjectSchema } from './UserCreateWithoutPodcastsAssignedInput.schema';
import { UserUncheckedCreateWithoutPodcastsAssignedInputObjectSchema } from './UserUncheckedCreateWithoutPodcastsAssignedInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserCreateOrConnectWithoutPodcastsAssignedInput> =
  z
    .object({
      where: z.lazy(() => UserWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => UserCreateWithoutPodcastsAssignedInputObjectSchema),
        z.lazy(
          () => UserUncheckedCreateWithoutPodcastsAssignedInputObjectSchema
        ),
      ]),
    })
    .strict();

export const UserCreateOrConnectWithoutPodcastsAssignedInputObjectSchema =
  Schema;
