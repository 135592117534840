import { z } from 'zod';

export const MAX_AUDIO_SIZE = 10000000; // 10MB
export const MAX_IMAGE_SIZE = 5000000; // 5MB

export const AudioSchema = z
  .any()
  .refine((value) => {
    let name = '';

    // client-side vs server-side
    if ('name' in value) name = value.name;
    else if ('filename' in value) name = value.filename;

    return name.length > 0;
  }, 'File must have a valid name')
  .refine((value) => {
    let type = '';

    if ('type' in value) type = value.type;
    else if ('mimetype' in value) type = value.mimetype;

    return type.startsWith('audio/');
  }, 'File must be a valid audio file with a valid type')
  .refine(
    (value) => 'size' in value && value.size < MAX_AUDIO_SIZE,
    `File must be less than ${MAX_AUDIO_SIZE * 0.001}MB`
  );

export const ImageSchema = z
  .any()
  .refine((value) => {
    let name = '';

    // client-side vs server-side
    if ('name' in value) name = value.name;
    else if ('filename' in value) name = value.filename;

    return name.length > 0;
  }, 'File must have a valid name')
  .refine((value) => {
    let type = '';

    if ('type' in value) type = value.type;
    else if ('mimetype' in value) type = value.mimetype;

    return type.startsWith('image/');
  }, 'File must be a valid image file with a valid type')
  .refine(
    (value) => 'size' in value && value.size < MAX_IMAGE_SIZE,
    `File must be less than ${MAX_IMAGE_SIZE * 0.001}MB`
  );
