import { z } from 'zod';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';
import { PodcastCreateWithoutEpisodesInputObjectSchema } from './PodcastCreateWithoutEpisodesInput.schema';
import { PodcastUncheckedCreateWithoutEpisodesInputObjectSchema } from './PodcastUncheckedCreateWithoutEpisodesInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastCreateOrConnectWithoutEpisodesInput> = z
  .object({
    where: z.lazy(() => PodcastWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => PodcastCreateWithoutEpisodesInputObjectSchema),
      z.lazy(() => PodcastUncheckedCreateWithoutEpisodesInputObjectSchema),
    ]),
  })
  .strict();

export const PodcastCreateOrConnectWithoutEpisodesInputObjectSchema = Schema;
