import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  InputGroup,
  InputGroupProps,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
} from '@chakra-ui/react';
import * as React from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  Path,
  UnPackAsyncDefaultValues,
  useController,
  UseControllerProps,
  useFormContext,
  UseFormStateReturn,
} from 'react-hook-form';

type FormFieldLayout = 'stacked' | 'inline';

interface FieldRenderFn<
  FormData extends FieldValues,
  Name extends FieldPath<FormData>
> {
  ({
    field,
    fieldState,
    formState,
  }: {
    field: ControllerRenderProps<FormData, Name>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<FormData>;
  }): React.ReactElement;
}

/** props used to render an input inside of a form field */
export type FieldRenderContext<
  FormData extends FieldValues = FieldValues,
  Name extends FieldPath<FormData> = FieldPath<FormData>
> = Parameters<FieldRenderFn<FormData, Name>>[0];

export interface FormFieldProps<
  FormData extends FieldValues = FieldValues,
  Name extends FieldPath<FormData> = Path<UnPackAsyncDefaultValues<FormData>>
> extends UseControllerProps<FormData, Name> {
  /** The name of the field.*/
  name: Name;

  /**
   * Render the actual input for the field. ie. {@link TextInput}
   *
   * @example
   * ```tsx
   * <FormField name="email" render={({ field, fieldState, formState }) => (
   *  <TextInput {...field} placeholder="Email" />
   * )} />
   */
  render: FieldRenderFn<FormData, Name>;

  label?: string | React.ReactNode; // label for the field
  helperText?: string; // helper text for the field

  leftElement?: null | React.ReactNode; // element to render on the left side of the field
  leftAddon?: null | React.ReactNode;

  rightElement?: null | React.ReactNode; // element to render on the right side of the field
  rightAddon?: null | React.ReactNode;

  containerStyle?: FormControlProps; // props to pass to the container
  inputContainerStyle?: InputGroupProps; // props to pass to the input container
  labelStyle?: FormLabelProps; // props to pass to the label

  layout?: FormFieldLayout; // layout of the field
}

/**
 * FormField
 *
 * A styled container to wrap inputs and other form elements. and connect to the form context.
 *
 * - Capable of adding left and right "icons" to the input and adding a label.
 * - Adds a border to the input if the field is invalid or dirty.
 * - Adds error text if the field is invalid and has a valid error
 *
 *
 * @example Basic text input
 *
 * ```tsx
 * impot { Form, FormField, useZodForm } from './forms/helpers';
 *
 * function ExampleForm() {
 *  const context = useZodForm({ ... other form props ... });
 *
 *  return (
 *   <Form context={context}>
 *    <FormField
 *      name="email"
 *      label="Please enter your email"
 *      render={(fieldContext) => (
 *         <TextInput {...fieldContext.field} />
 *      )}
 *    />
 *   </Form>
 *  );
 * }
 * ```
 */
export function FormField<
  FormData extends FieldValues = FieldValues,
  Name extends FieldPath<FormData> = Path<UnPackAsyncDefaultValues<FormData>>
>(props: FormFieldProps<FormData, Name>) {
  const {
    render,
    name,
    label,
    helperText,

    layout = 'stacked',
    leftElement = null,
    leftAddon = null,
    rightElement = null,
    rightAddon = null,

    containerStyle = {},
    inputContainerStyle = {},
    labelStyle = {},
    ...controllerProps
  } = props;

  const context = useFormContext<FormData>();

  const { field, fieldState, formState } = useController<FormData, Name>({
    name,
    control: context?.control,
    ...controllerProps,
  });

  const elementProps: Omit<ThemedFormFieldElementProps, 'children'> = {
    layout,
    ...fieldState,
  };

  return (
    <ThemedFormField.ControlContainer
      isInvalid={Boolean(fieldState.error) ?? undefined}
      {...elementProps}
      {...containerStyle}
    >
      {typeof label === 'string' ? (
        <ThemedFormField.Label style={labelStyle} {...elementProps}>
          {label}
        </ThemedFormField.Label>
      ) : React.isValidElement(label) ? (
        label
      ) : null}

      <ThemedFormField.InputContainer
        style={inputContainerStyle}
        hasLeftAddon={Boolean(leftAddon)}
        hasRightAddon={Boolean(rightAddon)}
        {...elementProps}
      >
        {leftElement ? (
          <ThemedFormField.LeftElement {...elementProps}>
            {leftElement}
          </ThemedFormField.LeftElement>
        ) : null}

        {leftAddon ? (
          <ThemedFormField.LeftAddon {...elementProps}>
            {leftAddon}
          </ThemedFormField.LeftAddon>
        ) : null}

        <ThemedFormField.Input {...elementProps}>
          {render({ field, fieldState, formState })}
        </ThemedFormField.Input>

        {rightAddon ? (
          <ThemedFormField.RightAddon {...elementProps}>
            {rightAddon}
          </ThemedFormField.RightAddon>
        ) : null}

        {rightElement ? (
          <ThemedFormField.RightElement {...elementProps}>
            {rightElement}
          </ThemedFormField.RightElement>
        ) : null}
      </ThemedFormField.InputContainer>

      {helperText ? (
        <ThemedFormField.HelperText {...elementProps}>
          {helperText}
        </ThemedFormField.HelperText>
      ) : null}

      {fieldState.error ? (
        <ThemedFormField.ErrorMessage
          error={fieldState.error}
          {...elementProps}
        />
      ) : null}
    </ThemedFormField.ControlContainer>
  );
}

type ThemedFormFieldElementProps = ControllerFieldState & {
  layout?: FormFieldLayout;
  children: React.ReactNode;
};

export const ThemedFormField = {
  ControlContainer: function ControlContainer({
    children,
    isDirty,
    invalid,
    error,
    isInvalid,
    isTouched,
    layout,
    ...props
  }: ThemedFormFieldElementProps & FormControlProps) {
    const noop = (...args: unknown[]) => args;

    noop(invalid, error, isTouched);

    return (
      <FormControl
        flex={1}
        marginY="4"
        marginX="auto"
        width="100%"
        position="relative"
        display="flex"
        flexDirection={layout === 'inline' ? 'row' : 'column'}
        isInvalid={isInvalid || undefined}
        {...props}
      >
        {children}
      </FormControl>
    );
  },

  InputContainer: function InputContainer(
    props: ThemedFormFieldElementProps & {
      style: InputGroupProps;
      hasLeftAddon?: boolean;
      hasRightAddon?: boolean;
    }
  ) {
    return (
      <InputGroup
        position="relative"
        flex={1}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        borderLeftRadius={props.hasLeftAddon ? '0' : 'md'}
        borderRightRadius={props.hasRightAddon ? '0' : 'md'}
        borderColor={props.error ? 'red.500' : '#gray.600'}
        {...props.style}
      >
        {props.children}
      </InputGroup>
    );
  },

  LeftElement: function LeftElement(props: ThemedFormFieldElementProps) {
    return <InputLeftElement>{props.children}</InputLeftElement>;
  },

  LeftAddon: function LeftAddon(props: ThemedFormFieldElementProps) {
    return <InputLeftAddon>{props.children}</InputLeftAddon>;
  },

  RightElement: function RightElement(props: ThemedFormFieldElementProps) {
    return <InputRightElement>{props.children}</InputRightElement>;
  },

  RightAddon: function RightAddon(props: ThemedFormFieldElementProps) {
    return <InputRightAddon>{props.children}</InputRightAddon>;
  },

  Input: function Input(props: ThemedFormFieldElementProps) {
    return <Box flex={1}>{props.children}</Box>;
  },

  HelperText: function HelperText(props: ThemedFormFieldElementProps) {
    return <FormHelperText textAlign="right">{props.children}</FormHelperText>;
  },

  ErrorMessage: function CustomErrorMessage(props: ControllerFieldState) {
    return <FormErrorMessage>{props.error?.message}</FormErrorMessage>;
  },

  Label: function Label(
    props: ThemedFormFieldElementProps & { style?: FormLabelProps }
  ) {
    return <FormLabel {...props.style}>{props.children}</FormLabel>;
  },
};
