import { z } from 'zod';
import { PodcastScalarWhereInputObjectSchema } from './PodcastScalarWhereInput.schema';
import { PodcastUpdateManyMutationInputObjectSchema } from './PodcastUpdateManyMutationInput.schema';
import { PodcastUncheckedUpdateManyWithoutPodcastsInputObjectSchema } from './PodcastUncheckedUpdateManyWithoutPodcastsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastUpdateManyWithWhereWithoutOwnersInput> = z
  .object({
    where: z.lazy(() => PodcastScalarWhereInputObjectSchema),
    data: z.union([
      z.lazy(() => PodcastUpdateManyMutationInputObjectSchema),
      z.lazy(() => PodcastUncheckedUpdateManyWithoutPodcastsInputObjectSchema),
    ]),
  })
  .strict();

export const PodcastUpdateManyWithWhereWithoutOwnersInputObjectSchema = Schema;
