import { z } from 'zod';
import { CurationWhereUniqueInputObjectSchema } from './CurationWhereUniqueInput.schema';
import { CurationUpdateWithoutCuratorInputObjectSchema } from './CurationUpdateWithoutCuratorInput.schema';
import { CurationUncheckedUpdateWithoutCuratorInputObjectSchema } from './CurationUncheckedUpdateWithoutCuratorInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.CurationUpdateWithWhereUniqueWithoutCuratorInput> =
  z
    .object({
      where: z.lazy(() => CurationWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => CurationUpdateWithoutCuratorInputObjectSchema),
        z.lazy(() => CurationUncheckedUpdateWithoutCuratorInputObjectSchema),
      ]),
    })
    .strict();

export const CurationUpdateWithWhereUniqueWithoutCuratorInputObjectSchema =
  Schema;
