import { z } from 'zod';
import { UserUncheckedCreateNestedManyWithoutPlaylistsInputObjectSchema } from './UserUncheckedCreateNestedManyWithoutPlaylistsInput.schema';
import { UserUncheckedCreateNestedManyWithoutPlaylistSubscriptionsInputObjectSchema } from './UserUncheckedCreateNestedManyWithoutPlaylistSubscriptionsInput.schema';
import { EpisodeUncheckedCreateNestedManyWithoutPlaylistsInputObjectSchema } from './EpisodeUncheckedCreateNestedManyWithoutPlaylistsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistUncheckedCreateInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    avatarUrl: z.string().optional().nullable(),
    title: z.string(),
    description: z.string(),
    owners: z
      .lazy(
        () => UserUncheckedCreateNestedManyWithoutPlaylistsInputObjectSchema
      )
      .optional(),
    subscribers: z
      .lazy(
        () =>
          UserUncheckedCreateNestedManyWithoutPlaylistSubscriptionsInputObjectSchema
      )
      .optional(),
    episodes: z
      .lazy(
        () => EpisodeUncheckedCreateNestedManyWithoutPlaylistsInputObjectSchema
      )
      .optional(),
  })
  .strict();

export const PlaylistUncheckedCreateInputObjectSchema = Schema;
