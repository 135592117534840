import { z } from 'zod';

import { EmailSchema } from './auth';
import { AudioSchema } from './files';
import { Username } from './username';

export const MAX_MESSAGE_LENGTH = 150;

export type CreateContactBody = z.infer<typeof CreateContactBody>;
export const CreateContactBody = z.object({
  name: z
    .string({
      required_error: 'Name is required.',
      invalid_type_error: 'Name must be a string.',
    })
    .min(2, { message: 'Name must be 2 or more characters long.' }),
  email: EmailSchema,
  message: z
    .string({
      required_error:
        'Please enter a message so we can help serve your request better.',
    })
    .min(10, { message: 'Message must be 10 or more characters' })
    .max(600, { message: 'Message must be 600 characters or less.' }),
});

export type CreateContactResponse = z.infer<typeof CreateContactResponse>;
export const CreateContactResponse = z.object({
  message: z.string(),
});

export const MusicSubmissionBody = z.object({
  name: z
    .string({
      required_error: 'Please enter your name.',
      invalid_type_error: 'Invalid entry. Please use alphanumeric characters.',
    })
    .min(2, { message: 'Must be 2 or more characters long.' }),

  description: z
    .string({
      required_error:
        'Please write a description about the music you want to submit.',
    })
    .min(10, { message: 'Message must be 10 or more characters' })
    .max(MAX_MESSAGE_LENGTH, {
      message: `Message must be ${MAX_MESSAGE_LENGTH} characters or less.`,
    }),

  websiteUrl: z
    .string({
      required_error: 'Please entere a valid url.',
    })
    .url({
      message: 'URL is invalid. Please use https://wwww.yourwebsite.com',
    })
    .min(7, 'URL must be 7 or more characters'),

  socialMedia: z.object({
    facebook: Username,
    instagram: Username,
    twitter: Username,
    tik_tok: Username,
  }),

  attachments: z.array(AudioSchema).length(1, 'Only one file is allowed.'),
});

export type MusicSubmissionBody = z.infer<typeof MusicSubmissionBody>;

//Form schema using zod
export const AdvertisersSubmissionBody = z.object({
  name: z
    .string({
      required_error: 'Name is required.',
      invalid_type_error: 'Name must be a string.',
    })
    .min(2, { message: 'Name must be 2 or more characters long.' }),
  email: z
    .string({
      required_error: 'Email is required.',
    })
    .email({ message: 'Invalid email address.' }),
  phone: z
    .string({
      required_error: 'Please enter a valid phone number.',
    })
    .min(10, {
      message: 'Phone number must be a minimum of 10 digits (area code/number)',
    })
    .max(13, { message: 'Phone number can only be a maximum of 13 numbers.' }),
  website: z.string().url({
    message:
      "URL is invalid. Please use 'https://' format and/or check web address.",
  }),
  message: z
    .string({
      required_error:
        'Please enter a message so we can help serve your request better.',
    })
    .min(10, { message: 'Message must be 10 or more characters' })
    .max(MAX_MESSAGE_LENGTH, {
      message: `Message must be ${MAX_MESSAGE_LENGTH} characters or less.`,
    }),
});
export type AdvertisersSubmissionBody = z.infer<
  typeof AdvertisersSubmissionBody
>;
