import * as z from "zod"
import { TagType } from "@prisma/client"
import { CompleteUser, RelatedUserSchema, CompletePodcast, RelatedPodcastSchema } from "./index"

export const TagSchema = z.object({
  id: z.number().int(),
  slug: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  publishedAt: z.date().nullish(),
  avatarUrl: z.string().nullish(),
  type: z.nativeEnum(TagType),
  name: z.string(),
})

export interface CompleteTag extends z.infer<typeof TagSchema> {
  users: CompleteUser[]
  podcasts: CompletePodcast[]
}

/**
 * RelatedTagSchema contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedTagSchema: z.ZodSchema<CompleteTag> = z.lazy(() => TagSchema.extend({
  users: RelatedUserSchema.array(),
  podcasts: RelatedPodcastSchema.array(),
}))
