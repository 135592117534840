import { z } from 'zod';
import { PodcastCreateWithoutCurationsInputObjectSchema } from './PodcastCreateWithoutCurationsInput.schema';
import { PodcastUncheckedCreateWithoutCurationsInputObjectSchema } from './PodcastUncheckedCreateWithoutCurationsInput.schema';
import { PodcastCreateOrConnectWithoutCurationsInputObjectSchema } from './PodcastCreateOrConnectWithoutCurationsInput.schema';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastCreateNestedOneWithoutCurationsInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => PodcastCreateWithoutCurationsInputObjectSchema),
            z.lazy(
              () => PodcastUncheckedCreateWithoutCurationsInputObjectSchema
            ),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .lazy(() => PodcastCreateOrConnectWithoutCurationsInputObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z.lazy(() => PodcastWhereUniqueInputObjectSchema).optional(),
      })
      .strict(),
  ]);

export const PodcastCreateNestedOneWithoutCurationsInputObjectSchema = Schema;
