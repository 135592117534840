import { z } from 'zod';
import { RssFeedCreateNestedOneWithoutPodcastInputObjectSchema } from './RssFeedCreateNestedOneWithoutPodcastInput.schema';
import { TagCreateNestedManyWithoutPodcastsInputObjectSchema } from './TagCreateNestedManyWithoutPodcastsInput.schema';
import { UserCreateNestedManyWithoutPodcastsInputObjectSchema } from './UserCreateNestedManyWithoutPodcastsInput.schema';
import { EpisodeCreateNestedManyWithoutPodcastInputObjectSchema } from './EpisodeCreateNestedManyWithoutPodcastInput.schema';
import { UserCreateNestedManyWithoutPodcastsAssignedInputObjectSchema } from './UserCreateNestedManyWithoutPodcastsAssignedInput.schema';
import { CurationCreateNestedManyWithoutPodcastInputObjectSchema } from './CurationCreateNestedManyWithoutPodcastInput.schema';
import { PodcastStatusSchema } from '../enums/PodcastStatus.schema';
import { NullableJsonNullValueInputSchema } from '../enums/NullableJsonNullValueInput.schema';

import type { Prisma } from '@prisma/client';

const literalSchema = z.union([z.string(), z.number(), z.boolean()]);
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ])
);

const Schema: z.ZodType<Prisma.PodcastCreateWithoutSubscribersInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    publishedAt: z.date().optional().nullable(),
    importedAt: z.date().optional().nullable(),
    rssFeed: z
      .lazy(() => RssFeedCreateNestedOneWithoutPodcastInputObjectSchema)
      .optional(),
    tags: z
      .lazy(() => TagCreateNestedManyWithoutPodcastsInputObjectSchema)
      .optional(),
    owners: z
      .lazy(() => UserCreateNestedManyWithoutPodcastsInputObjectSchema)
      .optional(),
    episodes: z
      .lazy(() => EpisodeCreateNestedManyWithoutPodcastInputObjectSchema)
      .optional(),
    curators: z
      .lazy(() => UserCreateNestedManyWithoutPodcastsAssignedInputObjectSchema)
      .optional(),
    curations: z
      .lazy(() => CurationCreateNestedManyWithoutPodcastInputObjectSchema)
      .optional(),
    status: z.lazy(() => PodcastStatusSchema),
    statusReason: z.string().optional().nullable(),
    avatarUrl: z.string().optional().nullable(),
    isOriginal: z.boolean().optional(),
    isExplicit: z.boolean(),
    title: z.string(),
    description: z.string().optional().nullable(),
    websiteUrl: z.string().optional().nullable(),
    socialMedia: z
      .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
      .optional(),
  })
  .strict();

export const PodcastCreateWithoutSubscribersInputObjectSchema = Schema;
