import { z } from 'zod';

export const GenderSchema = z.enum([
  'Agender',
  'Male',
  'Transgender Male',
  'Female',
  'Transgender Female',
  'Non-binary (Genderqueer, Gender Fluid, Gender Expansive)',
  'Prefer not to say',
  'Not listed',
]);

export type Gender = z.infer<typeof GenderSchema>;

export const Gender = GenderSchema.enum;
