import { z } from 'zod';
import { PermissionsWhereUniqueInputObjectSchema } from './PermissionsWhereUniqueInput.schema';
import { PermissionsCreateWithoutOwnerInputObjectSchema } from './PermissionsCreateWithoutOwnerInput.schema';
import { PermissionsUncheckedCreateWithoutOwnerInputObjectSchema } from './PermissionsUncheckedCreateWithoutOwnerInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PermissionsCreateOrConnectWithoutOwnerInput> = z
  .object({
    where: z.lazy(() => PermissionsWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => PermissionsCreateWithoutOwnerInputObjectSchema),
      z.lazy(() => PermissionsUncheckedCreateWithoutOwnerInputObjectSchema),
    ]),
  })
  .strict();

export const PermissionsCreateOrConnectWithoutOwnerInputObjectSchema = Schema;
