import { z } from 'zod';
import { PlaylistWhereUniqueInputObjectSchema } from './objects/PlaylistWhereUniqueInput.schema';
import { PlaylistCreateInputObjectSchema } from './objects/PlaylistCreateInput.schema';
import { PlaylistUpdateInputObjectSchema } from './objects/PlaylistUpdateInput.schema';

export const PlaylistUpsertSchema = z.object({
  where: PlaylistWhereUniqueInputObjectSchema,
  create: PlaylistCreateInputObjectSchema,
  update: PlaylistUpdateInputObjectSchema,
});
