import { z } from 'zod';
import { EpisodeWhereUniqueInputObjectSchema } from './EpisodeWhereUniqueInput.schema';
import { EpisodeCreateWithoutPlaylistsInputObjectSchema } from './EpisodeCreateWithoutPlaylistsInput.schema';
import { EpisodeUncheckedCreateWithoutPlaylistsInputObjectSchema } from './EpisodeUncheckedCreateWithoutPlaylistsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.EpisodeCreateOrConnectWithoutPlaylistsInput> = z
  .object({
    where: z.lazy(() => EpisodeWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => EpisodeCreateWithoutPlaylistsInputObjectSchema),
      z.lazy(() => EpisodeUncheckedCreateWithoutPlaylistsInputObjectSchema),
    ]),
  })
  .strict();

export const EpisodeCreateOrConnectWithoutPlaylistsInputObjectSchema = Schema;
