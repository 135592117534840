import { Heading } from '@chakra-ui/react';
import { HTMLRenderer, PageHeading, PageHero } from '../../components';
import { trpc } from '../../utils';
import { Detail, Details, PodcastWindow } from '../podcasts';
import { TagList } from '../tags';

type UserHeaderProps = { userId: string };

export function UserHeader({ userId }: UserHeaderProps) {
  const { data: user, isSuccess } = trpc.users.findOne.useQuery(
    { where: { id: userId }, orderBy: { createdAt: 'desc' } },
    { enabled: Boolean(userId) }
  );

  return (
    <PageHero
      isLoaded={isSuccess}
      heading={{
        src: user?.avatarUrl ?? '/images/logo--full.png',
      }}
      thumbnail={{
        src: user?.avatarUrl ?? '/images/logo.png',
      }}
    >
      <PageHeading>{user?.displayName}</PageHeading>

      <TagList query={{ where: { users: { some: { id: userId } } } }} />

      <HTMLRenderer html={user?.biography} marginY="4" />

      <Details>
        {Object.entries(user?.socialMedia ?? {}).map(([platform, username]) => (
          <Detail key={platform}>{username}</Detail>
        ))}
      </Details>

      <PodcastWindow
        header={<Heading>Podcasts</Heading>}
        query={{
          where: { owners: { some: { id: userId } } },
        }}
      />
    </PageHero>
  );
}
