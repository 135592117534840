import { z } from 'zod';
import { RssFeedWhereUniqueInputObjectSchema } from './RssFeedWhereUniqueInput.schema';
import { RssFeedCreateWithoutPodcastInputObjectSchema } from './RssFeedCreateWithoutPodcastInput.schema';
import { RssFeedUncheckedCreateWithoutPodcastInputObjectSchema } from './RssFeedUncheckedCreateWithoutPodcastInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.RssFeedCreateOrConnectWithoutPodcastInput> = z
  .object({
    where: z.lazy(() => RssFeedWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => RssFeedCreateWithoutPodcastInputObjectSchema),
      z.lazy(() => RssFeedUncheckedCreateWithoutPodcastInputObjectSchema),
    ]),
  })
  .strict();

export const RssFeedCreateOrConnectWithoutPodcastInputObjectSchema = Schema;
