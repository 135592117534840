import { z } from 'zod';
import { CurationWhereInputObjectSchema } from './objects/CurationWhereInput.schema';
import { CurationOrderByWithAggregationInputObjectSchema } from './objects/CurationOrderByWithAggregationInput.schema';
import { CurationScalarWhereWithAggregatesInputObjectSchema } from './objects/CurationScalarWhereWithAggregatesInput.schema';
import { CurationScalarFieldEnumSchema } from './enums/CurationScalarFieldEnum.schema';

export const CurationGroupBySchema = z.object({
  where: CurationWhereInputObjectSchema.optional(),
  orderBy: z
    .union([
      CurationOrderByWithAggregationInputObjectSchema,
      CurationOrderByWithAggregationInputObjectSchema.array(),
    ])
    .optional(),
  having: CurationScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(CurationScalarFieldEnumSchema),
});
