import * as React from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Heading,
  HStack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { ZodError } from 'zod';

import type { Router } from '@theqube/trpc';
import { TRPCClientErrorLike } from '@trpc/client';
import { Code } from './code';

export type ErrorWrapperProps = {
  title?: string;
  error?:
    | TRPCClientErrorLike<Router>
    | Error
    | ZodError
    | { message: React.ReactNode }
    | null;
  reset?: (...args: unknown[]) => unknown;
  retry?: () => void;
};

/**
 * Placeholder compenent to display when an error occurs.
 *
 * @remarks
 * Displays a message and a title
 * Also, allows a user to refresh or clear an error
 */
export function ErrorWrapper(props: ErrorWrapperProps) {
  const { title = 'Uh Oh!', error, reset, retry } = props;
  const router = useRouter();

  if (process.env['NEXT_PUBLIC_APP_ENV'] !== 'production') {
    console.error(error);
  }

  const getMessage = () => {
    if (!error) return undefined;

    return error.message;
  };

  const { onClose } = useDisclosure();
  const refreshRef = React.useRef<HTMLButtonElement | null>(null);

  return error ? (
    <AlertDialog
      isOpen={true}
      onClose={onClose}
      leastDestructiveRef={refreshRef}
      colorScheme="error"
    >
      <AlertDialogOverlay bg="blackAlpha.700">
        <AlertDialogContent bg="black">
          <AlertDialogHeader>
            <Heading as="h2">{title}</Heading>

            <Text
              margin="2 auto"
              wordBreak="break-word"
              overflowX="auto"
              overflowWrap="break-word"
            >
              {getMessage()}
            </Text>
          </AlertDialogHeader>

          <AlertDialogFooter
            as={HStack}
            flexWrap="wrap"
            justifyContent="end"
            spacing="1"
          >
            <Button
              onClick={() => router.reload()}
              ref={refreshRef}
              colorScheme="gray"
              variant="ghost"
            >
              Refresh
            </Button>

            {reset ? (
              <Button onClick={reset} colorScheme="yellow" variant="ghost">
                Reset
              </Button>
            ) : null}

            {retry ? (
              <Button onClick={retry} colorScheme="red" variant="ghost">
                Retry
              </Button>
            ) : null}
          </AlertDialogFooter>

          <Accordion allowToggle margin="4 auto" borderColor="error">
            <AccordionItem>
              <AccordionButton justifyContent="flex-end">
                <Text>Show Details</Text>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel>
                <Code data={error} />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  ) : null;
}
