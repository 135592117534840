import * as React from 'react';

import { Box, Flex, HStack, Image, Text } from '@chakra-ui/react';

interface OriginalShowPlayerProps {
  title: string;
  category: string;
  description: string[];
}

export function OriginalShowPlayer(props: OriginalShowPlayerProps) {
  const { title, category, description } = props;

  return (
    <Flex flexFlow="row wrap" justify="center">
      <HStack w="60%" mx="auto" my={20} spacing={10} border="1px">
        <Box w="50%">
          <Image src="/images/logo.png" alt="The Qube App Logo" />
        </Box>

        <Box mx="auto" px={2}>
          <Text color="gold.500" fontWeight="black" fontSize="2xl" my={2}>
            {title}
          </Text>

          <Text fontSize="md">{category}</Text>

          <Box my={30}>
            {description.map((txt, id) => (
              <Text key={id} mb={5}>
                <Text
                  as="span"
                  color="#D62979"
                  //   color={`(${id} === 0) ? #D62979 : white.500`}
                  fontWeight="semibold"
                  fontSize="lg"
                >
                  {title}
                </Text>

                {txt}
              </Text>
            ))}
          </Box>
        </Box>
      </HStack>
    </Flex>
  );
}
