import { z } from 'zod';
import { RssFeedUpdateWithoutPodcastInputObjectSchema } from './RssFeedUpdateWithoutPodcastInput.schema';
import { RssFeedUncheckedUpdateWithoutPodcastInputObjectSchema } from './RssFeedUncheckedUpdateWithoutPodcastInput.schema';
import { RssFeedCreateWithoutPodcastInputObjectSchema } from './RssFeedCreateWithoutPodcastInput.schema';
import { RssFeedUncheckedCreateWithoutPodcastInputObjectSchema } from './RssFeedUncheckedCreateWithoutPodcastInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.RssFeedUpsertWithoutPodcastInput> = z
  .object({
    update: z.union([
      z.lazy(() => RssFeedUpdateWithoutPodcastInputObjectSchema),
      z.lazy(() => RssFeedUncheckedUpdateWithoutPodcastInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => RssFeedCreateWithoutPodcastInputObjectSchema),
      z.lazy(() => RssFeedUncheckedCreateWithoutPodcastInputObjectSchema),
    ]),
  })
  .strict();

export const RssFeedUpsertWithoutPodcastInputObjectSchema = Schema;
