/* eslint-disable @typescript-eslint/no-explicit-any */
import { z } from 'zod';

import { BuildEnv, Environment, PublicEnv, VercelEnv } from '@theqube/schemas';

class VercelEnvironment extends Environment<BuildEnv> {
  override get isProduction(): boolean {
    return this.get('NEXT_PUBLIC_APP_ENV') === 'production';
  }

  override get<Schema extends 'number' | 'boolean' | 'string' = 'string'>(
    name: BuildEnv,
    schema?: Schema
  ): Schema extends 'number'
    ? number
    : Schema extends 'boolean'
    ? boolean
    : string {
    return this.parse(name, {
      initialValue: this.process[name],
      schema,
    });
  }

  /**
   * Static map of next secrets to be "baked" at build time.
   * Next.JS has a _special_ method at buildtime for these so we should catch
   * them all here to make this work.
   *
   * ! This shoule be kept in sync with anything in .env or next.config.js
   */
  get process(): Record<BuildEnv, string> {
    const global: Record<PublicEnv, string | undefined> = {
      NEXT_PUBLIC_APP_ENV: process.env['NEXT_PUBLIC_APP_ENV'],
      NEXT_PUBLIC_APP_NAME: process.env['NEXT_PUBLIC_APP_NAME'],
      NEXT_PUBLIC_MOBILE_URI: process.env['NEXT_PUBLIC_MOBILE_URI'],
      NEXT_PUBLIC_SENTRY_DSN: process.env['NEXT_PUBLIC_SENTRY_DSN'],
      NEXT_PUBLIC_API_URL: process.env['NEXT_PUBLIC_API_URL'],
      NEXT_PUBLIC_GCP_CLIENT_ID: process.env['NEXT_PUBLIC_GCP_CLIENT_ID'],
      NEXT_PUBLIC_GCP_MEASUREMENT_ID:
        process.env['NEXT_PUBLIC_GCP_MEASUREMENT_ID'],
    };

    const next: Record<VercelEnv, string> = {
      NEXT_PUBLIC_VERCEL_ENV: process.env['NEXT_PUBLIC_VERCEL_ENV'] ?? 'n/a',
      NEXT_PUBLIC_VERCEL_URL: process.env['NEXT_PUBLIC_VERCEL_URL'] ?? 'n/a',
      NEXT_PUBLIC_VERCEL_GIT_PROVIDER:
        process.env['NEXT_PUBLIC_VERCEL_GIT_PROVIDER'] ?? 'n/a',
      NEXT_PUBLIC_VERCEL_GIT_REPO_SLUG:
        process.env['NEXT_PUBLIC_VERCEL_GIT_REPO_SLUG'] ?? 'n/a',
      NEXT_PUBLIC_VERCEL_GIT_REPO_OWNER:
        process.env['NEXT_PUBLIC_VERCEL_GIT_REPO_OWNER'] ?? 'n/a',
      NEXT_PUBLIC_VERCEL_GIT_REPO_ID:
        process.env['NEXT_PUBLIC_VERCEL_GIT_REPO_ID'] ?? 'n/a',
      NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF:
        process.env['NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF'] ?? 'n/a',
      NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA:
        process.env['NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA'] ?? 'n/a',
      NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE:
        process.env['NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE'] ?? 'n/a',
      NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_LOGIN:
        process.env['NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_LOGIN'] ?? 'n/a',
      NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_NAME:
        process.env['NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_NAME'] ?? 'n/a',
    };

    return z.record(z.string().min(1)).parse({
      ...global,
      ...next,
    });
  }
}

export const environment = new VercelEnvironment();
