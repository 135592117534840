import * as z from "zod"
import { CompletePodcast, RelatedPodcastSchema, CompletePlaylist, RelatedPlaylistSchema } from "./index"

export const EpisodeSchema = z.object({
  id: z.number().int(),
  updatedAt: z.date(),
  publishedAt: z.date(),
  podcastId: z.number().int(),
  title: z.string(),
  description: z.string().nullish(),
  imageUrl: z.string().nullish(),
  audioUrl: z.string(),
  duration: z.number().int(),
  explicit: z.boolean(),
})

export interface CompleteEpisode extends z.infer<typeof EpisodeSchema> {
  podcast: CompletePodcast
  playlists: CompletePlaylist[]
}

/**
 * RelatedEpisodeSchema contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedEpisodeSchema: z.ZodSchema<CompleteEpisode> = z.lazy(() => EpisodeSchema.extend({
  podcast: RelatedPodcastSchema,
  playlists: RelatedPlaylistSchema.array(),
}))
