import * as z from "zod"
import { CompletePodcast, RelatedPodcastSchema } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const RssFeedSchema = z.object({
  updatedAt: z.date(),
  podcastId: z.number().int(),
  url: z.string(),
  data: jsonSchema,
})

export interface CompleteRssFeed extends z.infer<typeof RssFeedSchema> {
  podcast: CompletePodcast
}

/**
 * RelatedRssFeedSchema contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedRssFeedSchema: z.ZodSchema<CompleteRssFeed> = z.lazy(() => RssFeedSchema.extend({
  podcast: RelatedPodcastSchema,
}))
