import { z } from 'zod';
import { TagCreateWithoutPodcastsInputObjectSchema } from './TagCreateWithoutPodcastsInput.schema';
import { TagUncheckedCreateWithoutPodcastsInputObjectSchema } from './TagUncheckedCreateWithoutPodcastsInput.schema';
import { TagCreateOrConnectWithoutPodcastsInputObjectSchema } from './TagCreateOrConnectWithoutPodcastsInput.schema';
import { TagUpsertWithWhereUniqueWithoutPodcastsInputObjectSchema } from './TagUpsertWithWhereUniqueWithoutPodcastsInput.schema';
import { TagWhereUniqueInputObjectSchema } from './TagWhereUniqueInput.schema';
import { TagUpdateWithWhereUniqueWithoutPodcastsInputObjectSchema } from './TagUpdateWithWhereUniqueWithoutPodcastsInput.schema';
import { TagUpdateManyWithWhereWithoutPodcastsInputObjectSchema } from './TagUpdateManyWithWhereWithoutPodcastsInput.schema';
import { TagScalarWhereInputObjectSchema } from './TagScalarWhereInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.TagUncheckedUpdateManyWithoutPodcastsNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => TagCreateWithoutPodcastsInputObjectSchema),
            z.lazy(() => TagCreateWithoutPodcastsInputObjectSchema).array(),
            z.lazy(() => TagUncheckedCreateWithoutPodcastsInputObjectSchema),
            z
              .lazy(() => TagUncheckedCreateWithoutPodcastsInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(() => TagCreateOrConnectWithoutPodcastsInputObjectSchema),
            z
              .lazy(() => TagCreateOrConnectWithoutPodcastsInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .union([
            z.lazy(
              () => TagUpsertWithWhereUniqueWithoutPodcastsInputObjectSchema
            ),
            z
              .lazy(
                () => TagUpsertWithWhereUniqueWithoutPodcastsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        set: z
          .union([
            z.lazy(() => TagWhereUniqueInputObjectSchema),
            z.lazy(() => TagWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        disconnect: z
          .union([
            z.lazy(() => TagWhereUniqueInputObjectSchema),
            z.lazy(() => TagWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        delete: z
          .union([
            z.lazy(() => TagWhereUniqueInputObjectSchema),
            z.lazy(() => TagWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => TagWhereUniqueInputObjectSchema),
            z.lazy(() => TagWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(
              () => TagUpdateWithWhereUniqueWithoutPodcastsInputObjectSchema
            ),
            z
              .lazy(
                () => TagUpdateWithWhereUniqueWithoutPodcastsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        updateMany: z
          .union([
            z.lazy(
              () => TagUpdateManyWithWhereWithoutPodcastsInputObjectSchema
            ),
            z
              .lazy(
                () => TagUpdateManyWithWhereWithoutPodcastsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        deleteMany: z
          .union([
            z.lazy(() => TagScalarWhereInputObjectSchema),
            z.lazy(() => TagScalarWhereInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const TagUncheckedUpdateManyWithoutPodcastsNestedInputObjectSchema =
  Schema;
