import * as React from 'react';

import { Box, BoxProps, DarkMode } from '@chakra-ui/react';
import Head from 'next/head';
import { Footer } from './footers';
import { StudioHeader } from './headers';

export interface LayoutProps {
  children: React.ReactNode;
  title?: string;
  description?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  mainProps?: BoxProps;
}

export function BaseLayout({
  title = process.env['NEXT_PUBLIC_APP_NAME'],
  description = 'A one of a kind platform of music & podcasts',
  header = <StudioHeader />,
  footer = <Footer />,
  mainProps,
  children,
}: LayoutProps) {
  return (
    <>
      <Head key="layout">
        <title>{title}</title>

        <meta name="description" content={description} />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <DarkMode>
        {header}

        <Box
          as="main"
          position="relative"
          minH="100vh"
          pt="2.5%"
          bg="black"
          {...mainProps}
        >
          {children}
        </Box>

        {footer}
      </DarkMode>
    </>
  );
}
