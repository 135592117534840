import { z } from 'zod';
import { PlaylistCreateWithoutSubscribersInputObjectSchema } from './PlaylistCreateWithoutSubscribersInput.schema';
import { PlaylistUncheckedCreateWithoutSubscribersInputObjectSchema } from './PlaylistUncheckedCreateWithoutSubscribersInput.schema';
import { PlaylistCreateOrConnectWithoutSubscribersInputObjectSchema } from './PlaylistCreateOrConnectWithoutSubscribersInput.schema';
import { PlaylistWhereUniqueInputObjectSchema } from './PlaylistWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistUncheckedCreateNestedManyWithoutSubscribersInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => PlaylistCreateWithoutSubscribersInputObjectSchema),
            z
              .lazy(() => PlaylistCreateWithoutSubscribersInputObjectSchema)
              .array(),
            z.lazy(
              () => PlaylistUncheckedCreateWithoutSubscribersInputObjectSchema
            ),
            z
              .lazy(
                () => PlaylistUncheckedCreateWithoutSubscribersInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(
              () => PlaylistCreateOrConnectWithoutSubscribersInputObjectSchema
            ),
            z
              .lazy(
                () => PlaylistCreateOrConnectWithoutSubscribersInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema),
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const PlaylistUncheckedCreateNestedManyWithoutSubscribersInputObjectSchema =
  Schema;
