import { z } from 'zod';
import { TagWhereUniqueInputObjectSchema } from './TagWhereUniqueInput.schema';
import { TagUpdateWithoutUsersInputObjectSchema } from './TagUpdateWithoutUsersInput.schema';
import { TagUncheckedUpdateWithoutUsersInputObjectSchema } from './TagUncheckedUpdateWithoutUsersInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.TagUpdateWithWhereUniqueWithoutUsersInput> = z
  .object({
    where: z.lazy(() => TagWhereUniqueInputObjectSchema),
    data: z.union([
      z.lazy(() => TagUpdateWithoutUsersInputObjectSchema),
      z.lazy(() => TagUncheckedUpdateWithoutUsersInputObjectSchema),
    ]),
  })
  .strict();

export const TagUpdateWithWhereUniqueWithoutUsersInputObjectSchema = Schema;
