import { z } from 'zod';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserUpdateWithoutPodcastsAssignedInputObjectSchema } from './UserUpdateWithoutPodcastsAssignedInput.schema';
import { UserUncheckedUpdateWithoutPodcastsAssignedInputObjectSchema } from './UserUncheckedUpdateWithoutPodcastsAssignedInput.schema';
import { UserCreateWithoutPodcastsAssignedInputObjectSchema } from './UserCreateWithoutPodcastsAssignedInput.schema';
import { UserUncheckedCreateWithoutPodcastsAssignedInputObjectSchema } from './UserUncheckedCreateWithoutPodcastsAssignedInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUpsertWithWhereUniqueWithoutPodcastsAssignedInput> =
  z
    .object({
      where: z.lazy(() => UserWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => UserUpdateWithoutPodcastsAssignedInputObjectSchema),
        z.lazy(
          () => UserUncheckedUpdateWithoutPodcastsAssignedInputObjectSchema
        ),
      ]),
      create: z.union([
        z.lazy(() => UserCreateWithoutPodcastsAssignedInputObjectSchema),
        z.lazy(
          () => UserUncheckedCreateWithoutPodcastsAssignedInputObjectSchema
        ),
      ]),
    })
    .strict();

export const UserUpsertWithWhereUniqueWithoutPodcastsAssignedInputObjectSchema =
  Schema;
