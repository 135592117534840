import * as React from 'react';

import {
  chakra,
  Box,
  Flex,
  HStack,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FaFacebook, FaTwitter } from 'react-icons/fa';

import { Link, LocalImage } from '../../../components';
import { StudioRoutes } from '../../../utils';

export function Footer() {
  return (
    <Box mb="8" px="5%" mt="48">
      <SimpleGrid
        templateColumns={{ sm: '1fr 1fr', md: '1fr 1fr 1fr 1fr 2fr' }}
        spacing={8}
      >
        <Stack spacing={6} justify="start" align="start">
          <LocalImage
            src="/images/logo.png"
            alt="Logo"
            height={40}
            width={40}
            objectFit="contain"
          />
        </Stack>

        <Stack align="flex-start">
          <ListHeader>Creators</ListHeader>
          <Link
            href={
              StudioRoutes['discover'].children?.podcasts.children?.new.href ??
              ''
            }
          >
            Submit your podcast
          </Link>
        </Stack>

        <Stack align="flex-start">
          <ListHeader>Company</ListHeader>
          <chakra.a
            href={StudioRoutes.info.children?.about.href ?? ''}
            _hover={{ textDecoration: 'underline' }}
          >
            About
          </chakra.a>
          <chakra.a
            fontWeight="bold"
            _hover={{ textDecoration: 'underline' }}
            href="https://theqube.app"
          >
            The Qube
          </chakra.a>
          <chakra.a
            fontWeight="bold"
            _hover={{ textDecoration: 'underline' }}
            href="https://e3radio.fm"
          >
            E3 Radio
          </chakra.a>
          <Link href={StudioRoutes.info.children?.contact.href ?? ''}>
            Contact
          </Link>
        </Stack>

        <Stack direction="row">
          <IconButton
            as="a"
            aria-label="twitter"
            href="https://twitter.com/thequbeapp"
            icon={<FaTwitter />}
          />
          <IconButton
            as="a"
            aria-label="facebook"
            href="https://facebook.com/thequbeapp"
            icon={<FaFacebook />}
          />
        </Stack>
      </SimpleGrid>

      <Flex justify="space-around" fontSize="sm" color="gray.200" mt="12">
        <HStack spacing={6}>
          <Link href={StudioRoutes.info.children?.privacy.href ?? ''}>
            Privacy Policy
          </Link>
          <Link href={StudioRoutes.info.children?.terms.href ?? ''}>
            Terms &amp; Conditions
          </Link>
        </HStack>

        <Text>Copyright © {new Date().getFullYear()} All rights reserved.</Text>
      </Flex>
    </Box>
  );
}

function ListHeader({ children }: { children: React.ReactNode }) {
  return (
    <Text fontSize="sm" mb={2}>
      {children}
    </Text>
  );
}
