import * as React from 'react';

import { Box, Flex, Input } from '@chakra-ui/react';
import {
  FieldPath,
  FieldValues,
  Path,
  UnPackAsyncDefaultValues,
  useController,
  useFormContext,
} from 'react-hook-form';
import { z, ZodError } from 'zod';

import { FormField, FormFieldProps } from '../helpers';
import { TextInputProps } from './text';

type DateInputProps<
  FormData extends FieldValues = FieldValues,
  Name extends FieldPath<FormData> = Path<UnPackAsyncDefaultValues<FormData>>
> = Omit<FormFieldProps<FormData, Name>, 'render'> & {
  input?: TextInputProps;
};

export function DateInput<
  FormData extends FieldValues = FieldValues,
  Name extends FieldPath<FormData> = Path<UnPackAsyncDefaultValues<FormData>>
>(props: DateInputProps<FormData, Name>) {
  const { input, ...rest } = props;

  const context = useFormContext<FormData>();

  const { field } = useController<FormData, Name>({
    control: context?.control,
    name: props.name,
  });

  type DateInputState = {
    day: string;
    month: string;
    year: string;
  };

  const [values, setValues] = React.useState<DateInputState>({
    day: '',
    month: '',
    year: '',
  });

  const makeOnChangeHandler =
    (
      fieldName: keyof BirthdayZod
    ): React.ChangeEventHandler<HTMLInputElement> =>
    (event) => {
      const parse = (next: DateInputState) => {
        const isEmpty = Object.values(next).every((value) => value === '');

        if (isEmpty) return;
        return BirthdayZod.parseAsync(next)
          .then((fieldValues) => {
            const newDate = new Date(
              Number(fieldValues.year),
              Number(fieldValues.month) - 1,
              Number(fieldValues.day)
            );

            // @ts-expect-error -- we know that the field value is (& should be) a Date string
            context.setValue(props.name, newDate);
            context.clearErrors(props.name);
          })
          .catch((error: ZodError) => {
            // @ts-expect-error -- we know that the field value is (& should be) a Date string
            context.setValue(props.name, '');
            context.setError(props.name, {
              message: error.errors[0].message,
            });
          });
      };

      setValues((prev) => {
        const next = { ...prev, [fieldName]: event.target.value };

        parse(next);
        return next;
      });
    };

  return (
    <FormField
      {...rest}
      helperText={
        typeof field.value === 'string'
          ? `- ${new Date(field.value).toLocaleDateString()}`
          : undefined
      }
      render={() => (
        <Box>
          <Flex>
            <Box>
              <Input
                {...input}
                name="month"
                type="number"
                placeholder="MM"
                value={values.month}
                onChange={makeOnChangeHandler('month')}
              />
            </Box>

            <Box>
              <Input
                {...input}
                name="day"
                type="number"
                placeholder="DD"
                value={values.day}
                onChange={makeOnChangeHandler('day')}
              />
            </Box>

            <Box>
              <Input
                {...input}
                name="year"
                type="number"
                placeholder="YYYY"
                value={values.year}
                onChange={makeOnChangeHandler('year')}
              />
            </Box>
          </Flex>
        </Box>
      )}
    />
  );
}

export type BirthdayZod = z.infer<typeof BirthdayZod>;
const BirthdayZod = z.object({
  day: z.string().refine((v) => {
    const number = Number(v);

    if (Number.isNaN(number)) {
      return false;
    }

    return number >= 1 && number <= 31;
  }, 'Day must be between a number between 1 and 31'),
  month: z.string().refine((v) => {
    const number = Number(v);

    if (Number.isNaN(number)) {
      return false;
    }

    return number >= 1 && number <= 12;
  }, 'Month must be a number between 1 and 12'),

  year: z.string().refine((v) => {
    const number = Number(v);
    const currentYear = new Date().getFullYear();

    if (Number.isNaN(number)) {
      return false;
    }

    return number >= currentYear - 150 && number <= currentYear;
  }, 'Year must be a number within the last 150 years'),
});
