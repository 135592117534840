import { z } from 'zod';
import { PodcastStatusSchema } from '../enums/PodcastStatus.schema';
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema';
import { NestedEnumPodcastStatusFilterObjectSchema } from './NestedEnumPodcastStatusFilter.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.NestedEnumPodcastStatusWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => PodcastStatusSchema).optional(),
    in: z
      .lazy(() => PodcastStatusSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => PodcastStatusSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => PodcastStatusSchema),
        z.lazy(() => NestedEnumPodcastStatusWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumPodcastStatusFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumPodcastStatusFilterObjectSchema).optional(),
  })
  .strict();

export const NestedEnumPodcastStatusWithAggregatesFilterObjectSchema = Schema;
