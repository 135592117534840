import { z } from 'zod';
import { UserCreateWithoutPodcastsAssignedInputObjectSchema } from './UserCreateWithoutPodcastsAssignedInput.schema';
import { UserUncheckedCreateWithoutPodcastsAssignedInputObjectSchema } from './UserUncheckedCreateWithoutPodcastsAssignedInput.schema';
import { UserCreateOrConnectWithoutPodcastsAssignedInputObjectSchema } from './UserCreateOrConnectWithoutPodcastsAssignedInput.schema';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserCreateNestedManyWithoutPodcastsAssignedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => UserCreateWithoutPodcastsAssignedInputObjectSchema),
            z
              .lazy(() => UserCreateWithoutPodcastsAssignedInputObjectSchema)
              .array(),
            z.lazy(
              () => UserUncheckedCreateWithoutPodcastsAssignedInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  UserUncheckedCreateWithoutPodcastsAssignedInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(
              () => UserCreateOrConnectWithoutPodcastsAssignedInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  UserCreateOrConnectWithoutPodcastsAssignedInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const UserCreateNestedManyWithoutPodcastsAssignedInputObjectSchema =
  Schema;
