import { Box, BoxProps, Flex } from '@chakra-ui/react';

type HeaderSection = {
  content: React.ReactNode;
  key: string;
  boxProps?: BoxProps;
};

export type HeaderProps = {
  containerProps?: BoxProps;
  sections: HeaderSection[];
};

export function Header({ sections, containerProps }: HeaderProps) {
  return (
    <Flex
      as="header"
      position="sticky"
      zIndex="docked"
      top="0"
      left="0"
      right="0"
      height="12"
      px="5%"
      alignItems="center"
      justifyContent="space-end"
      bg="blackAlpha.400"
      backdropFilter="blur(6px)"
      shadow="md"
      {...containerProps}
    >
      {sections.map(({ key, content, boxProps }) => (
        <Box key={key} {...boxProps}>
          {content}
        </Box>
      ))}
    </Flex>
  );
}
