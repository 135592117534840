import { TagType } from '@prisma/client';

export type Route<
  Href = string,
  Children = { [key: string]: Route<unknown, unknown> }
> = {
  href: Href;
  label: string;
  children?: Children;
};

export interface StudioRouteMap {
  home: Route;
  account: Route<string, { preferences: Route<(type: TagType) => string> }>;
  admin: Route;

  auth: Route<
    string,
    {
      login: Route;
      register: Route;
      ['password-reset']: Route;
      ['email-verify']: Route;
    }
  >;

  info: Route<
    string,
    {
      about: Route;
      contact: Route;
      privacy: Route;
      terms: Route;
    }
  >;

  discover: Route<
    string,
    {
      users: Route<string, { id: Route<(id: string) => string> }>;

      tags: Route<
        string,
        {
          id: Route<
            (id: string) => string,
            { edit: Route<(id: string) => string> }
          >;
        }
      >;

      episodes: Route<
        string,
        {
          id: Route<
            (id: string) => string,
            { edit: Route<(id: string) => string> }
          >;
        }
      >;

      podcasts: Route<
        string,
        {
          new: Route;
          id: Route<(id: string) => string>;
          originals: Route<string, { id: Route<(id: string) => string> }>;
        }
      >;
    }
  >;
}

export const StudioRoutes: StudioRouteMap = {
  home: { href: '/', label: 'Home' },
  admin: { href: '/admin', label: 'Admin Dashboard' },
  account: {
    href: '/account',
    label: 'Account',

    children: {
      preferences: {
        href: (type: TagType) => `/account/preferences?type=${type}`,
        label: 'Preferences',
      },
    },
  },

  auth: {
    href: '/auth',
    label: 'Authentication',

    children: {
      login: { href: '/auth/login', label: 'Login' },
      register: { href: '/auth/register', label: 'Sign Up' },
      'password-reset': {
        href: '/auth/password-reset',
        label: 'Forgot Password',
      },
      'email-verify': {
        href: '/auth/email-verify',
        label: 'Verify Email',
      },
    },
  },

  info: {
    href: '/info',
    label: 'Info',

    children: {
      about: { href: 'https://theqube.app/about', label: 'About Us' },
      contact: { href: '/info/contact', label: 'Contact Us' },
      privacy: { href: '/info/privacy', label: 'Privacy Policy' },
      terms: { href: '/info/terms', label: 'Terms of Service' },
    },
  },

  discover: {
    href: '/discover',
    label: 'Discover',
    children: {
      users: {
        href: '/discover/users',
        label: 'Users',

        children: {
          id: {
            href: (userId: string) => `/discover/users/${userId}`,
            label: 'View User',
          },
        },
      },

      tags: {
        href: '/discover/tags',
        label: 'Tags',

        children: {
          id: {
            href: (tagId: string) => `/discover/tags/${tagId}`,
            label: 'View Tag',
            children: {
              edit: {
                href: (tagId: string) => `/discover/tags/${tagId}/edit`,
                label: 'Edit a Tag',
              },
            },
          },
        },
      },

      episodes: {
        href: '/discover/episodes',
        label: 'Episodes',

        children: {
          id: {
            href: (episodeId: string) => `/discover/episodes/${episodeId}`,
            label: 'View Episode',
            children: {
              edit: {
                href: (episodeId: string) =>
                  `/discover/episodes/${episodeId}/edit`,
                label: 'Edit an Episode',
              },
            },
          },
        },
      },

      podcasts: {
        href: '/discover/podcasts',
        label: 'Podcasts',

        children: {
          new: {
            href: 'https://docs.google.com/forms/d/e/1FAIpQLSf65RATnm7LSadH9V8LjiQ4ONxUMPUAhjznHmrxsXRXveCXew/viewform',
            label: 'Submit your podcast',
          },
          id: {
            href: (podcastId: string) => `/discover/podcasts/${podcastId}`,
            label: 'View a podcasts',
            children: {
              edit: {
                href: (podcastId: string) =>
                  `/discover/podcasts/${podcastId}/edit`,
                label: 'Edit a podcast',
              },
            },
          },
          originals: {
            href: '/discover/podcasts/originals',
            label: 'View an Original Podcast',
            children: {
              id: {
                href: (podcastId: string) =>
                  `/discover/podcasts/original/${podcastId}`,
                label: 'View a podcasts',
              },
            },
          },
        },
      },
    },
  },
} satisfies StudioRouteMap;
