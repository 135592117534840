import DOMPurify from 'dompurify';

import { chakra, ChakraProps } from '@chakra-ui/react';

interface HTMLRendererProps extends ChakraProps {
  html: string | null | undefined;
}

export function HTMLRenderer({ html, ...props }: HTMLRendererProps) {
  if (html) {
    const safeSpace = DOMPurify.sanitize(html);
    return (
      <chakra.div dangerouslySetInnerHTML={{ __html: safeSpace }} {...props} />
    );
  }

  return null;
}
