export const partnerData = [
  {
    name: 'Afros and Audio',
    imageUrl: '/images/logos/afro_audio_logo.svg',
  },
  {
    name: 'Women of Color Podcasters',
    imageUrl: '/images/logos/woc_black_logo.png',
  },
  {
    name: 'Podfest',
    imageUrl: '/images/logos/podfest_logo.png',
  },
  {
    name: 'Podcasts In Color',
    imageUrl: '/images/logos/podcasts_in_color_logo.png',
  },
  {
    name: 'Podthon 2022',
    imageUrl: '/images/logos/podthon2022_logo.png',
  },
  {
    name: 'Asian American Podcasters Association',
    imageUrl: '/images/logos/aap_logo.png',
  },
  {
    name: 'DCP Entertainment',
    imageUrl: '/images/logos/DCP_logo.png',
  },
];
