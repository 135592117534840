import { Box, Flex, Stack } from '@chakra-ui/react';

import { PageHeading } from '../../../../components';
import { OriginalShowMember } from './member';

interface OriginalShowTeamProps {
  team: Array<{
    image: string;
    name: string;
    title: string;
    bio: string;
    socials: Array<{ [key: string]: string }>;
  }>;
}

export function OriginalShowTeam(props: OriginalShowTeamProps) {
  const { team } = props;

  return (
    <Flex as="section" justify="center">
      <Stack w="60%" spacing={50}>
        <PageHeading fontSize="4xl" mb={30}>
          Meet The Team
        </PageHeading>

        {team.map((member, idx) => (
          <Box key={idx}>
            <OriginalShowMember
              image={member.image}
              name={member.name}
              title={member.title}
              bio={member.bio}
              socials={member.socials}
            />
          </Box>
        ))}
      </Stack>
    </Flex>
  );
}
