import { z } from 'zod';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';
import { PodcastUpdateWithoutCuratorsInputObjectSchema } from './PodcastUpdateWithoutCuratorsInput.schema';
import { PodcastUncheckedUpdateWithoutCuratorsInputObjectSchema } from './PodcastUncheckedUpdateWithoutCuratorsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastUpdateWithWhereUniqueWithoutCuratorsInput> =
  z
    .object({
      where: z.lazy(() => PodcastWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => PodcastUpdateWithoutCuratorsInputObjectSchema),
        z.lazy(() => PodcastUncheckedUpdateWithoutCuratorsInputObjectSchema),
      ]),
    })
    .strict();

export const PodcastUpdateWithWhereUniqueWithoutCuratorsInputObjectSchema =
  Schema;
