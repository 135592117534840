import { HStack, Skeleton, SkeletonProps } from '@chakra-ui/react';

import { Image, ImageProps } from '../atoms';

type PageHeroProp = SkeletonProps & {
  heading?: ImageProps;
  thumbnail?: ImageProps;
  actions?: React.ReactNode;
};

export function PageHero({
  heading,
  thumbnail,
  children,
  actions,
  ...props
}: PageHeroProp) {
  return (
    <Skeleton
      zIndex="0"
      px="5%"
      marginX="auto"
      w="90%"
      maxW="container.lg"
      {...props}
    >
      {heading || thumbnail ? (
        <Image
          {...heading}
          src={heading?.src ?? thumbnail?.src}
          display="block"
          pointerEvents="none"
          zIndex="0"
          objectFit="cover"
          objectPosition="center"
          width="100%"
          height="sm"
          overflow="hidden"
          position="relative"
          rounded="md"
          _before={{
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1,
            pointerEvents: 'none',
            backdropFilter: 'auto',
            backdropBlur: '12px',
            height: '100%',
            width: '100%',
          }}
        />
      ) : null}

      {thumbnail || actions ? (
        <HStack mt="-10%" ml="5%" justifyContent="space-between" zIndex="1">
          {thumbnail ? (
            <Image rounded="md" width="48" height="48" mb="6" {...thumbnail} />
          ) : null}
          {actions}
        </HStack>
      ) : null}

      {children}
    </Skeleton>
  );
}
