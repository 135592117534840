import { z } from 'zod';
import { SortOrderSchema } from '../enums/SortOrder.schema';
import { RssFeedOrderByWithRelationInputObjectSchema } from './RssFeedOrderByWithRelationInput.schema';
import { TagOrderByRelationAggregateInputObjectSchema } from './TagOrderByRelationAggregateInput.schema';
import { UserOrderByRelationAggregateInputObjectSchema } from './UserOrderByRelationAggregateInput.schema';
import { EpisodeOrderByRelationAggregateInputObjectSchema } from './EpisodeOrderByRelationAggregateInput.schema';
import { CurationOrderByRelationAggregateInputObjectSchema } from './CurationOrderByRelationAggregateInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    publishedAt: z.lazy(() => SortOrderSchema).optional(),
    importedAt: z.lazy(() => SortOrderSchema).optional(),
    rssFeed: z
      .lazy(() => RssFeedOrderByWithRelationInputObjectSchema)
      .optional(),
    tags: z.lazy(() => TagOrderByRelationAggregateInputObjectSchema).optional(),
    owners: z
      .lazy(() => UserOrderByRelationAggregateInputObjectSchema)
      .optional(),
    episodes: z
      .lazy(() => EpisodeOrderByRelationAggregateInputObjectSchema)
      .optional(),
    subscribers: z
      .lazy(() => UserOrderByRelationAggregateInputObjectSchema)
      .optional(),
    curators: z
      .lazy(() => UserOrderByRelationAggregateInputObjectSchema)
      .optional(),
    curations: z
      .lazy(() => CurationOrderByRelationAggregateInputObjectSchema)
      .optional(),
    status: z.lazy(() => SortOrderSchema).optional(),
    statusReason: z.lazy(() => SortOrderSchema).optional(),
    avatarUrl: z.lazy(() => SortOrderSchema).optional(),
    isOriginal: z.lazy(() => SortOrderSchema).optional(),
    isExplicit: z.lazy(() => SortOrderSchema).optional(),
    title: z.lazy(() => SortOrderSchema).optional(),
    description: z.lazy(() => SortOrderSchema).optional(),
    websiteUrl: z.lazy(() => SortOrderSchema).optional(),
    socialMedia: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict();

export const PodcastOrderByWithRelationInputObjectSchema = Schema;
