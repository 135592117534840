import { z } from 'zod';
import { CurationWhereUniqueInputObjectSchema } from './CurationWhereUniqueInput.schema';
import { CurationCreateWithoutCuratorInputObjectSchema } from './CurationCreateWithoutCuratorInput.schema';
import { CurationUncheckedCreateWithoutCuratorInputObjectSchema } from './CurationUncheckedCreateWithoutCuratorInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.CurationCreateOrConnectWithoutCuratorInput> = z
  .object({
    where: z.lazy(() => CurationWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => CurationCreateWithoutCuratorInputObjectSchema),
      z.lazy(() => CurationUncheckedCreateWithoutCuratorInputObjectSchema),
    ]),
  })
  .strict();

export const CurationCreateOrConnectWithoutCuratorInputObjectSchema = Schema;
