import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Skeleton,
  SkeletonProps,
  Text,
} from '@chakra-ui/react';
import { z } from 'zod';

import { User, UserFindManySchema } from '@theqube/schemas';
import { TRPCUseQueryBaseOptions } from '@trpc/react-query/shared';
import { Link, Image, ErrorWrapper } from '../../components';
import { StudioRoutes, trpc, usePagination } from '../../utils';

export type UserListProps = {
  query: z.infer<typeof UserFindManySchema>;
  options?: TRPCUseQueryBaseOptions;
  skeleton?: SkeletonProps;
};

export function UserList({ query, options, skeleton }: UserListProps) {
  const [pagination, { move }] = usePagination();

  const {
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
    data: users,
  } = trpc.users.findMany.useQuery({ ...pagination, ...query }, options);

  if (isError) {
    return <ErrorWrapper error={error} retry={refetch} />;
  }

  return (
    <Skeleton isLoaded={isSuccess} {...skeleton}>
      {users && users?.length > 0 ? (
        <>
          {users.map((user) => (
            <UserListItem key={user.id} user={user} />
          ))}

          <ButtonGroup justifyContent="space-between" width="100%">
            <Button onClick={move} isDisabled={isLoading} isLoading={isLoading}>
              Show More
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <Heading>There doesn&apos;t appear to be any more users.</Heading>
      )}
    </Skeleton>
  );
}

type UserListItemProps = {
  user: Partial<User>;
};

function UserListItem({
  user: { id, displayName = 'Untitled', avatarUrl, createdAt },
}: UserListItemProps) {
  return (
    <Box
      display="flex"
      alignItems="center"
      px="4"
      py="8"
      borderBottom="1px solid white"
      _hover={{
        textDecoration: 'none',
      }}
    >
      <Link
        href={
          StudioRoutes.discover.children?.users.children?.id.href(String(id)) ??
          ''
        }
        flex="0 0 20%"
      >
        <Image src={avatarUrl ?? ''} rounded="md" />
      </Link>

      <Box ml="4">
        <Heading
          as={Link}
          href={
            StudioRoutes.discover.children?.users.children?.id.href(
              String(id)
            ) ?? ''
          }
          size={'lg'}
        >
          {displayName}
        </Heading>

        <Text mt="4">
          <Text as="span">{createdAt?.toLocaleDateString()}</Text>
        </Text>
      </Box>
    </Box>
  );
}
