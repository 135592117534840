import { z } from 'zod';
import { SortOrderSchema } from '../enums/SortOrder.schema';
import { PodcastOrderByWithRelationInputObjectSchema } from './PodcastOrderByWithRelationInput.schema';
import { UserOrderByWithRelationInputObjectSchema } from './UserOrderByWithRelationInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.CurationOrderByWithRelationInput> = z
  .object({
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    podcastId: z.lazy(() => SortOrderSchema).optional(),
    podcast: z
      .lazy(() => PodcastOrderByWithRelationInputObjectSchema)
      .optional(),
    curatorId: z.lazy(() => SortOrderSchema).optional(),
    curator: z.lazy(() => UserOrderByWithRelationInputObjectSchema).optional(),
    contactName: z.lazy(() => SortOrderSchema).optional(),
    contactEmail: z.lazy(() => SortOrderSchema).optional(),
    wouldRecommend: z.lazy(() => SortOrderSchema).optional(),
    ratings: z.lazy(() => SortOrderSchema).optional(),
    notes: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict();

export const CurationOrderByWithRelationInputObjectSchema = Schema;
