import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { forwardRef, PropsWithChildren } from 'react';

export type LinkProps = PropsWithChildren<NextLinkProps & ChakraLinkProps>;

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  function WrappedLink({ children, ...props }, ref) {
    return (
      <ChakraLink as={NextLink} {...props} ref={ref}>
        {children}
      </ChakraLink>
    );
  }
);
