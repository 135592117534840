import { z } from 'zod';
import { SortOrderSchema } from '../enums/SortOrder.schema';
import { UserOrderByRelationAggregateInputObjectSchema } from './UserOrderByRelationAggregateInput.schema';
import { EpisodeOrderByRelationAggregateInputObjectSchema } from './EpisodeOrderByRelationAggregateInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    avatarUrl: z.lazy(() => SortOrderSchema).optional(),
    title: z.lazy(() => SortOrderSchema).optional(),
    description: z.lazy(() => SortOrderSchema).optional(),
    owners: z
      .lazy(() => UserOrderByRelationAggregateInputObjectSchema)
      .optional(),
    subscribers: z
      .lazy(() => UserOrderByRelationAggregateInputObjectSchema)
      .optional(),
    episodes: z
      .lazy(() => EpisodeOrderByRelationAggregateInputObjectSchema)
      .optional(),
  })
  .strict();

export const PlaylistOrderByWithRelationInputObjectSchema = Schema;
