import { z } from 'zod';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.EpisodeCreateManyPodcastInput> = z
  .object({
    id: z.number().optional(),
    updatedAt: z.date().optional(),
    publishedAt: z.date().optional(),
    title: z.string(),
    description: z.string().optional().nullable(),
    imageUrl: z.string().optional().nullable(),
    audioUrl: z.string(),
    duration: z.number().optional(),
    explicit: z.boolean().optional(),
  })
  .strict();

export const EpisodeCreateManyPodcastInputObjectSchema = Schema;
