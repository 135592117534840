import { z } from 'zod';
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema';
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema';
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema';
import { UserUpdateManyWithoutPlaylistsNestedInputObjectSchema } from './UserUpdateManyWithoutPlaylistsNestedInput.schema';
import { EpisodeUpdateManyWithoutPlaylistsNestedInputObjectSchema } from './EpisodeUpdateManyWithoutPlaylistsNestedInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistUpdateWithoutSubscribersInput> = z
  .object({
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    avatarUrl: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    title: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    description: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    owners: z
      .lazy(() => UserUpdateManyWithoutPlaylistsNestedInputObjectSchema)
      .optional(),
    episodes: z
      .lazy(() => EpisodeUpdateManyWithoutPlaylistsNestedInputObjectSchema)
      .optional(),
  })
  .strict();

export const PlaylistUpdateWithoutSubscribersInputObjectSchema = Schema;
