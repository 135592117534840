import { z } from 'zod';

export type PublicEnv = z.infer<typeof PublicEnv>;
export type VercelEnv = z.infer<typeof VercelEnv>;
export type PrivateEnv = z.infer<typeof PrivateEnv>;

/**
 * Static builtime environment variables.
 *
 * Accessible from the both the server and client side of things.
 *
 * ! These are PUBLIC. Be careful what you put in here.
 */
export type BuildEnv = PublicEnv | VercelEnv;

/**
 * Dynamic runtime environment variables.
 *
 * Accessible only on the server
 */
export type RunEnv = PublicEnv | PrivateEnv;

/** Client-side/Shared environment variables */
export const PublicEnv = z.enum([
  'NEXT_PUBLIC_APP_ENV',
  'NEXT_PUBLIC_APP_NAME',
  'NEXT_PUBLIC_MOBILE_URI',
  'NEXT_PUBLIC_SENTRY_DSN',
  'NEXT_PUBLIC_API_URL',
  'NEXT_PUBLIC_GCP_CLIENT_ID',
  'NEXT_PUBLIC_GCP_MEASUREMENT_ID',
]);

/** Server-side/private environment variables (secrets)  */
export const PrivateEnv = z.enum([
  /** Server Env */
  'PORT',
  'RADIO_ID',
  'JWT_SECRET',
  'DATABASE_URL',
  'PORT',

  /** Mail Server */
  'SMTP_EMAIL',
  'SMTP_PASSWORD',
  'SMTP_HOST',
  'SMTP_USER',
  'SMTP_PORT',

  /** Search (algolia) */
  'ALGOLIA_PREFIX',
  'ALGOLIA_IS_ENABLED',
  'ALGOLIA_APP_ID',
  'ALGOLIA_ADMIN_API_KEY',
  'ALGOLIA_SEARCH_API_KEY',

  /** GCP and Google OAuth */
  'GCP_STORAGE_BUCKET',
  'GCP_CLIENT_EMAIL',
  'GCP_PRIVATE_KEY',
  'GCP_PROJECT_ID',
  'GCP_CLIENT_SECRET',

  /** Twitter Oauth */
  'TWITTER_CONSUMER_KEY',
  'TWITTER_CONSUMER_SECRET',
  'TWITTER_CLIENT_ID',
  'TWITTER_CLIENT_SECRET',

  /** Apple OAuth */
  'APPLE_CLIENT_ID',
  'APPLE_TEAM_ID',
  'APPLE_KEY_ID',
  'APPLE_PRIVATE_KEY_LOCATION',
  'APPLE_PRIVATE_KEY_STRING',
]);

/** Vercel System Environment Variables */
export const VercelEnv = z.enum([
  'NEXT_PUBLIC_VERCEL_ENV',
  'NEXT_PUBLIC_VERCEL_URL',
  'NEXT_PUBLIC_VERCEL_GIT_PROVIDER',
  'NEXT_PUBLIC_VERCEL_GIT_REPO_SLUG',
  'NEXT_PUBLIC_VERCEL_GIT_REPO_OWNER',
  'NEXT_PUBLIC_VERCEL_GIT_REPO_ID',
  'NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF',
  'NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA',
  'NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE',
  'NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_LOGIN',
  'NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_NAME',
]);

export type EnvironmentValue = 'number' | 'boolean' | 'string';

export class Environment<Secrets extends BuildEnv | RunEnv> {
  get isProduction(): boolean {
    return false;
  }

  get<Schema extends EnvironmentValue = 'string'>(
    secret: Secrets,
    schema?: Schema
  ): Schema extends 'number'
    ? number
    : Schema extends 'boolean'
    ? boolean
    : string {
    const value = process.env[secret] ?? undefined;

    if (!value) {
      throw new Error(
        `[Secrets] :: No secret found for ${secret} (schema: ${schema})`
      );
    }

    return this.parse(secret, { initialValue: value, schema });
  }

  parse<Schema extends 'string' | 'number' | 'boolean' = 'string'>(
    name: Secrets,
    opts: {
      initialValue?: string;
      schema?: Schema;
    }
  ): Schema extends 'number'
    ? number
    : Schema extends 'boolean'
    ? boolean
    : string {
    const { initialValue = '', schema } = opts;

    if (initialValue === '') {
      throw new Error(
        `[Secrets] :: No secret found for ${name} (schema: ${schema})`
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let result: z.SafeParseReturnType<any, any>;

    if (schema === 'number') {
      result = z.number().safeParse(parseInt(initialValue, 10));
    } else if (schema === 'boolean') {
      result = z
        .boolean()
        .safeParse(initialValue === 'true' || initialValue === '1');
    } else {
      result = z.string().min(1).safeParse(initialValue);
    }

    if (result.success === true) {
      return result.data;
    }

    // * Improves error-handling
    console.warn(
      `[Env] :: Failed to parse ${name}`,
      result.error.flatten().fieldErrors
    );

    throw new Error(`[Env] :: Failed to parse ${name}`);
  }
}
