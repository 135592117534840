import { z } from 'zod';
import { PermissionsCreaterolesInputObjectSchema } from './PermissionsCreaterolesInput.schema';
import { RoleSchema } from '../enums/Role.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PermissionsUncheckedCreateWithoutOwnerInput> = z
  .object({
    updatedAt: z.date().optional(),
    roles: z
      .union([
        z.lazy(() => PermissionsCreaterolesInputObjectSchema),
        z.lazy(() => RoleSchema).array(),
      ])
      .optional(),
    canCreateOriginalPodcast: z.boolean().optional(),
    canReadOriginalPodcast: z.boolean().optional(),
    canUpdateOriginalPodcasts: z.boolean().optional(),
    canDeleteOriginalPodcasts: z.boolean().optional(),
    canCreateAdvertisement: z.boolean().optional(),
    canReadAdvertisement: z.boolean().optional(),
    canUpdateAdvertisements: z.boolean().optional(),
    canDeleteAdvertisements: z.boolean().optional(),
    canCreateUser: z.boolean().optional(),
    canReadUser: z.boolean().optional(),
    canUpdateUsers: z.boolean().optional(),
    canDeleteUsers: z.boolean().optional(),
    canCreateOriginalPlaylist: z.boolean().optional(),
    canReadOriginalPlaylist: z.boolean().optional(),
    canUpdateOriginalPlaylists: z.boolean().optional(),
    canDeleteOriginalPlaylists: z.boolean().optional(),
  })
  .strict();

export const PermissionsUncheckedCreateWithoutOwnerInputObjectSchema = Schema;
