import { Prisma } from '@prisma/client';
import { publicPodcastSelect } from './index';

export const publicEpisodeSelect /**: Prisma.EpisodeSelect /**/ = {
  id: true,

  updatedAt: true,
  publishedAt: true,

  audioUrl: true,
  imageUrl: true,
  title: true,
  description: true,
  duration: true,
  explicit: true,

  podcastId: true,
  podcast: { select: publicPodcastSelect },
} as const;

export const privateEpisodeSelect /**: Prisma.EpisodeSelect /**/ = {
  ...publicEpisodeSelect,
} as const;

export type PublicEpisode = Prisma.EpisodeGetPayload<{
  select: typeof publicEpisodeSelect;
}>;

export type PrivateEpisode = Prisma.EpisodeGetPayload<{
  select: typeof privateEpisodeSelect;
}>;
