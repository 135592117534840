import { z } from 'zod';
import { PodcastCreateWithoutEpisodesInputObjectSchema } from './PodcastCreateWithoutEpisodesInput.schema';
import { PodcastUncheckedCreateWithoutEpisodesInputObjectSchema } from './PodcastUncheckedCreateWithoutEpisodesInput.schema';
import { PodcastCreateOrConnectWithoutEpisodesInputObjectSchema } from './PodcastCreateOrConnectWithoutEpisodesInput.schema';
import { PodcastUpsertWithoutEpisodesInputObjectSchema } from './PodcastUpsertWithoutEpisodesInput.schema';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';
import { PodcastUpdateWithoutEpisodesInputObjectSchema } from './PodcastUpdateWithoutEpisodesInput.schema';
import { PodcastUncheckedUpdateWithoutEpisodesInputObjectSchema } from './PodcastUncheckedUpdateWithoutEpisodesInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastUpdateOneRequiredWithoutEpisodesNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => PodcastCreateWithoutEpisodesInputObjectSchema),
            z.lazy(
              () => PodcastUncheckedCreateWithoutEpisodesInputObjectSchema
            ),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .lazy(() => PodcastCreateOrConnectWithoutEpisodesInputObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .lazy(() => PodcastUpsertWithoutEpisodesInputObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z.lazy(() => PodcastWhereUniqueInputObjectSchema).optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(() => PodcastUpdateWithoutEpisodesInputObjectSchema),
            z.lazy(
              () => PodcastUncheckedUpdateWithoutEpisodesInputObjectSchema
            ),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const PodcastUpdateOneRequiredWithoutEpisodesNestedInputObjectSchema =
  Schema;
