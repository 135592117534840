import { z } from 'zod';
import { FileWhereUniqueInputObjectSchema } from './objects/FileWhereUniqueInput.schema';
import { FileCreateInputObjectSchema } from './objects/FileCreateInput.schema';
import { FileUpdateInputObjectSchema } from './objects/FileUpdateInput.schema';

export const FileUpsertSchema = z.object({
  where: FileWhereUniqueInputObjectSchema,
  create: FileCreateInputObjectSchema,
  update: FileUpdateInputObjectSchema,
});
