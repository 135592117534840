import { z } from 'zod';
import { AccountCreateNestedManyWithoutUserInputObjectSchema } from './AccountCreateNestedManyWithoutUserInput.schema';
import { SessionCreateNestedManyWithoutUserInputObjectSchema } from './SessionCreateNestedManyWithoutUserInput.schema';
import { TagCreateNestedManyWithoutUsersInputObjectSchema } from './TagCreateNestedManyWithoutUsersInput.schema';
import { SettingsCreateNestedOneWithoutOwnerInputObjectSchema } from './SettingsCreateNestedOneWithoutOwnerInput.schema';
import { PermissionsCreateNestedOneWithoutOwnerInputObjectSchema } from './PermissionsCreateNestedOneWithoutOwnerInput.schema';
import { PlaylistCreateNestedManyWithoutOwnersInputObjectSchema } from './PlaylistCreateNestedManyWithoutOwnersInput.schema';
import { PodcastCreateNestedManyWithoutCuratorsInputObjectSchema } from './PodcastCreateNestedManyWithoutCuratorsInput.schema';
import { CurationCreateNestedManyWithoutCuratorInputObjectSchema } from './CurationCreateNestedManyWithoutCuratorInput.schema';
import { PodcastCreateNestedManyWithoutOwnersInputObjectSchema } from './PodcastCreateNestedManyWithoutOwnersInput.schema';
import { PodcastCreateNestedManyWithoutSubscribersInputObjectSchema } from './PodcastCreateNestedManyWithoutSubscribersInput.schema';
import { NullableJsonNullValueInputSchema } from '../enums/NullableJsonNullValueInput.schema';

import type { Prisma } from '@prisma/client';

const literalSchema = z.union([z.string(), z.number(), z.boolean()]);
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ])
);

const Schema: z.ZodType<Prisma.UserCreateWithoutPlaylistSubscriptionsInput> = z
  .object({
    id: z.string().optional(),
    avatarUrl: z.string().optional().nullable(),
    displayName: z.string().optional().nullable(),
    fullName: z.string(),
    email: z.string().optional().nullable(),
    emailVerified: z.date().optional().nullable(),
    passwordHash: z.string().optional().nullable(),
    accounts: z
      .lazy(() => AccountCreateNestedManyWithoutUserInputObjectSchema)
      .optional(),
    sessions: z
      .lazy(() => SessionCreateNestedManyWithoutUserInputObjectSchema)
      .optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    tags: z
      .lazy(() => TagCreateNestedManyWithoutUsersInputObjectSchema)
      .optional(),
    settings: z
      .lazy(() => SettingsCreateNestedOneWithoutOwnerInputObjectSchema)
      .optional(),
    permissions: z
      .lazy(() => PermissionsCreateNestedOneWithoutOwnerInputObjectSchema)
      .optional(),
    playlists: z
      .lazy(() => PlaylistCreateNestedManyWithoutOwnersInputObjectSchema)
      .optional(),
    podcastsAssigned: z
      .lazy(() => PodcastCreateNestedManyWithoutCuratorsInputObjectSchema)
      .optional(),
    podcastsCurated: z
      .lazy(() => CurationCreateNestedManyWithoutCuratorInputObjectSchema)
      .optional(),
    podcasts: z
      .lazy(() => PodcastCreateNestedManyWithoutOwnersInputObjectSchema)
      .optional(),
    podcastSubscriptions: z
      .lazy(() => PodcastCreateNestedManyWithoutSubscribersInputObjectSchema)
      .optional(),
    biography: z.string().optional().nullable(),
    birthday: z.date().optional().nullable(),
    gender: z.string().optional().nullable(),
    socialMedia: z
      .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
      .optional(),
  })
  .strict();

export const UserCreateWithoutPlaylistSubscriptionsInputObjectSchema = Schema;
