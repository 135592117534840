import { Code as CCode, List, ListItem, ListProps } from '@chakra-ui/react';

export type CodeBlockProps = {
  children?: React.ReactNode;
  data?: unknown;
  list?: ListProps;
};

/**
 * # CodeBlock
 *
 *
 * Displays a block of code stringifying anything the `data` obj
 *
 * @note
 * Runs recursively on arrays to display a <CodeBlock/> for each item in the
 *  array.
 *
 */
export function Code(props: CodeBlockProps) {
  const { data, children, list } = props;

  if (Array.isArray(data)) {
    return (
      <>
        {data.sort().map((obj) => (
          <Code data={obj} key={JSON.stringify(obj)} {...list} />
        ))}
      </>
    );
  }

  const renderObj = (obj: object) =>
    Object.entries(obj)
      .sort(([a], [b]) => a.localeCompare(b))
      .map(([key, value]) => (
        <ListItem key={key}>
          <p>{key}</p>

          <CCode wordBreak="initial">
            {typeof value === 'undefined'
              ? 'null or undefined'
              : JSON.stringify(value, getCircularReplacer(), 2)}
          </CCode>
        </ListItem>
      ));

  return (
    <List {...list} bg="blackAlpha.200" p="4" overflow="auto" maxWidth="100%">
      {data instanceof Object ? renderObj(data) : null}
      {children instanceof Object ? renderObj(children) : null}
    </List>
  );
}

/** @link https://stackoverflow.com/questions/11616630/how-can-i-print-a-circular-structure-in-a-json-like-format */
function getCircularReplacer() {
  const seen = new WeakSet();

  return (_: unknown, value: string) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return '[Circular]';
      }

      seen.add(value);
    }

    if (typeof value === 'string' && value.length > 80) {
      return `${value.slice(0, 80)}...`;
    }

    return value;
  };
}
