import { Prisma } from '@prisma/client';
import { z } from 'zod';

import { PaginationSchema } from '../pagination';
import { publicUserSelect } from '../users';

export const podcastCountSelect = {
  episodes: true,
  owners: true,
  tags: true,
  subscribers: true,
};

export const publicPodcastSelect /**: Prisma.PodcastSelect /**/ = {
  id: true,
  createdAt: true,
  updatedAt: true,

  _count: { select: podcastCountSelect },
  owners: { select: publicUserSelect },
  tags: { select: { name: true } },

  avatarUrl: true,
  isOriginal: true,
  isExplicit: true,
  title: true,
  description: true,
  websiteUrl: true,
  socialMedia: true,
} as const;

export const privatePodcastSelect /**: Prisma.PodcastSelect /**/ = {
  ...publicPodcastSelect,

  rssFeed: { select: { url: true } },
  subscribers: { select: { id: true } },
  owners:
    typeof publicPodcastSelect.owners === 'object'
      ? {
          ...publicPodcastSelect.owners,
          select: { ...publicPodcastSelect.owners.select, email: true },
        }
      : publicPodcastSelect.owners,
} as const;

export type PrivatePodcast = Prisma.PodcastGetPayload<{
  select: typeof privatePodcastSelect;
}>;

export type PublicPodcast = Prisma.PodcastGetPayload<{
  select: typeof publicPodcastSelect;
}>;

export const GetPodcastQuery = PaginationSchema.extend({
  category: z.string().optional(),
  identity: z.string().optional(),
});
