import { z } from 'zod';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserCreateWithoutPodcastsCuratedInputObjectSchema } from './UserCreateWithoutPodcastsCuratedInput.schema';
import { UserUncheckedCreateWithoutPodcastsCuratedInputObjectSchema } from './UserUncheckedCreateWithoutPodcastsCuratedInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserCreateOrConnectWithoutPodcastsCuratedInput> =
  z
    .object({
      where: z.lazy(() => UserWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => UserCreateWithoutPodcastsCuratedInputObjectSchema),
        z.lazy(
          () => UserUncheckedCreateWithoutPodcastsCuratedInputObjectSchema
        ),
      ]),
    })
    .strict();

export const UserCreateOrConnectWithoutPodcastsCuratedInputObjectSchema =
  Schema;
