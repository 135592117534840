import { z } from 'zod';
import { SortOrderSchema } from '../enums/SortOrder.schema';
import { RssFeedCountOrderByAggregateInputObjectSchema } from './RssFeedCountOrderByAggregateInput.schema';
import { RssFeedAvgOrderByAggregateInputObjectSchema } from './RssFeedAvgOrderByAggregateInput.schema';
import { RssFeedMaxOrderByAggregateInputObjectSchema } from './RssFeedMaxOrderByAggregateInput.schema';
import { RssFeedMinOrderByAggregateInputObjectSchema } from './RssFeedMinOrderByAggregateInput.schema';
import { RssFeedSumOrderByAggregateInputObjectSchema } from './RssFeedSumOrderByAggregateInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.RssFeedOrderByWithAggregationInput> = z
  .object({
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    podcastId: z.lazy(() => SortOrderSchema).optional(),
    url: z.lazy(() => SortOrderSchema).optional(),
    data: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => RssFeedCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z.lazy(() => RssFeedAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => RssFeedMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => RssFeedMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => RssFeedSumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict();

export const RssFeedOrderByWithAggregationInputObjectSchema = Schema;
