import { z } from 'zod';
import { PodcastCreateWithoutTagsInputObjectSchema } from './PodcastCreateWithoutTagsInput.schema';
import { PodcastUncheckedCreateWithoutTagsInputObjectSchema } from './PodcastUncheckedCreateWithoutTagsInput.schema';
import { PodcastCreateOrConnectWithoutTagsInputObjectSchema } from './PodcastCreateOrConnectWithoutTagsInput.schema';
import { PodcastUpsertWithWhereUniqueWithoutTagsInputObjectSchema } from './PodcastUpsertWithWhereUniqueWithoutTagsInput.schema';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';
import { PodcastUpdateWithWhereUniqueWithoutTagsInputObjectSchema } from './PodcastUpdateWithWhereUniqueWithoutTagsInput.schema';
import { PodcastUpdateManyWithWhereWithoutTagsInputObjectSchema } from './PodcastUpdateManyWithWhereWithoutTagsInput.schema';
import { PodcastScalarWhereInputObjectSchema } from './PodcastScalarWhereInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastUpdateManyWithoutTagsNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => PodcastCreateWithoutTagsInputObjectSchema),
            z.lazy(() => PodcastCreateWithoutTagsInputObjectSchema).array(),
            z.lazy(() => PodcastUncheckedCreateWithoutTagsInputObjectSchema),
            z
              .lazy(() => PodcastUncheckedCreateWithoutTagsInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(() => PodcastCreateOrConnectWithoutTagsInputObjectSchema),
            z
              .lazy(() => PodcastCreateOrConnectWithoutTagsInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .union([
            z.lazy(
              () => PodcastUpsertWithWhereUniqueWithoutTagsInputObjectSchema
            ),
            z
              .lazy(
                () => PodcastUpsertWithWhereUniqueWithoutTagsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        set: z
          .union([
            z.lazy(() => PodcastWhereUniqueInputObjectSchema),
            z.lazy(() => PodcastWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        disconnect: z
          .union([
            z.lazy(() => PodcastWhereUniqueInputObjectSchema),
            z.lazy(() => PodcastWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        delete: z
          .union([
            z.lazy(() => PodcastWhereUniqueInputObjectSchema),
            z.lazy(() => PodcastWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => PodcastWhereUniqueInputObjectSchema),
            z.lazy(() => PodcastWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(
              () => PodcastUpdateWithWhereUniqueWithoutTagsInputObjectSchema
            ),
            z
              .lazy(
                () => PodcastUpdateWithWhereUniqueWithoutTagsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        updateMany: z
          .union([
            z.lazy(
              () => PodcastUpdateManyWithWhereWithoutTagsInputObjectSchema
            ),
            z
              .lazy(
                () => PodcastUpdateManyWithWhereWithoutTagsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        deleteMany: z
          .union([
            z.lazy(() => PodcastScalarWhereInputObjectSchema),
            z.lazy(() => PodcastScalarWhereInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const PodcastUpdateManyWithoutTagsNestedInputObjectSchema = Schema;
