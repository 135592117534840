import { z } from 'zod';
import { PlaylistCreateWithoutOwnersInputObjectSchema } from './PlaylistCreateWithoutOwnersInput.schema';
import { PlaylistUncheckedCreateWithoutOwnersInputObjectSchema } from './PlaylistUncheckedCreateWithoutOwnersInput.schema';
import { PlaylistCreateOrConnectWithoutOwnersInputObjectSchema } from './PlaylistCreateOrConnectWithoutOwnersInput.schema';
import { PlaylistUpsertWithWhereUniqueWithoutOwnersInputObjectSchema } from './PlaylistUpsertWithWhereUniqueWithoutOwnersInput.schema';
import { PlaylistWhereUniqueInputObjectSchema } from './PlaylistWhereUniqueInput.schema';
import { PlaylistUpdateWithWhereUniqueWithoutOwnersInputObjectSchema } from './PlaylistUpdateWithWhereUniqueWithoutOwnersInput.schema';
import { PlaylistUpdateManyWithWhereWithoutOwnersInputObjectSchema } from './PlaylistUpdateManyWithWhereWithoutOwnersInput.schema';
import { PlaylistScalarWhereInputObjectSchema } from './PlaylistScalarWhereInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistUncheckedUpdateManyWithoutOwnersNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => PlaylistCreateWithoutOwnersInputObjectSchema),
            z.lazy(() => PlaylistCreateWithoutOwnersInputObjectSchema).array(),
            z.lazy(() => PlaylistUncheckedCreateWithoutOwnersInputObjectSchema),
            z
              .lazy(() => PlaylistUncheckedCreateWithoutOwnersInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(() => PlaylistCreateOrConnectWithoutOwnersInputObjectSchema),
            z
              .lazy(() => PlaylistCreateOrConnectWithoutOwnersInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .union([
            z.lazy(
              () => PlaylistUpsertWithWhereUniqueWithoutOwnersInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  PlaylistUpsertWithWhereUniqueWithoutOwnersInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        set: z
          .union([
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema),
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        disconnect: z
          .union([
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema),
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        delete: z
          .union([
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema),
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema),
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(
              () => PlaylistUpdateWithWhereUniqueWithoutOwnersInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  PlaylistUpdateWithWhereUniqueWithoutOwnersInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        updateMany: z
          .union([
            z.lazy(
              () => PlaylistUpdateManyWithWhereWithoutOwnersInputObjectSchema
            ),
            z
              .lazy(
                () => PlaylistUpdateManyWithWhereWithoutOwnersInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        deleteMany: z
          .union([
            z.lazy(() => PlaylistScalarWhereInputObjectSchema),
            z.lazy(() => PlaylistScalarWhereInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const PlaylistUncheckedUpdateManyWithoutOwnersNestedInputObjectSchema =
  Schema;
