import { z } from 'zod';
import { CurationWhereUniqueInputObjectSchema } from './CurationWhereUniqueInput.schema';
import { CurationCreateWithoutPodcastInputObjectSchema } from './CurationCreateWithoutPodcastInput.schema';
import { CurationUncheckedCreateWithoutPodcastInputObjectSchema } from './CurationUncheckedCreateWithoutPodcastInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.CurationCreateOrConnectWithoutPodcastInput> = z
  .object({
    where: z.lazy(() => CurationWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => CurationCreateWithoutPodcastInputObjectSchema),
      z.lazy(() => CurationUncheckedCreateWithoutPodcastInputObjectSchema),
    ]),
  })
  .strict();

export const CurationCreateOrConnectWithoutPodcastInputObjectSchema = Schema;
