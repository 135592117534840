import { z } from 'zod';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserUpdateWithoutPodcastSubscriptionsInputObjectSchema } from './UserUpdateWithoutPodcastSubscriptionsInput.schema';
import { UserUncheckedUpdateWithoutPodcastSubscriptionsInputObjectSchema } from './UserUncheckedUpdateWithoutPodcastSubscriptionsInput.schema';
import { UserCreateWithoutPodcastSubscriptionsInputObjectSchema } from './UserCreateWithoutPodcastSubscriptionsInput.schema';
import { UserUncheckedCreateWithoutPodcastSubscriptionsInputObjectSchema } from './UserUncheckedCreateWithoutPodcastSubscriptionsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUpsertWithWhereUniqueWithoutPodcastSubscriptionsInput> =
  z
    .object({
      where: z.lazy(() => UserWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => UserUpdateWithoutPodcastSubscriptionsInputObjectSchema),
        z.lazy(
          () => UserUncheckedUpdateWithoutPodcastSubscriptionsInputObjectSchema
        ),
      ]),
      create: z.union([
        z.lazy(() => UserCreateWithoutPodcastSubscriptionsInputObjectSchema),
        z.lazy(
          () => UserUncheckedCreateWithoutPodcastSubscriptionsInputObjectSchema
        ),
      ]),
    })
    .strict();

export const UserUpsertWithWhereUniqueWithoutPodcastSubscriptionsInputObjectSchema =
  Schema;
