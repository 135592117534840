import { z } from 'zod';
import { TagWhereUniqueInputObjectSchema } from './TagWhereUniqueInput.schema';
import { TagUpdateWithoutPodcastsInputObjectSchema } from './TagUpdateWithoutPodcastsInput.schema';
import { TagUncheckedUpdateWithoutPodcastsInputObjectSchema } from './TagUncheckedUpdateWithoutPodcastsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.TagUpdateWithWhereUniqueWithoutPodcastsInput> = z
  .object({
    where: z.lazy(() => TagWhereUniqueInputObjectSchema),
    data: z.union([
      z.lazy(() => TagUpdateWithoutPodcastsInputObjectSchema),
      z.lazy(() => TagUncheckedUpdateWithoutPodcastsInputObjectSchema),
    ]),
  })
  .strict();

export const TagUpdateWithWhereUniqueWithoutPodcastsInputObjectSchema = Schema;
