import { z } from 'zod';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';
import { PodcastUpdateWithoutTagsInputObjectSchema } from './PodcastUpdateWithoutTagsInput.schema';
import { PodcastUncheckedUpdateWithoutTagsInputObjectSchema } from './PodcastUncheckedUpdateWithoutTagsInput.schema';
import { PodcastCreateWithoutTagsInputObjectSchema } from './PodcastCreateWithoutTagsInput.schema';
import { PodcastUncheckedCreateWithoutTagsInputObjectSchema } from './PodcastUncheckedCreateWithoutTagsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastUpsertWithWhereUniqueWithoutTagsInput> = z
  .object({
    where: z.lazy(() => PodcastWhereUniqueInputObjectSchema),
    update: z.union([
      z.lazy(() => PodcastUpdateWithoutTagsInputObjectSchema),
      z.lazy(() => PodcastUncheckedUpdateWithoutTagsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => PodcastCreateWithoutTagsInputObjectSchema),
      z.lazy(() => PodcastUncheckedCreateWithoutTagsInputObjectSchema),
    ]),
  })
  .strict();

export const PodcastUpsertWithWhereUniqueWithoutTagsInputObjectSchema = Schema;
