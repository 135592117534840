import { z } from 'zod';
import { PlaylistWhereUniqueInputObjectSchema } from './PlaylistWhereUniqueInput.schema';
import { PlaylistCreateWithoutSubscribersInputObjectSchema } from './PlaylistCreateWithoutSubscribersInput.schema';
import { PlaylistUncheckedCreateWithoutSubscribersInputObjectSchema } from './PlaylistUncheckedCreateWithoutSubscribersInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistCreateOrConnectWithoutSubscribersInput> =
  z
    .object({
      where: z.lazy(() => PlaylistWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => PlaylistCreateWithoutSubscribersInputObjectSchema),
        z.lazy(
          () => PlaylistUncheckedCreateWithoutSubscribersInputObjectSchema
        ),
      ]),
    })
    .strict();

export const PlaylistCreateOrConnectWithoutSubscribersInputObjectSchema =
  Schema;
