import {
  Box,
  Button,
  ButtonGroup,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';

import {
  AdvertisersSubmissionBody,
  MAX_MESSAGE_LENGTH,
} from '@theqube/schemas';
import { Form, FormField, PageHeading } from '../../../components';
import { environment, trpc } from '../../../utils';

const defaultValues: AdvertisersSubmissionBody = environment.isProduction
  ? {
      name: '',
      email: '',
      message: '',
      phone: '',
      website: '',
    }
  : {
      name: 'First Last',
      email: 'example@email.com',
      message:
        'Short description of message. This was made automatically. Please ignore if you are not the author.',
      phone: '1234567890',
      website: 'https://example.com',
    };

export function AdvertisersForm() {
  const toast = useToast();
  const {
    mutateAsync: submitInquiry,
    reset: resetMutation,
    isLoading,
    isSuccess,
    error,
  } = trpc.support.advertisers.useMutation();

  const context = useForm<AdvertisersSubmissionBody>({
    mode: 'onChange',
    resolver: zodResolver(AdvertisersSubmissionBody),
    defaultValues,
  });

  const {
    watch,
    handleSubmit,
    reset: resetForm,
    formState: { isDirty, isValid },
  } = context;

  const messageRemaining = MAX_MESSAGE_LENGTH - watch('message').length;

  const reset = () => {
    resetForm();
    resetMutation();
  };

  const onValidFormSubmit: SubmitHandler<AdvertisersSubmissionBody> = (
    data
  ) => {
    submitInquiry(data)
      .then((response) =>
        toast({
          title: 'Form Submitted Successfully.',
          description: response.message,
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      )
      .catch(() => null);
  };

  const onInvalidFormSubmit: SubmitErrorHandler<
    AdvertisersSubmissionBody
  > = () => {
    toast({
      status: 'error',
      title: 'Form Submission Failed.',
      description: 'Please check the form for errors and try again.',
      duration: 4000,
      isClosable: true,
    });
  };

  return (
    <Stack
      w={{ base: '90%', md: '60%' }}
      mx="auto"
      border="1px solid"
      borderColor="gold.500"
      px="10%"
      py="12"
    >
      {isSuccess ? (
        <Box my={5}>
          <PageHeading fontSize="xl">Thank You For Contacting Us. </PageHeading>
          <Text> Someone from our team will contact you shortly.</Text>

          <ButtonGroup my="5">
            <Button onClick={reset} variant="outline">
              Submit more inquiries
            </Button>
          </ButtonGroup>
        </Box>
      ) : (
        <>
          <PageHeading fontSize="xl">Advertise With Us.</PageHeading>
          <Form
            context={context}
            onSubmit={handleSubmit(onValidFormSubmit, onInvalidFormSubmit)}
          >
            <FormField<AdvertisersSubmissionBody, 'name'>
              name="name"
              label="Name"
              containerStyle={{ my: 5, isRequired: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  placeholder="What is your name?"
                />
              )}
            />

            <FormField<AdvertisersSubmissionBody, 'email'>
              name="email"
              label="Email"
              containerStyle={{ my: 5, isRequired: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="email"
                  placeholder="What is your email?"
                />
              )}
            />

            <FormField<AdvertisersSubmissionBody, 'phone'>
              name="phone"
              label="Phone"
              containerStyle={{ my: 5, isRequired: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="tel"
                  placeholder="What is your phone number?"
                />
              )}
            />

            <FormField<AdvertisersSubmissionBody, 'website'>
              name="website"
              label="Company Website"
              containerStyle={{ my: 5, isRequired: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="url"
                  placeholder="What is your website?"
                />
              )}
            />

            <FormField<AdvertisersSubmissionBody, 'message'>
              name="message"
              label="Message"
              containerStyle={{ my: 5, isRequired: true }}
              helperText={`Remaining: ${messageRemaining}`}
              render={({ field }) => (
                <Textarea
                  {...field}
                  placeholder="Enter message"
                  maxLength={MAX_MESSAGE_LENGTH}
                />
              )}
            />

            <ButtonGroup display="flex" justifyContent="flex-end" mt={10}>
              <Button
                type="button"
                disabled={isLoading}
                isLoading={isLoading}
                onClick={() => resetForm()}
                colorScheme="yellow"
                variant="ghost"
              >
                Reset
              </Button>

              {error ? (
                <Button
                  type="button"
                  disabled={isLoading}
                  onClick={resetMutation}
                  colorScheme="red"
                >
                  Try Again
                </Button>
              ) : (
                <Button
                  type="submit"
                  disabled={!isDirty || !isValid || isLoading || Boolean(error)}
                  isLoading={isLoading}
                  loadingText="Submitting..."
                >
                  Submit
                </Button>
              )}
            </ButtonGroup>
          </Form>
        </>
      )}
    </Stack>
  );
}
