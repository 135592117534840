import * as z from "zod"

export const AdvertisementSchema = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  header: z.string().nullish(),
  title: z.string(),
  description: z.string().nullish(),
  url: z.string().nullish(),
  order: z.number().int(),
})
