import * as React from 'react';

import { Flex, Spinner, Stack, Text } from '@chakra-ui/react';
import { QubeElementHero, RainbowWaveForm } from '../../../../components';

import { OriginalShowPlayer } from './player';
import { OriginalShowQuote } from './quote';
import { OriginalShowTeam } from './team';

type OriginalShowDataProps = {
  data: Array<{
    id: number;
    originalTitle: string;
    sub_heading: string;
    image: string;
    quote: string;
    quote_author: string;
    category: string;
    description: Array<string>;
    team: Array<{
      image: string;
      name: string;
      title: string;
      bio: string;
      socials: Array<{ [key: string]: string }>;
    }>;
  }>;
};

type OriginalShowIdProps = {
  id?: string;
};

type OriginalShowProps = OriginalShowDataProps & OriginalShowIdProps;

export const OriginalShow = ({
  id = '1',
  data,
}: OriginalShowProps): React.ReactElement => {
  const [shows] = React.useState(data);
  const [original, setOriginal] = React.useState({
    title: '',
    image: '',
    sub_heading: '',
    quote: '',
    quote_author: '',
    category: '',
    description: [''],
    team: [{ image: '', name: '', title: '', bio: '', socials: [{}] }],
  });
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const newShow = shows.find((show) => show.id === parseInt(id));
    if (newShow) {
      setOriginal({
        title: newShow.originalTitle,
        image: newShow.image,
        sub_heading: newShow.sub_heading,
        quote: newShow.quote,
        quote_author: newShow.quote_author,
        category: newShow.category,
        description: newShow.description,
        team: newShow.team,
      });

      setIsLoading(!isLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, shows]);

  return (
    <>
      {isLoading && (
        <Flex as="section" justify="center" align="center">
          <Spinner size="xl" color="gold.500" m={'auto'} />
        </Flex>
      )}
      {!isLoading && (
        <Flex as="section">
          {!isLoading && (
            <Stack>
              <QubeElementHero
                heading={original?.title}
                description={original?.sub_heading}
              >
                <Text fontSize="sm" color="#707070">
                  Exclusively on The Qube
                </Text>
              </QubeElementHero>
              <RainbowWaveForm />
              {original.quote.length > 0 && (
                <OriginalShowQuote
                  quote={original.quote}
                  quote_author={original.quote_author}
                />
              )}
              <OriginalShowPlayer
                title={original.title}
                description={original.description}
                category={original.category}
              />
              <OriginalShowTeam team={original.team} />
            </Stack>
          )}
        </Flex>
      )}
    </>
  );
};
