import { z } from 'zod';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserUpdateWithoutPlaylistSubscriptionsInputObjectSchema } from './UserUpdateWithoutPlaylistSubscriptionsInput.schema';
import { UserUncheckedUpdateWithoutPlaylistSubscriptionsInputObjectSchema } from './UserUncheckedUpdateWithoutPlaylistSubscriptionsInput.schema';
import { UserCreateWithoutPlaylistSubscriptionsInputObjectSchema } from './UserCreateWithoutPlaylistSubscriptionsInput.schema';
import { UserUncheckedCreateWithoutPlaylistSubscriptionsInputObjectSchema } from './UserUncheckedCreateWithoutPlaylistSubscriptionsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUpsertWithWhereUniqueWithoutPlaylistSubscriptionsInput> =
  z
    .object({
      where: z.lazy(() => UserWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => UserUpdateWithoutPlaylistSubscriptionsInputObjectSchema),
        z.lazy(
          () => UserUncheckedUpdateWithoutPlaylistSubscriptionsInputObjectSchema
        ),
      ]),
      create: z.union([
        z.lazy(() => UserCreateWithoutPlaylistSubscriptionsInputObjectSchema),
        z.lazy(
          () => UserUncheckedCreateWithoutPlaylistSubscriptionsInputObjectSchema
        ),
      ]),
    })
    .strict();

export const UserUpsertWithWhereUniqueWithoutPlaylistSubscriptionsInputObjectSchema =
  Schema;
