import { z } from 'zod';
import { PlaylistWhereUniqueInputObjectSchema } from './PlaylistWhereUniqueInput.schema';
import { PlaylistUpdateWithoutSubscribersInputObjectSchema } from './PlaylistUpdateWithoutSubscribersInput.schema';
import { PlaylistUncheckedUpdateWithoutSubscribersInputObjectSchema } from './PlaylistUncheckedUpdateWithoutSubscribersInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistUpdateWithWhereUniqueWithoutSubscribersInput> =
  z
    .object({
      where: z.lazy(() => PlaylistWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => PlaylistUpdateWithoutSubscribersInputObjectSchema),
        z.lazy(
          () => PlaylistUncheckedUpdateWithoutSubscribersInputObjectSchema
        ),
      ]),
    })
    .strict();

export const PlaylistUpdateWithWhereUniqueWithoutSubscribersInputObjectSchema =
  Schema;
