import { z } from 'zod';
import { EpisodeWhereUniqueInputObjectSchema } from './EpisodeWhereUniqueInput.schema';
import { EpisodeUpdateWithoutPlaylistsInputObjectSchema } from './EpisodeUpdateWithoutPlaylistsInput.schema';
import { EpisodeUncheckedUpdateWithoutPlaylistsInputObjectSchema } from './EpisodeUncheckedUpdateWithoutPlaylistsInput.schema';
import { EpisodeCreateWithoutPlaylistsInputObjectSchema } from './EpisodeCreateWithoutPlaylistsInput.schema';
import { EpisodeUncheckedCreateWithoutPlaylistsInputObjectSchema } from './EpisodeUncheckedCreateWithoutPlaylistsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.EpisodeUpsertWithWhereUniqueWithoutPlaylistsInput> =
  z
    .object({
      where: z.lazy(() => EpisodeWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => EpisodeUpdateWithoutPlaylistsInputObjectSchema),
        z.lazy(() => EpisodeUncheckedUpdateWithoutPlaylistsInputObjectSchema),
      ]),
      create: z.union([
        z.lazy(() => EpisodeCreateWithoutPlaylistsInputObjectSchema),
        z.lazy(() => EpisodeUncheckedCreateWithoutPlaylistsInputObjectSchema),
      ]),
    })
    .strict();

export const EpisodeUpsertWithWhereUniqueWithoutPlaylistsInputObjectSchema =
  Schema;
