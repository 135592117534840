import * as React from 'react';

import { Image, ImageProps } from './image';

export function Logo(props: Omit<ImageProps, 'src'>) {
  return (
    <Image
      mx="auto"
      src="/images/qube-element-hero/qube_element_logo.png"
      alt="The Qube Logo Element"
      placeholder={undefined}
      {...props}
    />
  );
}

Logo.defaultProps = { width: '153px', height: '200px' };
