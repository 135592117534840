import { z } from 'zod';
import { PlaylistWhereUniqueInputObjectSchema } from './PlaylistWhereUniqueInput.schema';
import { PlaylistUpdateWithoutOwnersInputObjectSchema } from './PlaylistUpdateWithoutOwnersInput.schema';
import { PlaylistUncheckedUpdateWithoutOwnersInputObjectSchema } from './PlaylistUncheckedUpdateWithoutOwnersInput.schema';
import { PlaylistCreateWithoutOwnersInputObjectSchema } from './PlaylistCreateWithoutOwnersInput.schema';
import { PlaylistUncheckedCreateWithoutOwnersInputObjectSchema } from './PlaylistUncheckedCreateWithoutOwnersInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistUpsertWithWhereUniqueWithoutOwnersInput> =
  z
    .object({
      where: z.lazy(() => PlaylistWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => PlaylistUpdateWithoutOwnersInputObjectSchema),
        z.lazy(() => PlaylistUncheckedUpdateWithoutOwnersInputObjectSchema),
      ]),
      create: z.union([
        z.lazy(() => PlaylistCreateWithoutOwnersInputObjectSchema),
        z.lazy(() => PlaylistUncheckedCreateWithoutOwnersInputObjectSchema),
      ]),
    })
    .strict();

export const PlaylistUpsertWithWhereUniqueWithoutOwnersInputObjectSchema =
  Schema;
