import { z } from 'zod';
import { TokenWhereUniqueInputObjectSchema } from './objects/TokenWhereUniqueInput.schema';
import { TokenCreateInputObjectSchema } from './objects/TokenCreateInput.schema';
import { TokenUpdateInputObjectSchema } from './objects/TokenUpdateInput.schema';

export const TokenUpsertSchema = z.object({
  where: TokenWhereUniqueInputObjectSchema,
  create: TokenCreateInputObjectSchema,
  update: TokenUpdateInputObjectSchema,
});
