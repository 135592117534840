import { Box, Button, ButtonGroup, Flex, Stack, Text } from '@chakra-ui/react';

import { LoginInput } from '@theqube/schemas';
import {
  Checkbox,
  EmailInput,
  Form,
  Link,
  LocalImage,
  PasswordInput,
} from '../../../components';
import { useSession } from '../../../features';
import { environment, StudioRoutes, useZodForm } from '../../../utils';
import { GoogleButton } from '../google-button';

type FormData = LoginInput & { rememberMe: boolean };

const defaultValues: FormData = {
  email: environment.isProduction ? '' : 'example@email.com',
  password: environment.isProduction ? '' : 'password',
  rememberMe: false,
};

export function LoginForm() {
  const [_, { login }] = useSession();

  const form = useZodForm<typeof LoginInput>({
    schema: LoginInput,
    // hack to get arround recursive types
    defaultValues: { ...defaultValues },
  });

  const handleFormSubmit = form.handleSubmit(async (formData, e) => {
    e?.preventDefault();
    return login(formData).catch(() => null);
  });

  return (
    <Box maxW="lg" mx="auto" p="8">
      <Stack>
        <Flex width="full" alignItems="center" justifyContent="center">
          <LocalImage
            priority
            src="/images/logo--full.png"
            alt="Qube Logo"
            width="285"
            height="165"
          />
        </Flex>

        <Text my="12" textAlign="center">
          Or continue with{' '}
        </Text>

        <GoogleButton />
      </Stack>

      <Form context={form} onSubmit={handleFormSubmit}>
        <EmailInput />
        <PasswordInput />

        <Link
          href={StudioRoutes['auth'].children?.['password-reset'].href ?? '#'}
          my="8"
        >
          Forgot Password?
        </Link>

        <Stack>
          <ButtonGroup display="flex" justifyContent="center" my="4">
            <Button
              onClick={handleFormSubmit}
              isLoading={form.formState.isSubmitting}
              disabled={form.formState.isSubmitting}
              bg={!form.formState.isValid ? 'error' : 'themeGold.500'}
            >
              Sign In
            </Button>
          </ButtonGroup>

          <Checkbox name="remember" label="Remember me" />
        </Stack>
      </Form>

      <Stack spacing={12} mt="6">
        <Text textAlign="center" fontWeight="bold">
          Don&apos;t have an account yet?
        </Text>

        <Button
          as={Link}
          href={StudioRoutes['auth'].children?.register.href ?? '#'}
          bg="transparent"
          border="4px solid"
          mx="auto"
        >
          Create an account
        </Button>
      </Stack>

      <Text textAlign="center" mt="4">
        <Text as="span" fontWeight="bold">
          Disclaimer:{' '}
        </Text>
        Please note that this software is still in the beta testing phase and
        may contain bugs or other issues. If you encounter any problems, please
        reach out to the development team at{' '}
        <a href="mailto:dev@e3radio.fm" target="_blank" rel="noreferrer">
          dev@e3radio.fm{' '}
        </a>
        for assistance.
      </Text>
    </Box>
  );
}
