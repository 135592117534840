import { z } from 'zod';
import { StringFilterObjectSchema } from './StringFilter.schema';
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema';
import { DateTimeNullableFilterObjectSchema } from './DateTimeNullableFilter.schema';
import { AccountListRelationFilterObjectSchema } from './AccountListRelationFilter.schema';
import { SessionListRelationFilterObjectSchema } from './SessionListRelationFilter.schema';
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema';
import { TagListRelationFilterObjectSchema } from './TagListRelationFilter.schema';
import { SettingsRelationFilterObjectSchema } from './SettingsRelationFilter.schema';
import { SettingsWhereInputObjectSchema } from './SettingsWhereInput.schema';
import { PermissionsRelationFilterObjectSchema } from './PermissionsRelationFilter.schema';
import { PermissionsWhereInputObjectSchema } from './PermissionsWhereInput.schema';
import { PlaylistListRelationFilterObjectSchema } from './PlaylistListRelationFilter.schema';
import { PodcastListRelationFilterObjectSchema } from './PodcastListRelationFilter.schema';
import { CurationListRelationFilterObjectSchema } from './CurationListRelationFilter.schema';
import { JsonNullableFilterObjectSchema } from './JsonNullableFilter.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => UserWhereInputObjectSchema),
        z.lazy(() => UserWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => UserWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => UserWhereInputObjectSchema),
        z.lazy(() => UserWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    avatarUrl: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    displayName: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    fullName: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    email: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    emailVerified: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    passwordHash: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    accounts: z.lazy(() => AccountListRelationFilterObjectSchema).optional(),
    sessions: z.lazy(() => SessionListRelationFilterObjectSchema).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    tags: z.lazy(() => TagListRelationFilterObjectSchema).optional(),
    settings: z
      .union([
        z.lazy(() => SettingsRelationFilterObjectSchema),
        z.lazy(() => SettingsWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
    permissions: z
      .union([
        z.lazy(() => PermissionsRelationFilterObjectSchema),
        z.lazy(() => PermissionsWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
    playlists: z.lazy(() => PlaylistListRelationFilterObjectSchema).optional(),
    playlistSubscriptions: z
      .lazy(() => PlaylistListRelationFilterObjectSchema)
      .optional(),
    podcastsAssigned: z
      .lazy(() => PodcastListRelationFilterObjectSchema)
      .optional(),
    podcastsCurated: z
      .lazy(() => CurationListRelationFilterObjectSchema)
      .optional(),
    podcasts: z.lazy(() => PodcastListRelationFilterObjectSchema).optional(),
    podcastSubscriptions: z
      .lazy(() => PodcastListRelationFilterObjectSchema)
      .optional(),
    biography: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    birthday: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    gender: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    socialMedia: z.lazy(() => JsonNullableFilterObjectSchema).optional(),
  })
  .strict();

export const UserWhereInputObjectSchema = Schema;
