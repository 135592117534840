import { z } from 'zod';
import { TagWhereUniqueInputObjectSchema } from './TagWhereUniqueInput.schema';
import { TagUpdateWithoutUsersInputObjectSchema } from './TagUpdateWithoutUsersInput.schema';
import { TagUncheckedUpdateWithoutUsersInputObjectSchema } from './TagUncheckedUpdateWithoutUsersInput.schema';
import { TagCreateWithoutUsersInputObjectSchema } from './TagCreateWithoutUsersInput.schema';
import { TagUncheckedCreateWithoutUsersInputObjectSchema } from './TagUncheckedCreateWithoutUsersInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.TagUpsertWithWhereUniqueWithoutUsersInput> = z
  .object({
    where: z.lazy(() => TagWhereUniqueInputObjectSchema),
    update: z.union([
      z.lazy(() => TagUpdateWithoutUsersInputObjectSchema),
      z.lazy(() => TagUncheckedUpdateWithoutUsersInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => TagCreateWithoutUsersInputObjectSchema),
      z.lazy(() => TagUncheckedCreateWithoutUsersInputObjectSchema),
    ]),
  })
  .strict();

export const TagUpsertWithWhereUniqueWithoutUsersInputObjectSchema = Schema;
