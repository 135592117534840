export const submissionData = [
  {
    image_url: '/images/podcasters/podcasters-cta-megaphone.png',
    title: 'Introduction',
    description:
      'The intro should be hella memorable and well produced. It should provide relevant information and establish a clear purpose engaging the listener immediately.',
  },
  {
    image_url: '/images/podcasters/podcasters-cta-content.png',
    title: 'Content',
    description:
      'The content should be original, accurate, and presented in a creative way that keeps your listeners engaged.',
  },
  {
    image_url: '/images/podcasters/podcasters-cta-delivery.png',
    title: 'Delivery',
    description:
      'The hosts should bring the energy throughout the show. Listeners should be able to feel it through their headphones.',
  },
  {
    image_url: '/images/podcasters/podcasters-cta-microphone.png',
    title: 'Production Quality',
    description:
      'The hosts should bring the energy throughout the show. Listeners should be able to feel it through their headphones.',
  },
  {
    image_url: '/images/podcasters/podcasters-cta-music.png',
    title: 'Music',
    description:
      'The music enhances the mood, quality and understanding of the story or topic. It has smooth transitions without dead space.',
  },
  {
    image_url: '/images/podcasters/podcasters-cta-graphic-design.png',
    title: 'Graphic Design',
    description:
      'The podcast artwork enhances what is being said in the podcast and follows the rules for quality graphic design.',
  },
  {
    image_url: '/images/podcasters/podcasters-cta-graphic-design.png',
    title: 'Consistency',
    description: 'The podcast occurs as part of a regularly scheduled series.',
  },
];
