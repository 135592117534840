import { z } from 'zod';
import { CurationScalarWhereInputObjectSchema } from './CurationScalarWhereInput.schema';
import { CurationUpdateManyMutationInputObjectSchema } from './CurationUpdateManyMutationInput.schema';
import { CurationUncheckedUpdateManyWithoutCurationsInputObjectSchema } from './CurationUncheckedUpdateManyWithoutCurationsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.CurationUpdateManyWithWhereWithoutPodcastInput> =
  z
    .object({
      where: z.lazy(() => CurationScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => CurationUpdateManyMutationInputObjectSchema),
        z.lazy(
          () => CurationUncheckedUpdateManyWithoutCurationsInputObjectSchema
        ),
      ]),
    })
    .strict();

export const CurationUpdateManyWithWhereWithoutPodcastInputObjectSchema =
  Schema;
