import {
  GetObjectsResponse,
  MultipleQueriesQuery,
} from '@algolia/client-search';
import {
  AlgoliaIndices,
  EpisodeSearchIndex,
  IndexedSearchQuery,
  PodcastSearchIndex,
  SearchIndexEpisode,
  SearchIndexPodcast,
  UserSearchIndex,
} from './index';

/**
 * Function used to convert our {@link IndexedSearchQuery} to the
 *  current provider (algolia).
 *
 * * Should really only be used server-side.
 */

export function convertIndexedSearchQuery(
  input: Array<IndexedSearchQuery>
): Array<MultipleQueriesQuery> {
  return input.map((query) => {
    return {
      ...query,
      // configure global search queries below this line
    };
  });
}

export async function convertPodcastToSearchIndex(
  podcast: SearchIndexPodcast,
  existingUsers?: GetObjectsResponse<UserSearchIndex>,
  isProduction = false
): Promise<{
  [AlgoliaIndices.users]: Array<UserSearchIndex>;
  [AlgoliaIndices.podcasts]: [PodcastSearchIndex];
  [AlgoliaIndices.episodes]: Array<EpisodeSearchIndex>;
}> {
  const podcastIndex: PodcastSearchIndex = {
    objectID: String(podcast.id),
    isOriginal: podcast.isOriginal,
    title: podcast.title,
    description: podcast.description,
    websiteUrl: podcast.websiteUrl,
    avatarUrl: podcast.avatarUrl,

    tags: podcast.tags.map((tag) => ({ name: tag.name })),

    owners: podcast.owners?.map((owner) => ({
      id: owner.id,
      displayName: owner.displayName,
    })),
  };

  const episodesIndex: EpisodeSearchIndex[] =
    podcast.episodes
      .map((episode) => ({
        objectID: String(episode.id),
        title: episode.title,
        publishedAt: episode.publishedAt.toISOString(),
        description: episode.description,
        imageUrl: episode.imageUrl,
        audioUrl: episode.audioUrl,

        podcast: {
          objectID: String(podcast.id),
          title: podcast.title,
          description: podcast.description,
          websiteUrl: podcast.websiteUrl,
          avatarUrl: podcast.avatarUrl,
          owners: podcast.owners?.map((owner) => ({
            id: owner.id,
            displayName: owner.displayName,
          })),
        },
      }))
      .sort(
        (a, b) =>
          new Date(a.publishedAt).getTime() - new Date(b.publishedAt).getTime()
      ) ?? [];

  const usersIndex = podcast.owners?.map(
    (owner): UserSearchIndex | undefined => {
      const user = existingUsers?.results.find(
        (u) => u?.objectID === String(owner.id)
      );

      const existingPodcastOnUser = user?.podcasts?.find(
        (pod) => pod.objectID === String(podcast.id)
      );

      if (user) {
        if (existingPodcastOnUser) return;

        return {
          ...user,
          podcasts: [
            ...(user.podcasts ?? []),
            {
              objectID: String(podcast.id),
              title: podcast.title ?? 'Untitled',
              description: podcast.description ?? null,
              websiteUrl: podcast.websiteUrl ?? null,
              avatarUrl: podcast.avatarUrl ?? null,
            },
          ],
        };
      }

      return {
        objectID: String(owner.id),
        displayName: owner.displayName,
        biography: owner.biography,
        tags: owner.tags.map((tag) => ({ name: tag.name })),
        podcasts: null,
        avatarUrl: null,
      };
    }
  );

  // When not in production, we only want the last 20 or so episodes
  // to be indexed. This is to prevent the search index from getting
  // too large and slowing down the server/increasing in cost.
  return {
    [AlgoliaIndices.users]: usersIndex.filter(Boolean) as UserSearchIndex[],
    [AlgoliaIndices.podcasts]: [podcastIndex],
    [AlgoliaIndices.episodes]: isProduction
      ? episodesIndex
      : episodesIndex.slice(-20),
  };
}

export function episodeToSearchIndex(
  episode: SearchIndexEpisode
): EpisodeSearchIndex {
  return {
    objectID: String(episode.id),
    audioUrl: episode.audioUrl,
    description: episode.description,
    imageUrl: episode.imageUrl,
    title: episode.title,
    publishedAt: episode.publishedAt.toISOString(),
    podcast: {
      objectID: String(episode.podcast.id),
      avatarUrl: episode.podcast.avatarUrl,
      description: episode.podcast.description,
      title: episode.podcast.title,
      websiteUrl: episode.podcast.websiteUrl,
      owners: episode.podcast.owners.map((o) => ({
        id: o.id,
        displayName: o.displayName,
      })),
    },
  };
}
