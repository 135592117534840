import { z } from 'zod';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';
import { PodcastUpdateWithoutSubscribersInputObjectSchema } from './PodcastUpdateWithoutSubscribersInput.schema';
import { PodcastUncheckedUpdateWithoutSubscribersInputObjectSchema } from './PodcastUncheckedUpdateWithoutSubscribersInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastUpdateWithWhereUniqueWithoutSubscribersInput> =
  z
    .object({
      where: z.lazy(() => PodcastWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => PodcastUpdateWithoutSubscribersInputObjectSchema),
        z.lazy(() => PodcastUncheckedUpdateWithoutSubscribersInputObjectSchema),
      ]),
    })
    .strict();

export const PodcastUpdateWithWhereUniqueWithoutSubscribersInputObjectSchema =
  Schema;
