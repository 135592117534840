import { z } from 'zod';
import { EpisodeScalarWhereInputObjectSchema } from './EpisodeScalarWhereInput.schema';
import { EpisodeUpdateManyMutationInputObjectSchema } from './EpisodeUpdateManyMutationInput.schema';
import { EpisodeUncheckedUpdateManyWithoutEpisodesInputObjectSchema } from './EpisodeUncheckedUpdateManyWithoutEpisodesInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.EpisodeUpdateManyWithWhereWithoutPodcastInput> =
  z
    .object({
      where: z.lazy(() => EpisodeScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => EpisodeUpdateManyMutationInputObjectSchema),
        z.lazy(
          () => EpisodeUncheckedUpdateManyWithoutEpisodesInputObjectSchema
        ),
      ]),
    })
    .strict();

export const EpisodeUpdateManyWithWhereWithoutPodcastInputObjectSchema = Schema;
