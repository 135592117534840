import { z } from 'zod';
import { SortOrderSchema } from '../enums/SortOrder.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.CurationMinOrderByAggregateInput> = z
  .object({
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    podcastId: z.lazy(() => SortOrderSchema).optional(),
    curatorId: z.lazy(() => SortOrderSchema).optional(),
    contactName: z.lazy(() => SortOrderSchema).optional(),
    contactEmail: z.lazy(() => SortOrderSchema).optional(),
    wouldRecommend: z.lazy(() => SortOrderSchema).optional(),
    notes: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict();

export const CurationMinOrderByAggregateInputObjectSchema = Schema;
