import { z } from 'zod';
import { CurationCreateWithoutPodcastInputObjectSchema } from './CurationCreateWithoutPodcastInput.schema';
import { CurationUncheckedCreateWithoutPodcastInputObjectSchema } from './CurationUncheckedCreateWithoutPodcastInput.schema';
import { CurationCreateOrConnectWithoutPodcastInputObjectSchema } from './CurationCreateOrConnectWithoutPodcastInput.schema';
import { CurationUpsertWithWhereUniqueWithoutPodcastInputObjectSchema } from './CurationUpsertWithWhereUniqueWithoutPodcastInput.schema';
import { CurationCreateManyPodcastInputEnvelopeObjectSchema } from './CurationCreateManyPodcastInputEnvelope.schema';
import { CurationWhereUniqueInputObjectSchema } from './CurationWhereUniqueInput.schema';
import { CurationUpdateWithWhereUniqueWithoutPodcastInputObjectSchema } from './CurationUpdateWithWhereUniqueWithoutPodcastInput.schema';
import { CurationUpdateManyWithWhereWithoutPodcastInputObjectSchema } from './CurationUpdateManyWithWhereWithoutPodcastInput.schema';
import { CurationScalarWhereInputObjectSchema } from './CurationScalarWhereInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.CurationUpdateManyWithoutPodcastNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => CurationCreateWithoutPodcastInputObjectSchema),
            z.lazy(() => CurationCreateWithoutPodcastInputObjectSchema).array(),
            z.lazy(
              () => CurationUncheckedCreateWithoutPodcastInputObjectSchema
            ),
            z
              .lazy(
                () => CurationUncheckedCreateWithoutPodcastInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(
              () => CurationCreateOrConnectWithoutPodcastInputObjectSchema
            ),
            z
              .lazy(
                () => CurationCreateOrConnectWithoutPodcastInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .union([
            z.lazy(
              () => CurationUpsertWithWhereUniqueWithoutPodcastInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  CurationUpsertWithWhereUniqueWithoutPodcastInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        createMany: z
          .lazy(() => CurationCreateManyPodcastInputEnvelopeObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        set: z
          .union([
            z.lazy(() => CurationWhereUniqueInputObjectSchema),
            z.lazy(() => CurationWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        disconnect: z
          .union([
            z.lazy(() => CurationWhereUniqueInputObjectSchema),
            z.lazy(() => CurationWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        delete: z
          .union([
            z.lazy(() => CurationWhereUniqueInputObjectSchema),
            z.lazy(() => CurationWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => CurationWhereUniqueInputObjectSchema),
            z.lazy(() => CurationWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(
              () => CurationUpdateWithWhereUniqueWithoutPodcastInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  CurationUpdateWithWhereUniqueWithoutPodcastInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        updateMany: z
          .union([
            z.lazy(
              () => CurationUpdateManyWithWhereWithoutPodcastInputObjectSchema
            ),
            z
              .lazy(
                () => CurationUpdateManyWithWhereWithoutPodcastInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        deleteMany: z
          .union([
            z.lazy(() => CurationScalarWhereInputObjectSchema),
            z.lazy(() => CurationScalarWhereInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const CurationUpdateManyWithoutPodcastNestedInputObjectSchema = Schema;
