import { useEffect, useRef } from 'react';
import { environment } from './environment.next';

export function useAnalytics() {
  const { isProduction } = environment;
  const gtag = useRef<false | Gtag.Gtag>(false);

  useEffect(() => {
    if (!isProduction) return;
    if (typeof gtag.current !== 'function') {
      gtag.current =
        (typeof window !== 'undefined' &&
          'gtag' in window &&
          typeof window.gtag === 'function' &&
          window.gtag) ??
        undefined;
    }
  }, [isProduction]);

  return {
    gtag: gtag.current,
  };
}
