import { z } from 'zod';
import { SortOrderSchema } from '../enums/SortOrder.schema';
import { PodcastCountOrderByAggregateInputObjectSchema } from './PodcastCountOrderByAggregateInput.schema';
import { PodcastAvgOrderByAggregateInputObjectSchema } from './PodcastAvgOrderByAggregateInput.schema';
import { PodcastMaxOrderByAggregateInputObjectSchema } from './PodcastMaxOrderByAggregateInput.schema';
import { PodcastMinOrderByAggregateInputObjectSchema } from './PodcastMinOrderByAggregateInput.schema';
import { PodcastSumOrderByAggregateInputObjectSchema } from './PodcastSumOrderByAggregateInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    publishedAt: z.lazy(() => SortOrderSchema).optional(),
    importedAt: z.lazy(() => SortOrderSchema).optional(),
    status: z.lazy(() => SortOrderSchema).optional(),
    statusReason: z.lazy(() => SortOrderSchema).optional(),
    avatarUrl: z.lazy(() => SortOrderSchema).optional(),
    isOriginal: z.lazy(() => SortOrderSchema).optional(),
    isExplicit: z.lazy(() => SortOrderSchema).optional(),
    title: z.lazy(() => SortOrderSchema).optional(),
    description: z.lazy(() => SortOrderSchema).optional(),
    websiteUrl: z.lazy(() => SortOrderSchema).optional(),
    socialMedia: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => PodcastCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z.lazy(() => PodcastAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => PodcastMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => PodcastMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => PodcastSumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict();

export const PodcastOrderByWithAggregationInputObjectSchema = Schema;
