import { z } from 'zod';
import { TokenIdentifierTokenCompoundUniqueInputObjectSchema } from './TokenIdentifierTokenCompoundUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.TokenWhereUniqueInput> = z
  .object({
    token: z.string().optional(),
    identifier_token: z
      .lazy(() => TokenIdentifierTokenCompoundUniqueInputObjectSchema)
      .optional(),
  })
  .strict();

export const TokenWhereUniqueInputObjectSchema = Schema;
