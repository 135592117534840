import { z } from 'zod';
import { RssFeedWhereInputObjectSchema } from './objects/RssFeedWhereInput.schema';
import { RssFeedOrderByWithRelationInputObjectSchema } from './objects/RssFeedOrderByWithRelationInput.schema';
import { RssFeedWhereUniqueInputObjectSchema } from './objects/RssFeedWhereUniqueInput.schema';
import { RssFeedScalarFieldEnumSchema } from './enums/RssFeedScalarFieldEnum.schema';

export const RssFeedFindManySchema = z.object({
  where: RssFeedWhereInputObjectSchema.optional(),
  orderBy: z
    .union([
      RssFeedOrderByWithRelationInputObjectSchema,
      RssFeedOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  cursor: RssFeedWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(RssFeedScalarFieldEnumSchema).optional(),
});
