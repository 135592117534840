import { z } from 'zod';
import { PodcastCreateWithoutSubscribersInputObjectSchema } from './PodcastCreateWithoutSubscribersInput.schema';
import { PodcastUncheckedCreateWithoutSubscribersInputObjectSchema } from './PodcastUncheckedCreateWithoutSubscribersInput.schema';
import { PodcastCreateOrConnectWithoutSubscribersInputObjectSchema } from './PodcastCreateOrConnectWithoutSubscribersInput.schema';
import { PodcastUpsertWithWhereUniqueWithoutSubscribersInputObjectSchema } from './PodcastUpsertWithWhereUniqueWithoutSubscribersInput.schema';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';
import { PodcastUpdateWithWhereUniqueWithoutSubscribersInputObjectSchema } from './PodcastUpdateWithWhereUniqueWithoutSubscribersInput.schema';
import { PodcastUpdateManyWithWhereWithoutSubscribersInputObjectSchema } from './PodcastUpdateManyWithWhereWithoutSubscribersInput.schema';
import { PodcastScalarWhereInputObjectSchema } from './PodcastScalarWhereInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastUpdateManyWithoutSubscribersNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => PodcastCreateWithoutSubscribersInputObjectSchema),
            z
              .lazy(() => PodcastCreateWithoutSubscribersInputObjectSchema)
              .array(),
            z.lazy(
              () => PodcastUncheckedCreateWithoutSubscribersInputObjectSchema
            ),
            z
              .lazy(
                () => PodcastUncheckedCreateWithoutSubscribersInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(
              () => PodcastCreateOrConnectWithoutSubscribersInputObjectSchema
            ),
            z
              .lazy(
                () => PodcastCreateOrConnectWithoutSubscribersInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .union([
            z.lazy(
              () =>
                PodcastUpsertWithWhereUniqueWithoutSubscribersInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  PodcastUpsertWithWhereUniqueWithoutSubscribersInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        set: z
          .union([
            z.lazy(() => PodcastWhereUniqueInputObjectSchema),
            z.lazy(() => PodcastWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        disconnect: z
          .union([
            z.lazy(() => PodcastWhereUniqueInputObjectSchema),
            z.lazy(() => PodcastWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        delete: z
          .union([
            z.lazy(() => PodcastWhereUniqueInputObjectSchema),
            z.lazy(() => PodcastWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => PodcastWhereUniqueInputObjectSchema),
            z.lazy(() => PodcastWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(
              () =>
                PodcastUpdateWithWhereUniqueWithoutSubscribersInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  PodcastUpdateWithWhereUniqueWithoutSubscribersInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        updateMany: z
          .union([
            z.lazy(
              () =>
                PodcastUpdateManyWithWhereWithoutSubscribersInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  PodcastUpdateManyWithWhereWithoutSubscribersInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        deleteMany: z
          .union([
            z.lazy(() => PodcastScalarWhereInputObjectSchema),
            z.lazy(() => PodcastScalarWhereInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const PodcastUpdateManyWithoutSubscribersNestedInputObjectSchema =
  Schema;
