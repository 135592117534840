import { z } from 'zod';
import { UserCreateWithoutPlaylistsInputObjectSchema } from './UserCreateWithoutPlaylistsInput.schema';
import { UserUncheckedCreateWithoutPlaylistsInputObjectSchema } from './UserUncheckedCreateWithoutPlaylistsInput.schema';
import { UserCreateOrConnectWithoutPlaylistsInputObjectSchema } from './UserCreateOrConnectWithoutPlaylistsInput.schema';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUncheckedCreateNestedManyWithoutPlaylistsInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => UserCreateWithoutPlaylistsInputObjectSchema),
            z.lazy(() => UserCreateWithoutPlaylistsInputObjectSchema).array(),
            z.lazy(() => UserUncheckedCreateWithoutPlaylistsInputObjectSchema),
            z
              .lazy(() => UserUncheckedCreateWithoutPlaylistsInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(() => UserCreateOrConnectWithoutPlaylistsInputObjectSchema),
            z
              .lazy(() => UserCreateOrConnectWithoutPlaylistsInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const UserUncheckedCreateNestedManyWithoutPlaylistsInputObjectSchema =
  Schema;
