import { z } from 'zod';
import { SettingsCreateWithoutOwnerInputObjectSchema } from './SettingsCreateWithoutOwnerInput.schema';
import { SettingsUncheckedCreateWithoutOwnerInputObjectSchema } from './SettingsUncheckedCreateWithoutOwnerInput.schema';
import { SettingsCreateOrConnectWithoutOwnerInputObjectSchema } from './SettingsCreateOrConnectWithoutOwnerInput.schema';
import { SettingsUpsertWithoutOwnerInputObjectSchema } from './SettingsUpsertWithoutOwnerInput.schema';
import { SettingsWhereUniqueInputObjectSchema } from './SettingsWhereUniqueInput.schema';
import { SettingsUpdateWithoutOwnerInputObjectSchema } from './SettingsUpdateWithoutOwnerInput.schema';
import { SettingsUncheckedUpdateWithoutOwnerInputObjectSchema } from './SettingsUncheckedUpdateWithoutOwnerInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.SettingsUncheckedUpdateOneWithoutOwnerNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => SettingsCreateWithoutOwnerInputObjectSchema),
            z.lazy(() => SettingsUncheckedCreateWithoutOwnerInputObjectSchema),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .lazy(() => SettingsCreateOrConnectWithoutOwnerInputObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .lazy(() => SettingsUpsertWithoutOwnerInputObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        disconnect: z.boolean().optional(),
      })
      .strict(),
    z
      .object({
        delete: z.boolean().optional(),
      })
      .strict(),
    z
      .object({
        connect: z.lazy(() => SettingsWhereUniqueInputObjectSchema).optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(() => SettingsUpdateWithoutOwnerInputObjectSchema),
            z.lazy(() => SettingsUncheckedUpdateWithoutOwnerInputObjectSchema),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const SettingsUncheckedUpdateOneWithoutOwnerNestedInputObjectSchema =
  Schema;
