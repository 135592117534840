import * as z from "zod"
import { PodcastStatus } from "@prisma/client"
import { CompleteRssFeed, RelatedRssFeedSchema, CompleteTag, RelatedTagSchema, CompleteUser, RelatedUserSchema, CompleteEpisode, RelatedEpisodeSchema, CompleteCuration, RelatedCurationSchema } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const PodcastSchema = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  publishedAt: z.date().nullish(),
  importedAt: z.date().nullish(),
  status: z.nativeEnum(PodcastStatus),
  statusReason: z.string().nullish(),
  avatarUrl: z.string().nullish(),
  isOriginal: z.boolean(),
  isExplicit: z.boolean(),
  title: z.string(),
  description: z.string().nullish(),
  websiteUrl: z.string().nullish(),
  socialMedia: jsonSchema,
})

export interface CompletePodcast extends z.infer<typeof PodcastSchema> {
  rssFeed?: CompleteRssFeed | null
  tags: CompleteTag[]
  owners: CompleteUser[]
  episodes: CompleteEpisode[]
  subscribers: CompleteUser[]
  curators: CompleteUser[]
  curations: CompleteCuration[]
}

/**
 * RelatedPodcastSchema contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedPodcastSchema: z.ZodSchema<CompletePodcast> = z.lazy(() => PodcastSchema.extend({
  rssFeed: RelatedRssFeedSchema.nullish(),
  tags: RelatedTagSchema.array(),
  owners: RelatedUserSchema.array(),
  episodes: RelatedEpisodeSchema.array(),
  subscribers: RelatedUserSchema.array(),
  curators: RelatedUserSchema.array(),
  curations: RelatedCurationSchema.array(),
}))
