import { z } from 'zod';
import { AdvertisementWhereInputObjectSchema } from './objects/AdvertisementWhereInput.schema';
import { AdvertisementOrderByWithAggregationInputObjectSchema } from './objects/AdvertisementOrderByWithAggregationInput.schema';
import { AdvertisementScalarWhereWithAggregatesInputObjectSchema } from './objects/AdvertisementScalarWhereWithAggregatesInput.schema';
import { AdvertisementScalarFieldEnumSchema } from './enums/AdvertisementScalarFieldEnum.schema';

export const AdvertisementGroupBySchema = z.object({
  where: AdvertisementWhereInputObjectSchema.optional(),
  orderBy: z
    .union([
      AdvertisementOrderByWithAggregationInputObjectSchema,
      AdvertisementOrderByWithAggregationInputObjectSchema.array(),
    ])
    .optional(),
  having: AdvertisementScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(AdvertisementScalarFieldEnumSchema),
});
