import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { z } from 'zod';

import { Tag, TagFindManySchema } from '@theqube/schemas';
import { TRPCUseQueryBaseOptions } from '@trpc/react-query/shared';
import { Link, Image, ErrorWrapper } from '../../components';
import { StudioRoutes, trpc, usePagination } from '../../utils';

export type TagListProps = {
  query: z.infer<typeof TagFindManySchema>;
  options?: TRPCUseQueryBaseOptions;
};

export function TagList({ query, options }: TagListProps) {
  const [pagination, { move }] = usePagination();

  const {
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
    data: tags,
  } = trpc.tags.findMany.useQuery({ ...pagination, ...query }, options);

  if (isError) {
    return <ErrorWrapper error={error} retry={refetch} />;
  }

  return (
    <Skeleton isLoaded={isSuccess}>
      {tags && tags?.length > 0 ? (
        <>
          {tags.map((tag) => (
            <TagListItem key={tag.id} tag={tag} />
          ))}

          <ButtonGroup justifyContent="space-between" width="100%">
            <Button onClick={move} isDisabled={isLoading} isLoading={isLoading}>
              Show More
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <Text color="gray.400">There doesn&apos;t appear to be any tags.</Text>
      )}
    </Skeleton>
  );
}

type TagListItemProps = {
  tag: Partial<Tag>;
};

function TagListItem({
  tag: { id, name = 'Untitled', avatarUrl, publishedAt },
}: TagListItemProps) {
  return (
    <Box
      display="flex"
      alignItems="center"
      px="4"
      py="8"
      borderBottom="1px solid white"
      _hover={{
        textDecoration: 'none',
      }}
    >
      <Link
        href={
          StudioRoutes.discover.children?.tags.children?.id.href(String(id)) ??
          ''
        }
        flex="0 0 20%"
      >
        <Image src={avatarUrl ?? ''} rounded="md" />
      </Link>

      <Box ml="4">
        <Heading
          as={Link}
          href={
            StudioRoutes.discover.children?.tags.children?.id.href(
              String(id)
            ) ?? ''
          }
          size={'lg'}
        >
          {name}
        </Heading>

        <Text mt="4">
          <Text as="span">{publishedAt?.toLocaleDateString()}</Text> ·{' '}
        </Text>
      </Box>
    </Box>
  );
}
