import { z } from 'zod';
import { RssFeedCreateWithoutPodcastInputObjectSchema } from './RssFeedCreateWithoutPodcastInput.schema';
import { RssFeedUncheckedCreateWithoutPodcastInputObjectSchema } from './RssFeedUncheckedCreateWithoutPodcastInput.schema';
import { RssFeedCreateOrConnectWithoutPodcastInputObjectSchema } from './RssFeedCreateOrConnectWithoutPodcastInput.schema';
import { RssFeedWhereUniqueInputObjectSchema } from './RssFeedWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.RssFeedCreateNestedOneWithoutPodcastInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => RssFeedCreateWithoutPodcastInputObjectSchema),
            z.lazy(() => RssFeedUncheckedCreateWithoutPodcastInputObjectSchema),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .lazy(() => RssFeedCreateOrConnectWithoutPodcastInputObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z.lazy(() => RssFeedWhereUniqueInputObjectSchema).optional(),
      })
      .strict(),
  ]);

export const RssFeedCreateNestedOneWithoutPodcastInputObjectSchema = Schema;
