import { z } from 'zod';
import { RssFeedWhereInputObjectSchema } from './objects/RssFeedWhereInput.schema';
import { RssFeedOrderByWithAggregationInputObjectSchema } from './objects/RssFeedOrderByWithAggregationInput.schema';
import { RssFeedScalarWhereWithAggregatesInputObjectSchema } from './objects/RssFeedScalarWhereWithAggregatesInput.schema';
import { RssFeedScalarFieldEnumSchema } from './enums/RssFeedScalarFieldEnum.schema';

export const RssFeedGroupBySchema = z.object({
  where: RssFeedWhereInputObjectSchema.optional(),
  orderBy: z
    .union([
      RssFeedOrderByWithAggregationInputObjectSchema,
      RssFeedOrderByWithAggregationInputObjectSchema.array(),
    ])
    .optional(),
  having: RssFeedScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(RssFeedScalarFieldEnumSchema),
});
