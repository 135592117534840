import { z } from 'zod';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.SettingsCreateWithoutOwnerInput> = z
  .object({
    onboardedAt: z.date().optional().nullable(),
    acceptedTermsAt: z.date().optional().nullable(),
    acceptedPrivacyAt: z.date().optional().nullable(),
    acceptedNewsletterAt: z.date().optional().nullable(),
  })
  .strict();

export const SettingsCreateWithoutOwnerInputObjectSchema = Schema;
