import { z } from 'zod';
import { EpisodeWhereInputObjectSchema } from './objects/EpisodeWhereInput.schema';
import { EpisodeOrderByWithRelationInputObjectSchema } from './objects/EpisodeOrderByWithRelationInput.schema';
import { EpisodeWhereUniqueInputObjectSchema } from './objects/EpisodeWhereUniqueInput.schema';
import { EpisodeCountAggregateInputObjectSchema } from './objects/EpisodeCountAggregateInput.schema';
import { EpisodeMinAggregateInputObjectSchema } from './objects/EpisodeMinAggregateInput.schema';
import { EpisodeMaxAggregateInputObjectSchema } from './objects/EpisodeMaxAggregateInput.schema';
import { EpisodeAvgAggregateInputObjectSchema } from './objects/EpisodeAvgAggregateInput.schema';
import { EpisodeSumAggregateInputObjectSchema } from './objects/EpisodeSumAggregateInput.schema';

export const EpisodeAggregateSchema = z.object({
  where: EpisodeWhereInputObjectSchema.optional(),
  orderBy: z
    .union([
      EpisodeOrderByWithRelationInputObjectSchema,
      EpisodeOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  cursor: EpisodeWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), EpisodeCountAggregateInputObjectSchema])
    .optional(),
  _min: EpisodeMinAggregateInputObjectSchema.optional(),
  _max: EpisodeMaxAggregateInputObjectSchema.optional(),
  _avg: EpisodeAvgAggregateInputObjectSchema.optional(),
  _sum: EpisodeSumAggregateInputObjectSchema.optional(),
});
