import { Permissions, Tag, TagType } from '@prisma/client';

export type PermissionRule =
  | keyof Permissions // single key must be true
  | Record<keyof Permissions, Permissions[keyof Permissions]> // all keys must match given record
  | ((source: Permissions) => boolean); // custom verify function

/**
 * Verify a user's permission's table against the rules given
 */
export function verifyPermissions(
  rules: Array<PermissionRule>,
  source: Permissions
): { success: true } | { success: false } {
  const success = rules.every((rule) => {
    switch (typeof rule) {
      case 'function': {
        return rule(source);
      }

      case 'string': {
        return testKeyInSource(rule);
      }

      default: {
        const expectedKeys = Object.keys(rule) as Array<keyof Permissions>;

        return expectedKeys.every(testKeyInSource);
      }
    }

    function testKeyInSource(key: keyof Permissions) {
      return key in source && source[key] && Boolean(source[key]);
    }
  });

  return { success };
}

type ShouldUserOnboardInput = {
  tags: Pick<Tag, 'id' | 'type' | 'name'>[] | undefined;
};

/**
 * Used to verify whether a user needs to visit a specific preferences page
 */
export function shouldUserOnboard({
  tags,
}: ShouldUserOnboardInput): false | TagType {
  if (!Array.isArray(tags)) return false;

  const firstEmptyPreference = Object.values(TagType).find((value) => {
    if (value === TagType['network']) {
      return false;
    } else if (typeof tags === 'undefined') {
      return false;
    }

    const firstIndex = tags.findIndex((tag) => tag.type === value);

    return firstIndex < 0;
  });

  return firstEmptyPreference ?? false;
}
