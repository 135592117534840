import { forwardRef, memo } from 'react';
import { areEqual, ListChildComponentProps } from 'react-window';
import { z } from 'zod';

import { Flex } from '@chakra-ui/react';
import { EpisodeFindManySchema } from '@theqube/schemas';
import {
  ErrorWrapper,
  ListWindow,
  WindowLayout,
  WindowProps,
  WindowConfigProps,
  EmptyMessage,
  EmptyMessageProps,
} from '../../components';
import { trpc, usePagination } from '../../utils';
import { EpisodeCard } from './card';

export type EpisodeWindowProps<Layout extends WindowLayout = 'list'> = {
  header?: React.ReactNode;
  empty?: EmptyMessageProps;
  query: z.infer<typeof EpisodeFindManySchema>;
  config?: WindowConfigProps<Layout>;
};

const GUTTER_SIZE = 12,
  PADDING_SIZE = 12;

export function useEpisodeWindow({ query }: Pick<EpisodeWindowProps, 'query'>) {
  const [pagination, { move }] = usePagination(query);

  const queryResults = trpc.episodes.findMany.useQuery({
    orderBy: { publishedAt: 'desc' },
    ...pagination,
    ...query,
  });

  const isEmpty = Boolean((queryResults.data?.length || 0) <= 0);

  return {
    isEmpty,
    results: queryResults,
    pagination,
    move,
  };
}

export function EpisodeWindow({
  header,
  empty,
  query,
  config = {
    loader: undefined,
    resizer: { disableHeight: true },
    window: { height: 350, itemSize: 200, layout: 'horizontal' },
  },
}: EpisodeWindowProps) {
  const {
    results: { isLoading, data: episodes, error, refetch },
    isEmpty,
    pagination,
    move,
  } = useEpisodeWindow({ query });

  const windowProps = {
    ...config,
    loader: {
      loadMoreItems: () => {
        if (episodes?.length && pagination.take) {
          if (episodes.length <= pagination.take) return;
        }

        return move();
      },
      itemCount: episodes?.length ?? 0,
      isItemLoaded: () => !isLoading,
      ...config.loader,
    },
    window: {
      innerElementType: withGutterInnerElement,
      itemData: episodes,
      children: ListItem,
      itemCount: episodes?.length ?? 0,
      ...config.window,
    },
  } satisfies WindowProps<'list'>;

  if (error) {
    return <ErrorWrapper error={error} retry={refetch} />;
  } else if (isEmpty) {
    return <EmptyMessage {...empty} />;
  }

  return (
    <>
      {header ? (
        <Flex alignItems="center" justifyContent="space-between" px="5%">
          {header}
        </Flex>
      ) : null}

      <ListWindow {...windowProps} />
    </>
  );
}

const withGutterInnerElement = forwardRef<
  HTMLDivElement,
  ListChildComponentProps
>(({ style, ...props }, ref) => (
  <div
    ref={ref}
    style={{ ...style, paddingLeft: GUTTER_SIZE, paddingRight: GUTTER_SIZE }}
    {...props}
  />
));

const ListItem = memo<ListChildComponentProps>(({ index, style, data }) => {
  return (
    <div
      style={{
        ...style,
        // adds padding to top/bottom of list
        height: style.height
          ? `${Number(style.height) + PADDING_SIZE * 2}px`
          : style.height,

        // adds space between elements
        left: Number(style.left) + GUTTER_SIZE,
        top: Number(style.top) + GUTTER_SIZE,
        right: Number(style.right) + GUTTER_SIZE,
        bottom: Number(style.bottom) + GUTTER_SIZE,
      }}
    >
      {data ? (
        <EpisodeCard container={{ margin: 0, flex: 0 }} episode={data[index]} />
      ) : null}
    </div>
  );
}, areEqual);
