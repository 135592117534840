import { z } from 'zod';
import { PodcastWhereUniqueInputObjectSchema } from './objects/PodcastWhereUniqueInput.schema';
import { PodcastCreateInputObjectSchema } from './objects/PodcastCreateInput.schema';
import { PodcastUpdateInputObjectSchema } from './objects/PodcastUpdateInput.schema';

export const PodcastUpsertSchema = z.object({
  where: PodcastWhereUniqueInputObjectSchema,
  create: PodcastCreateInputObjectSchema,
  update: PodcastUpdateInputObjectSchema,
});
