import { BoxProps, Stack, Text } from '@chakra-ui/react';
import { z } from 'zod';

import { CompleteUser, RelatedPodcastSchema } from '@theqube/schemas';
import { Image, Link } from '../../components/atoms';
import { StudioRoutes } from '../../utils';

export type PodcastCardProps = {
  container?: BoxProps;
  podcast: Pick<
    z.infer<typeof RelatedPodcastSchema>,
    'id' | 'avatarUrl' | 'title'
  > &
    // Additional (optional) related properties
    Partial<{
      // Only what's required from the user.
      // Get too specific/too many keys and you risk losing type-safety.
      owners: Pick<CompleteUser, 'id' | 'displayName'>[];
    }>;
};

export function PodcastCard({
  container,
  podcast: { avatarUrl, id, title, owners },
}: PodcastCardProps) {
  return (
    <Stack key={id} {...container}>
      <Link
        href={
          StudioRoutes.discover.children?.podcasts.children?.id.href(
            String(id)
          ) ?? ''
        }
      >
        <Image
          src={avatarUrl ?? undefined}
          alt={`${title} cover art`}
          objectFit="contain"
          rounded="md"
        />

        <Text
          mt={3}
          fontWeight="bold"
          fontSize="md"
          maxWidth="90%"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {title}
        </Text>
      </Link>

      {owners?.map((owner) => (
        <Link
          href={
            StudioRoutes.discover.children?.users.children?.id.href(owner.id) ??
            ''
          }
        >
          {owner.displayName}
        </Link>
      ))}
    </Stack>
  );
}
