import { z } from 'zod';
import { CurationCreateWithoutPodcastInputObjectSchema } from './CurationCreateWithoutPodcastInput.schema';
import { CurationUncheckedCreateWithoutPodcastInputObjectSchema } from './CurationUncheckedCreateWithoutPodcastInput.schema';
import { CurationCreateOrConnectWithoutPodcastInputObjectSchema } from './CurationCreateOrConnectWithoutPodcastInput.schema';
import { CurationCreateManyPodcastInputEnvelopeObjectSchema } from './CurationCreateManyPodcastInputEnvelope.schema';
import { CurationWhereUniqueInputObjectSchema } from './CurationWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.CurationCreateNestedManyWithoutPodcastInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => CurationCreateWithoutPodcastInputObjectSchema),
            z.lazy(() => CurationCreateWithoutPodcastInputObjectSchema).array(),
            z.lazy(
              () => CurationUncheckedCreateWithoutPodcastInputObjectSchema
            ),
            z
              .lazy(
                () => CurationUncheckedCreateWithoutPodcastInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(
              () => CurationCreateOrConnectWithoutPodcastInputObjectSchema
            ),
            z
              .lazy(
                () => CurationCreateOrConnectWithoutPodcastInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        createMany: z
          .lazy(() => CurationCreateManyPodcastInputEnvelopeObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => CurationWhereUniqueInputObjectSchema),
            z.lazy(() => CurationWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const CurationCreateNestedManyWithoutPodcastInputObjectSchema = Schema;
