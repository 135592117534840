import { z } from 'zod';
import { TagWhereUniqueInputObjectSchema } from './TagWhereUniqueInput.schema';
import { TagCreateWithoutUsersInputObjectSchema } from './TagCreateWithoutUsersInput.schema';
import { TagUncheckedCreateWithoutUsersInputObjectSchema } from './TagUncheckedCreateWithoutUsersInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.TagCreateOrConnectWithoutUsersInput> = z
  .object({
    where: z.lazy(() => TagWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => TagCreateWithoutUsersInputObjectSchema),
      z.lazy(() => TagUncheckedCreateWithoutUsersInputObjectSchema),
    ]),
  })
  .strict();

export const TagCreateOrConnectWithoutUsersInputObjectSchema = Schema;
