import { z } from 'zod';
import { PlaylistCreateWithoutSubscribersInputObjectSchema } from './PlaylistCreateWithoutSubscribersInput.schema';
import { PlaylistUncheckedCreateWithoutSubscribersInputObjectSchema } from './PlaylistUncheckedCreateWithoutSubscribersInput.schema';
import { PlaylistCreateOrConnectWithoutSubscribersInputObjectSchema } from './PlaylistCreateOrConnectWithoutSubscribersInput.schema';
import { PlaylistUpsertWithWhereUniqueWithoutSubscribersInputObjectSchema } from './PlaylistUpsertWithWhereUniqueWithoutSubscribersInput.schema';
import { PlaylistWhereUniqueInputObjectSchema } from './PlaylistWhereUniqueInput.schema';
import { PlaylistUpdateWithWhereUniqueWithoutSubscribersInputObjectSchema } from './PlaylistUpdateWithWhereUniqueWithoutSubscribersInput.schema';
import { PlaylistUpdateManyWithWhereWithoutSubscribersInputObjectSchema } from './PlaylistUpdateManyWithWhereWithoutSubscribersInput.schema';
import { PlaylistScalarWhereInputObjectSchema } from './PlaylistScalarWhereInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistUncheckedUpdateManyWithoutSubscribersNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => PlaylistCreateWithoutSubscribersInputObjectSchema),
            z
              .lazy(() => PlaylistCreateWithoutSubscribersInputObjectSchema)
              .array(),
            z.lazy(
              () => PlaylistUncheckedCreateWithoutSubscribersInputObjectSchema
            ),
            z
              .lazy(
                () => PlaylistUncheckedCreateWithoutSubscribersInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(
              () => PlaylistCreateOrConnectWithoutSubscribersInputObjectSchema
            ),
            z
              .lazy(
                () => PlaylistCreateOrConnectWithoutSubscribersInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .union([
            z.lazy(
              () =>
                PlaylistUpsertWithWhereUniqueWithoutSubscribersInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  PlaylistUpsertWithWhereUniqueWithoutSubscribersInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        set: z
          .union([
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema),
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        disconnect: z
          .union([
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema),
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        delete: z
          .union([
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema),
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema),
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(
              () =>
                PlaylistUpdateWithWhereUniqueWithoutSubscribersInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  PlaylistUpdateWithWhereUniqueWithoutSubscribersInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        updateMany: z
          .union([
            z.lazy(
              () =>
                PlaylistUpdateManyWithWhereWithoutSubscribersInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  PlaylistUpdateManyWithWhereWithoutSubscribersInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        deleteMany: z
          .union([
            z.lazy(() => PlaylistScalarWhereInputObjectSchema),
            z.lazy(() => PlaylistScalarWhereInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const PlaylistUncheckedUpdateManyWithoutSubscribersNestedInputObjectSchema =
  Schema;
