import {
  BoxProps,
  Button,
  ButtonGroup,
  Flex,
  Skeleton,
} from '@chakra-ui/react';
import { z } from 'zod';

import { PodcastFindManySchema } from '@theqube/schemas';
import {
  ErrorWrapper,
  EmptyMessageProps,
  EmptyMessage,
} from '../../components';
import { trpc, usePagination } from '../../utils';
import { PodcastCard } from './card';

export type PodcastGridProps = {
  query: z.infer<typeof PodcastFindManySchema>;
  container?: BoxProps;
  empty?: EmptyMessageProps;
};

export function PodcastGrid({ query, container, empty }: PodcastGridProps) {
  const [pagination, { move }] = usePagination();

  const {
    data: podcasts,
    isLoading,
    error,
    refetch,
  } = trpc.podcasts.findMany.useQuery({
    orderBy: { publishedAt: 'desc' },
    ...pagination,
    ...query,
  });

  const isEmpty = Boolean((podcasts?.length || 0) <= 0);

  if (error) {
    return <ErrorWrapper error={error} retry={refetch} />;
  }

  if (isEmpty) {
    return <EmptyMessage {...empty} />;
  }

  return (
    <Skeleton as="section" isLoaded={!isLoading}>
      <Flex
        width="100%"
        maxWidth="container.xl"
        margin="0 auto"
        flexFlow="row wrap"
        align="flex-start"
        justify="center"
        {...container}
      >
        {podcasts?.map((podcast) => (
          <PodcastCard
            key={podcast.id}
            podcast={podcast}
            container={{
              flex: '1 0 30%',
              width: 'full',
              maxWidth: '250px',
              margin: 12,
            }}
          />
        ))}
      </Flex>

      <ButtonGroup
        width="100%"
        p={5}
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          m="auto"
          disabled={isEmpty}
          isLoading={isLoading}
          onClick={move}
        >
          Show More
        </Button>
      </ButtonGroup>
    </Skeleton>
  );
}
