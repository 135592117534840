import * as React from 'react';

import {
  chakra,
  ChakraProps,
  Flex,
  Image,
  List,
  ListItem,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { DropEvent, DropzoneOptions, useDropzone } from 'react-dropzone';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  Path,
  UnPackAsyncDefaultValues,
  UseFormStateReturn,
} from 'react-hook-form';
import { BiCloudUpload } from 'react-icons/bi';

import { RootTheme } from '@theqube/theme';
import { FormField, FormFieldProps } from '../helpers';

export type FileInputProps<
  FormData extends FieldValues = FieldValues,
  Name extends FieldPath<FormData> = Path<UnPackAsyncDefaultValues<FormData>>
> = {
  id?: string;
  placeholder?: string;
  dropzone?: DropzoneOptions;
  form: {
    field: ControllerRenderProps<FormData, Name>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<FormData>;
  };
};

export type FileFieldProps = {
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  dropzone?: DropzoneOptions;
} & Partial<FormFieldProps>;

const previewStyle: ChakraProps = {
  maxWidth: '48',
  maxHeight: '48',
  objectFit: 'contain',
  objectPosition: 'center',
  margin: '4',
};

/**
 * When a input has multiple accepted file types and is display: none or visibility: hidden,
 * the browser (chrome specifically) will not show the file choose dialog.
 *
 * These styles prevent that while also not "showing" the raw file input in favor of the UI built;
 */
const hiddenInputStyle = {
  opacity: 0,
  display: 'block',
  flexBasis: '100%',
  visibility: 'initial',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
} as const;

const placeholderIconStyle = {
  flexBasis: '100%',
  color: RootTheme.colors.themeGold[500],
  height: 100,
  width: 100,
};
export function FileInput<
  FormData extends FieldValues = FieldValues,
  Name extends FieldPath<FormData> = Path<UnPackAsyncDefaultValues<FormData>>
>(props: FileInputProps<FormData, Name>) {
  const {
    id,
    placeholder,
    form: { field },
    dropzone: dropzoneProps,
  } = props;

  const toast = useToast();
  const [previews, setPreviews] = React.useState<
    (File & { preview: string })[]
  >(() => {
    if (Array.isArray(field.value)) {
      return field.value.map((file: File) => ({
        ...file,
        preview: URL.createObjectURL(file),
      }));
    }

    return [];
  });

  const onDropAccepted = React.useCallback(
    (acceptedFiles: File[], e: DropEvent) => {
      acceptedFiles.forEach((file) => {
        const title = `${file.name} has been attached.`;

        toast({ status: 'success', title, duration: 5000 });
      });

      setPreviews(
        acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      );

      dropzoneProps?.onDropAccepted?.(acceptedFiles, e);
      field.onChange(acceptedFiles);
    },
    [toast, field, dropzoneProps]
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      ...dropzoneProps,
      onDropAccepted: onDropAccepted,
    });

  React.useEffect(() => {
    return () => previews.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [previews]);

  const renderPreview = (
    file: File & { preview: string }
  ): JSX.Element | undefined => {
    const onLoad = () => {
      URL.revokeObjectURL(file.preview);
    };

    if (file.type.startsWith('image/')) {
      return (
        <Image
          src={file.preview}
          key={file.name}
          alt="Preview"
          onLoad={onLoad}
          {...previewStyle}
        />
      );
    } else if (file.type.startsWith('audio/')) {
      return (
        <chakra.audio
          controls
          src={file.preview}
          key={file.name}
          onLoad={onLoad}
          {...previewStyle}
        />
      );
    } else if (file.type.startsWith('video/')) {
      return (
        <chakra.video
          controls
          src={file.preview}
          key={file.name}
          onLoad={onLoad}
          {...previewStyle}
        />
      );
    }

    return undefined;
  };

  return (
    <>
      <Flex
        position="relative"
        flexWrap="wrap"
        justify="center"
        align="center"
        textAlign="center"
        minWidth={250}
        minHeight={150}
        mx="auto"
        borderRadius={5}
        cursor="pointer"
        {...getRootProps()}
      >
        <input id={id} {...getInputProps({ style: hiddenInputStyle })} />

        {previews.length > 0 ? (
          previews.map(renderPreview)
        ) : (
          <>
            <BiCloudUpload style={placeholderIconStyle} />

            {isDragActive ? (
              <Text>Drop the files here ...</Text>
            ) : (
              <Text>{placeholder}</Text>
            )}
          </>
        )}
      </Flex>

      {fileRejections.length > 0 ? (
        <List
          as={VStack}
          flex="1"
          flexBasis="100%"
          alignItems="flex-start"
          justifyContent="center"
          padding="2"
          mx="auto"
        >
          <ListItem>
            <Text>Please check your files for error.</Text>
          </ListItem>
          {/* {fileRejections.map(({ file, errors }) => {
            const description = errors.map((error) => error.message).join('\n');

            return (
              <ListItem key={file.name}>
                <Text>
                  <chakra.strong color="error">{file.name}</chakra.strong>
                  <br />
                  {description}
                </Text>
              </ListItem>
            );
          })} */}
        </List>
      ) : null}
    </>
  );
}
export function FileField(props: FileFieldProps) {
  const {
    dropzone,
    name = 'file',
    label = 'Upload a file',
    id = 'file',
    placeholder = "Drag 'n' drop some files here, or click to select files",
    ...fieldProps
  } = props;

  return (
    <FormField
      {...fieldProps}
      name={name}
      label={label}
      render={(form) => (
        <FileInput
          form={form}
          id={id}
          dropzone={dropzone}
          placeholder={placeholder}
        />
      )}
    />
  );
}
