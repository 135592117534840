import { z } from 'zod';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.FileUncheckedCreateInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    name: z.string(),
    type: z.string().optional().nullable(),
    size: z.number().optional().nullable(),
    provider: z.string().optional().nullable(),
    path: z.string().optional().nullable(),
    url: z.string().optional().nullable(),
  })
  .strict();

export const FileUncheckedCreateInputObjectSchema = Schema;
