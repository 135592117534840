import { z } from 'zod';
import { PodcastCreateNestedOneWithoutEpisodesInputObjectSchema } from './PodcastCreateNestedOneWithoutEpisodesInput.schema';
import { PlaylistCreateNestedManyWithoutEpisodesInputObjectSchema } from './PlaylistCreateNestedManyWithoutEpisodesInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.EpisodeCreateInput> = z
  .object({
    updatedAt: z.date().optional(),
    publishedAt: z.date().optional(),
    podcast: z.lazy(
      () => PodcastCreateNestedOneWithoutEpisodesInputObjectSchema
    ),
    playlists: z
      .lazy(() => PlaylistCreateNestedManyWithoutEpisodesInputObjectSchema)
      .optional(),
    title: z.string(),
    description: z.string().optional().nullable(),
    imageUrl: z.string().optional().nullable(),
    audioUrl: z.string(),
    duration: z.number().optional(),
    explicit: z.boolean().optional(),
  })
  .strict();

export const EpisodeCreateInputObjectSchema = Schema;
