import { StyleConfig } from '@chakra-ui/theme-tools';

export const Button: StyleConfig = {
  defaultProps: {
    colorScheme: 'themeGold',
  },

  baseStyle: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    fontSize: 'sm',
    lineHeight: 'sm',
    letterSpacing: 'wide',
    rounded: 'full',
    borderRadius: 'md',
    cursor: 'pointer',
    backgroundColor: 'themeGold.500',
    px: '8',

    transition: 'all 0.2s',

    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },

  variants: {
    solid: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
      rounded: 'full',
      bg: 'themeGold.500',
      color: 'white',
      px: 6,
      py: 2,
    },
  },
};
