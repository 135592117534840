import * as z from "zod"
import { CompleteUser, RelatedUserSchema } from "./index"

export const SettingsSchema = z.object({
  ownerId: z.string(),
  onboardedAt: z.date().nullish(),
  acceptedTermsAt: z.date().nullish(),
  acceptedPrivacyAt: z.date().nullish(),
  acceptedNewsletterAt: z.date().nullish(),
})

export interface CompleteSettings extends z.infer<typeof SettingsSchema> {
  owner: CompleteUser
}

/**
 * RelatedSettingsSchema contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedSettingsSchema: z.ZodSchema<CompleteSettings> = z.lazy(() => SettingsSchema.extend({
  owner: RelatedUserSchema,
}))
