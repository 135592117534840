import { z } from 'zod';
import { CurationWhereInputObjectSchema } from './objects/CurationWhereInput.schema';
import { CurationOrderByWithRelationInputObjectSchema } from './objects/CurationOrderByWithRelationInput.schema';
import { CurationWhereUniqueInputObjectSchema } from './objects/CurationWhereUniqueInput.schema';
import { CurationCountAggregateInputObjectSchema } from './objects/CurationCountAggregateInput.schema';
import { CurationMinAggregateInputObjectSchema } from './objects/CurationMinAggregateInput.schema';
import { CurationMaxAggregateInputObjectSchema } from './objects/CurationMaxAggregateInput.schema';
import { CurationAvgAggregateInputObjectSchema } from './objects/CurationAvgAggregateInput.schema';
import { CurationSumAggregateInputObjectSchema } from './objects/CurationSumAggregateInput.schema';

export const CurationAggregateSchema = z.object({
  where: CurationWhereInputObjectSchema.optional(),
  orderBy: z
    .union([
      CurationOrderByWithRelationInputObjectSchema,
      CurationOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  cursor: CurationWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), CurationCountAggregateInputObjectSchema])
    .optional(),
  _min: CurationMinAggregateInputObjectSchema.optional(),
  _max: CurationMaxAggregateInputObjectSchema.optional(),
  _avg: CurationAvgAggregateInputObjectSchema.optional(),
  _sum: CurationSumAggregateInputObjectSchema.optional(),
});
