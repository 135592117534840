import { z } from 'zod';
import { PodcastUpdateWithoutEpisodesInputObjectSchema } from './PodcastUpdateWithoutEpisodesInput.schema';
import { PodcastUncheckedUpdateWithoutEpisodesInputObjectSchema } from './PodcastUncheckedUpdateWithoutEpisodesInput.schema';
import { PodcastCreateWithoutEpisodesInputObjectSchema } from './PodcastCreateWithoutEpisodesInput.schema';
import { PodcastUncheckedCreateWithoutEpisodesInputObjectSchema } from './PodcastUncheckedCreateWithoutEpisodesInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastUpsertWithoutEpisodesInput> = z
  .object({
    update: z.union([
      z.lazy(() => PodcastUpdateWithoutEpisodesInputObjectSchema),
      z.lazy(() => PodcastUncheckedUpdateWithoutEpisodesInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => PodcastCreateWithoutEpisodesInputObjectSchema),
      z.lazy(() => PodcastUncheckedCreateWithoutEpisodesInputObjectSchema),
    ]),
  })
  .strict();

export const PodcastUpsertWithoutEpisodesInputObjectSchema = Schema;
