import { z } from 'zod';
import { PodcastCreateWithoutCuratorsInputObjectSchema } from './PodcastCreateWithoutCuratorsInput.schema';
import { PodcastUncheckedCreateWithoutCuratorsInputObjectSchema } from './PodcastUncheckedCreateWithoutCuratorsInput.schema';
import { PodcastCreateOrConnectWithoutCuratorsInputObjectSchema } from './PodcastCreateOrConnectWithoutCuratorsInput.schema';
import { PodcastUpsertWithWhereUniqueWithoutCuratorsInputObjectSchema } from './PodcastUpsertWithWhereUniqueWithoutCuratorsInput.schema';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';
import { PodcastUpdateWithWhereUniqueWithoutCuratorsInputObjectSchema } from './PodcastUpdateWithWhereUniqueWithoutCuratorsInput.schema';
import { PodcastUpdateManyWithWhereWithoutCuratorsInputObjectSchema } from './PodcastUpdateManyWithWhereWithoutCuratorsInput.schema';
import { PodcastScalarWhereInputObjectSchema } from './PodcastScalarWhereInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastUpdateManyWithoutCuratorsNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => PodcastCreateWithoutCuratorsInputObjectSchema),
            z.lazy(() => PodcastCreateWithoutCuratorsInputObjectSchema).array(),
            z.lazy(
              () => PodcastUncheckedCreateWithoutCuratorsInputObjectSchema
            ),
            z
              .lazy(
                () => PodcastUncheckedCreateWithoutCuratorsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(
              () => PodcastCreateOrConnectWithoutCuratorsInputObjectSchema
            ),
            z
              .lazy(
                () => PodcastCreateOrConnectWithoutCuratorsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .union([
            z.lazy(
              () => PodcastUpsertWithWhereUniqueWithoutCuratorsInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  PodcastUpsertWithWhereUniqueWithoutCuratorsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        set: z
          .union([
            z.lazy(() => PodcastWhereUniqueInputObjectSchema),
            z.lazy(() => PodcastWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        disconnect: z
          .union([
            z.lazy(() => PodcastWhereUniqueInputObjectSchema),
            z.lazy(() => PodcastWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        delete: z
          .union([
            z.lazy(() => PodcastWhereUniqueInputObjectSchema),
            z.lazy(() => PodcastWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => PodcastWhereUniqueInputObjectSchema),
            z.lazy(() => PodcastWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(
              () => PodcastUpdateWithWhereUniqueWithoutCuratorsInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  PodcastUpdateWithWhereUniqueWithoutCuratorsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        updateMany: z
          .union([
            z.lazy(
              () => PodcastUpdateManyWithWhereWithoutCuratorsInputObjectSchema
            ),
            z
              .lazy(
                () => PodcastUpdateManyWithWhereWithoutCuratorsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        deleteMany: z
          .union([
            z.lazy(() => PodcastScalarWhereInputObjectSchema),
            z.lazy(() => PodcastScalarWhereInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const PodcastUpdateManyWithoutCuratorsNestedInputObjectSchema = Schema;
