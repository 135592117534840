import { List, ListItem, Skeleton } from '@chakra-ui/react';
import { TRPCUseQueryBaseOptions } from '@trpc/react-query/shared';

import { TagFindManySchema } from '@theqube/schemas';
import { Link } from '../../components';
import { z } from 'zod';

import { StudioRoutes, trpc } from '../../utils';

export type TagListProps = {
  query: z.infer<typeof TagFindManySchema>;
  options?: TRPCUseQueryBaseOptions;
};

export function TagList({ query, options }: TagListProps) {
  const { data: tags, isSuccess } = trpc.tags.findMany.useQuery(query, options);

  return (
    <List
      as={Skeleton}
      isLoaded={isSuccess}
      display="flex"
      flexWrap="wrap"
      color="gray.700"
      mb="8"
    >
      {tags?.map((tag) => (
        <ListItem
          as={Link}
          href={
            StudioRoutes.discover.children?.tags.children?.id.href(
              String(tag.id)
            ) ?? ''
          }
          key={tag.id}
          ml="0"
          mr="2"
        >
          {tag.name}
        </ListItem>
      )) ?? null}
    </List>
  );
}
