import { z } from 'zod';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.SettingsUncheckedCreateInput> = z
  .object({
    ownerId: z.string(),
    onboardedAt: z.date().optional().nullable(),
    acceptedTermsAt: z.date().optional().nullable(),
    acceptedPrivacyAt: z.date().optional().nullable(),
    acceptedNewsletterAt: z.date().optional().nullable(),
  })
  .strict();

export const SettingsUncheckedCreateInputObjectSchema = Schema;
