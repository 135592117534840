import { z } from 'zod';

export const UserScalarFieldEnumSchema = z.enum([
  'id',
  'avatarUrl',
  'displayName',
  'fullName',
  'email',
  'emailVerified',
  'passwordHash',
  'createdAt',
  'updatedAt',
  'biography',
  'birthday',
  'gender',
  'socialMedia',
]);
