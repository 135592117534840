import { z } from 'zod';
import { PodcastCreateWithoutRssFeedInputObjectSchema } from './PodcastCreateWithoutRssFeedInput.schema';
import { PodcastUncheckedCreateWithoutRssFeedInputObjectSchema } from './PodcastUncheckedCreateWithoutRssFeedInput.schema';
import { PodcastCreateOrConnectWithoutRssFeedInputObjectSchema } from './PodcastCreateOrConnectWithoutRssFeedInput.schema';
import { PodcastWhereUniqueInputObjectSchema } from './PodcastWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PodcastCreateNestedOneWithoutRssFeedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => PodcastCreateWithoutRssFeedInputObjectSchema),
            z.lazy(() => PodcastUncheckedCreateWithoutRssFeedInputObjectSchema),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .lazy(() => PodcastCreateOrConnectWithoutRssFeedInputObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z.lazy(() => PodcastWhereUniqueInputObjectSchema).optional(),
      })
      .strict(),
  ]);

export const PodcastCreateNestedOneWithoutRssFeedInputObjectSchema = Schema;
