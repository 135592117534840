import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';

import { Link, UserAvatar } from '../../../../components';
import { useSession } from '../../../../features';
import { StudioRoutes } from '../../../../utils/route-map';

export function AuthMenu() {
  const [{ data: session }, { logout }] = useSession();

  return (
    <Flex alignItems="center">
      <UserAvatar
        name={session?.user.displayName}
        src={session?.user.avatarUrl}
      />

      <Menu>
        <MenuButton
          as={Button}
          bg="transparent"
          variant="link"
          cursor="pointer"
          size="xs"
          rightIcon={<ChevronDownIcon />}
        >
          Account
        </MenuButton>

        <MenuList padding="0">
          <MenuGroup color="white">
            <Link href={StudioRoutes['home'].href}>
              <MenuItem>{StudioRoutes['home'].label}</MenuItem>
            </Link>
          </MenuGroup>

          <MenuGroup color="white">
            <Link href={StudioRoutes.account.href}>
              <MenuItem>{StudioRoutes.account.label}</MenuItem>
            </Link>
          </MenuGroup>

          <MenuGroup color="white">
            <Link href={StudioRoutes['discover'].href}>
              <MenuItem>{StudioRoutes['discover'].label}</MenuItem>
            </Link>
          </MenuGroup>

          <MenuItem onClick={() => logout()} bg="warning">
            Sign Out
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
}

export function AuthList() {
  return (
    <>
      <Link href={StudioRoutes['auth'].children?.login.href ?? '/'} passHref>
        <Button as="span" fontSize="sm" fontWeight={600} bg="transparent">
          Log In
        </Button>
      </Link>

      <Link href={StudioRoutes['auth'].children?.register.href ?? '/'} passHref>
        <Button
          as="span"
          fontSize="sm"
          fontWeight={600}
          bg="transparent"
          border="4px solid white"
        >
          Sign Up
        </Button>
      </Link>
    </>
  );
}
