import { z } from 'zod';
import { EpisodeCreateWithoutPodcastInputObjectSchema } from './EpisodeCreateWithoutPodcastInput.schema';
import { EpisodeUncheckedCreateWithoutPodcastInputObjectSchema } from './EpisodeUncheckedCreateWithoutPodcastInput.schema';
import { EpisodeCreateOrConnectWithoutPodcastInputObjectSchema } from './EpisodeCreateOrConnectWithoutPodcastInput.schema';
import { EpisodeCreateManyPodcastInputEnvelopeObjectSchema } from './EpisodeCreateManyPodcastInputEnvelope.schema';
import { EpisodeWhereUniqueInputObjectSchema } from './EpisodeWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.EpisodeCreateNestedManyWithoutPodcastInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => EpisodeCreateWithoutPodcastInputObjectSchema),
            z.lazy(() => EpisodeCreateWithoutPodcastInputObjectSchema).array(),
            z.lazy(() => EpisodeUncheckedCreateWithoutPodcastInputObjectSchema),
            z
              .lazy(() => EpisodeUncheckedCreateWithoutPodcastInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(() => EpisodeCreateOrConnectWithoutPodcastInputObjectSchema),
            z
              .lazy(() => EpisodeCreateOrConnectWithoutPodcastInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        createMany: z
          .lazy(() => EpisodeCreateManyPodcastInputEnvelopeObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema),
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const EpisodeCreateNestedManyWithoutPodcastInputObjectSchema = Schema;
