import { z } from 'zod';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserCreateWithoutPlaylistSubscriptionsInputObjectSchema } from './UserCreateWithoutPlaylistSubscriptionsInput.schema';
import { UserUncheckedCreateWithoutPlaylistSubscriptionsInputObjectSchema } from './UserUncheckedCreateWithoutPlaylistSubscriptionsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserCreateOrConnectWithoutPlaylistSubscriptionsInput> =
  z
    .object({
      where: z.lazy(() => UserWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => UserCreateWithoutPlaylistSubscriptionsInputObjectSchema),
        z.lazy(
          () => UserUncheckedCreateWithoutPlaylistSubscriptionsInputObjectSchema
        ),
      ]),
    })
    .strict();

export const UserCreateOrConnectWithoutPlaylistSubscriptionsInputObjectSchema =
  Schema;
