import { z } from 'zod';
import { CurationCreateWithoutCuratorInputObjectSchema } from './CurationCreateWithoutCuratorInput.schema';
import { CurationUncheckedCreateWithoutCuratorInputObjectSchema } from './CurationUncheckedCreateWithoutCuratorInput.schema';
import { CurationCreateOrConnectWithoutCuratorInputObjectSchema } from './CurationCreateOrConnectWithoutCuratorInput.schema';
import { CurationUpsertWithWhereUniqueWithoutCuratorInputObjectSchema } from './CurationUpsertWithWhereUniqueWithoutCuratorInput.schema';
import { CurationCreateManyCuratorInputEnvelopeObjectSchema } from './CurationCreateManyCuratorInputEnvelope.schema';
import { CurationWhereUniqueInputObjectSchema } from './CurationWhereUniqueInput.schema';
import { CurationUpdateWithWhereUniqueWithoutCuratorInputObjectSchema } from './CurationUpdateWithWhereUniqueWithoutCuratorInput.schema';
import { CurationUpdateManyWithWhereWithoutCuratorInputObjectSchema } from './CurationUpdateManyWithWhereWithoutCuratorInput.schema';
import { CurationScalarWhereInputObjectSchema } from './CurationScalarWhereInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.CurationUncheckedUpdateManyWithoutCuratorNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => CurationCreateWithoutCuratorInputObjectSchema),
            z.lazy(() => CurationCreateWithoutCuratorInputObjectSchema).array(),
            z.lazy(
              () => CurationUncheckedCreateWithoutCuratorInputObjectSchema
            ),
            z
              .lazy(
                () => CurationUncheckedCreateWithoutCuratorInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(
              () => CurationCreateOrConnectWithoutCuratorInputObjectSchema
            ),
            z
              .lazy(
                () => CurationCreateOrConnectWithoutCuratorInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .union([
            z.lazy(
              () => CurationUpsertWithWhereUniqueWithoutCuratorInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  CurationUpsertWithWhereUniqueWithoutCuratorInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        createMany: z
          .lazy(() => CurationCreateManyCuratorInputEnvelopeObjectSchema)
          .optional(),
      })
      .strict(),
    z
      .object({
        set: z
          .union([
            z.lazy(() => CurationWhereUniqueInputObjectSchema),
            z.lazy(() => CurationWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        disconnect: z
          .union([
            z.lazy(() => CurationWhereUniqueInputObjectSchema),
            z.lazy(() => CurationWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        delete: z
          .union([
            z.lazy(() => CurationWhereUniqueInputObjectSchema),
            z.lazy(() => CurationWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => CurationWhereUniqueInputObjectSchema),
            z.lazy(() => CurationWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(
              () => CurationUpdateWithWhereUniqueWithoutCuratorInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  CurationUpdateWithWhereUniqueWithoutCuratorInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        updateMany: z
          .union([
            z.lazy(
              () => CurationUpdateManyWithWhereWithoutCuratorInputObjectSchema
            ),
            z
              .lazy(
                () => CurationUpdateManyWithWhereWithoutCuratorInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        deleteMany: z
          .union([
            z.lazy(() => CurationScalarWhereInputObjectSchema),
            z.lazy(() => CurationScalarWhereInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const CurationUncheckedUpdateManyWithoutCuratorNestedInputObjectSchema =
  Schema;
