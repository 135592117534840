import * as React from 'react';

import {
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  Flex,
  Heading,
  Image,
  Stack,
  StackProps,
  Text,
  TextProps,
} from '@chakra-ui/react';

export type GridItemProps = GridContentProps &
  GridImageProps &
  GridActionsProps &
  GridItemTitleProps &
  GridDescriptionProps & {
    /** Swap the default order of image and content */
    alternate?: boolean;
  };

export type GridContentProps = {
  /** Control the content container of the the item */
  contentProps?: StackProps;
};

export type GridItemTitleProps =
  | {
      /** Title of the item, also used as id in {@link LandingGrid} */
      title: string;
      titleProps?: TextProps;

      /** Not used if title is supplied */
      id?: never;
    }
  | {
      /** If no title or title is comp, an Id must but be supplied, this is for mapping in the {@link LandingGrid} */
      id: string;
      title?: never;
      titleProps?: string;
    };

export type GridDescriptionProps =
  | {
      /** Description of the item */
      description?: string;
      /** Control the props for the description container */
      descriptionProps?: TextProps;
    }
  | {
      /** Description of the item */
      description?: JSX.Element;
      /** Control the props for the description container */
      descriptionProps?: TextProps;
    }
  | { description?: never; descriptionProps?: never };

export type GridImageProps =
  | {
      /** Add an {@link Image} component to the other half of the screen */
      image: { url: string; alt: string };
      /** Control the container for the im GridImage */
      imageContainer?: BoxProps;
      bg?: string;
    }
  | {
      /** Add any React Component to the other half of the screen */
      image: JSX.Element;
      /** Control the container for the im GridImage */
      imageContainer?: BoxProps;

      bg?: string;
    }
  | { image?: never; imageContainer?: never; bg: string }
  | { image?: never; imageContainer?: never; bg: never };

export type GridActionsProps =
  | {
      /** Add buttons to the area under the description */
      actions: (ButtonProps & { text: string })[];
      /** Control the container for actions */
      actionsGroupProps?: ButtonGroupProps;
    }
  | {
      /** Add buttons to the area under the description */
      actions: JSX.Element;
      /** Control the container for actions */
      actionsGroupProps?: never;
    }
  | { actions?: never; actionsGroupProps?: never };

export type LandingGridProps = {
  /** List of items to render */
  items: GridItemProps[];

  /**
   * Optional render fn to override the default order of the grid item.
   * @default item => if true, content left and image right
   */
  alternateFn?: (item: GridItemProps, idx: number) => boolean;
};

export function LandingGridItem({
  contentProps,
  title,
  titleProps,
  description,
  descriptionProps,
  actions,
  actionsGroupProps: actionsGroup,
  alternate,
  image,
  bg,
  imageContainer,
}: GridItemProps) {
  return (
    <Flex
      as="article"
      m={0}
      flex={1}
      alignItems="stretch"
      direction={{ base: 'column', md: 'row' }}
      width="85%"
    >
      <Stack
        p={20}
        flex={1}
        justifyContent="center"
        textAlign={{ base: 'center', md: 'initial' }}
        alignItems={{ base: 'center', md: 'initial' }}
        flexBasis={{ base: '100%', md: '50%' }}
        order={{ md: alternate ? 1 : 0 }}
        {...contentProps}
      >
        {typeof title === 'string' ? (
          <Heading
            as="h2"
            fontSize={{ base: '4xl', sm: '6xl' }}
            {...(titleProps && typeof titleProps == 'object' ? titleProps : {})}
          >
            {title}
          </Heading>
        ) : null}

        {/* eslint-disable-next-line no-nested-ternary */}
        {typeof description === 'string' ? (
          <Text maxW="60ch" fontSize="lg" mb="4" {...descriptionProps}>
            {description}
          </Text>
        ) : React.isValidElement(description) ? (
          description
        ) : null}

        {React.isValidElement(actions) ? actions : null}
        {Array.isArray(actions) ? (
          <ButtonGroup {...actionsGroup}>
            {actions?.map((action) => (
              <Button
                key={action.text}
                bg="themeBlue.500"
                w="100%"
                maxW="48"
                {...action}
              >
                {action.text}
              </Button>
            ))}
          </ButtonGroup>
        ) : null}
      </Stack>

      {image ? (
        <Box
          flex={1}
          flexBasis={{ base: '100%', md: '50%' }}
          bg="#DA2A8C"
          {...imageContainer}
        >
          {React.isValidElement(image) ? image : null}
          {'alt' in image ? (
            <Image
              alt={image.alt}
              src={image.url}
              objectFit="cover"
              objectPosition="center center"
            />
          ) : null}
        </Box>
      ) : null}

      {bg ? (
        <Box
          flex={1}
          flexBasis={{ base: '100%', md: '50%' }}
          flexGrow={1}
          bg={bg}
          minH="50vh"
          {...imageContainer}
        />
      ) : null}
    </Flex>
  );
}

/** Render list of items in a grid in a stacked (optionally alternating) grid */
export function LandingGrid({
  items,
  alternateFn = (_, index) => index % 2 === 0,
}: LandingGridProps) {
  return (
    <Stack as="section" spacing={0} align="center" justify="center" py="150">
      {items.map((item) => (
        <LandingGridItem
          key={typeof item.title === 'string' ? item.title : item.id}
          alternate={alternateFn(item, items.indexOf(item))}
          {...item}
        />
      ))}
    </Stack>
  );
}
