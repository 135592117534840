import { z } from 'zod';
import { EpisodeCreateWithoutPlaylistsInputObjectSchema } from './EpisodeCreateWithoutPlaylistsInput.schema';
import { EpisodeUncheckedCreateWithoutPlaylistsInputObjectSchema } from './EpisodeUncheckedCreateWithoutPlaylistsInput.schema';
import { EpisodeCreateOrConnectWithoutPlaylistsInputObjectSchema } from './EpisodeCreateOrConnectWithoutPlaylistsInput.schema';
import { EpisodeUpsertWithWhereUniqueWithoutPlaylistsInputObjectSchema } from './EpisodeUpsertWithWhereUniqueWithoutPlaylistsInput.schema';
import { EpisodeWhereUniqueInputObjectSchema } from './EpisodeWhereUniqueInput.schema';
import { EpisodeUpdateWithWhereUniqueWithoutPlaylistsInputObjectSchema } from './EpisodeUpdateWithWhereUniqueWithoutPlaylistsInput.schema';
import { EpisodeUpdateManyWithWhereWithoutPlaylistsInputObjectSchema } from './EpisodeUpdateManyWithWhereWithoutPlaylistsInput.schema';
import { EpisodeScalarWhereInputObjectSchema } from './EpisodeScalarWhereInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.EpisodeUncheckedUpdateManyWithoutPlaylistsNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => EpisodeCreateWithoutPlaylistsInputObjectSchema),
            z
              .lazy(() => EpisodeCreateWithoutPlaylistsInputObjectSchema)
              .array(),
            z.lazy(
              () => EpisodeUncheckedCreateWithoutPlaylistsInputObjectSchema
            ),
            z
              .lazy(
                () => EpisodeUncheckedCreateWithoutPlaylistsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(
              () => EpisodeCreateOrConnectWithoutPlaylistsInputObjectSchema
            ),
            z
              .lazy(
                () => EpisodeCreateOrConnectWithoutPlaylistsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .union([
            z.lazy(
              () =>
                EpisodeUpsertWithWhereUniqueWithoutPlaylistsInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  EpisodeUpsertWithWhereUniqueWithoutPlaylistsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        set: z
          .union([
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema),
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        disconnect: z
          .union([
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema),
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        delete: z
          .union([
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema),
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema),
            z.lazy(() => EpisodeWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(
              () =>
                EpisodeUpdateWithWhereUniqueWithoutPlaylistsInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  EpisodeUpdateWithWhereUniqueWithoutPlaylistsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        updateMany: z
          .union([
            z.lazy(
              () => EpisodeUpdateManyWithWhereWithoutPlaylistsInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  EpisodeUpdateManyWithWhereWithoutPlaylistsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        deleteMany: z
          .union([
            z.lazy(() => EpisodeScalarWhereInputObjectSchema),
            z.lazy(() => EpisodeScalarWhereInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const EpisodeUncheckedUpdateManyWithoutPlaylistsNestedInputObjectSchema =
  Schema;
