import { z } from 'zod';
import { SortOrderSchema } from '../enums/SortOrder.schema';
import { SettingsCountOrderByAggregateInputObjectSchema } from './SettingsCountOrderByAggregateInput.schema';
import { SettingsMaxOrderByAggregateInputObjectSchema } from './SettingsMaxOrderByAggregateInput.schema';
import { SettingsMinOrderByAggregateInputObjectSchema } from './SettingsMinOrderByAggregateInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.SettingsOrderByWithAggregationInput> = z
  .object({
    ownerId: z.lazy(() => SortOrderSchema).optional(),
    onboardedAt: z.lazy(() => SortOrderSchema).optional(),
    acceptedTermsAt: z.lazy(() => SortOrderSchema).optional(),
    acceptedPrivacyAt: z.lazy(() => SortOrderSchema).optional(),
    acceptedNewsletterAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => SettingsCountOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z.lazy(() => SettingsMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => SettingsMinOrderByAggregateInputObjectSchema).optional(),
  })
  .strict();

export const SettingsOrderByWithAggregationInputObjectSchema = Schema;
