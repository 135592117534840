import { useSession } from '../../../../features';
import { Link, LocalImage } from '../../../../components';
import { StudioRoutes } from '../../../../utils';

export function HeaderLogo() {
  const [{ data: session }] = useSession();

  const homeLink = (() => {
    if (session?.user) {
      return StudioRoutes['home'].href;
    }

    return 'https://theqube.app';
  })();

  return (
    <Link
      aria-label="Home"
      href={homeLink}
      passHref
      display="flex"
      alignItems="center"
      justifyContent="center"
      fontFamily="heading"
    >
      <LocalImage
        priority
        src="/images/logo.png"
        alt="Logo"
        height={8}
        width={8}
        objectFit="contain"
      />
    </Link>
  );
}
