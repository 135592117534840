import { Box, Heading, Text } from '@chakra-ui/react';

export type EmptyMessageProps = {
  title?: string | React.ReactElement;
  description?: string | React.ReactElement;
  action?: React.ReactNode;
};
export function EmptyMessage({
  title = "There don't seem to be any podcasts for this query",
  description,
  action,
}: EmptyMessageProps) {
  return (
    <Box m="auto" p="6" textAlign="center">
      {typeof title === 'string' ? (
        <Heading as="h3" fontSize="lg">
          {title}
        </Heading>
      ) : (
        title
      )}

      {typeof description === 'string' ? (
        <Text align="center" mt={5}>
          {description}
        </Text>
      ) : (
        description
      )}

      {action ?? null}
    </Box>
  );
}
