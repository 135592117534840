import { z } from 'zod';
import { SortOrderSchema } from '../enums/SortOrder.schema';
import { AccountOrderByRelationAggregateInputObjectSchema } from './AccountOrderByRelationAggregateInput.schema';
import { SessionOrderByRelationAggregateInputObjectSchema } from './SessionOrderByRelationAggregateInput.schema';
import { TagOrderByRelationAggregateInputObjectSchema } from './TagOrderByRelationAggregateInput.schema';
import { SettingsOrderByWithRelationInputObjectSchema } from './SettingsOrderByWithRelationInput.schema';
import { PermissionsOrderByWithRelationInputObjectSchema } from './PermissionsOrderByWithRelationInput.schema';
import { PlaylistOrderByRelationAggregateInputObjectSchema } from './PlaylistOrderByRelationAggregateInput.schema';
import { PodcastOrderByRelationAggregateInputObjectSchema } from './PodcastOrderByRelationAggregateInput.schema';
import { CurationOrderByRelationAggregateInputObjectSchema } from './CurationOrderByRelationAggregateInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    avatarUrl: z.lazy(() => SortOrderSchema).optional(),
    displayName: z.lazy(() => SortOrderSchema).optional(),
    fullName: z.lazy(() => SortOrderSchema).optional(),
    email: z.lazy(() => SortOrderSchema).optional(),
    emailVerified: z.lazy(() => SortOrderSchema).optional(),
    passwordHash: z.lazy(() => SortOrderSchema).optional(),
    accounts: z
      .lazy(() => AccountOrderByRelationAggregateInputObjectSchema)
      .optional(),
    sessions: z
      .lazy(() => SessionOrderByRelationAggregateInputObjectSchema)
      .optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    tags: z.lazy(() => TagOrderByRelationAggregateInputObjectSchema).optional(),
    settings: z
      .lazy(() => SettingsOrderByWithRelationInputObjectSchema)
      .optional(),
    permissions: z
      .lazy(() => PermissionsOrderByWithRelationInputObjectSchema)
      .optional(),
    playlists: z
      .lazy(() => PlaylistOrderByRelationAggregateInputObjectSchema)
      .optional(),
    playlistSubscriptions: z
      .lazy(() => PlaylistOrderByRelationAggregateInputObjectSchema)
      .optional(),
    podcastsAssigned: z
      .lazy(() => PodcastOrderByRelationAggregateInputObjectSchema)
      .optional(),
    podcastsCurated: z
      .lazy(() => CurationOrderByRelationAggregateInputObjectSchema)
      .optional(),
    podcasts: z
      .lazy(() => PodcastOrderByRelationAggregateInputObjectSchema)
      .optional(),
    podcastSubscriptions: z
      .lazy(() => PodcastOrderByRelationAggregateInputObjectSchema)
      .optional(),
    biography: z.lazy(() => SortOrderSchema).optional(),
    birthday: z.lazy(() => SortOrderSchema).optional(),
    gender: z.lazy(() => SortOrderSchema).optional(),
    socialMedia: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict();

export const UserOrderByWithRelationInputObjectSchema = Schema;
