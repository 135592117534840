import * as Sentry from '@sentry/nextjs';
import * as React from 'react';

/**
 *
 * @see https://usehooks.com/useLocalStorage/
 */
export function useLocalStorage<T>(key: string, initialValue?: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = React.useState<T | undefined>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(`lsid-${key}`);
      // Parse stored json or if none return initialValue/undefined
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  // Wrapped version of useState's setter function that persists the new value to localStorage.
  const setValue = (value: React.SetStateAction<typeof storedValue>) => {
    try {
      // Save state
      setStoredValue(valueToStore);
    } catch (error) {
      Sentry.captureException(error);
    }

    // Allow value to be a function `(prev: T) => T` so we have same API as useState
    function valueToStore(prev: typeof storedValue): typeof storedValue {
      let result: typeof storedValue = prev;

      if (value instanceof Function) {
        result = value(prev);
      } else {
        result = value;
      }

      try {
        // Save to local storage along the way
        window.localStorage.setItem(`lsid-${key}`, JSON.stringify(result));
      } catch (error) {
        Sentry.captureException(error);
      }

      return result;
    }
  };

  return [storedValue, setValue] as const;
}
