import { z } from 'zod';
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema';
import { IntFilterObjectSchema } from './IntFilter.schema';
import { PodcastRelationFilterObjectSchema } from './PodcastRelationFilter.schema';
import { PodcastWhereInputObjectSchema } from './PodcastWhereInput.schema';
import { StringFilterObjectSchema } from './StringFilter.schema';
import { JsonFilterObjectSchema } from './JsonFilter.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.RssFeedWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => RssFeedWhereInputObjectSchema),
        z.lazy(() => RssFeedWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => RssFeedWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => RssFeedWhereInputObjectSchema),
        z.lazy(() => RssFeedWhereInputObjectSchema).array(),
      ])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    podcastId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    podcast: z
      .union([
        z.lazy(() => PodcastRelationFilterObjectSchema),
        z.lazy(() => PodcastWhereInputObjectSchema),
      ])
      .optional(),
    url: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    data: z.lazy(() => JsonFilterObjectSchema).optional(),
  })
  .strict();

export const RssFeedWhereInputObjectSchema = Schema;
