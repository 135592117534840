import { z } from 'zod';
import { UserUncheckedCreateNestedManyWithoutTagsInputObjectSchema } from './UserUncheckedCreateNestedManyWithoutTagsInput.schema';
import { TagTypeSchema } from '../enums/TagType.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.TagUncheckedCreateWithoutPodcastsInput> = z
  .object({
    id: z.number().optional(),
    slug: z.string().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    publishedAt: z.date().optional().nullable(),
    avatarUrl: z.string().optional().nullable(),
    users: z
      .lazy(() => UserUncheckedCreateNestedManyWithoutTagsInputObjectSchema)
      .optional(),
    type: z.lazy(() => TagTypeSchema),
    name: z.string(),
  })
  .strict();

export const TagUncheckedCreateWithoutPodcastsInputObjectSchema = Schema;
