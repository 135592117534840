import { Select, SelectProps } from '@chakra-ui/react';

import { Gender } from '@theqube/schemas';
import { FormField, FormFieldProps } from '../helpers';

export type GenderSelectProps = Omit<FormFieldProps, 'render'> & {
  input?: SelectProps;
  option?: Omit<React.HTMLProps<HTMLOptionElement>, 'value'>;
};

export function GenderSelect(props: GenderSelectProps) {
  const { input, option, ...rest } = props;

  return (
    <FormField
      {...rest}
      render={({ field }) => (
        <Select
          {...field}
          {...input}
          focusBorderColor="gold.500"
          errorBorderColor="error"
        >
          {Object.values(Gender).map((value) => (
            <option key={value} label={value} {...option} value={value} />
          ))}
        </Select>
      )}
    />
  );
}
