import { z } from 'zod';
import { PlaylistCreateWithoutOwnersInputObjectSchema } from './PlaylistCreateWithoutOwnersInput.schema';
import { PlaylistUncheckedCreateWithoutOwnersInputObjectSchema } from './PlaylistUncheckedCreateWithoutOwnersInput.schema';
import { PlaylistCreateOrConnectWithoutOwnersInputObjectSchema } from './PlaylistCreateOrConnectWithoutOwnersInput.schema';
import { PlaylistWhereUniqueInputObjectSchema } from './PlaylistWhereUniqueInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistUncheckedCreateNestedManyWithoutOwnersInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(() => PlaylistCreateWithoutOwnersInputObjectSchema),
            z.lazy(() => PlaylistCreateWithoutOwnersInputObjectSchema).array(),
            z.lazy(() => PlaylistUncheckedCreateWithoutOwnersInputObjectSchema),
            z
              .lazy(() => PlaylistUncheckedCreateWithoutOwnersInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(() => PlaylistCreateOrConnectWithoutOwnersInputObjectSchema),
            z
              .lazy(() => PlaylistCreateOrConnectWithoutOwnersInputObjectSchema)
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema),
            z.lazy(() => PlaylistWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const PlaylistUncheckedCreateNestedManyWithoutOwnersInputObjectSchema =
  Schema;
