import { z } from 'zod';
import { UserCreateWithoutPodcastSubscriptionsInputObjectSchema } from './UserCreateWithoutPodcastSubscriptionsInput.schema';
import { UserUncheckedCreateWithoutPodcastSubscriptionsInputObjectSchema } from './UserUncheckedCreateWithoutPodcastSubscriptionsInput.schema';
import { UserCreateOrConnectWithoutPodcastSubscriptionsInputObjectSchema } from './UserCreateOrConnectWithoutPodcastSubscriptionsInput.schema';
import { UserUpsertWithWhereUniqueWithoutPodcastSubscriptionsInputObjectSchema } from './UserUpsertWithWhereUniqueWithoutPodcastSubscriptionsInput.schema';
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema';
import { UserUpdateWithWhereUniqueWithoutPodcastSubscriptionsInputObjectSchema } from './UserUpdateWithWhereUniqueWithoutPodcastSubscriptionsInput.schema';
import { UserUpdateManyWithWhereWithoutPodcastSubscriptionsInputObjectSchema } from './UserUpdateManyWithWhereWithoutPodcastSubscriptionsInput.schema';
import { UserScalarWhereInputObjectSchema } from './UserScalarWhereInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.UserUncheckedUpdateManyWithoutPodcastSubscriptionsNestedInput> =
  z.union([
    z
      .object({
        create: z
          .union([
            z.lazy(
              () => UserCreateWithoutPodcastSubscriptionsInputObjectSchema
            ),
            z
              .lazy(
                () => UserCreateWithoutPodcastSubscriptionsInputObjectSchema
              )
              .array(),
            z.lazy(
              () =>
                UserUncheckedCreateWithoutPodcastSubscriptionsInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  UserUncheckedCreateWithoutPodcastSubscriptionsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connectOrCreate: z
          .union([
            z.lazy(
              () =>
                UserCreateOrConnectWithoutPodcastSubscriptionsInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  UserCreateOrConnectWithoutPodcastSubscriptionsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        upsert: z
          .union([
            z.lazy(
              () =>
                UserUpsertWithWhereUniqueWithoutPodcastSubscriptionsInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  UserUpsertWithWhereUniqueWithoutPodcastSubscriptionsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        set: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        disconnect: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        delete: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        connect: z
          .union([
            z.lazy(() => UserWhereUniqueInputObjectSchema),
            z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        update: z
          .union([
            z.lazy(
              () =>
                UserUpdateWithWhereUniqueWithoutPodcastSubscriptionsInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  UserUpdateWithWhereUniqueWithoutPodcastSubscriptionsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        updateMany: z
          .union([
            z.lazy(
              () =>
                UserUpdateManyWithWhereWithoutPodcastSubscriptionsInputObjectSchema
            ),
            z
              .lazy(
                () =>
                  UserUpdateManyWithWhereWithoutPodcastSubscriptionsInputObjectSchema
              )
              .array(),
          ])
          .optional(),
      })
      .strict(),
    z
      .object({
        deleteMany: z
          .union([
            z.lazy(() => UserScalarWhereInputObjectSchema),
            z.lazy(() => UserScalarWhereInputObjectSchema).array(),
          ])
          .optional(),
      })
      .strict(),
  ]);

export const UserUncheckedUpdateManyWithoutPodcastSubscriptionsNestedInputObjectSchema =
  Schema;
