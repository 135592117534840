import { z } from 'zod';
import { RssFeedUncheckedCreateNestedOneWithoutPodcastInputObjectSchema } from './RssFeedUncheckedCreateNestedOneWithoutPodcastInput.schema';
import { UserUncheckedCreateNestedManyWithoutPodcastsInputObjectSchema } from './UserUncheckedCreateNestedManyWithoutPodcastsInput.schema';
import { EpisodeUncheckedCreateNestedManyWithoutPodcastInputObjectSchema } from './EpisodeUncheckedCreateNestedManyWithoutPodcastInput.schema';
import { UserUncheckedCreateNestedManyWithoutPodcastSubscriptionsInputObjectSchema } from './UserUncheckedCreateNestedManyWithoutPodcastSubscriptionsInput.schema';
import { UserUncheckedCreateNestedManyWithoutPodcastsAssignedInputObjectSchema } from './UserUncheckedCreateNestedManyWithoutPodcastsAssignedInput.schema';
import { CurationUncheckedCreateNestedManyWithoutPodcastInputObjectSchema } from './CurationUncheckedCreateNestedManyWithoutPodcastInput.schema';
import { PodcastStatusSchema } from '../enums/PodcastStatus.schema';
import { NullableJsonNullValueInputSchema } from '../enums/NullableJsonNullValueInput.schema';

import type { Prisma } from '@prisma/client';

const literalSchema = z.union([z.string(), z.number(), z.boolean()]);
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ])
);

const Schema: z.ZodType<Prisma.PodcastUncheckedCreateWithoutTagsInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    publishedAt: z.date().optional().nullable(),
    importedAt: z.date().optional().nullable(),
    rssFeed: z
      .lazy(
        () => RssFeedUncheckedCreateNestedOneWithoutPodcastInputObjectSchema
      )
      .optional(),
    owners: z
      .lazy(() => UserUncheckedCreateNestedManyWithoutPodcastsInputObjectSchema)
      .optional(),
    episodes: z
      .lazy(
        () => EpisodeUncheckedCreateNestedManyWithoutPodcastInputObjectSchema
      )
      .optional(),
    subscribers: z
      .lazy(
        () =>
          UserUncheckedCreateNestedManyWithoutPodcastSubscriptionsInputObjectSchema
      )
      .optional(),
    curators: z
      .lazy(
        () =>
          UserUncheckedCreateNestedManyWithoutPodcastsAssignedInputObjectSchema
      )
      .optional(),
    curations: z
      .lazy(
        () => CurationUncheckedCreateNestedManyWithoutPodcastInputObjectSchema
      )
      .optional(),
    status: z.lazy(() => PodcastStatusSchema),
    statusReason: z.string().optional().nullable(),
    avatarUrl: z.string().optional().nullable(),
    isOriginal: z.boolean().optional(),
    isExplicit: z.boolean(),
    title: z.string(),
    description: z.string().optional().nullable(),
    websiteUrl: z.string().optional().nullable(),
    socialMedia: z
      .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
      .optional(),
  })
  .strict();

export const PodcastUncheckedCreateWithoutTagsInputObjectSchema = Schema;
