import { Box, Center, Flex, HStack, Image, Text } from '@chakra-ui/react';

import { PageHeading } from '../components';

export type QubePremiumProps = {
  heading: string | React.ReactNode;
  description?: string;
  image: string;
  actions?: React.ReactNode;
};

export function QubePremium({
  heading,
  description,
  image,
  actions,
}: QubePremiumProps) {
  return (
    <HStack width="70%" direction="column" my="10em" mx="auto" spacing={1}>
      <Box width="50%">
        <Center>
          <Image
            width="500px"
            height="500px"
            src={image}
            alt="Download the Qube"
            borderRadius={20}
          />
        </Center>
      </Box>
      <Flex
        flexFlow={'row wrap'}
        width="50%"
        height="500px"
        alignContent="center"
        alignItems={'center'}
        p={5}
      >
        <PageHeading fontSize={'6xl'} mb={10}>
          {heading}
        </PageHeading>
        <Text fontSize={'2xl'} mb={10}>
          {description}
        </Text>
        {actions}
      </Flex>
    </HStack>
  );
}
