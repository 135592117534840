import { z } from 'zod';
import { PlaylistScalarWhereInputObjectSchema } from './PlaylistScalarWhereInput.schema';
import { PlaylistUpdateManyMutationInputObjectSchema } from './PlaylistUpdateManyMutationInput.schema';
import { PlaylistUncheckedUpdateManyWithoutPlaylistsInputObjectSchema } from './PlaylistUncheckedUpdateManyWithoutPlaylistsInput.schema';

import type { Prisma } from '@prisma/client';

const Schema: z.ZodType<Prisma.PlaylistUpdateManyWithWhereWithoutEpisodesInput> =
  z
    .object({
      where: z.lazy(() => PlaylistScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => PlaylistUpdateManyMutationInputObjectSchema),
        z.lazy(
          () => PlaylistUncheckedUpdateManyWithoutPlaylistsInputObjectSchema
        ),
      ]),
    })
    .strict();

export const PlaylistUpdateManyWithWhereWithoutEpisodesInputObjectSchema =
  Schema;
